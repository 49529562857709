import { StripeElementsComponent } from '../stripe-elements.component';
import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { PaymentCard } from '../../models/payments.model';
import { UserService } from '../../services/user.service';
import { IntentResponse, StripeService } from "../../services/stripe.service";

export interface CardSelectionChange {
    type: 'saved-card' | 'new-card';
    isValid: boolean;
    card?: PaymentCard;
}


@Component({
    selector: 'cp-payment-element',
    templateUrl: './payment-element.component.html',
    styleUrls: [],
})
export class PaymentElementComponent implements OnInit, OnChanges {

    @Input() public paymentMethod: 'card' | 'sepa';
    @Input() public user: UserModel;
    @Input() public paymentCards: PaymentCard[];
    @Input() public showFirstLabel: boolean = true;
    @Input() public light: boolean = false;
    @Input() public intentClientSecret: string;
    @Input() public intentClientSecretType: 'paymentIntent' | 'setupIntent';

    // tslint:disable:no-output-on-prefix
    @Output() public canSubmit = new EventEmitter<boolean>();

    @ViewChild('stripeComponent') stripeComponent: StripeElementsComponent;

    public selectedCard: PaymentCard;
    private isLoading: boolean;
    public hasCards: boolean;
    public hasOtherCards: boolean;
    public showOtherCards: boolean;
    public useSavedCard: boolean;

    public darkCardElementStyle = {
        base: {
            backgroundColor: 'transparent',
            color: '#fff',
            fontFamily: "'Roboto',  sans-serif",
            fontSize: '17px',
            fontWeight: 'normal',
            iconColor: '#fff',
            lineHeight: 1.5,
            letterSpacing: 'normal',

            '::placeholder': {
                color: '#ffffffaa',
            },
            ':-webkit-autofill': {
                color: '#000'
            }
        },
        invalid: {
            iconColor: '#ff2828',
            color: '#ff2828'
        }

    };

    constructor(public userService: UserService, private readonly stripeService: StripeService) {
    }

    ngOnInit(): void {
        if (this.isCardPayment() && this.paymentCards && this.paymentCards.length > 0) {
            this.hasCards = true;
            this.hasOtherCards = this.paymentCards.length > 1;
            this.useSavedCard = true;
            this._preselectDefaultCard();
        } else {
            this.hasCards = false;
            this.useSavedCard =false;
        }
    }


    isCardPayment() {return  this.paymentMethod === 'card'; }
    isSepaPayment() {return  this.paymentMethod === 'sepa'; }

    //-----

    _preselectDefaultCard() {
        const defaultCardList = this.paymentCards.filter(card => card.default);
        if (defaultCardList.length > 0) {
            this.selectCard(defaultCardList[0]);
        } else {
            this.selectCard(this.paymentCards[0]);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.paymentCards) {
            if (this.selectedCard) {
                const found = this.paymentCards.filter(card => card.id === this.selectedCard.id);
                if (found.length === 0) {
                    this.selectedCard = undefined;
                    this.emitValidity()
                }
            }
            this._preselectDefaultCard();
        }
    }

    setUseSavedCard(val: boolean){
        this.useSavedCard = val && this.hasCards;
        this.emitValidity();
    }

    emitValidity() {
        const isValid = this.useSavedCard ? !!this.selectedCard : (this.stripeComponent?.isValid);
        this.canSubmit.emit(isValid);
    }

    public async confirmIntent(): Promise<IntentResponse> {
        if (!this.isLoading) {
            this.isLoading = true;

            let result: IntentResponse;
            try {
                if (this.useSavedCard) {
                    if (this.intentClientSecretType == 'paymentIntent') {
                        result = await this.stripeService.confirmCardPaymentIntentFromId(this.selectedCard.src, this.intentClientSecret);
                    } else if (this.intentClientSecretType == 'setupIntent') {
                        result = await this.stripeService.confirmCardSetupIntentFromId(this.selectedCard.src, this.intentClientSecret);
                    }
                } else {
                    result = await this.stripeComponent.confirmIntent();
                }
            } catch (ex: any) {
                console.debug("Obtained error in payment-element.component, rethrowing");
                this.isLoading = false;
                throw ex;
            }
            this.isLoading = false;
            return result;
        }
    }

    selectCard(card: PaymentCard) {
        if (!this.useSavedCard) return;
        this.selectedCard = card;
        this.showOtherCards = false;
        this.emitValidity();
    }
}
