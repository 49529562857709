import {environment} from '../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

export abstract class AbstractAuthorizationService {

  protected constructor(
    router: Router,
  ) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        // console.log(event.url, `/${environment.default_roots.not_logged}`, event.url === `/${environment.default_roots.not_logged}`);
        if (event.url === `/${environment.default_roots.not_logged}`) {
          this.clear();
        }
      });
  }

  abstract clear();
}
