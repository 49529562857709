<section class="card bg-primary border-0 rounded text-white position-relative mx-n2">
    <div class="card-body p-md-3 p-lg-4">
        <div class="mb-2">
            <h4 class="text-secondary text-center">FATTURE</h4>
        </div>

        <dl class="row">
            <dt class="col-5">Prossima:</dt>
            <dd *ngIf="upcomingInvoiceLoading" class="col-7 col-sm-5 ml-auto">Caricamento...</dd>
            <dd *ngIf="!upcomingInvoiceLoading && upcomingInvoice" class="col-7 col-sm-5 ml-auto">
                {{ upcomingInvoiceValue }} {{ upcomingInvoiceDate }}
            </dd>
            <dd *ngIf="!upcomingInvoiceLoading && !upcomingInvoice" class="col-7 col-sm-5 ml-auto">Nessuna</dd>
            <dt *ngIf="lastInvoice" class="col-5">Ultima:</dt>
            <dd *ngIf="lastInvoice" class="col-7 col-sm-5 ml-auto">{{ lastInvoiceValue }} {{ lastInvoiceDate }}</dd>
        </dl>
        <ng-container *ngIf="invoices?.length">
            <div *ngIf="expanded" id="InvoicesCollapse" class="mb-3 d-flex flex-column align-items-center">
                <div class="d-flex w-100">
                    <h6 class="text-secondary">TUTTE LE FATTURE</h6>

                    <div class="mb-1 ml-auto pl-1 pl-sm-2">
                        <button *ngIf="invoicesToShow && userServices && userServices.length > 0 && !userServiceFilter"
                            class="badge badge-secondary btn btn-secondary py-0 line-height-1 d-flex"
                            (click)="filterInvoices()">
                            <i class="ci ci-funnel-primary"></i>
                        </button>
                        <button *ngIf="userServiceFilter"
                            class="badge badge-danger btn btn-danger py-0 line-height-1 d-flex"
                            (click)="unfilterInvoices()">
                            <i class="ci ci-funnel-remove-primary"></i>
                        </button>
                    </div>
                </div>
                <div *ngFor="let invoice of invoicesToShow" class="mb-1 w-100">
                    <cp-client-invoice [invoice]="invoice" [download]="download"></cp-client-invoice>
                </div>
                <button class="btn btn-link text-secondary" *ngIf="canShowMore" (click)="showMore()">
                    Mostra altre
                </button>
                <p *ngIf="!invoicesToShow?.length">Nulla da mostrare</p>
            </div>

            <div class="d-flex justify-content-center pt-md-2 pt-lg-3">
                <button type="button" (click)="toggleExpand()" class="btn btn-icon-outline-secondary"
                    [attr.aria-expanded]="expanded" aria-controls="InvoicesCollapse">
                    <i class="ci" [ngClass]="expanded ? 'ci-minus-white' : 'ci-plus-white'"></i>
                </button>
            </div>
        </ng-container>
    </div>
    <cp-spinner spinnerClass="text-white" backdropClass="rounded" [hasBackDrop]="true" *ngIf="loading"></cp-spinner>
</section>
