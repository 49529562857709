import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NeedLoginGuard} from '../../../../core/api/guards/need-login.guard';
import {SubscriptionComponent} from './features/subscription/subscription.component';
import {SubscriptionRecapComponent} from './features/subscription-recap.component';
import {SubscriptionPayComponent} from './features/subscription-pay/subscription-pay.component';
import { KioskModeGuard } from '../../../../core/api/guards/kioskmode.guard';

const routes: Routes = [
  {
    path: 'subscription',
    component: SubscriptionComponent,
    canActivate: [NeedLoginGuard, KioskModeGuard]
  },
  {
    path: 'subscription/pay/:id',
    component: SubscriptionPayComponent,
    canActivate: [NeedLoginGuard, KioskModeGuard]
  },
  {
    path: 'subscription/recap',
    component: SubscriptionRecapComponent,
    canActivate: [NeedLoginGuard, KioskModeGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [NeedLoginGuard, KioskModeGuard]
})
export class SubscriptionRoutingModule { }
