<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">SOSPENDI</h4>
  </div>

  <form
    #f="ngForm"
    (ngSubmit)="!loading ? (f.valid && !dateErrors ? sub() : f.form.markAllAsTouched()) : null"
    class="line-form form--light form--color-textborder"
  >
    <div class="form-group" *ngIf="subscriptionCategory == 'standard'">
      <cp-date-picker
        [id]="'dateInput'"
        [label]="'Inizio<br />sospensione*'"
        name="date"
        #date="ngModel"
        [(ngModel)]="selectedDate" 
        [required]="true"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [startDate]="startDate"
        (ngModelChange)="onDateChange($event)"
        ngbAutofocus
        (errors)="dateErrors = $event"
        [isInvalid]="!!dateErrors && date.touched"
      ></cp-date-picker>
      <small class="invalid-tooltip" *ngIf="dateErrors?.required && date?.touched"> Inserire la data di inizio </small>
      <small
        class="invalid-tooltip"
        *ngIf="dateErrors && !dateErrors.required && !dateErrors?.ngbDate?.maxDate && date?.touched"
      >
        Data non valida
      </small>
    </div>

    <div class="form-group"  *ngIf="subscriptionCategory == 'flex'">
      <label for="DateInput">Inizio<br />sospensione*</label>
      <input
        type="text"
        labelForId="DateInput"
        disabled
        ngbAutofocus
        [value]="selectedDate.format('DD/MM/YYYY') | titlecase"
      />
    </div>

    <div class="form-group" *ngIf="subscriptionCategory == 'flex'">
      <label for="durationInput">Durata*<br/>(Mesi)</label>
      <input 
        id="durationInput" 
        type="number" 
        name="duration" 
        #durationControl="ngModel"
        [(ngModel)]="duration" 
        [minValue]="1" 
        [maxValue]="maxSuspensionDuration" 
        [ngClass]="{ 'is-invalid': durationControl.invalid  && durationControl.touched }"
      /> 
      <small class="invalid-tooltip" *ngIf="durationControl.errors?.required && durationControl.touched">
        Il valore è richiesto
      </small>
      <small class="invalid-tooltip" *ngIf="durationControl.errors?.max && durationControl.touched">
        Durata troppo grande
      </small>
      <small class="invalid-tooltip" *ngIf="durationControl.invalid && durationControl.touched && !durationControl.errors?.max && !durationControl.errors?.required">
        Il valore non è valido
      </small>
    </div>

    <div class="form-group">
      <label for="descriptionInput">Descrizione</label>
      <input 
        id="descriptionInput"
        type="text"
        name="description"
        [(ngModel)]="description"
        #descriptionControl = "ngModel"
        [ngClass]="{ 'is-invalid': descriptionControl.invalid  && descriptionControl.touched }"
      />
    </div>

    <div class="mt-3">
      <div class="row">
        <div class="col-6">
          <a class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</a>
        </div>
        <div class="col-6">
          <button class="btn btn-block btn-outline-secondary-text-white" type="submit" [ngClass]="{ active: loading }">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            Prosegui
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
