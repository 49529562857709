<div class="d-flex flex-row position-relative" [ngClass]="{ 'border-secondary border-bottom': isSelected }">
  <div
    class="flex-fill d-flex pointer"
    [ngbPopover]="!disableTooltip? ('Tipo: ' + enrichedCard.fundingFormatted): null"
    [placement]="['bottom-left', 'top-left']"
  >
    <img
      *ngIf="enrichedCard.logoUrl"
      src="{{ enrichedCard.logoUrl }}"
      style="height: 1.75em"
      class="mr-1 mr-sm-2"
    />
    <span class="d-md-inline d-none my-auto">
      {{ enrichedCard.card.brand + " - termina con: " }}
    </span>
    <span class="d-inline d-md-none my-auto">••••</span>
    <span class=" ml-1 my-auto">
      {{ enrichedCard.card.last4 }}
    </span>
    <span
      class="badge badge-pill badge-success align-middle ml-1 my-auto"
      *ngIf="enrichedCard.card.default"
      [ngClass]="{ 'ml-auto': !hasVisibleActions() || !allowActions }"
      >
      <span class="d-sm-inline d-none">
        DEFAULT
      </span>
      <span class="d-sm-none d-inline">
        DEF.
      </span>
      </span
    >
  </div>

  <div class="d-inline-flex">
    <span
      ngbDropdown
      [placement]="['bottom-right', 'top-right']"
      class="align-middle my-auto ml-auto"
      *ngIf="allowActions && hasVisibleActions()"
    >
      <button
        class="badge badge-light btn btn-light py-0 line-height-1 ml-1 ml-sm-2 no-after"
        ngbDropdownToggle
        [id]="'PaymentCard' + card.id"
      >
        <i class="ci ci-ellipsis-horizontal-primary"></i>
      </button>
      <div ngbDropdownMenu [attr.aria-labelledby]="'PaymentCard' + card.id">
        <ng-container *ngFor="let action of actions">
          <button
            ngbDropdownItem
            *ngIf="action.value.ngIf()"
            [disabled]="action.value.disabled()"
            (click)="action.value.action(card)"
          >
            {{ action.key }}
          </button>
        </ng-container>
      </div>
    </span>
  </div>
</div>
