<cp-waiting *ngIf="preconditionsOk === null"></cp-waiting>
<cp-unexpected-error *ngIf="preconditionsOk === false" redirectTo="{{ getRedirectUrl() }}"></cp-unexpected-error>
<cp-unexpected-error *ngIf="error === true" redirectTo=""></cp-unexpected-error>
<section class="registers" *ngIf="preconditionsOk === true && error !== true">
  <div class="container mt-3 mt-lg-10">
    <div class="row">
      <div
        class="col-md-10 offset-md-1"
        style="overflow-x: hidden"
        *ngIf="userService.isUserReady() && !userService.canSubscribe() && !userService.isMinor() && !userService.hasPendingAndConfirmedVirtualSubscription()"
      >
        <h1 class="text-secondary text-center">NON È POSSIBILE EFFETTUARE IL RINNOVO!</h1>
        <h4 class="text-primary text-center">Hai già un abbonamento futuro.</h4>
        <div class="text-center mt-3">
          <a class="btn btn-outline-secondary-text-primary" [routerLink]="['/home']">Vai alla dashboard</a>
        </div>
      </div>
      <div
        class="col-md-10 offset-md-1"
        *ngIf="userService.isUserReady() && !userService.canSubscribe() && userService.isMinor()"
      >
        <h1 class="text-secondary text-center">NON È POSSIBILE EFFETTUARE L'ACQUISTO!</h1>
        <h4 class="text-primary text-center">I minorenni non possono iscriversi autonomamente.</h4>
        <h4 class="text-primary text-center">
          Recati in palestra con i tuoi genitori e i nostri operatori saranno lieti di iscriverti!
        </h4>
        <div class="text-center mt-3">
          <a class="btn btn-outline-secondary-text-primary" [routerLink]="['/home']">Vai alla dashboard</a>
        </div>
      </div>
      <div
        class="col-md-10 offset-md-1"
        *ngIf="userService.isUserReady() && userService.hasPendingAndConfirmedVirtualSubscription()"
      >
        <h1 class="text-secondary text-center">NON È POSSIBILE PROCEDERE!</h1>
        <h4 class="text-primary text-center">C'è già un'operazione di acquisto in corso!</h4>
        <h4 class="text-primary text-center">
          Aspetta qualche minuto affinché l'operazione di acquisto si concluda
          e contatta i nostri operatori se desideri avere maggiori informazioni!
        </h4>
        <div class="text-center mt-3">
          <a class="btn btn-outline-secondary-text-primary" [routerLink]="['/home']">Vai alla dashboard</a>
        </div>
      </div>
      <div
        class="col-md-10 offset-md-1"
        *ngIf="userService.isUserReady() && userService.canSubscribe() && !subscriptionsService.subscriptions?.length"
      >
        <h1 *ngIf="shouldSellARenew" class="text-secondary text-center">NESSUN ABBONAMENTO DISPONIBILE!</h1>
        <h1 *ngIf="!shouldSellARenew" class="text-secondary text-center">NESSUN RINNOVO DISPONIBILE!</h1>
        <h4 class="text-primary text-center">Riprova più tardi.</h4>
        <div class="text-center mt-3">
          <a class="btn btn-outline-secondary-text-primary" [routerLink]="['/home']">Vai alla dashboard</a>
        </div>
      </div>

      <div
        class="col-md-10 offset-md-1"
        *ngIf="userService.isUserReady() && userService.canSubscribe() && subscriptionsService.subscriptions?.length"
      >
        <h1 *ngIf="!shouldSellARenew" class="text-secondary text-center">ABBONATI A WEBFIT!</h1>
        <h1 *ngIf="shouldSellARenew" class="text-secondary text-center">RINNOVA L'ABBONAMENTO!</h1>
        <h4 class="text-primary text-center" *ngIf="userService.hasToUpdateProfile()">
          Inserisci i tuoi dati ed effettua l'acquisto
        </h4>

        <div class="line-form mb-5" *ngIf="user">
          <form
            #f="ngForm"
            (ngSubmit)="
              !loading
                ? f.valid && !birthdayErrors
                  ? updateAndCreateVirtualSubscription(f)
                  : f.form.markAllAsTouched()
                : null
            "
            class="mt-3 container-fluid"
          >
            <!-- User step -->
            <div class="row form--secondary form--color-textborder" *ngIf="userService.hasToUpdateProfile()">
              <div class="col-12">
                <h4 class="text-primary mb-0 mt-3">Generalità</h4>
              </div>

              <!--Name-->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="NameInput">Nome*</label>
                  <input
                    id="NameInput"
                    #name="ngModel"
                    type="text"
                    name="name"
                    [ngModel]="user.name"
                    oninput="this.value = this.value.toUpperCase()"
                    [required]="true"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': (name.invalid && name.touched) || (error.name && name.pristine)
                    }"
                  />
                  <small class="invalid-tooltip" *ngIf="name.errors?.required">
                    Il nome è richiesto
                  </small>
                  <small class="invalid-tooltip" *ngIf="error.name">
                    {{ error.name }}
                  </small>
                </div>
              </div>

              <!-- Surname -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="SurnameInput">Cognome*</label>
                  <input
                    id="SurnameInput"
                    #surname="ngModel"
                    type="text"
                    name="surname"
                    [ngModel]="user.surname"
                    oninput="this.value = this.value.toUpperCase()"
                    [required]="true"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': (surname.invalid && surname.touched) || (error.surname && surname.pristine)
                    }"
                  />

                  <small class="invalid-tooltip" *ngIf="surname.errors?.required">
                    Il cognome è richiesto
                  </small>

                  <small class="invalid-tooltip" *ngIf="error.surname">
                    {{ error.surname }}
                  </small>
                </div>
              </div>

              <!-- Gender -->
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="GenderInput" class="d-flex">
                    Sesso*
                    <span
                      class="spinner-grow spinner-text ml-1"
                      role="status"
                      aria-hidden="true"
                      *ngIf="!isUserFormReady"
                    ></span>
                  </label>

                  <!-- TODO: use ng-select for all. plain select has issues on small screen (dropdown overlay bigger than the actual select).
                  However we want to fetch genders dynamically and apparently the [items] bind works immediately... in other words we probably
                  have to use something like this: https://stackblitz.com/edit/ng-select-egj2ht?file=app%2Fapp.component.html
                  Leave it alone for now -->

                  <select
                    [ngModel]="user.gender"
                    [required]="true"
                    #gender="ngModel"
                    name="gender"
                    class="custom-select"
                    [disabled]="!isUserFormReady"
                    [ngClass]="{
                      'is-invalid': (gender.invalid && gender.touched) || (error.gender && gender.pristine)
                    }"
                  >
                    <option hidden [value]="undefined" disabled selected>-seleziona-</option>
                    <option *ngFor="let g of userService.getSelect('gender')" [value]="g.key">
                      {{ g.value }}
                    </option>
                  </select>
                  <small *ngIf="gender.invalid" class="invalid-tooltip">
                    Selezionare il genere
                  </small>
                  <small *ngIf="error.gender" class="invalid-tooltip">
                    {{ error.gender }}
                  </small>
                </div>
              </div>

              <!-- Birthday -->
              <div class="col-lg-4">
                <div class="form-group">
                  <cp-date-picker
                    [id]="'birthdayInput'"
                    [label]="'Data di<br />nascita*'"
                    name="birthday"
                    #birthday="ngModel"
                    [ngModel]="user.birthday"
                    [required]="true"
                    [maxDate]="getMaxBirthDate()"
                    [minDate]="minBirthday"
                    [disabled]="submitted"
                    (errors)="birthdayErrors = $event"
                    style="margin-bottom: 0"
                    [isInvalid]="(!!birthdayErrors && birthday.touched) || (birthday.pristine && error.birthday)"
                  >
                  </cp-date-picker>
                  <small class="invalid-tooltip" *ngIf="birthdayErrors?.required">
                    Inserire la data di nascita
                  </small>
                  <small class="invalid-tooltip" *ngIf="birthdayErrors?.ngbDate?.maxDate">
                    Sei troppo giovane!
                  </small>
                  <small
                    class="invalid-tooltip"
                    *ngIf="
                      birthdayErrors &&
                      !birthdayErrors.required &&
                      !birthdayErrors?.ngbDate?.maxDate
                    "
                  >
                    Data di nascita non valida
                  </small>
                  <small class="invalid-tooltip" *ngIf="error.birthday">
                    {{ error.birthday }}
                  </small>
                </div>
              </div>

              <!-- Birthplace -->
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="BirthplaceInput">
                    Luogo di <br />
                    nascita*
                  </label>
                  <ng-select
                    #birthplace="ngModel"
                    [ngModel]="user.birthplace"
                    [items]="cities"
                    [addTag]="true"
                    addTagText="Aggiungi luogo"
                    [virtualScroll]="true"
                    name="birthplace"
                    [required]="true"
                    notFoundText="Non trovato"
                    labelForId="BirthplaceInput"
                    [ngClass]="{
                      'is-invalid':
                        (birthplace.errors && birthplace.touched) || (error.birthplace && birthplace.pristine)
                    }"
                  >
                  </ng-select>
                  <small class="invalid-tooltip" *ngIf="birthplace.errors?.required">
                    Selezionare il luogo di nascita
                  </small>
                  <small class="invalid-tooltip" *ngIf="error.birthplace">
                    {{ error.birthplace }}
                  </small>
                </div>
              </div>

              <!-- State -->
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="StateInput" class="align-items-center d-flex">
                    <span>
                      Stato di <br />
                      nascita*
                    </span>
                    <span
                      class="spinner-grow spinner-text ml-1"
                      role="status"
                      aria-hidden="true"
                      *ngIf="!isUserFormReady"
                    ></span>
                  </label>
                  <ng-select
                    [ngModel]="user.state"
                    [required]="true"
                    #state="ngModel"
                    name="state"
                    notFoundText="Non trovato"
                    labelForId="StateInput"
                    [ngClass]="{ 'is-invalid': (state.errors && state.touched) || (error.state && state.pristine) }"
                  >
                    <ng-option *ngFor="let state of userService.getSelect('state')" [value]="state.key">{{
                      state.value | titlecase
                    }}</ng-option>
                  </ng-select>
                  <small class="invalid-tooltip" *ngIf="state.valid">
                    Selezionare lo stato di nascita
                  </small>
                  <small class="invalid-tooltip" *ngIf="!error.state">
                    {{ error.state }}
                  </small>
                </div>
              </div>

              <!-- CF -->
              <div class="col-lg-12">
                <div class="form-group" *ngIf="!user.tax_code">
                  <label for="TaxCodeInput"
                    >Codice <br />
                    Fiscale*</label
                  >
                  <input
                    id="TaxCodeInput"
                    #tax_code="ngModel"
                    type="text"
                    name="tax_code"
                    [ngModel]="user.tax_code"
                    oninput="this.value = this.value.toUpperCase()"
                    [required]="true"
                    class="form-control"
                    pattern="^[a-zA-Z]{6}[a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$"
                    [ngClass]="{
                      'is-invalid': (tax_code.invalid && tax_code.touched) || (error.tax_code && tax_code.pristine)
                    }"
                  />

                  <small class="invalid-tooltip" *ngIf="tax_code.errors?.required">
                    Il codice fiscale è richiesto
                  </small>
                  <small class="invalid-tooltip" *ngIf="tax_code.errors?.pattern">
                    Il formato non è riconosciuto
                  </small>
                  <small class="invalid-tooltip" *ngIf="error.tax_code">
                    {{ error.tax_code }}
                  </small>
                </div>
                <!-- CF if already defined -->
                <div class="form-group" *ngIf="user.tax_code">
                  <label for="TaxCodeInput"
                    >Codice <br />
                    Fiscale*</label
                  >
                  <input id="TaxCodeInput" [value]="user.tax_code" type="text" class="form-control" disabled="true" />
                </div>
              </div>
            </div>

            <!-- Contacts step -->
            <div
              class="bg-primary form--color-textborder form--light mt-5 mx-n5 pb-5 px-5 rounded text-light"
              *ngIf="userService.hasToUpdateProfile()"
            >
              <div class="row">
                <div class="col-12">
                  <h4 class="text-secondary mb-0 mt-3">Contatti</h4>
                </div>

                <!-- Cel -->
                <div class="col-xxl-6">
                  <div class="form-group">
                    <label for="CelInput"> Cellulare* </label>
                    <input
                      id="CelInput"
                      #cel="ngModel"
                      type="text"
                      name="cel"
                      [ngModel]="user.cel"
                      [required]="true"
                      class="form-control"
                      pattern="^((\+|(00))[0-9]{1,3})?\s*[0-9]{2,4}\s*[0-9]{2,3}\s*[0-9]{2,3}\s*[0-9]{2,3}$"
                      [ngClass]="{ 'is-invalid': (cel.invalid || error.cel) && cel.touched }"
                    />
                    <small class="invalid-tooltip" *ngIf="cel.errors?.required">
                      Un numero cellulare è richiesto
                    </small>
                    <small class="invalid-tooltip" *ngIf="cel.errors?.pattern">
                      Il formato non è riconosciuto
                    </small>
                  </div>
                </div>

                <!-- Address -->
                <div class="col-xxl-6">
                  <div class="form-group">
                    <label for="address">Indirizzo*</label>
                    <cp-amg-autocomplete
                      [ngModel]="user.address"
                      #address="ngModel"
                      name="address"
                      [hideMap]="true"
                      placeholder=""
                      required
                      [isInvalid]="(address.invalid || error.address) && address.touched"
                    >
                    </cp-amg-autocomplete>
                    <small class="invalid-tooltip" *ngIf="address.errors?.required">
                      L'indirizzo è richiesto
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <!-- Subscription step -->
            <div class="row form--secondary form--color-textborder">
              <div class="col-12">
                <h4 class="text-primary mb-0 mt-3">Piano di abbonamento</h4>
              </div>

              <!-- Gym -->
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="GymInput">Palestra*</label>
                  <ng-select
                    [(ngModel)]="gymService.defaultGymId"
                    (ngModelChange)="changeGym($event)"
                    [required]="true"
                    #gym_id="ngModel"
                    name="gym_id"
                    [disabled]="!gymService.selectableGyms"
                    notFoundText="Non trovato"
                    labelForId="GymInput"
                    [clearable]="false"
                    [ngClass]="{ 'is-invalid': gym_id.invalid && gym_id.touched }"
                  >
                    <ng-option *ngFor="let gym of gymService.selectableGyms" [value]="gym.id">
                      <div class="d-flex align-items-center">
                        {{ gym.name }}
                        <span *ngIf="gym.is_in_presale_mode"
                              class="badge badge-pill badge-secondary text-primary ml-auto">PREVENDITA</span>
                      </div>
                    </ng-option>
                  </ng-select>
                  <small class="invalid-tooltip" *ngIf="gym_id.invalid">
                    Selezionare una palestra
                  </small>
                </div>
              </div>

              <!-- Subscription -->
              <div
                [ngbCollapse]="gym_id.value == null"
                class="col-12"
              >
                <div class="mx-md-n5">
                  <cp-subscription-list
                    [gymId]="gymService.defaultGymId"
                    [subscriptions]="subscriptionsService.selectableSubscriptions"
                    (selectedSubscription)="changeSubscription($event)"
                    [working]="loading"
                  ></cp-subscription-list>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</section>
