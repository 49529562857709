import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AuthorizationService} from '../services/authorization.service';

@Injectable()
export class NoLoginGuard implements CanActivate {

  constructor(private authorizationService: AuthorizationService, private router: Router) { }

  canActivate(): boolean {
    if (this.authorizationService.isAuthenticated()) {
      this.router.navigate([environment.default_roots.logged]);
      return false;
    }
    return true;
  }
}
