import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ConfigurationService} from './services/configuration.service';
import {GymService} from './services/gym.service';
import {UserService} from './services/user.service';
import {PurchaseRecapComponent} from './features/purchase-recap/purchase-recap.component';
import {RouterModule} from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import {environment} from '../../environments/environment';
import {AgmAutocompleteComponent} from './features/agm-autocomplete.component';
import {FormsModule} from '@angular/forms';
import {HelperService} from './services/helper.service';
import {StripeElementsComponent} from './features/stripe-elements.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MomentDateFormatter} from '../core/formatters/moment-date.formatter';
import {SubscriptionListComponent} from './features/subscription-list/subscription-list.component';
import {DatePickerComponent} from './features/date-picker/date-picker.component';
import {UnexpectedErrorComponent} from "./features/unexpected-error.component"
import { SpinnerComponent } from './features/spinner.component';
import {WaitingComponent} from "./features/waiting.component";
import {PaymentCardComponent} from "./features/payment-card/payment-card.component";
import {PaymentElementComponent} from './features/payment-element/payment-element.component';
import { UnexpectedErrorDialogComponent } from './features/unexpected-error-dialog/unexpected-error-dialog.component';
import { ProductService } from './services/product.service';
import { PaymentDialog } from './features/payment-dialog/payment-dialog.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MinValidatorDirective } from './directives/min-validator.directive';
import { MaxValidatorDirective } from './directives/max-validator.directive';
import { SubscriptionPriceComponent } from './features/subscription-price/subscription-price.component';

@NgModule({
  declarations: [
    SubscriptionListComponent,
    PurchaseRecapComponent,
    AgmAutocompleteComponent,
    StripeElementsComponent,
    DatePickerComponent,
    UnexpectedErrorComponent,
    SpinnerComponent,
    WaitingComponent,
    PaymentCardComponent,
    PaymentElementComponent,
    UnexpectedErrorDialogComponent,
    PaymentDialog,
    AutofocusDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    SubscriptionPriceComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.maps_api_key,
      libraries: ['places'],
    }),
    NgbModule
  ],
  exports: [
    SubscriptionListComponent,
    PurchaseRecapComponent,
    AgmAutocompleteComponent,
    StripeElementsComponent,
    DatePickerComponent,
    UnexpectedErrorComponent,
    SpinnerComponent,
    WaitingComponent,
    PaymentCardComponent,
    PaymentElementComponent,
    UnexpectedErrorDialogComponent,
    SubscriptionPriceComponent,
    PaymentDialog,
    AutofocusDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
  ],
  providers: [
    ConfigurationService,
    GymService,
    UserService,
    HelperService,
    ProductService,
    {
      provide: NgbDateParserFormatter,
      useClass: MomentDateFormatter
    },
  ]
})
export class SharedModule {
}
