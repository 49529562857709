import {Component, Input, OnInit} from '@angular/core';
import { UserService } from "../../services/user.service";

@Component({
  selector: 'cp-purchase-recap',
  templateUrl: './purchase-recap.component.html',
  styleUrls: [],
})
export class PurchaseRecapComponent implements OnInit {

  @Input() public isError: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public isFreeTrial: boolean = false;
  @Input() public showHomeLink: boolean = true;
  @Input() public hasAmnestyToForgive: boolean = false;
  @Input() public isIntentConfirmationRegistrationFailed: boolean = false

  constructor(
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    if (!this.isError) {
      this.userService.clearPayload();
    }
  }

}
