import { UnexpectedErrorDialogComponent } from './../../../../../../shared/features/unexpected-error-dialog/unexpected-error-dialog.component';
import {Component, Input, OnChanges, SimpleChanges, OnInit, EventEmitter, Output} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {UserService} from '../../../../../../shared/services/user.service';
import {Router} from '@angular/router';
import { UserServiceObject, SuspensionModel } from '../../../../../../shared/models/user.model';
import * as moment from 'moment';
import {Moment} from 'moment';
import { CreateSuspensionDialog } from './create-suspension-dialog/create-suspension-dialog.component';
import { DeleteSuspensionDialog } from './delete-suspension-dialog/delete-suspension-dialog.component';
import { CheckoutDialog } from './checkout-dialog/checkout-dialog.component';
import { HelperService } from '../../../../../../shared/services/helper.service';

@Component({
  selector: 'cp-client-subscription-view',
  templateUrl: './subscription-view.component.html',
  styleUrls: [],
})
export class SubscriptionViewComponent implements OnInit, OnChanges {

  public expanded: boolean = false;
  public subscriptionStatus: string = '';
  public subscriptionStatusCssClass: string = '';
  public paymentMethod: string = '';
  public paymentMethodLabel: string = '';
  public suspensionInfo = {
    usedText: '',
    remainingText: '',
  };
  public suspensions = [];
  public hasSuspension = false;
  public isMonthlySubscription = false;
  public subCategory: "flex" | "standard"
  public subStartDate: string = '';
  public subEndDate: string = '';
  public subCreatedAt: string = '';
  public lastInvoiceAt: string = '';
  public nextInvoiceAt: string = '';
  public guessedEndDateForCheckout: string = ''
  public isFutureSubscription: boolean = false;

  public canSuspend: boolean = false;
  public canRenew: boolean = false;
  public canCheckout: boolean = false;

  // Control of actions
  public modalShown = false;

  @Input()
  public userSubscription: UserServiceObject;
  @Input()
  private forceNoRenew: boolean;
  @Output() reload: EventEmitter<void> = new EventEmitter()

  constructor(
    public userService: UserService,
    private router: Router,
    private modalService: NgbModal,
    private readonly helperService: HelperService
  ) {
  }

  ngOnInit() {
    this.recomputeValues();
  }

  openCreateSuspensionDialog() {
    if (this.modalShown) return;
    this.modalShown = true;
    let ref = this.modalService.open(CreateSuspensionDialog, { centered: true });
    let dialogRef = ref.componentInstance as CreateSuspensionDialog;

    dialogRef.onSubmit.subscribe(({ sub_sus_start_date, description, duration }: { sub_sus_start_date: Moment, description: string, duration?: number }) => {
      this.userService.createSuspension(sub_sus_start_date, "" + (description || ""), this.userSubscription, duration)
        .subscribe(x => {
          ref.close();
          return x
        }, ex => {
            ref.dismiss();
            this.modalShown = true;
            let ueRef =this.modalService.open(UnexpectedErrorDialogComponent, { centered: true });
            ueRef.result.finally(() => {
              this.modalShown = false;
            })
            let ueDialogRef = ueRef.componentInstance as UnexpectedErrorDialogComponent
            ueDialogRef.error = ex?.error?.message ?? null
        });
    });

    ref.result.then(() => {
      this.reload.emit();
    }).catch(err => {
      console.log('Rejected!');
    }).finally(() => {
      this.modalShown = false;
    });

    dialogRef.userSubscription = this.userSubscription;
    dialogRef.maxSuspensionDuration = this.userSubscription.suspension_values.plannable_units
  }

  goToRenewPage() {
    this.router.navigate(['subscription']);
  }

  openCheckoutDialog() {
    if (this.modalShown) return;
    this.modalShown = true;
    let ref = this.modalService.open(CheckoutDialog, { centered: true });
    let dialogRef = ref.componentInstance as CheckoutDialog;
    dialogRef.onSubmit.subscribe(() => {
      this.userService.checkout(this.userSubscription)
        .subscribe(x => {
          ref.close();
          return x
        }, ex => {
          ref.dismiss();
          this.modalShown = true;
          let ueRef =this.modalService.open(UnexpectedErrorDialogComponent, { centered: true });
          ueRef.result.finally(() => {
            this.modalShown = false;
          })
        });
    });

    ref.result.then(() => {
      this.reload.emit();
    }).catch(err => {
      console.log('Rejected!');
    }).finally(() => {
      this.modalShown = false;
    });
    dialogRef.userSubscription = this.userSubscription;
  }

  toggleExpandIfNotSelected() {
    const selection = window.getSelection();
    if ((selection.type !== undefined && selection.type != 'Range') || (selection.type === undefined && !selection.toString())) {
      this.toggleExpand();
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userSubscription) {
      this.recomputeValues();
    } else if (changes.forceNoRenew) {
      this.canRenew = this.computeCanRenew(changes.forceNoRenew.currentValue);
    }
  }

  private computeCanRenew(force) {
    return force ? false
      : moment(this.userSubscription.sub_end_date).endOf('day').isAfter(moment())
        && moment(this.userSubscription.sub_start_date).startOf('day').isBefore(moment())
        && this.userService.canSubscribe();
  }

  private recomputeValues() {
    this.subCategory = this.userSubscription.sub_info.subscription_category;
    this.subscriptionStatus = this.getSubscriptionStatus();
    this.subscriptionStatusCssClass = this.getSubscriptionStatusCssClass();
    const {label, value} = this.getPaymentInfo();
    this.paymentMethod = value;
    this.paymentMethodLabel = label;
    this.suspensionInfo = this.getSuspensionInfo();
    this.suspensions = this.getNonDeletedSuspensions();
    this.hasSuspension = this.hasSuspensions();
    this.isMonthlySubscription = this.userSubscription.plan.interval === 'month'
    this.subStartDate = this.formatDateString(this.userSubscription.sub_start_date);
    this.subEndDate = this.formatDateString(this.userSubscription.sub_end_date);
    this.subCreatedAt = this.formatDateString(this.userSubscription.created_at, 'DD/MM/YYYY HH:mm', true);
    this.lastInvoiceAt = this.formatDateString(this.userSubscription.last_invoice_at);
    this.nextInvoiceAt = this.userSubscription.next_invoice_at ? this.formatDateString(this.userSubscription.next_invoice_at) : "-";
    this.canRenew = this.computeCanRenew(this.forceNoRenew);
    this.canSuspend = this.userSubscription.can_suspend
    this.canCheckout = this.userSubscription.sub_info.subscription_category == "flex"
        && !this.userSubscription.sub_checkout_date
        && moment(this.userSubscription.sub_end_date).endOf('day').isAfter(moment());
    if(this.userSubscription.sub_checkout_date && this.subCategory == "flex"){
      this.guessedEndDateForCheckout = this.helperService.guessUserServiceEndDateForCheckout(this.userSubscription, moment(this.userSubscription.sub_checkout_date));
    }
  }

  openDeleteSuspensionDialog(suspension: SuspensionModel) {
    if (this.modalShown) return;
    this.modalShown = true;
    let ref = this.modalService.open(DeleteSuspensionDialog, { centered: true });
    (ref.componentInstance as DeleteSuspensionDialog).onSubmit.subscribe(() => {
      this.userService.deleteSuspension(suspension, this.userSubscription)
        .subscribe(x => {
          ref.close();
          return x
        });
    }, ex => {
      ref.dismiss();
      this.modalShown = true;
      let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, { centered: true });
      ueRef.result.finally(() => {
        this.modalShown = false;
      });
    });

    ref.result.then(() => {
      this.reload.emit()
    }).catch(err => {
      console.log('Rejected!');
    }).finally(() => {
      this.modalShown = false;
    });
  }

  private getSubscriptionStatus() {
    if (this.userSubscription.active) return 'Attivo';
    const end = moment(this.userSubscription.sub_end_date).endOf('day');
    if (end.isBefore(moment())) {
      return 'Scaduto';
    }
    const start = moment(this.userSubscription.sub_start_date).startOf('day');
    if (start.isAfter(moment())) {
      this.isFutureSubscription = true;
      return 'Futuro';
    }
    const suspensions = this.userSubscription.suspensions || [];
    for (const susp of suspensions) {
      if (susp.processed === 'active') {
        return 'Sospeso';
      }
    }
    const termination_attributes = [
      'right_withdrawal_at',
      'right_reconsider_at',
      'amnesty_at',
      'expelled_at',
    ]
    for (const attr in termination_attributes) {
      if (this.userSubscription[attr]) {
        return 'Terminato';
      }
    }
    return 'Non Attivo';
  }

  private getSubscriptionStatusCssClass() {
    switch (this.getSubscriptionStatus()) {
      case 'Attivo':
        return 'badge-success';
      case 'Scaduto':
        return 'badge-dark';
      case 'Sospeso':
        return 'badge-warning';
      case 'Terminato':
        return 'badge-danger';
      case 'Futuro':
        return 'badge-light';
      default:
        return 'badge-danger';
    }
  }

  private getPaymentInfo() {
    let info = {
      label: 'Metodo di Pagamento:',
      value: 'Sconosciuto',
    }
    switch (this.userSubscription.sub_info.payment_method) {
      case 'sepa':
        const mandate = this.userSubscription.sepa_mandate_ref;
        info.label = 'Mandato SDD:'
        if (!mandate) {
          info.value = 'Nessuno'
        } else {
          info.value = mandate.sepa_mandate_ref.replace(/(\w{8})/, '$1\u200b');
        }
        break;
      case 'card':
        if (this.userSubscription.sub_info.interval === 'year') {
          info.value = 'Carta (Soluzione Unica)';
        } else if (this.userSubscription.sub_info.interval === 'month') {
          const card = this.userSubscription.card_source;
          if (card) {
            info.label = 'Carta:'
            info.value = card.brand + ' •••• ' + card.last4;
          } else {
            info.value = 'Carta (Rateale)';
          }
        } else {
          info.value = 'Carta (Soluzione Unica)';
        }
        break;
      case 'pagodil':
        info.value = 'PagoDIL';
        break;
      case 'voucher':
        info.value = 'Voucher';
        break;
      case 'guests':
        info.value = 'Ospite';
        break;
      case 'bank_transfer':
        info.value =  'Bonifico bancario';
        break;
    }
    return info;
  }

  private getSuspensionInfo() {
    let unit: string;
    switch (this.subCategory) {
      case "standard":
        unit = "giorni";
        break;
      case "flex":
        unit = "mesi"
        break;
    }
    return {
      remainingText: `${this.userSubscription.suspension_values.remaining_count} (${this.userSubscription.suspension_values.remaining_units} ${unit})`,
      usedText: `${this.userSubscription.suspension_values.consumed_count} (${this.userSubscription.suspension_values.consumed_units} ${unit})`,
    }
  }

  private getNonDeletedSuspensions() {
    return (this.userSubscription.suspensions || []).filter(susp => !susp.deleted_at);
  }

 private hasSuspensions() {
    const suspensions = this.getNonDeletedSuspensions();
    return suspensions.length !== 0;
  }

  formatDateString(value: string|Date, fmt: string = 'DD/MM/YYYY', utc = false) {
    const date = utc ? moment.utc(value) : moment(value);
    return utc ? date.local().format(fmt) : date.format(fmt);
  }
}
