import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[maxValue]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }]
})
export class MaxValidatorDirective implements Validator {

  @Input() maxValue: any;

  validate(control: AbstractControl): { [key: string]: any } {    
        var v;
        switch(typeof(this.maxValue)){
            case "number":
                v = new Number(control.value);
                break;
            case "object":
                try{
                    v = control.value;
                } catch {
                    return null;
                }
                break;
            default: 
            return null;
        }

    if(v > this.maxValue)
        return {max: {
            max: this.maxValue,
            actual: v
        }}
  }
}
