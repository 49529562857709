import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {UserService} from '../../../../shared/services/user.service';
import {ApiModule} from '../../../../core/api/api.module';
import {FormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../../shared/shared.module';
import {MomentDateFormatter} from '../../../../core/formatters/moment-date.formatter';
import {HomeRoutingModule} from './home-routing.module';
import {NgSelectModule} from '@ng-select/ng-select';
import { HomeComponent } from './features/home/home.component';
import { SubscriptionViewComponent } from './features/subscription-view/subscription-view.component';
import { InvoicesViewComponent } from './features/invoices-view/invoices-view.component';
import { UnsolvedsViewComponent } from './features/unsolveds-view/unsolveds-view.component';
import { InvoiceComponent } from './features/invoices-view/invoice/invoice.component';
import { UnsolvedComponent } from './features/unsolveds-view/unsolved/unsolved.component';
import { SubscriptionSelectionDialog } from './features/invoices-view/subscription-selection-dialog/subscription-selection-dialog.component';
import { CreateSuspensionDialog } from './features/subscription-view/create-suspension-dialog/create-suspension-dialog.component';
import { DeleteSuspensionDialog } from './features/subscription-view/delete-suspension-dialog/delete-suspension-dialog.component';
import { SuspensionComponent } from './features/subscription-view/suspension/suspension.component';
import { PaymentMethodsViewComponent } from './features/payment-methods-view/payment-methods-view.component';
import { SetDefaultDialog } from './features/payment-methods-view/set-default-dialog/set-default-dialog.component';
import { UpdateCardDialogComponent } from './features/payment-methods-view/update-card-dialog/update-card-dialog.component';
import { AddCardDialog } from './features/payment-methods-view/add-card-dialog/add-card-dialog.component';
import { ShowersViewComponent } from './features/showers-view/showers-view.component';
import { CheckoutDialog } from './features/subscription-view/checkout-dialog/checkout-dialog.component';
import { SettingsComponent } from './features/settings/settings.component';

@NgModule({
  declarations: [
    HomeComponent,
    SubscriptionViewComponent,
    CreateSuspensionDialog,
    DeleteSuspensionDialog,
    PaymentMethodsViewComponent,
    SetDefaultDialog,
    InvoicesViewComponent,
    SuspensionComponent,
    InvoiceComponent,
    UnsolvedComponent,
    SubscriptionSelectionDialog,
    UnsolvedsViewComponent,
    UpdateCardDialogComponent,
    AddCardDialog,
    ShowersViewComponent,
    CheckoutDialog,
    SettingsComponent
  ],
  imports: [
    HomeRoutingModule,
    CommonModule,
    HttpClientModule,
    ApiModule,
    FormsModule,
    NgbModule,
    RouterModule,
    SharedModule,
    NgSelectModule,
  ],
  providers: [
    UserService,
    {
      provide: NgbDateParserFormatter,
      useClass: MomentDateFormatter
    },
    CurrencyPipe
  ]
})
export class HomeModule {
}
