<section class="border border-secondary rounded p-1 p-sm-2 position-relative">
  <div ngbDropdown class="float-right" [placement]="['bottom-right', 'top-right']"  *ngIf="download">
    <button
    ngbDropdownToggle
    class="btn btn-link btn-sm line-height-1 mr-n1 mt-n1 py-1 px-0 text-body no-after btn-icon btn-icon-white-shadowed"
    [id]="'Invoice' + invoice.id"
  >
    <i class="ci ci-ellipsis-vertical-white"></i>
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="'Invoice' + invoice.id">
    <button ngbDropdownItem (click)="download(invoice)">Download</button>
  </div>
  </div>

  <dl class="row mb-0 pointer" [ngbPopover]="popContent" [placement]="['bottom-left', 'top-left']" [ngClass]="{'pr-1': download}">
    <dt class="col-5 col-sm-6">Fattura</dt>
    <dd class="col-7 col-sm-6 ml-auto">{{ invoiceNumber }}</dd>
    <dt class="col-5 col-sm-6">Data</dt>
    <dd class="col-7 col-sm-6 ml-auto">{{ invoiceDate }}</dd>
    <dt class="col-5 col-sm-6">Importo</dt>
    <dd class="col-7 col-sm-6 ml-auto">
      {{ invoiceValue }}
    </dd>
    <ng-template #popContent>
      <dl class="row mb-0">
        <ng-container *ngIf="invoice.unsolved_object">
          <dt class="col-5">Costo</dt>
          <dd class="col-7">
            {{ baseInvoiceValue }}
          </dd>
          <dt class="col-5">Costi insoluto</dt>
          <dd class="col-7">
            {{ unsolvedInvoiceValue }}
          </dd>
        </ng-container>
        <dt class="col-5">Tipologia</dt>
        <dd class="col-7">
          {{ invoiceType }}
        </dd>
        <ng-container *ngIf="invoiceStatus">
          <dt class="col-5">Stato</dt>
          <dd class="col-7" [ngClass]="invoiceStatusCssClass">{{ invoiceStatus }}</dd>
        </ng-container>
      </dl>
    </ng-template>
  </dl>
</section>
