<div class="mt-3 mt-lg-5">
  <cp-waiting *ngIf="verifying"></cp-waiting>
  <cp-unexpected-error *ngIf="error" redirectTo="/register"></cp-unexpected-error>
  <section *ngIf="invalid" class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <h1 class="text-secondary text-center">
          ATTIVAZIONE FALLITA!
        </h1>
        <h4 class="text-primary text-center">
          Il link di attivazione non è più valido, effettua una
          <a routerLink="/register" class="text-secondary">nuova registrazione</a>.
        </h4>
      </div>
    </div>
  </section>
  <section *ngIf="invalidEmailChange" class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <h1 class="text-secondary text-center">
          VERIFICA EMAIL FALLITA!
        </h1>
        <h4 class="text-primary text-center">
          Il link di verifica non è più valido, richiedi una nuova
          modifica della tua email al servizio clienti.
        </h4>
      </div>
    </div>
  </section>
</div>
  