import {Component, Input} from '@angular/core';

@Component({
  selector: 'cp-spinner',
  template: `
    <div class="d-flex justify-content-center align-items-center" [ngClass]="_backDropClass()">
      <div class="spinner-border"  [ngClass]="spinnerClass" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  `,
  styles: [`
      @keyframes spinner-border {
          to {
              transform: rotate(360deg);
          }
      }

      .spinner-border {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          vertical-align: text-bottom;
          border: .25em solid currentColor;
          border-right-color: transparent;
          border-radius: 50%;
          animation: spinner-border .75s linear infinite;
      }

      .spinner-border {
          color: #bababa;
      }

      .isBackDrop {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background-color: #1116
      }
  `]
})
export class SpinnerComponent {
  @Input() hasBackDrop: boolean = false;
  @Input() spinnerClass: string;
  @Input() backdropClass: string;

  _backDropClass() {
    let cls = this.backdropClass;
    if (this.hasBackDrop)
      cls += ' isBackDrop'
    return cls;
  }
};
