import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[minValue]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }]
})
export class MinValidatorDirective implements Validator {

  @Input() minValue: any;

  validate(control: AbstractControl): { [key: string]: any } {    
        var v;
        switch(typeof(this.minValue)){
            case "number":
                v = new Number(control.value);
                break;
            case "object":
                try{
                    v = control.value;
                } catch {
                    return null;
                }
                break;
            default: 
            return null;
        }

    if(v < this.minValue)
        return {min: {
            min: this.minValue,
            actual: v
        }}
  }
}

