<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">QUALCOSA È ANDATO STORTO</h4>
  </div>
  <p ngbAutofocus *ngIf="!error; else customError">
      Si è verificato un errore imprevisto<br />Riprova più tardi
  </p>
  <ng-template #customError>
    <p ngbAutofocus [innerHTML]="error"></p>
  </ng-template>
  <div class="mt-3">
    <button class="btn btn-block btn-outline-secondary-text-white" type="button" (click)="modal.close()">Ok</button>
  </div>
</div>
