import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanFreeTrialGuard } from './guards/can-free-trial.guard';
import { NeedLoginGuard } from '../../../../core/api/guards/need-login.guard';
import { FreeTrialRecapComponent } from './features/free-trial-recap.component';
import { EnvFreeTrialGuard } from './guards/env-free-trial.guard';
import { FreeTrialMainComponent } from './features/free-trial-main/free-trial-main.component';

const routes: Routes = [
  {
    path: 'free-trial',
    component: FreeTrialMainComponent,
    canActivate: [NeedLoginGuard, EnvFreeTrialGuard, CanFreeTrialGuard]
  },
  {
    path: 'free-trial/recap/:id',
    component: FreeTrialRecapComponent,
    canActivate: [NeedLoginGuard, EnvFreeTrialGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [NeedLoginGuard, CanFreeTrialGuard, EnvFreeTrialGuard]
})
export class FreeTrialRoutingModule { }
