import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SubscriptionModel } from '../../models/subscription.model';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'cp-subscription-list',
    templateUrl: './subscription-list.component.html',
    styleUrls: ['./subscription-list.component.scss'],
})
export class SubscriptionListComponent {
    @ViewChild('accordion') accordion: NgbAccordion;
    @Input() public gymId: string;
    @Input() public subscriptions: SubscriptionModel[] = [];
    @Input() public working = false;
    @Output() selectedSubscription = new EventEmitter<SubscriptionModel>();
    currentSelectedSubscription: SubscriptionModel = null;
    public defaultPrecontractualAgreementUrl: string = environment.legal.preContractualAgreementUrl;


    isPromoOrExtraAllowedOrPresale(subscription: SubscriptionModel) {
        return subscription.promotion !== undefined || subscription.is_extra_allowed || subscription.is_presale;
    }


    getPromoOrExtraAllowedOrPresaleText(subscription: SubscriptionModel) {
        if (subscription.is_extra_allowed) {
            return 'SELEZIONATO PER TE';
        }
        if (subscription.is_presale) {
            return 'PREVENDITA';
        }
        if (subscription.promotion) {
            return 'PROMO';
        }
        return '';
    }

    private getPaymentType(subscription: SubscriptionModel) {
        if (
            subscription.subscription_category === 'standard' &&
            subscription.interval === 'year' &&
            subscription.payment_method === 'sepa'
        ) {
            return 'unicasoluzione';
        }
        if (
            subscription.subscription_category === 'standard' &&
            subscription.interval === 'year' &&
            subscription.payment_method === 'card'
        ) {
            return 'pagosubito';
        }
        if (
            subscription.subscription_category === 'standard' &&
            subscription.interval === 'month' &&
            subscription.payment_method === 'sepa'
        ) {
            return 'rateale-sdd';
        }
        if (
            subscription.subscription_category === 'standard' &&
            subscription.interval === 'month' &&
            subscription.payment_method === 'card'
        ) {
            return 'rateale-carta';
        }
        if (subscription.subscription_category === 'flex' && subscription.payment_method === 'card') {
            return 'flex-carta';
        }
        if (subscription.subscription_category === 'flex' && subscription.payment_method === 'sepa') {
            return 'flex-sdd';
        }
    }

    getPaymentTitle(subscription: SubscriptionModel) {
        const paymentType = this.getPaymentType(subscription);
        switch (paymentType) {
            case 'pagosubito':
                return 'Pagosubito';
            case 'unicasoluzione':
                return 'Unicasoluzione';
            case 'rateale-sdd':
                return 'Rateale SDD';
            case 'rateale-carta':
                return 'Rateale Carta';
            case 'flex-carta':
                return 'Flex Carta';
            case 'flex-sdd':
                return 'Flex SDD';
        }
    }

    getPaymentInfo(subscription: SubscriptionModel) {
        const paymentType = this.getPaymentType(subscription);
        switch (paymentType) {
            case 'pagosubito':
                return "Paga l'importo annuale con la carta di credito";
            case 'unicasoluzione':
                return "Paga l'importo annuale con addebito su C/C";
            case 'rateale-sdd':
            case 'flex-sdd':
                return 'Pagamento mensile con addebito su C/C';
            case 'rateale-carta':
            case 'flex-carta':
                return 'Pagamento mensile con addebito su carta di credito';
        }
    }

    getPreContractualDocsUrl(subscription: SubscriptionModel) {
        return (
            subscription.active_promo_campaign?.precontractual_agreement_url ||
            subscription.precontractual_agreement_url ||
            this.defaultPrecontractualAgreementUrl
        );
    }

    selectSubscription(subscription: SubscriptionModel = null) {
        this.currentSelectedSubscription = subscription;
        this.selectedSubscription.emit(subscription);
    }
}
