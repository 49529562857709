import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AccessService } from '../../services/access.service';
import { CorporateStorageService } from '../../../../core/api/services/corporate-storage.service';
import { GymService } from '../../../../shared/services/gym.service';
import { SubscriptionsService } from 'src/app/shared/services/subscriptions.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '../../services/image.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cp-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @ViewChild('f') form: FormGroup;

  public submitted = false;
  public preconditionsOk: boolean = null;
  public loading: boolean;
  private emailSub: Subscription = null;
  public isTaxcodeExisting: boolean = false;
  public error: any = {};
  public errorMessage: string = null;
  public autohideErrorMessage: boolean = true;
  @ViewChild('mobileCarouselContainer', { static: false })
  private mobileCarouselContainer: TemplateRef<any>;
  public imageUrls: string[] = [];

  constructor(
    public readonly accessService: AccessService,
    private readonly router: Router,
    private readonly corporateStorageService: CorporateStorageService,
    private readonly gymService: GymService,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly modalService: NgbModal,
    private readonly imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.checkPreconditions().subscribe(
      ok => {
        this.preconditionsOk = ok;
      },
      () => {
        this.preconditionsOk = false;
      }
    );
    this.fetchImageUrls();
    this.accessService.resetRegistration();
  }

  ngAfterViewInit(): void {
    if (window.innerWidth <= 992) {
      this.modalService.open(this.mobileCarouselContainer, {
        windowClass: 'd-flex',
        modalDialogClass: 'flex-fill d-flex full-size-modal',
        container: 'cp-register',
      } as NgbModalOptions);
    }
  }

  private checkPreconditions(): Observable<boolean> {
    if (!this.corporateStorageService.hasQueryParams()) {
      return of(true);
    }
    const regType = this.corporateStorageService.getRegistrationType();
    switch (regType) {
      case '/free-trial':
        const gymId = this.corporateStorageService.getGymId();
        if (!gymId) {
          return of(false);
        }
        return this.gymService.getGyms().pipe(
          map(gyms => {
            return gyms.find(it => it.id === gymId) !== undefined;
          })
        );
      case '/subscription':
        const subIds = this.corporateStorageService.getSubscriptionIds();
        if (subIds.length === 0) {
          return of(false);
        }
        return this.subscriptionsService.getSubscriptions().pipe(
          map(subs => {
            return subIds.every(subId => subs.find(it => it.id === subId) !== undefined)
          })
        );
    }
    return of(false);
  }

  private fetchImageUrls() {
    this.imageService.getRegisterImageUrls().subscribe(urls => {
      this.imageUrls = urls.map(url => environment.apiUrl + url);
    })
  }

  isFreeTrial() {
    return this.corporateStorageService.getRegistrationType() === '/free-trial';
  }

  getRedirectUrl(): string {
    const regType = this.corporateStorageService.getRegistrationType();
    if (regType === '/free-trial') {
      return 'http://www.webfit.it/prova-gratuita';
    }
    return 'http://www.webfit.it/abbonamento';
  }

  onMailChange(email: string, isValid: boolean) {
    if (isValid) {
      this.loading = true;
      this.accessService.canRegister = false;
      if (this.emailSub && !this.emailSub.closed) {
        this.emailSub.unsubscribe();
      }

      this.emailSub = this.accessService.checkEmail(email);
      this.emailSub.add(() => {
        this.loading = false;
      });
    }
  }

  performRegistration(form: NgForm) {
    this.loading = true;
    form.form.markAllAsTouched();

    if (form.value.name) {
      form.controls.name.setValue(form.value.name.trim(), { emitEvent: false });
    }

    if (form.value.surname) {
      form.controls.surname.setValue(form.value.surname.trim(), {
        emitEvent: false,
      });
    }

    form.controls.marketing_opt.setValue(
      !!form.value.marketing_opt,
      { emitEvent: false }
    );
    form.controls.profiling_opt.setValue(!!form.value.profiling_opt, {
      emitEvent: false,
    });

    if (
      form.valid &&
      this.accessService.canRegister &&
      form.value.password === form.value.password_confirmation
    ) {
      this.error = {};
      this.errorMessage = null;
      this.isTaxcodeExisting = false;
      this.submitted = true;

      this.accessService.register(form.value).subscribe(
        () => {
          this.loading = false;
          this.router.navigate(['/verify']);
        },
        res => {
          this.loading = false;
          this.submitted = false;
          if (res.error && typeof res.error === 'object') {
            let error = res.error;
            Object.keys(error).forEach(k => {
              if (form.controls[k]) {
                this.error[k] = error[k];
                form.controls[k].markAsPristine();
              }
            });

            if (error.email == 'email è già stato utilizzato.') {
              this.accessService.isMailFree = false;
              this.accessService.canRegister = false;
            }

            if (error.tax_code == 'codice fiscale è già stato utilizzato.') {
              this.isTaxcodeExisting = true;
            }

            if (error.message) this.errorMessage = error.message;
            else
              this.errorMessage =
                'Errore di validazione, controlla i dati inseriti e riprova';
          } else {
            this.errorMessage = 'Si è verificato un errore, riprova più tardi';
          }
          console.error(res);
        }
      );
    } else {
      this.errorMessage =
        'Errore di validazione, controlla i dati inseriti e riprova';
      this.loading = false;
    }
  }
}
