import { Component } from "@angular/core";
import { AutoLogoutService } from '../../../logged-in/services/autologout.service';

@Component({
  selector: 'cp-verify-notice',
  templateUrl: './verify-notice.component.html',
  styleUrls: [],
})
export class VerifyNoticeComponent {
  constructor(autoLogoutService: AutoLogoutService) {
    autoLogoutService.logoutLater();
  }
}
