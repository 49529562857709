import {Component, Input, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {UserService} from '../../../../../../shared/services/user.service';
import * as moment from 'moment';
import { InvoiceModel } from '../../../../../../shared/models/invoices.model';
import { map} from 'rxjs/operators';
import { UserServiceObject } from '../../../../../../shared/models/user.model';
import { SubscriptionSelectionDialog } from './subscription-selection-dialog/subscription-selection-dialog.component';
import { PdfService } from '../../../../../../shared/services/pdf.service';
import { UnexpectedErrorDialogComponent } from 'src/app/shared/features/unexpected-error-dialog/unexpected-error-dialog.component';

@Component({
  selector: 'cp-client-invoices-view',
  templateUrl: './invoices-view.component.html',
  styleUrls: [],
})
export class InvoicesViewComponent implements OnInit, OnChanges {

  public expanded: boolean = false;

  // Control of actions
  private modalShown = false;

  @Input()
  public invoices: InvoiceModel[];
  public invoicesToShow: InvoiceModel[];

  public upcomingInvoice: InvoiceModel;
  public upcomingInvoiceLoading: boolean = false;
  public lastInvoice: InvoiceModel;

  public upcomingInvoiceValue: string;
  public upcomingInvoiceDate: string;
  public lastInvoiceValue: string;
  public lastInvoiceDate: string;

  public userServiceFilter: UserServiceObject;
  public userServices: UserServiceObject[];

  public download: (invoice: InvoiceModel) => void;
  public loading: boolean;

  public showCount: number = 3;
  public canShowMore : boolean

  constructor(
    private readonly userService: UserService,
    private readonly modalService: NgbModal,
    private readonly pdfService: PdfService
  ) {
    let self = this;
    this.download = (async (invoice: InvoiceModel) => {
      self.loading = true;
      self.pdfService
        .DownloadInvoicePdf(invoice.id ?? '', invoice)
        .subscribe(
          res => {},
          ex => {
            self.modalShown = true;
            let ueRef = self.modalService.open(UnexpectedErrorDialogComponent, {
              centered: true,
            });
            ueRef.result.finally(() => {
                self.modalShown = false;
            });
            console.log(ex);
          }
        )
        .add(x => {
          self.loading = false;
        });
    }).bind(self);
  }

  ngOnInit() {
    this.recomputeValues();
    this.userService.subscribeSubscriptions()
      .pipe(map(uses => {return uses;}))
      .subscribe(uses => {
        this.userServices = uses;
      });
  }

  toggleExpandIfNotSelected() {
    const selection = window.getSelection();
    if ((selection.type !== undefined && selection.type != 'Range') || (selection.type === undefined && !selection.toString())) {
      this.toggleExpand();
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.invoices) {
      this.recomputeValues();
    }
  }

  recomputeValues() {
    if (!this.invoices || this.invoices.length === 0) {
      this.lastInvoice = null;
    } else {
      this.lastInvoice = this.invoices[0];
      this.lastInvoiceDate = moment.unix(this.lastInvoice.internal_number_date).format('DD/MM/YYYY');
      if (this.lastInvoice.unsolved_object) {
        this.lastInvoiceValue = ((this.lastInvoice.total + this.lastInvoice.unsolved_object.unsolved_invoice.total) / 100).toFixed(2) + ' €';
      } else {
        this.lastInvoiceValue = (this.lastInvoice.total / 100).toFixed(2) + ' €';
      }
    }
    this.fetchUpcomingInvoice();
    this._filterInvoicesShown();
  }

  filterInvoices() {
    if (this.modalShown) return;
    this.modalShown = true;
    let ref = this.modalService.open(SubscriptionSelectionDialog, {centered: true});
    ref.result.then(us => {
      this.userServiceFilter = us;
      this.recomputeValues();
    }).catch(err => {
      console.log('Rejected!');
    }).finally(() => {
      this.modalShown = false;
    });
    ref.componentInstance.userServices = this.userServices;
    ref.componentInstance.preselectedUserService = this.userServiceFilter;
  }

  unfilterInvoices() {
    this.userServiceFilter = null;
    this.recomputeValues();
  }

  _filterInvoicesShown() {
    const filteredInvoices = this.userServiceFilter
        ? this.invoices.filter(inv => inv.subscription.id === this.userServiceFilter.id)
        : this.invoices;
    this.canShowMore = this.showCount < filteredInvoices.length
    this.invoicesToShow = filteredInvoices.slice(0, this.showCount)
  }

  showMore(){
      this.showCount += 3
      this._filterInvoicesShown()
  }

  fetchUpcomingInvoice() {
    this.upcomingInvoiceLoading = true;
    this.upcomingInvoice = undefined;
    this.userService.subscribeUpcomingInvoice()
      .subscribe(invoice => {
        this.upcomingInvoice = invoice;
        if (this.upcomingInvoice && !(this.upcomingInvoice as any)?.error) {
          this.upcomingInvoiceDate = moment.unix(this.upcomingInvoice.internal_number_date).format('DD/MM/YYYY');
          this.upcomingInvoiceValue = (this.upcomingInvoice.total / 100).toFixed(2) + ' €';
        } else {
          this.upcomingInvoiceDate = "-";
          this.upcomingInvoiceValue = "";
        }
        this.upcomingInvoiceLoading = false;
      });
  }

  _formatDateString(value: string|Date, fmt: string = 'DD/MM/YYYY', utc = false) {
    const date = utc ? moment.utc(value) : moment(value);
    return utc ? date.local().format(fmt) : date.format(fmt);
  }
}
