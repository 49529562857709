import {AfterViewInit, Component, Input} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'cp-unexpected-error',
  template: `
    <section class="unexpected-error container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <h1 class="text-center text-secondary">QUALCOSA È ANDATO STORTO...</h1>
          <h4 class="text-primary text-center" *ngIf="redirectTo">Tra pochi secondi verrai riportato su webfit.it</h4>
          <h4 class=" text-center" *ngIf="!redirectTo">
            Riprova più tardi
            <br>
            <a class="btn btn-outline-secondary-text-primary mt-7" [routerLink]="['/home']" *ngIf="showHomeLink">Vai alla dashboard</a>
          </h4>
        </div>
      </div>
    </section>
  `,
  styles: [`
      .unexpected-error {
          height: 650px;
          padding-top: 200px;
      }
  `]
})
export class UnexpectedErrorComponent implements AfterViewInit {
  @Input() redirectTo: string;
  @Input() showHomeLink: boolean = true;

  constructor() {}

  ngAfterViewInit(): void {
    if (!this.redirectTo) return;
    if (!environment.production) {
      console.log('Now i should redirect to ' + this.redirectTo )
      return;
    }
    setTimeout(() => {
      window.location.href = this.redirectTo;
    }, 2000);
  }
}
