import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractAuthorizationService} from '../../core/api/services/abstract-authorization.service';
import {Router} from '@angular/router';
import {GymModel} from '../../shared/models/gym.model';
import {environment} from '../../../environments/environment';
import {CorporateStorageService} from '../../core/api/services/corporate-storage.service';
import {FreeTrialService} from '../../features/logged-in/features/free-trial/services/free-trial.service';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ListInterface } from '../models/type.model';

@Injectable()
export class GymService extends AbstractAuthorizationService {

  gyms: GymModel[] = undefined;
  selectableGyms: GymModel[] = undefined;
  defaultGym: GymModel = null;
  defaultGymId: string = null;

  constructor(router: Router, private httpClient: HttpClient, private corporateStorageService: CorporateStorageService, private purchaseTrialService: FreeTrialService) {
    super(router);
  }

  getGyms() {
    return this.httpClient
      .post<ListInterface<GymModel>>(`${environment.apiUrl}/gyms`, {})
      .pipe(map(response => response.data));
  }

  retrieveGyms() {
    return this.getGyms()
      .pipe(tap(gyms => {
        this.gyms = gyms;
        this.refreshPreferredGym();
      }));
  }

  refreshPreferredGym(filter: (gym: GymModel) => boolean = (x) => true) {
    this.selectableGyms = this.gyms.filter(filter);
    const preferredGymId = this.corporateStorageService.getGymId() || (this.defaultGym && this.defaultGym.id);
    this.defaultGym = this.selectableGyms.find(it => it.id === this.defaultGymId) || this.selectableGyms.find(it => it.id === preferredGymId);
    this.defaultGymId = this.defaultGym ? this.defaultGym.id : null;
    this.purchaseTrialService.setGym(this.defaultGym);
  }

  getSelectableGym(gymId: string) {
    return this.selectableGyms.find(it => it.id === gymId);
  }

  hasOpenDateInTheFuture(gymId: string) {
    if (this.gyms && gymId) {
      const gym = this.gyms.find(g => g.id === gymId);
      return gym && gym.open_date && gym.is_in_presale_mode;
    }
    return false;
  }

  clear() {
    this.gyms = [];
    this.defaultGym = null;
  }
}
