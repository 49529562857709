import {Component, OnInit} from '@angular/core';
import { CorporateStorageService } from '../../../api/services/corporate-storage.service';
import { ConfigurationService } from '../../../../shared/services/configuration.service';
import { environment } from '../../../../../environments/environment';
import * as moment from "moment";
import {map} from 'rxjs/operators';

@Component({
  selector: 'cp-footer',
  templateUrl: './footer.component.html',
  styleUrls: [],
})
export class FooterComponent implements OnInit {

  public contractUrl: string = environment.legal.contractUrl;
  public customerServiceEmail: string = environment.customer_service.email;
  public customerServicePhone: string = environment.customer_service.phone;
  public customerServiceActiveTimeDescription = '';
  public year = '2022';

  constructor(
    public corporateStorageService: CorporateStorageService,
    public configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    this.year = moment().format('YYYY');
    this.configurationService.subscribeConfiguration().subscribe(config => {
      this.customerServiceActiveTimeDescription = config.customer_service_active_time;
    });
  }
}
