import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentCard } from '../../models/payments.model';
import { PaymentElementComponent } from '../payment-element/payment-element.component';
import { UserModel } from '../../models/user.model';

@Component({
    selector: 'cp-payment-modal',
    templateUrl: './payment-dialog.component.html',
    styleUrls: [],
})
export class PaymentDialog {

    @Input()
    public user: UserModel;
    @Input()
    public paymentCards: PaymentCard[];
    @Input()
    public title: string;
    @Input()
    public label: string;
    @Input()
    public total: number;
    @Input()
    public intentClientSecret: string;

    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

    public loading: boolean = false;
    public canPay: boolean = false;
    @ViewChild('paymentCardSelector', { static: true }) paymentElement: PaymentElementComponent;

    constructor(public modal: NgbActiveModal) { }

    sub() {
        if (this.loading || !this.canPay) return;
        this.loading = true;
        this.paymentElement.confirmIntent().then(
            _ => { this.onSubmit.emit(); },
            e => { this.onSubmit.error(e); }
        )
    }

    formatBalance() {
        return this.total.toFixed(2) + ' €';
    }

    onCanSubmitChange(isValid) {
        this.canPay = isValid;
    }
}
