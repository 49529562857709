<section class="my-3 mt-lg-10 d-flex flex-column flex-fill">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 col-md-10 col-xl-6 mx-auto">
                <h1 class="text-secondary text-center">RECUPERO PASSWORD</h1>
                <h4 *ngIf="!response || !response?.success" class="text-primary text-center">
                    Non preoccuparti, succede ai migliori.<br />Inserisci la tua email.
                </h4>
                <h4 *ngIf="response && response?.success" class="text-primary text-center">
                    Ti abbiamo inviato un'email con le istruzioni per procedere.
                </h4>

                <div *ngIf="!response || !response.success">
                    <div class="text line-form form--secondary form--color-textborder">
                        <div class="gform_wrapper">
                            <form
                                #f="ngForm"
                                (ngSubmit)="
                                    !this.loading && (!this.response || !this.response.success)
                                        ? f.valid
                                            ? checkEmail(f)
                                            : f.form.markAllAsTouched()
                                        : null
                                "
                                *ngIf="!response?.success"
                            >
                                <ul class="gform_fields">
                                    <li>
                                        <label for="EmailInput">Email</label>
                                        <input
                                            id="EmailInput"
                                            #email="ngModel"
                                            type="email"
                                            name="email"
                                            [ngModel]
                                            required
                                            email
                                            [disabled]="loading"
                                            (ngModelChange)="response = undefined"
                                            class="form-control"
                                            [ngClass]="{
                                                'is-invalid':
                                                    (email.invalid && email.touched) || (response && !response.success)
                                            }"
                                            autofocus
                                        />

                                        <small
                                            class="invalid-tooltip"
                                            [hidden]="email.valid || (email.untouched && !f.submitted)"
                                        >
                                            La mail non è valida.
                                        </small>
                                        <small class="invalid-tooltip" [hidden]="!response || response?.success">
                                            La mail inserita non è stata trovata nel sistema
                                        </small>
                                    </li>
                                    <li class="row mt-5">
                                        <div class="col-md-6">
                                            <button
                                                type="submit"
                                                class="btn btn-outline-secondary-text-primary btn-block"
                                            >
                                                <span
                                                    class="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    *ngIf="loading"
                                                ></span>
                                                Invia Mail
                                            </button>
                                        </div>
                                        <div class="col-md-6 mt-md-0 mt-1">
                                            <button class="btn btn-outline-primary btn-block" routerLink="/login">
                                                Annulla
                                            </button>
                                        </div>
                                    </li>
                                    <li class="text-center mt-2">
                                        <ng-template #popContent>
                                            <h5 class="text-center text-primary my-1">CONTATTACI</h5>
                                            <dl class="row mx-0 mb-0">
                                                <dt class="col-4">Orario :</dt>
                                                <dd class="col-8">{{ customerServiceActiveTimeDescription }}</dd>
                                                <dt class="col-4">Tel :</dt>
                                                <dd class="col-8">
                                                    <a href="tel:{{ customerServicePhoneForLink }}"> {{ customerServicePhone }}  </a>
                                                </dd>
                                                <dt class="col-4">Email :</dt>
                                                <dd class="col-8">
                                                    <a href="mailto:{{ customerServiceEmail }}">
                                                        {{ customerServiceEmail }}
                                                    </a>
                                                </dd>
                                            </dl>
                                        </ng-template>
                                        <a
                                            class="force-href-style text-reset"
                                            [ngbPopover]="popContent"
                                            placement="bottom"
                                            popoverClass="wider popover-secondary"
                                        >
                                            Non ti ricordi con quale email ti sei iscritto?
                                        </a>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
