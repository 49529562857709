import {Component, ElementRef, forwardRef, HostBinding, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MapsAPILoader} from '@agm/core';
// @ts-ignore
import { } from 'googlemaps';
import {Address, AddressModel} from '../models/address.model';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

@Component({
  selector: 'cp-amg-autocomplete',
  template: `
    <div>
      <input
        #searchTextField
        [placeholder]="placeholder ? placeholder : ''"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        class="form-control"
        (blur)="onBlur()"
        (change)="onChange()"
        [value]="current?.label"
        [ngClass]="{ 'is-invalid': isInvalid }"
        >
    </div>
    <agm-map
      *ngIf="!hideMap"
      [latitude]="latitude"
      [longitude]="longitude"
      [scrollwheel]="false"
      [zoom]="zoom"
    >
      <agm-marker
        [latitude]="latitude"
        [longitude]="longitude"
      ></agm-marker>
    </agm-map>
  `,
  styles: [`
    agm-map {
      height: 300px;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgmAutocompleteComponent),
      multi: true
    }
  ]
})


export class AgmAutocompleteComponent implements OnInit, ControlValueAccessor {

  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;

  private onChangeFn;
  private onTouchFn;

  public current: AddressModel;

  @ViewChild('searchTextField', { static: true }) public searchElementRef: ElementRef;

  @Input() defaultPosition: number[];
  @Input() defaultZoom: number;
  @Input() userPosition: boolean;
  @Input() hideMap: boolean;
  @Input() placeholder: string;
  @HostBinding("class.is-invalid") @Input() isInvalid: boolean;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    // set google maps defaults
    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;

    // create search FormControl
    this.searchControl = new FormControl();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;

          this.current = {
            address: this.generateAddressFronArray(place.address_components),
            coordinates: [this.latitude, this.longitude],
            position: [this.latitude, this.longitude],
            label: place.formatted_address,
            type: 'Point',
            zoom: this.zoom
          };

          this.onChangeFn(this.current);
        });
      });
    });
  }

  private generateAddressFronArray(address: GeocoderAddressComponent[]): Address {
    const out = {};
    address.forEach(a => out[a.types[0]] = a.short_name);
    return out as Address;
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  // called when the form is initialized, with the form model’s initial value
  writeValue(value: AddressModel) {
    if (!value) {
      if (this.userPosition) {
        this.setCurrentPosition();
      } else {
        if (this.defaultPosition) {
          this.latitude = this.defaultPosition[0];
          this.longitude = this.defaultPosition[1];
        }
        if (!isNaN(this.defaultZoom)) {
          this.zoom = this.defaultZoom;
        }
      }
    } else {
      this.current = value;
      this.current.position = this.current.coordinates;
      this.latitude = value.coordinates[0];
      this.longitude = value.coordinates[1];
    }
  }

  onChange() {
    this.onBlur();
  }

  onBlur() {
    if (this.onTouchFn) {
      this.onTouchFn();
    }
  }

  // has access to a function that informs the outside world about changes
  registerOnChange(fn) {
    this.onChangeFn = fn;
  }

  // registers a callback that is excuted whenever a form control is “touched”
  registerOnTouched(fn) {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    console.log(isDisabled);
  }
}
