import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthorizationService } from '../../../api/services/authorization.service';
import { CorporateStorageService } from '../../../api/services/corporate-storage.service';
import { UserModel } from '../../../../shared/models/user.model';
import {Router} from '@angular/router';

@Component({
  selector: 'cp-header',
  templateUrl: './header.component.html',
  styleUrls: [],
})
export class HeaderComponent implements OnInit {
  user: UserModel;

  constructor(
    public authorizationService: AuthorizationService,
    public corporateStorageService: CorporateStorageService,
    public readonly userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userService.subscribeHasUser().subscribe(hasUser => {
      if(hasUser){
        this.userService.subscribe().subscribe(u => {
          this.user = u;
        });
      }
    })
  }

  navigateToSettings() {
    this.router.navigate(['/settings']);
    return false;
  }
}
