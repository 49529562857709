import { Component, EventEmitter, Output, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { UserServiceObject } from '../../../../../../../shared/models/user.model';
import { HelperService } from '../../../../../../../shared/services/helper.service';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'cp-create-suspension-modal',
    templateUrl: './create-suspension-dialog.component.html',
    styleUrls: [],
})
export class CreateSuspensionDialog implements OnInit {
    @Input() public userSubscription: UserServiceObject;
    @Input() public maxSuspensionDuration: number;
    @Output() onSubmit = new EventEmitter<{sub_sus_start_date: Moment;description: string;}>();
    @ViewChild('durationControl') durationControl :NgModel;
    
    public loading: boolean = false;

    public subscriptionCategory: "standard"|"flex";
    public minDate: NgbDate;
    public maxDate: NgbDate;
    public startDate: NgbDate;
    public selectedDate: Moment;
    public dateErrors: any = null;
    public selectableDates: {key: string; value: Moment; maxDuration: number}[] = [];
    
    public duration: number;
    public description: string = '';
    public submitted: boolean = false;

    constructor(
        public readonly modal: NgbActiveModal,
        private readonly helperService: HelperService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    ngOnInit() { 
        const start = this.helperService.stringToMomentAtTZ(this.userSubscription.sub_start_date);
        const end = this.helperService.stringToMomentAtTZ(this.userSubscription.sub_end_date);
        const now = moment();
        
        this.subscriptionCategory = this.userSubscription.plan.subscription_category;
        switch (this.subscriptionCategory) {
            case "standard":
                this.minDate = this.helperService.getDateForPicker(start.isAfter(now) ? start : now.add(1, 'day'));
                this.startDate = this.minDate;
                this.maxDate = this.helperService.getDateForPicker(end);
                break;
            case "flex":
                let susStartDate = end.add(1, 'day').startOf('day');
                this.onDateChange(susStartDate);
        }
    }

    onDateChange(date: Moment) {
        this.selectedDate = date;
    }


    sub() {
        if (this.loading) return;
        this.loading = true;
        let params = {
            sub_sus_start_date: this.selectedDate,
            description: this.description,
        }
        if(this.subscriptionCategory == "flex"){
            params['duration'] = this.duration;
        }
        this.onSubmit.emit(params);
    }
}
