import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "cp-root",
    template: `
        <div id="MainContainer">
            <cp-header></cp-header>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
        <cp-footer></cp-footer>
        <cp-cookie-alert></cp-cookie-alert>
    `,
    styles: [
        `
            :host {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            #MainContainer {
                min-height: 100vh;
                display:flex;
                flex-direction: column;
            }
        `,
    ],
})
export class AppComponent {

    constructor(private route: ActivatedRoute) {}

    onActivate(event) {
        // do nothing if there is an URL fragment
        if (this.route.snapshot.fragment) return;
        // otherwise scroll to top
        setTimeout(() => window.scrollTo(0, 0), 0);
    }
}
