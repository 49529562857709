<section class="card bg-primary border-0 rounded text-white mx-n2">
  <div class="card-body p-md-3 p-lg-4">
    <div class="text-center">
      <div class="mb-2">
        <h4 class="text-secondary">SITUAZIONE CONTABILE</h4>
      </div>
      <p class="">Risultano dei pagamenti da saldare!</p>
      <p>Totale: {{ user.outstanding_balance.toFixed(2) + " €" }}</p>
    </div>

    <div *ngIf="expanded" class="mb-2">
      <cp-client-unsolved *ngFor="let unsolved of unsolveds" [unsolved]="unsolved"></cp-client-unsolved>

      <ng-container *ngIf="openInvoices && openInvoices.length">
        <div class="text-center mt-1">{{unsolveds.length? 'Altre fatture' : 'Fatture'}} correlate:</div>

        <div class="">
          <ng-container *ngFor="let invoice of openInvoices">
            <div class="mt-1">
              <cp-client-invoice [invoice]="invoice" #invoiceComponent></cp-client-invoice>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center"></div>
    </div>

    <div class="d-flex justify-content-center pt-md-2 pt-lg-3">
       <div class="flex-fill mr-2 mr-md-3">
        <button
          type="button"
          (click)="payOutstandingBalance()"
          class="btn btn-block btn-outline-secondary-text-white"
        >
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="modalShown"></span>
          Paga
        </button>
      </div>
        <button
          type="button"
          (click)="toggleExpand()"
          class="btn btn-icon-outline-secondary"
          [attr.aria-expanded]="expanded"
          aria-controls="CardsCollapse"
        >
          <i class="ci" [ngClass]="expanded ? 'ci-minus-white' : 'ci-plus-white'"></i>
        </button>

    </div>
  </div>
</section>
