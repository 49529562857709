import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AuthorizationService} from '../services/authorization.service';

@Injectable()
export class NeedLoginGuard implements CanActivate {

  constructor(private authorizationService: AuthorizationService, private router: Router) { }

  // @ts-ignore
  canActivate(state: RouterStateSnapshot): boolean {
    if (!this.authorizationService.isAuthenticated()) {
      const extras = { queryParams: { returnUrl: state.url }};
      if (state.url === 'logout') {
        delete extras.queryParams;
      }

      this.router.navigate([environment.default_roots.not_logged], extras);
      return false;
    }
    return true;
  }
}
