import { HelperService } from "./../../../../../../../shared/services/helper.service";
import {Component, Input, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import * as moment from 'moment';
import { InvoiceModel } from '../../../../../../../shared/models/invoices.model';

@Component({
  selector: 'cp-client-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit, OnChanges {

  public expanded: boolean = false;

  @Input()
  public invoice: InvoiceModel;
  @Input()
  public download: (invoice: InvoiceModel) => void;

  public invoiceNumber: string;
  public invoiceDate: string;
  public invoiceValue: string;
  public invoiceType: string;
  public invoiceStatus: string;
  public invoiceStatusCssClass: string;
  public unsolvedInvoiceValue: string;
  public baseInvoiceValue: string;

  constructor(
    private readonly helper: HelperService
  ) {
  }

  ngOnInit() {
    this.recomputeValues();
  }

  toggleExpandIfNotSelected() {
    const selection = window.getSelection();
    if ((selection.type !== undefined && selection.type != 'Range') || (selection.type === undefined && !selection.toString())) {
      this.toggleExpand();
    }
  }

  public toggleExpand() {
    this.expanded = !this.expanded;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.invoice) {
      this.recomputeValues();
    }
  }

  _formatPrice(value) {
    return (value / 100).toFixed(2) + ' €';
  }

  recomputeValues() {
    this.invoiceNumber = ("" + this.invoice.internal_number).substring(2);
    this.invoiceDate = moment.unix(this.invoice.internal_number_date).format('DD/MM/YYYY');
    if (this.invoice.unsolved_object) {
      this.baseInvoiceValue = this._formatPrice(this.invoice.total);
      this.invoiceValue = this._formatPrice(this.invoice.total + this.invoice.unsolved_object.unsolved_invoice.total);
      this.unsolvedInvoiceValue = this._formatPrice(this.invoice.unsolved_object.unsolved_invoice.total);
    } else {
      this.invoiceValue = this._formatPrice(this.invoice.total);
    }
    this.invoiceType = this.helper.guessInvoiceDescription(this.invoice);
    this.invoiceStatus = this.invoiceStatusCssClass = '';
    if (this.invoice.dispute_status != 'none' && this.invoice.unsolved !== false) {
      this.invoiceStatusCssClass = 'disputed';
      switch (this.invoice.dispute_status) {
        case 'needs_response':
          this.invoiceStatus = 'Disputa aperta';
          break;
        case 'under_review':
          this.invoiceStatus = 'Sotto Esame';
          break;
        case 'won':
          // everything is fine
          break;
        case 'lost':
          if (this.invoice.unsolved) {
            this.invoiceStatus = 'Insoluta';
            this.invoiceStatusCssClass = 'unsolved';
          } else {
            // Probably a paid unsolved, so fine
            this.invoiceStatusCssClass = '';
          }
          break;
        case 'charge_refunded':
          this.invoiceStatus = 'Rimborsata';
          this.invoiceStatusCssClass = 'refunded';
          break;
      }
    } else {
      switch (this.invoice.status) {
        case 'void':
          this.invoiceStatus = 'Non Dovuta';
          this.invoiceStatusCssClass = 'void';
          break;
        case 'paid':
          this.invoiceStatus = 'Pagata';
          break;
        case 'draft':
          this.invoiceStatus = 'In Preparazione'
          this.invoiceStatusCssClass = 'draft';
          break;
        case 'open':
          this.invoiceStatus = 'Da Pagare';
          this.invoiceStatusCssClass = 'open';
          break;
      }
    }
  }

  getCreatedAtDate() {
    return moment.unix(this.invoice.internal_number_date).format('DD/MM/YYYY')
  }

  _formatDateString(value: string|Date, fmt: string = 'DD/MM/YYYY', utc = false) {
    const date = utc ? moment.utc(value) : moment(value);
    return utc ? date.local().format(fmt) : date.format(fmt);
  }
}
