import { UserService } from '../../../shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from '../services/token-storage.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
  template: ``,
  styles: []
})
export class LogoutComponent implements OnInit {

  constructor(private tokenStorageService: TokenStorageService, private router: Router, private http: HttpClient, private userService: UserService) { }

  ngOnInit() {
    this.http.post(`${environment.apiUrl}/auth/logout`, {})
      .subscribe(
        res => this.onLogoutFinished(),
        err => this.onLogoutFinished()
      );
  }

  private onLogoutFinished() {
    this.tokenStorageService.setAccessToken();
    this.userService.clear();
    this.router.navigate([environment.default_roots.not_logged]);
  }
}
