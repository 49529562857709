import {Component} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'cp-cookie-alert',
  template: `
    <div class="cookie-alert" *ngIf="!areCookiesAccepted">
      <div>
      Questo sito utilizza cookie, anche di terze parti, per inviarti pubblicità e servizi in linea con i tuoi gusti.
      Per saperne di più o negare il consenso a tutti o ad alcuni cookie <a target="_blank" href="//www.webfit.it/blog/cookie-policy/" class="text-secondary">clicca qui</a>.
      Chiudendo questo banner, scorrendo questa pagina o cliccando qualunque suo elemento acconsenti all'utilizzo dei cookie.
      </div>
      <a class="btn btn-default close-cookie-alert h3 mb-0 p-0 text-white" (click)="acceptCookies()" [routerLink]="">&times;</a>
    </div>
  `,
  styles: [`
    .cookie-alert {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 100px 20px 20px;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      z-index: 9999;
    }

    .close-cookie-alert {
      position: absolute;
      top: 10px;
      right: 20px;
      line-height: 1em;
    }
  `]
})
export class CookieAlertComponent {

  public areCookiesAccepted = false;

  constructor(
    private cookieService: CookieService,
  ) {
    this.areCookiesAccepted = this.cookieService.get('cookiebar') === 'hide';
  }

  acceptCookies() {
    this.areCookiesAccepted = true;
    this.cookieService.set('cookiebar', 'hide');
  }
}
