import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from './features/login/login.component';
import {RegisterComponent} from './features/registration/register.component';
import {PasswordRecoveryStartComponent} from './features/password-recovery/password-recovery-start/password-recovery-start.component';
import {PasswordRecoverComponent} from './features/password-recovery/password-recovery/password-recover.component';
import {ApiModule} from '../../core/api/api.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {GuestRoutingModule} from './guest-routing.module';
import {AccessService} from './services/access.service';
import {SharedModule} from "../../shared/shared.module"
import { VerifyNoticeComponent } from './features/verification/verify-notice.component';
import { VerifyActionComponent } from './features/verification/verify-action.component';
import {EmailChangedNoticeComponent} from './features/email-changed-notice/email-changed-notice.component';
import {EmailVerifiedNoticeComponent} from './features/email-verified-notice/email-verified-notice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from './services/image.service';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    PasswordRecoveryStartComponent,
    PasswordRecoverComponent,
    VerifyNoticeComponent,
    VerifyActionComponent,
    EmailChangedNoticeComponent,
    EmailVerifiedNoticeComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ApiModule,
    FormsModule,
    NgbModule,
    GuestRoutingModule,
    SharedModule,
  ],
  providers: [
    AccessService,
    ImageService
  ]
})
export class GuestModule {
}
