import { Component, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cp-delete-suspension-modal',
    templateUrl: './delete-suspension-dialog.component.html',
    styleUrls: [],
})
export class DeleteSuspensionDialog {
    public loading: boolean = false;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

    constructor(public modal: NgbActiveModal) {}

    sub() {
        if (this.loading) return;
        this.loading = true;
        this.onSubmit.emit();
    }
}
