<div class="row justify-content-center">
    <div class="col-lg-6 mt-2" *ngFor="let subscription of subscriptions">
        <section
            [ngClass]="isPromoOrExtraAllowedOrPresale(subscription) ? 'yellow-shadow bg-secondary text-body' : 'bg-primary  text-white'"
            class="card border-0 rounded subscription-item text-center">
            <div class="card-body p-md-3 p-lg-4">
                <svg *ngIf="isPromoOrExtraAllowedOrPresale(subscription)"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                     class="on-sale-icon">
                    <path
                        d="M49.06.44A1.5,1.5,0,0,0,47,.43h0L42.69,4.69A7.16,7.16,0,0,0,38.78,3.5H25.5a7,7,0,0,0-5,2.12L2.15,24A6.75,6.75,0,0,0,0,29.1a7.07,7.07,0,0,0,2.12,5.1L15.3,47.37a7.08,7.08,0,0,0,5.1,2.13,6.71,6.71,0,0,0,5.08-2.1L43.88,29A7,7,0,0,0,46,24V10.72a7,7,0,0,0-1.19-3.91l4.25-4.25a1.48,1.48,0,0,0,0-2.1ZM43,10.72V24a3.9,3.9,0,0,1-1.25,2.87L23.31,45.32A3.75,3.75,0,0,1,20.4,46.5a4.06,4.06,0,0,1-3-1.25L4.25,32.08A4.07,4.07,0,0,1,3,29.1a3.8,3.8,0,0,1,1.23-3L22.62,7.75A4,4,0,0,1,25.5,6.5H38.78a4.19,4.19,0,0,1,1.71.39L36.42,11A6.49,6.49,0,1,0,33,23h0a6.43,6.43,0,0,0,6.5-6.38V16.5a6.58,6.58,0,0,0-1-3.42L42.61,9A4,4,0,0,1,43,10.72Zm-9.56,5.34a1.49,1.49,0,0,0,2.12,0l.73-.73a3.53,3.53,0,1,1-2.12-2.12l-.73.73a1.5,1.5,0,0,0,0,2.11Z"
                        fill="#1d356a"/>
                    <polygon points="17 30.5 15 28.5 13 30.5 15 32.5 17 30.5" fill-rule="evenodd" fill="#1d356a"/>
                    <polygon points="21 34.5 19 32.5 17 34.5 19 36.5 21 34.5" fill-rule="evenodd" fill="#1d356a"/>
                    <polygon points="25 38.5 22.95 36.5 21 38.5 22.95 40.5 25 38.5" fill-rule="evenodd" fill="#1d356a"/>
                    <polygon points="13 26.45 11 24.5 9 26.55 11 28.5 13 26.45" fill-rule="evenodd" fill="#1d356a"/>
                </svg>
                <div
                    class="font-weight-bold mb-1 mt-lg-n2 mt-md-n1"
                    style="font-size: 0.75em;"
                >
                    {{ getPromoOrExtraAllowedOrPresaleText(subscription) }}
                </div>
                <h6 class="card-title text-uppercase accent">{{ subscription.name }}</h6>
                <div>
                    <span [ngbPopover]="this.getPaymentInfo(subscription)" triggers="click" placement="bottom">
                        {{ this.getPaymentTitle(subscription) }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="align-text-top" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"></path>
                        </svg>
                    </span>
                </div>
                <div class="accent">
                    <cp-subscription-price [subscription]="subscription"></cp-subscription-price>
                </div>
                <hr *ngIf="subscription.details_text"
                    [ngClass]="isPromoOrExtraAllowedOrPresale(subscription) ? 'border-primary' : 'border-light'"
                    style="border-width: 1px;">
                <div *ngIf="subscription.details_text" [innerHtml]="subscription.details_text"></div>
                <div *ngIf="subscription.is_presale">
                    <h6 class="accent">RICORDA:</h6>
                    Trattandosi di prevendita, i pagamenti cominceranno prima dell’apertura della palestra.
                    Presentati nel centro Webfit una volta che la palestra sarà inaugurata e comunque non oltre
                    quanto stabilito da contratto.
                </div>
                <div class="pre-contractual-link mb-1 mt-2">
                    *proseguendo accetti
                    <a [href]="getPreContractualDocsUrl(subscription)" target="_blank"
                       class="accent font-weight-bold mt-2">l'Informativa precontrattuale</a>
                </div>
                <button
                    type="submit"
                    class="btn btn-block"
                    [ngClass]="isPromoOrExtraAllowedOrPresale(subscription) ? 'btn-outline-primary' : 'btn-outline-secondary-text-white'"
                    [disabled]="working"
                    (click)="selectSubscription(subscription)"
                >
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="working && currentSelectedSubscription == subscription"
                    ></span>
                    Abbonati*
                </button>
            </div>
        </section>
    </div>
</div>
