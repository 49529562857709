import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PaymentCard } from '../models/payments.model';
import { ShowerProduct } from '../models/shower-product.model';
import { ListInterface } from '../models/type.model';
import VirtualProductModel from '../models/virtual-product.model.ts';

@Injectable()
export class ProductService {
    constructor(private http: HttpClient) {}

    public getAvailableShowerProducts(): Observable<ShowerProduct[]> {
        return this.http
            .post<ListInterface<ShowerProduct>>(
                `${environment.apiUrl}/products/showers/availablepacks`,
                {}
            )
            .pipe(map(response => response.data));
    }

    public bookShowerProduct(product: ShowerProduct): Observable<VirtualProductModel> {
        return this.http.post<VirtualProductModel>(`${environment.apiUrl}/products/showers/book`, {
            product_id: product.id,
        });
    }

    public buyShowerProduct(card: PaymentCard, v_product: VirtualProductModel) {
        return this.http.post<void>(`${environment.apiUrl}/products/showers/pay`, {
            source: card.src,
            payment_id: v_product.id
        });
    }
}
