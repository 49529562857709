export const Cities = [
  'Abano Terme',
  'Abbadia Alpina',
  'Abbadia Cerreto',
  'Abbadia Lariana',
  'Abbadia San Salvatore',
  'Abbadia Sopra Adda',
  'Abbasanta',
  'Abbateggio',
  'Abbazia',
  'Abbiategrasso',
  'Abbiateguazzone',
  'Abetone',
  'Abetone Cutigliano',
  'Abriola',
  'Acate',
  'Accadia',
  'Acceglio',
  'Accettura',
  'Acciano',
  'Accumoli',
  'Acerenza',
  'Acereto',
  'Acerno',
  'Acerra',
  'Aci Bonaccorsi',
  'Aci Castello',
  'Aci Catena',
  'Aci Sant\'Antonio',
  'Acireale',
  'Acqua',
  'Acquacanina',
  'Acquafondata',
  'Acquaformosa',
  'Acquafredda',
  'Acqualagna',
  'Acqualunga',
  'Acqualunga Badona',
  'Acquanegra Cremonese',
  'Acquanegra sul Chiese',
  'Acquapendente',
  'Acquappesa',
  'Acquarica Del Capo',
  'Acquaro',
  'Acquasanta Terme',
  'Acquasparta',
  'Acquate',
  'Acquaviva Collecroce',
  'Acquaviva Picena',
  'Acquaviva Platani',
  'Acquaviva d\'Isernia',
  'Acquaviva delle Fonti',
  'Acquedolci',
  'Acqui Terme',
  'Acri',
  'Acuto',
  'Adelfia',
  'Adrano',
  'Adrara San Martino',
  'Adrara San Rocco',
  'Adria',
  'Adro',
  'Affi',
  'Affile',
  'Affori',
  'Afragola',
  'Africo',
  'Agaro',
  'Agazzano',
  'Agerola',
  'Aggius',
  'Agira',
  'Agliana',
  'Agliano Terme',
  'Agliate',
  'Aglientu',
  'Agliè',
  'Agna',
  'Agnadello',
  'Agnana Calabra',
  'Agnellengo',
  'Agnona',
  'Agnone',
  'Agnosine',
  'Agordo',
  'Agosta',
  'Agra',
  'Agrano',
  'Agrate Brianza',
  'Agrate Conturbia',
  'Agrigento',
  'Agrone',
  'Agropoli',
  'Agugliano',
  'Agugliaro',
  'Aiba',
  'Aicurzio',
  'Aidomaggiore',
  'Aidone',
  'Aidussina',
  'Aielli',
  'Aiello Calabro',
'Aiello In Calabria',
'Aiello del Friuli',
'Aiello del Sabato',
'Aieta',
'Ailano',
'Ailoche',
'Airasca',
'Airola',
'Airole',
'Airuno',
'Aisone',
'Aizurro',
'Ala',
'Ala di Stura',
'Alagna',
'Alagna Valsesia',
'Alanno',
'Alano di Piave',
'Alassio',
'Alatri',
'Alba',
'Alba Adriatica',
'Albagiara',
'Albairate',
'Albanella',
'Albano Laziale',
'Albano Sant\'Alessandro',
'Albano Vercellese',
'Albano di Lucania',
'Albanova',
'Albaredo Arnaboldi',
'Albaredo d\'Adige',
'Albaredo per San Marco',
'Albareto',
'Albaretto Valle Di Macra',
'Albaretto della Torre',
'Albaro Vescovà',
'Albate',
'Albavilla',
'Albegno',
'Albenga',
'Alber Di Sesana',
'Albera Ligure',
'Alberobello',
'Alberona',
'Albes',
'Albese',
'Albese con Cassano',
'Albettone',
'Albi',
'Albiano',
'Albiano Di Magra',
'Albiano d\'Ivrea',
'Albiate',
'Albidona',
'Albignasego',
'Albinea',
'Albino',
'Albiolo',
'Albisola Superiore',
'Albissola Marina',
'Albizzate',
'Albogasio',
'Albogno',
'Albona',
'Albonese',
'Albosaggia',
'Albugnano',
'Albusciago',
'Albuzzano',
'Alcamo',
'Alcara li Fusi',
'Aldeno',
'Aldino',
'Ales',
'Alessandria',
'Alessandria del Carretto',
'Alessandria della Rocca',
'Alessano',
'Alezio',
'Alfano',
'Alfedena',
'Alfianello',
'Alfiano Ed Uniti',
'Alfiano Natta',
'Alfonsine',
'Alghero',
'Algua',
'Alia',
'Aliano',
'Alice Bel Colle',
'Alice Castello',
'Alice Superiore',
'Alife',
'Alimena',
'Aliminusa',
'Allai',
'Alleghe',
'Allein',
'Allerona',
'Alliste',
'Alliz',
'Allumiere',
'Alluvioni Cambio\'',
'Alluvioni Piovera',
'Alma',
'Almazzago',
'Almenno San Bartolomeo',
'Almenno San Salvatore',
'Almese',
'Almè',
'Alone',
'Alonte',
'Alpago',
'Alpette',
'Alpignano',
'Alseno',
'Alserio',
'Alta Val Tidone',
'Alta Valle Intelvi',
'Altamura',
'Altare',
'Altavalle',
'Altavilla Irpina',
'Altavilla Milicia',
'Altavilla Monferrato',
'Altavilla Silentina',
'Altavilla Vicentina',
'Alteta',
'Altidona',
'Altilia',
'Altino',
'Altissimo',
'Altivole',
'Alto',
'Alto Reno Terme',
'Alto Sermenza',
'Altofonte',
'Altomonte',
'Altopascio',
'Altopiano della Vigolana',
'Alviano',
'Alvignano',
'Alvito',
'Alzano Di Sopra',
'Alzano Lombardo',
'Alzano Maggiore',
'Alzano Scrivia',
'Alzate Brianza',
'Alzate Con Linduno',
'Alà dei Sardi',
'Alì',
'Alì Terme',
'Amalfi',
'Amandola',
'Amantea',
'Amaro',
'Amaroni',
'Amaseno',
'Amato',
'Amatrice',
'Ambivere',
'Amblar',
'Amblar-Don',
'Ameglia',
'Amelia',
'Amendolara',
'Ameno',
'Amorosi',
'Ampezzo',
'Anacapri',
'Anagni',
'Ancarano',
'Ancona',
'Andali',
'Andalo',
'Andalo Valtellino',
'Andezeno',
'Andogno',
'Andonno',
'Andora',
'Andorno Cacciorna',
'Andorno Micca',
'Andrano',
'Andrate',
'Andreis',
'Andretta',
'Andria',
'Andriano',
'Anela',
'Anfo',
'Anfurro',
'Angera',
'Anghiari',
'Angiari',
'Angolo Terme',
'Angri',
'Angrogna',
'Anguillara Sabazia',
'Anguillara Veneta',
'Anicova Corada',
'Annicco',
'Annone Veneto',
'Annone di Brianza',
'Anoia',
'Antegnate',
'Anterivo',
'Anterselva',
'Antey-Saint-André',
'Anticoli Corrado',
'Antignana',
'Antignano',
'Antillo',
'Antonimina',
'Antrodoco',
'Antrona Schieranco',
'Antronapiana',
'Anversa degli Abruzzi',
'Anzano del Parco',
'Anzano di Puglia',
'Anzi',
'Anzino',
'Anzio',
'Anzola d\'Ossola',
'Anzola dell\'Emilia',
'Aosta',
'Apecchio',
'Apice',
'Apiro',
'Apollosa',
'Apparizione',
'Appiano Gentile',
'Appiano sulla strada del vino',
'Appignano',
'Appignano del Tronto',
'Apriano',
'Aprica',
'Apricale',
'Apricena',
'Aprigliano',
'Aprilia',
'Apuania',
'Aquara',
'Aquila d\'Arroscia',
'Aquileia',
'Aquilonia',
'Aquino',
'Ara',
'Aradeo',
'Aragona',
'Aramengo',
'Aranco',
'Arba',
'Arbizzo',
'Arborea',
'Arborio',
'Arbus',
'Arcade',
'Arcagna',
'Arce',
'Arcellasco',
'Arcene',
'Arcevia',
'Archi',
'Arcidosso',
'Arcinazzo Romano',
'Arcisate',
'Arco',
'Arcola',
'Arcole',
'Arconate',
'Arcore',
'Arcugnano',
'Arcumeggia',
'Ardara',
'Ardauli',
'Ardea',
'Ardena',
'Ardenno',
'Ardesio',
'Ardole San Marino',
'Ardore',
'Arena',
'Arena Po',
'Arenzano',
'Arese',
'Arezzo',
'Argegno',
'Argelato',
'Argenta',
'Argentera',
'Arguello',
'Argusto',
'Ari',
'Ariano Irpino',
'Ariano nel Polesine',
'Ariccia',
'Arielli',
'Arienzo',
'Arienzo San Felice',
'Arignano',
'Arischia',
'Aritzo',
'Arixi',
'Arizzano',
'Arizzano Inferiore',
'Arizzano Superiore',
'Arlena di Castro',
'Arluno',
'Armeno',
'Armento',
'Armio',
'Armo',
'Armungia',
'Arnad',
'Arnago',
'Arnara',
'Arnasco',
'Arnate',
'Arnesano',
'Arola',
'Arolo',
'Arona',
'Arosio',
'Arpaia',
'Arpaise',
'Arpino',
'Arquata Scrivia',
'Arquata del Tronto',
'Arquà Petrarca',
'Arquà Polesine',
'Arre',
'Arrone',
'Arsago Seprio',
'Arsia',
'Arsiero',
'Arsita',
'Arsiè',
'Arsoli',
'Arta Terme',
'Artegna',
'Artena',
'Artogne',
'Artò',
'Arvier',
'Arzachena',
'Arzago d\'Adda',
'Arzana',
'Arzano',
'Arzene',
'Arzeno D\'Oneglia',
'Arzergrande',
'Arzignano',
'Ascea',
'Asciano',
'Ascoli Piceno',
'Ascoli Satriano',
'Ascrea',
'Asiago',
'Asigliano Veneto',
'Asigliano Vercellese',
'Asnago',
'Asola',
'Asolo',
'Assago',
'Assemini',
'Assisi',
'Asso',
'Assolo',
'Assoro',
'Asti',
'Asuni',
'Ateleta',
'Atella',
'Atella Di Napoli',
'Atena Lucana',
'Atessa',
'Atina',
'Atrani',
'Atri',
'Atripalda',
'Attigliano',
'Attimis',
'Atzara',
'Auditore',
'Augusta',
'Auletta',
'Aulla',
'Aurano',
'Auremo Di Sopra',
'Aurigo',
'Aurisina',
'Auronzo di Cadore',
'Ausonia',
'Austis',
'Auzate',
'Auzza',
'Avegno',
'Avelengo',
'Avella',
'Avellino',
'Avenone',
'Averara',
'Aversa',
'Avesa',
'Avetrana',
'Avezzano',
'Aviano',
'Aviatico',
'Avigliana',
'Avigliano',
'Avigliano Umbro',
'Avio',
'Avise',
'Avola',
'Avolasca',
'Avuglione E Vernone',
'Ayas',
'Aymavilles',
'Azeglio',
'Azzanello',
'Azzano Decimo',
'Azzano Mella',
'Azzano San Paolo',
'Azzano d\'Asti',
'Azzate',
'Azzio',
'Azzone',
'Baceno',
'Bacoli',
'Badalucco',
'Badesi',
'Badia',
'Badia Calavena',
'Badia Pavese',
'Badia Polesine',
'Badia Tedalda',
'Badolato',
'Bagaggera',
'Bagaladi',
'Baggio',
'Bagheria',
'Bagnacavallo',
'Bagnaja',
'Bagnara Calabra',
'Bagnara Di Benevento',
'Bagnara di Romagna',
'Bagnaria',
'Bagnaria Arsa',
'Bagnarolo',
'Bagnasco',
'Bagnasco D\'Asti',
'Bagnatica',
'Bagni di Lucca',
'Bagno',
'Bagno a Ripoli',
'Bagno di Romagna',
'Bagnoli Irpino',
'Bagnoli del Trigno',
'Bagnoli di Sopra',
'Bagnolo Cremasco',
'Bagnolo Mella',
'Bagnolo Piemonte',
'Bagnolo San Vito',
'Bagnolo del Salento',
'Bagnolo di Po',
'Bagnolo in Piano',
'Bagnone',
'Bagnoregio',
'Bagolino',
'Baia e Latina',
'Baiano',
'Baiedo',
'Baio Dora',
'Bairo',
'Bairo Torre',
'Baiso',
'Bajardo',
'Balangero',
'Baldichieri d\'Asti',
'Baldissero Canavese',
'Baldissero Torinese',
'Baldissero d\'Alba',
'Balestrate',
'Balestrino',
'Ballabio',
'Ballabio Inferiore',
'Ballabio Superiore',
'Ballao',
'Ballarate',
'Ballino',
'Balme',
'Balmuccia',
'Balocco',
'Balsamo',
'Balsorano',
'Balvano',
'Balzola',
'Banari',
'Banchette',
'Banco',
'Bannio Anzino',
'Bantine',
'Banzi',
'Baone',
'Baradili',
'Baragiano',
'Baranello',
'Barano d\'Ischia',
'Baranzate',
'Barasso',
'Barate',
'Baratili San Pietro',
'Baratonia',
'Barbajana',
'Barbana D\'Istria',
'Barbania',
'Barbara',
'Barbarano Mossano',
'Barbarano Romano',
'Barbarano Vicentino',
'Barbaresco',
'Barbariga',
'Barbata',
'Barberino Tavarnelle',
'Barberino Val D\'Elsa',
'Barberino di Mugello',
'Barbianello',
'Barbiano',
'Barbiselle',
'Barbona',
'Barcellona Pozzo di Gotto',
'Barcesino',
'Barchi',
'Barcis',
'Barco',
'Barcone',
'Bard',
'Bardassano',
'Bardello',
'Bardi',
'Bardineto',
'Bardino Nuovo',
'Bardino Vecchio',
'Bardolino',
'Bardonecchia',
'Bareggio',
'Barengo',
'Baresi',
'Baressa',
'Barete',
'Barga',
'Bargagli',
'Bargano',
'Barge',
'Barghe',
'Bari',
'Bari Sardo',
'Bariano',
'Baricella',
'Barile',
'Barisciano',
'Barlassina',
'Barletta',
'Barni',
'Barolo',
'Barona',
'Barone Canavese',
'Baronissi',
'Barra',
'Barrafranca',
'Barrali',
'Barrea',
'Barsizza',
'Bartesate',
'Barumini',
'Barza',
'Barzago',
'Barzana',
'Barzaniga',
'Barzanò',
'Barzio',
'Barzola',
'Basaluzzo',
'Bascapè',
'Baschi',
'Basciano',
'Baselga Di Vezzano',
'Baselga di Pinè',
'Baselica Bologna',
'Baselice',
'Basiano',
'Basicò',
'Basiglio',
'Basiliano',
'Bassano Bresciano',
'Bassano Romano',
'Bassano del Grappa',
'Bassano in Teverina',
'Bassiano',
'Bassignana',
'Bastia Mondovì',
'Bastia Umbra',
'Bastida De\' Dossi',
'Bastida Pancarana',
'Bastiglia',
'Battaglia Della Bainsizza',
'Battaglia Terme',
'Battifollo',
'Battipaglia',
'Battuda',
'Baucina',
'Bauladu',
'Baunei',
'Bauso',
'Bavari',
'Baveno',
'Bazzano',
'Beaulard',
'Bedero Valcuvia',
'Bedizzole',
'Bedollo',
'Bedonia',
'Bedulita',
'Bee',
'Beinasco',
'Beinette',
'Bel Prato',
'Belcastro',
'Belfiore',
'Belforte Monferrato',
'Belforte all\'Isauro',
'Belforte del Chienti',
'Belgioioso',
'Belgirate',
'Bella',
'Bellagio',
'Bellano',
'Bellante',
'Bellaria-Igea Marina',
'Belledo',
'Bellegra',
'Bellino',
'Bellinzago Lombardo',
'Bellinzago Novarese',
'Bellizzi',
'Bellizzi Irpino',
'Bellombra',
'Bellona',
'Bellosguardo',
'Belluno',
'Belluno Veronese',
'Bellusco',
'Belmonte Calabro',
'Belmonte Castello',
'Belmonte Mezzagno',
'Belmonte Piceno',
'Belmonte del Sannio',
'Belmonte in Sabina',
'Belpasso',
'Belsito',
'Belvedere Al Po',
'Belvedere Langhe',
'Belvedere Marittimo',
'Belvedere Ostrense',
'Belvedere di Spinello',
'Belveglio',
'Belvì',
'Bema',
'Bene Lario',
'Bene Vagienna',
'Benestare',
'Benetutti',
'Benevello',
'Benevento',
'Benna',
'Bentivoglio',
'Berbenno',
'Berbenno di Valtellina',
'Berceto',
'Berchidda',
'Berdo San Giovanni',
'Beregazzo con Figliaro',
'Bereguardo',
'Bergamasco',
'Bergamo',
'Bergantino',
'Bergeggi',
'Bergogna',
'Bergolo',
'Berlingo',
'Bernaga',
'Bernalda',
'Bernareggio',
'Bernate Rosales',
'Bernate Ticino',
'Bernezzo',
'Berra',
'Bersez',
'Bersezio',
'Bersone',
'Bertinoro',
'Bertiolo',
'Bertonico',
'Berzano di San Pietro',
'Berzano di Tortona',
'Berzo Demo',
'Berzo Inferiore',
'Berzo San Fermo',
'Besana in Brianza',
'Besano',
'Besate',
'Besenello',
'Beseno',
'Besenzone',
'Besnate',
'Besozzo',
'Bessude',
'Bestagno',
'Bestazzo',
'Bettenesco',
'Bettola',
'Bettona',
'Beura',
'Beura-Cardezza',
'Bevagna',
'Beverino',
'Bevilacqua',
'Bevilacqua-Boschi',
'Bezzecca',
'Biacesa',
'Biancavilla',
'Bianchi',
'Bianco',
'Biandrate',
'Biandronno',
'Bianzano',
'Bianzone',
'Bianzè',
'Biassono',
'Bibbiano',
'Bibbiena',
'Bibbona',
'Bibiana',
'Biccari',
'Bicinicco',
'Bidonì',
'Biegno',
'Biella',
'Bienate',
'Bienno',
'Bieno',
'Bientina',
'Biestro',
'Bigarello',
'Biglia',
'Bigliana',
'Biglio',
'Binago',
'Binanuova',
'Binasco',
'Bindo',
'Binetto',
'Binzago',
'Bioglio',
'Bionaz',
'Bione',
'Birago',
'Birori',
'Bisaccia',
'Bisacquino',
'Bisceglie',
'Bisegna',
'Bisenti',
'Bisentrate',
'Bisignano',
'Bisio',
'Bistagno',
'Bisterza',
'Bisuschio',
'Bitetto',
'Bitonto',
'Bitritto',
'Bitti',
'Bivona',
'Bivongi',
'Bizzarone',
'Bizzozero',
'Bleggio',
'Bleggio Inferiore',
'Bleggio Superiore',
'Blello',
'Blera',
'Blessagno',
'Blevio',
'Blufi',
'Boara Pisani',
'Boara Polesine',
'Bobbiate',
'Bobbio',
'Bobbio Pellice',
'Boca',
'Bocchigliero',
'Bocchignano',
'Boccioleto',
'Boccolo De\' Tassi',
'Bocenago',
'Bodio',
'Bodio Lomnago',
'Boffalora d\'Adda',
'Boffalora sopra Ticino',
'Boffetto',
'Bogliasco',
'Bogliasco Pieve',
'Bogliuno',
'Bognanco',
'Bognanco Dentro',
'Bognanco Fuori',
'Bogno',
'Bogogno',
'Boissano',
'Bojano',
'Bolano',
'Bolbeno',
'Boldinasco',
'Bolentina',
'Boleto',
'Bolgare',
'Bolgiano',
'Bolladello',
'Bollate',
'Bollengo',
'Bollone',
'Bologna',
'Bolognano',
'Bolognetta',
'Bolognola',
'Bolotana',
'Bolsena',
'Boltiere',
'Bolzaneto',
'Bolzano',
'Bolzano Novarese',
'Bolzano Vicentino',
'Bomarzo',
'Bomba',
'Bompensiere',
'Bompietro',
'Bomporto',
'Bonarcado',
'Bonassola',
'Bonate Sopra',
'Bonate Sotto',
'Bonavigo',
'Bondeno',
'Bondione',
'Bondo',
'Bondo Breguzzo',
'Bondo Petello',
'Bondone',
'Bondone Storo',
'Bonea',
'Bonefro',
'Bonemerse',
'Bonifati',
'Bonirola',
'Bonito',
'Bonnanaro',
'Bono',
'Bonorva',
'Bonvicino',
'Bonzo',
'Borbona',
'Borca di Cadore',
'Bordano',
'Bordighera',
'Bordogna',
'Bordolano',
'Bore',
'Boretto',
'Borgarello',
'Borgaro Torinese',
'Borgetto',
'Borghetto',
'Borghetto Lodigiano',
'Borghetto San Nicolò',
'Borghetto Santo Spirito',
'Borghetto d\'Arroscia',
'Borghetto di Borbera',
'Borghetto di Vara',
'Borghi',
'Borgia',
'Borgiallo',
'Borgio',
'Borgio Verezzi',
'Borgo Chiese',
'Borgo Lares',
'Borgo Mantovano',
'Borgo Pace',
'Borgo Panigale',
'Borgo Priolo',
'Borgo San Bernardino',
'Borgo San Dalmazzo',
'Borgo San Giacomo',
'Borgo San Giovanni',
'Borgo San Lorenzo',
'Borgo San Martino',
'Borgo San Siro',
'Borgo Sant\'Agata',
'Borgo Ticino',
'Borgo Tossignano',
'Borgo Val di Taro',
'Borgo Valbelluna',
'Borgo Valsugana',
'Borgo Velino',
'Borgo Veneto',
'Borgo Vercelli',
'Borgo Virgilio',
'Borgo a Mozzano',
'Borgo d\'Ale',
'Borgo d\'Anaunia',
'Borgo di Terzo',
'Borgocarbonara',
'Borgoforte',
'Borgofranco Sul Po',
'Borgofranco d\'Ivrea',
'Borgolavezzaro',
'Borgomale',
'Borgomanero',
'Borgomaro',
'Borgomasino',
'Borgomezzavalle',
'Borgonato',
'Borgone D\'Ossola',
'Borgone Susa',
'Borgonovo Val Tidone',
'Borgoratto Alessandrino',
'Borgoratto Mormorolo',
'Borgoricco',
'Borgorose',
'Borgosatollo',
'Borgosesia',
'Borgounito',
'Boriano',
'Bormida',
'Bormio',
'Bornago',
'Bornasco',
'Bornate',
'Bornato',
'Borno',
'Boroneddu',
'Borore',
'Borrello',
'Borriana',
'Borsano',
'Borsea',
'Borso del Grappa',
'Bortigali',
'Bortigiadas',
'Borutta',
'Borzago',
'Borzoli',
'Borzonasca',
'Bosa',
'Bosaro',
'Boschi Sant\'Anna',
'Bosco Chiesanuova',
'Bosco Marengo',
'Bosco Valtravaglia',
'Boscomare',
'Bosconero',
'Boscoreale',
'Boscotrecase',
'Bosentino',
'Bosia',
'Bosio',
'Bosisio Parini',
'Bosnasco',
'Bossico',
'Bossolasco',
'Botricello',
'Botrugno',
'Bottaiano',
'Bottanuco',
'Bottarone',
'Bottedo',
'Botticino',
'Botticino Mattina',
'Botticino Sera',
'Bottidda',
'Bottrighe',
'Bousson',
'Bova',
'Bova Marina',
'Bovalino',
'Bovegno',
'Boves',
'Bovezzo',
'Bovile',
'Boville',
'Boville Ernica',
'Bovino',
'Bovisio-Masciago',
'Bovolenta',
'Bovolone',
'Bozzana',
'Bozzole',
'Bozzolo',
'Bra',
'Bracca',
'Bracciano',
'Bracigliano',
'Braies',
'Brallo di Pregola',
'Brancaleone',
'Brancere',
'Brancolino',
'Brandico',
'Brandizzo',
'Branduzzo',
'Branzi',
'Braone',
'Brazzano',
'Brebbia',
'Breccia',
'Breda Guazzona',
'Breda di Piave',
'Bregano',
'Breganze',
'Breglia',
'Bregnano',
'Breguzzo',
'Breia',
'Brembate',
'Brembate Di Sotto',
'Brembate di Sopra',
'Brembilla',
'Brembio',
'Breme',
'Brendola',
'Brenna',
'Brennero',
'Brenno Della Torre',
'Brenno Useria',
'Breno',
'Brenta',
'Brentino',
'Brentino Belluno',
'Brentonico',
'Brenzone sul Garda',
'Breonio',
'Brescello',
'Brescia',
'Bresimo',
'Bressana',
'Bressana Bottarone',
'Bressanone',
'Bressanvido',
'Bresso',
'Brestovizza In Valle',
'Bretto',
'Brez',
'Brezzo di Bedero',
'Briaglia',
'Brianzola',
'Briatico',
'Briavacca',
'Bricherasio',
'Brienno',
'Brienza',
'Briga Alta',
'Briga Marittima',
'Briga Novarese',
'Brignano Del Curone',
'Brignano Gera d\'Adda',
'Brignano-Frascata',
'Brindisi',
'Brindisi Montagna',
'Brinzio',
'Briona',
'Brione',
'Brioni Maggiore',
'Briosco',
'Brisighella',
'Brisino',
'Brissago',
'Brissago-Valtravaglia',
'Brissogne',
'Brittoli',
'Brivio',
'Broccostella',
'Brogliano',
'Brognaturo',
'Brolo',
'Brolpasino',
'Brondello',
'Broni',
'Bronte',
'Bronzolo',
'Brossasco',
'Brosso',
'Brovello',
'Brovello-Carpugnino',
'Brovida',
'Brozolo',
'Brozzi',
'Brozzo',
'Brugherio',
'Brugine',
'Brugnato',
'Brugnera',
'Bruino',
'Brumano',
'Brunate',
'Brunello',
'Brunico',
'Bruno',
'Bruntino',
'Brusaporto',
'Brusaschetto',
'Brusasco',
'Brusciano',
'Brusimpiano',
'Brusino',
'Brusnengo',
'Brusson',
'Brusùglio',
'Bruzolo',
'Bruzzano Dei Due Borghi',
'Bruzzano Zeffirio',
'Bubbiano',
'Bubbio',
'Buccheri',
'Bucchianico',
'Bucciano',
'Buccinasco',
'Buccinigo',
'Buccino',
'Bucine',
'Bucuie',
'Budagne',
'Buddusò',
'Budoia',
'Budoni',
'Budrio',
'Buggerru',
'Buggiano',
'Buggiolo',
'Bugiallo',
'Buglio in Monte',
'Bugnara',
'Bugnate',
'Buguggiate',
'Buie D\'Istria',
'Buja',
'Bulciago',
'Bulgarograsso',
'Bulgorello',
'Bultei',
'Bulzi',
'Buonabitacolo',
'Buonalbergo',
'Buonconvento',
'Buonvicino',
'Burago Riviera',
'Burago di Molgora',
'Burano',
'Burcei',
'Burgio',
'Burgos',
'Burgusio',
'Buriasco',
'Burolo',
'Buronzo',
'Busachi',
'Busalla',
'Busana',
'Busano',
'Busca',
'Buscate',
'Buscemi',
'Buseto Palizzolo',
'Busnago',
'Buso Sarzano',
'Bussana',
'Bussero',
'Busseto',
'Bussi sul Tirino',
'Busso',
'Bussolengo',
'Bussoleno',
'Bussolino Gassinese',
'Bustighera',
'Busto Arsizio',
'Busto Garolfo',
'Butera',
'Buti',
'Buttapietra',
'Buttigliera Alta',
'Buttigliera d\'Asti',
'Buttirago',
'Buttogno',
'Buttrio',
'Bàlbido',
'Ca\' D\'Andrea',
'Ca\' De\' Bonavogli',
'Ca\' De\' Caggi',
'Ca\' De\' Corti',
'Ca\' De\' Mazzi',
'Ca\' De\' Quinzani',
'Ca\' De\' Sfondrati',
'Ca\' De\' Soresini',
'Ca\' De\' Staoli',
'Ca\' De\' Stefani',
'Ca\' De\' Tedioli',
'Ca\' De\' Zecchi',
'Ca\' Dei Bolli',
'Ca\' Dell\'Acqua',
'Ca\' Della Terra',
'Ca\' Di David',
'Ca\' Emo',
'Ca\' Santo Spirito Di Bainsizza',
'Cabella Ligure',
'Cabiate',
'Cabras',
'Caccamo',
'Caccia',
'Caccuri',
'Caddo',
'Cadegliano',
'Cadegliano-Viconago',
'Cadelbosco di Sopra',
'Cadeo',
'Cadero Con Graglio',
'Caderzone Terme',
'Cadignano',
'Cadine',
'Cadoneghe',
'Cadorago',
'Cadrezzate',
'Cadrezzate con Osmate',
'Caerano di San Marco',
'Cafasse',
'Caggiano',
'Cagli',
'Cagliano',
'Cagliari',
'Caglio',
'Cagna',
'Cagnano Amiterno',
'Cagnano Varano',
'Cagno',
'Cagno\'',
'Caianello',
'Caiazzo',
'Caidate',
'Caines',
'Caino',
'Caiolo',
'Cairano',
'Cairate',
'Cairo Lomellino',
'Cairo Montenotte',
'Caivano',
'Cajello',
'Cajonvico',
'Cal Di Canale',
'Calabritto',
'Calalzo di Cadore',
'Calamandrana',
'Calamonaci',
'Calangianus',
'Calanna',
'Calasca-Castiglione',
'Calascibetta',
'Calascio',
'Calasetta',
'Calatabiano',
'Calatafimi-Segesta',
'Calavino',
'Calcata',
'Calceranica al Lago',
'Calci',
'Calciano',
'Calcinaia',
'Calcinate',
'Calcinato',
'Calcio',
'Calco',
'Caldaro sulla strada del vino',
'Caldarola',
'Calderara di Reno',
'Caldes',
'Caldiero',
'Caldogno',
'Caldonazzo',
'Calendasco',
'Calenzano',
'Calepio',
'Calestano',
'Calice Ligure',
'Calice Ossolano',
'Calice al Cornoviglio',
'Calignano',
'Calimera',
'Calino',
'Calitri',
'Calizzano',
'Callabiana',
'Calliano',
'Calogna',
'Calolzio',
'Calolziocorte',
'Calopezzati',
'Calosso',
'Caloveto',
'Caltabellotta',
'Caltagirone',
'Caltanissetta',
'Caltavuturo',
'Caltignaga',
'Calto',
'Caltrano',
'Calusco d\'Adda',
'Caluso',
'Calvagese della Riviera',
'Calvanico',
'Calvaruso',
'Calvatone',
'Calvello',
'Calvene',
'Calvenzano',
'Calvera',
'Calvi',
'Calvi Risorta',
'Calvi dell\'Umbria',
'Calvignano',
'Calvignasco',
'Calvisano',
'Calvisio',
'Calvizzano',
'Calò',
'Camagna Di Torino',
'Camagna Monferrato',
'Camaiore',
'Camairago',
'Camandona',
'Camarda',
'Camasco',
'Camastra',
'Cambiago',
'Cambiano',
'Cambiasca',
'Cambiò',
'Camburzano',
'Camerana',
'Camerano',
'Camerano Casasco',
'Camerata Cornello',
'Camerata Nuova',
'Camerata Picena',
'Cameri',
'Camerino',
'Camerlata',
'Camerota',
'Camigliano',
'Camigna',
'Camignone',
'Caminata',
'Caminata In Tures',
'Camini',
'Camino',
'Camino al Tagliamento',
'Camisano',
'Camisano Vicentino',
'Cammarata',
'Camnago Faloppia',
'Camnago Volta',
'Camo',
'Camogli',
'Campagna',
'Campagna Lupia',
'Campagnano Vedasca',
'Campagnano di Roma',
'Campagnatico',
'Campagnola Cremasca',
'Campagnola Emilia',
'Campana',
'Camparada',
'Campegine',
'Campello Monti',
'Campello sul Clitunno',
'Campertogno',
'Campi Bisenzio',
'Campi Salentina',
'Campiglia Cervo',
'Campiglia Marittima',
'Campiglia dei Berici',
'Campiglione Fenile',
'Campione d\'Italia',
'Campitello di Fassa',
'Campli',
'Campo',
'Campo Calabro',
'Campo Canavese',
'Campo Ligure',
'Campo San Martino',
'Campo Tures',
'Campo di Giove',
'Campo di Trens',
'Campo nell\'Elba',
'Campobasso',
'Campobello di Licata',
'Campobello di Mazara',
'Campochiaro',
'Campochiesa',
'Campodarsego',
'Campodenno',
'Campodimele',
'Campodipietra',
'Campodolcino',
'Campodoro',
'Campofelice di Fitalia',
'Campofelice di Roccella',
'Campofilone',
'Campofiorito',
'Campoformido',
'Campofranco',
'Campogalliano',
'Campolattaro',
'Campoli Appennino',
'Campoli del Monte Taburno',
'Campolieto',
'Campolongo Al Torre',
'Campolongo Maggiore',
'Campolongo Sul Brenta',
'Campolongo Tapogliano',
'Campolungo',
'Campomaggiore',
'Campomarino',
'Campomorone',
'Campomorto',
'Camponogara',
'Campora',
'Camporeale',
'Camporgiano',
'Camporinaldo',
'Camporosso',
'Camporosso In Valcanale',
'Camporotondo Etneo',
'Camporotondo di Fiastrone',
'Camposampiero',
'Camposano',
'Camposanto',
'Campospinoso',
'Campospinoso Albaredo',
'Campotosto',
'Campoverde',
'Campovico',
'Camugnano',
'Canal San Bovo',
'Canale',
'Canale D\'Isonzo',
'Canale Monterano',
'Canale d\'Agordo',
'Canaro',
'Canazei',
'Cancellara',
'Cancello ed Arnone',
'Canda',
'Candeasco',
'Candela',
'Candelara',
'Candelo',
'Candia Canavese',
'Candia Lomellina',
'Candiana',
'Candida',
'Candidoni',
'Candiolo',
'Canegrate',
'Canelli',
'Canepa',
'Canepina',
'Caneva',
'Canevino',
'Canezza',
'Canfanaro',
'Canicattini Bagni',
'Canicattì',
'Canino',
'Canischio',
'Canistro',
'Canna',
'Cannalonga',
'Cannara',
'Cannero Riviera',
'Canneto Di Bari',
'Canneto Pavese',
'Canneto sull\'Oglio',
'Cannitello',
'Cannobio',
'Cannole',
'Canolo',
'Canonica d\'Adda',
'Canosa Sannita',
'Canosa di Puglia',
'Canosio',
'Canossa',
'Canova Del Mornasco',
'Canova Olzano',
'Cansano',
'Cansero',
'Cantagallo',
'Cantalice',
'Cantalupa',
'Cantalupo Ligure',
'Cantalupo in Sabina',
'Cantalupo nel Sannio',
'Cantarana',
'Cantello',
'Canterano',
'Cantiano',
'Cantoira',
'Cantonale',
'Cantù',
'Canzano',
'Canzo',
'Caorle',
'Caorso',
'Capaccio Paestum',
'Capaci',
'Capalbio',
'Capannoli',
'Capannori',
'Capena',
'Capergnanica',
'Capestrano',
'Capiago',
'Capiago Intimiano',
'Capiate',
'Capistrano',
'Capistrello',
'Capitello',
'Capitignano',
'Capitone',
'Capizzi',
'Capizzone',
'Capo d\'Orlando',
'Capo di Ponte',
'Capodimonte',
'Capodistria',
'Capodrise',
'Capolago',
'Capoliveri',
'Capolona',
'Caponago',
'Caporciano',
'Caporetto',
'Caposele',
'Capoterra',
'Capovalle',
'Cappadocia',
'Cappella Cantone',
'Cappella Maggiore',
'Cappella de\' Picenardi',
'Cappelle sul Tavo',
'Capracotta',
'Capradosso',
'Capraia Isola',
'Capraia e Limite',
'Capralba',
'Capranica',
'Capranica Prenestina',
'Caprarica di Lecce',
'Caprarola',
'Caprauna',
'Caprese Michelangelo',
'Caprezzo',
'Capri',
'Capri Leone',
'Capriana',
'Capriano Di Brianza',
'Capriano del Colle',
'Capriata d\'Orba',
'Capriate D\'Adda',
'Capriate San Gervasio',
'Capriati a Volturno',
'Caprie',
'Capriglia Irpina',
'Capriglio',
'Caprile',
'Caprino Bergamasco',
'Caprino Veronese',
'Capriolo',
'Capriva Nel Carso',
'Capriva del Friuli',
'Capronno',
'Capua',
'Capurso',
'Caraffa del Bianco',
'Caraffa di Catanzaro',
'Caraglio',
'Caramagna Ligure',
'Caramagna Piemonte',
'Caramanico Terme',
'Carano',
'Carapelle',
'Carapelle Calvisio',
'Carasco',
'Carassai',
'Carate Brianza',
'Carate Lario',
'Carate Urio',
'Caravaggio',
'Caravate',
'Caravino',
'Caravonica',
'Carbognano',
'Carbonara Di Bari',
'Carbonara Di Po',
'Carbonara Scrivia',
'Carbonara al Ticino',
'Carbonara di Nola',
'Carbonate',
'Carbone',
'Carbonera',
'Carbonia',
'Carcaci',
'Carcano',
'Carcare',
'Carceri',
'Carciato',
'Carcina',
'Carcoforo',
'Cardana',
'Cardano al Campo',
'Cardedu',
'Cardeto',
'Cardezza',
'Cardinale',
'Cardito',
'Cardè',
'Careggine',
'Carella Con Mariaga',
'Carema',
'Carenno',
'Careno',
'Carentino',
'Careri',
'Cares',
'Caresana',
'Caresanablot',
'Carezzano',
'Carezzano Superiore',
'Carfizzi',
'Cargeghe',
'Cargiago',
'Cariati',
'Caridà',
'Carife',
'Carignano',
'Carimate',
'Carinaro',
'Carini',
'Carinola',
'Carisio',
'Carisolo',
'Carlantino',
'Carlazzo',
'Carlazzo Valsolda',
'Carlentini',
'Carlino',
'Carloforte',
'Carlopoli',
'Carmagnola',
'Carmiano',
'Carmignano',
'Carmignano di Brenta',
'Carnago',
'Carnaiola',
'Carnate',
'Carobbio',
'Carobbio degli Angeli',
'Carolei',
'Carona',
'Caronia',
'Caronno Corbellaro',
'Caronno Pertusella',
'Caronno Varesino',
'Carosino',
'Carovigno',
'Carovilli',
'Carpaneta Con Dosimo',
'Carpaneto Piacentino',
'Carpanzano',
'Carpasio',
'Carpe',
'Carpegna',
'Carpenedolo',
'Carpeneto',
'Carpi',
'Carpiano',
'Carpignago',
'Carpignano Salentino',
'Carpignano Sesia',
'Carpineti',
'Carpineto Romano',
'Carpineto Sinello',
'Carpineto della Nora',
'Carpino',
'Carpinone',
'Carpugnino',
'Carrara',
'Carrara San Giorgio',
'Carrara Santo Stefano',
'Carrega Ligure',
'Carretto',
'Carro',
'Carrodano',
'Carrosio',
'Carrè',
'Carrù',
'Carsoli',
'Cartari E Calderara',
'Cartigliano',
'Cartignano',
'Cartoceto',
'Cartosio',
'Cartura',
'Caruberto',
'Carugate',
'Carugo',
'Carugo Arosio',
'Carunchio',
'Carvico',
'Carzago Della Riviera',
'Carzano',
'Casabona',
'Casacalenda',
'Casacanditella',
'Casagiove',
'Casal Cermelli',
'Casal Velino',
'Casal di Principe',
'Casalanguida',
'Casalattico',
'Casalba',
'Casalbeltrame',
'Casalbordino',
'Casalbore',
'Casalborgone',
'Casalbuono',
'Casalbuttano ed Uniti',
'Casalciprano',
'Casalduni',
'Casale Corte Cerro',
'Casale Cremasco',
'Casale Cremasco-Vidolasco',
'Casale Litta',
'Casale Marittimo',
'Casale Monferrato',
'Casale di Scodosia',
'Casale sul Sile',
'Casalecchio di Reno',
'Casaleggio Boiro',
'Casaleggio Castellazzo',
'Casaleggio Novara',
'Casaleone',
'Casaletto Ceredano',
'Casaletto Lodigiano',
'Casaletto Spartano',
'Casaletto Vaprio',
'Casaletto di Sopra',
'Casalfiumanese',
'Casalgrande',
'Casalgrasso',
'Casali del Manco',
'Casalincontrada',
'Casalino',
'Casalmaggiore',
'Casalmaiocco',
'Casalmorano',
'Casalmoro',
'Casalnoceto',
'Casalnuovo Monterotaro',
'Casalnuovo di Napoli',
'Casaloldo',
'Casalorzo Geroldi',
'Casalpoglio',
'Casalpusterlengo',
'Casalromano',
'Casalserugo',
'Casalsigone',
'Casaluce',
'Casalvecchio Siculo',
'Casalvecchio di Puglia',
'Casalvieri',
'Casalvolone',
'Casalzuigno',
'Casamarciano',
'Casamassima',
'Casamicciola Terme',
'Casandrino',
'Casanova D\'Offredi',
'Casanova Del Morbasco',
'Casanova Elvo',
'Casanova Lanza',
'Casanova Lerrone',
'Casanova Lonati',
'Casape',
'Casapesenna',
'Casapinta',
'Casaprota',
'Casapulla',
'Casarano',
'Casargo',
'Casarile',
'Casarsa della Delizia',
'Casarza Ligure',
'Casasco',
'Casasco D\'Intelvi',
'Casatenovo',
'Casatico',
'Casatisma',
'Casavatore',
'Casazza',
'Cascia',
'Casciago',
'Casciana Terme',
'Casciana Terme Lari',
'Cascina',
'Cascine',
'Cascine Di Stra',
'Cascinette d\'Ivrea',
'Casei Gerola',
'Caselette',
'Casella',
'Caselle D\'Ozero',
'Caselle Landi',
'Caselle Lurani',
'Caselle Torinese',
'Caselle in Pittari',
'Caserta',
'Casez',
'Casier',
'Casignana',
'Casina',
'Casirago',
'Casirate Olona',
'Casirate d\'Adda',
'Casletto',
'Caslino Al Piano',
'Caslino d\'Erba',
'Casnate',
'Casnate con Bernate',
'Casnigo',
'Casola Valsenio',
'Casola di Napoli',
'Casola in Lunigiana',
'Casolate',
'Casole Bruzio',
'Casole d\'Elsa',
'Casoli',
'Casone',
'Casorate Primo',
'Casorate Sempione',
'Casorezzo',
'Casoria',
'Casorzo',
'Casotto',
'Casperia',
'Caspoggio',
'Cassacco',
'Cassago',
'Cassago Brianza',
'Cassano Albese',
'Cassano Irpino',
'Cassano Magnago',
'Cassano Spinola',
'Cassano Valcuvia',
'Cassano all\'Ionio',
'Cassano d\'Adda',
'Cassano delle Murge',
'Cassaro',
'Cassiglio',
'Cassina Aliprandi',
'Cassina Amata',
'Cassina Baraggia',
'Cassina De\' Bracchi',
'Cassina De\' Gatti',
'Cassina Ferrara',
'Cassina Mariaga',
'Cassina Nuova',
'Cassina Pertusella',
'Cassina Pobbia',
'Cassina Rizzardi',
'Cassina Savina',
'Cassina Triulza',
'Cassina Valsassina',
'Cassina Verghera',
'Cassina de\' Pecchi',
'Cassinasco',
'Cassine',
'Cassine Calderari',
'Cassine Gandine',
'Cassine Sirigari',
'Cassine Tolentine',
'Cassinelle',
'Cassinetta di Lugagnano',
'Cassino',
'Cassino D\'Alberi',
'Cassino Po',
'Cassola',
'Cassolnovo',
'Castagna',
'Castagnaro',
'Castagnate Olona',
'Castagneto Carducci',
'Castagneto Po',
'Castagnito',
'Castagnole Monferrato',
'Castagnole Piemonte',
'Castagnole delle Lanze',
'Castagnè',
'Castana',
'Castano Primo',
'Casteggio',
'Castegnate Olona',
'Castegnato',
'Castegnero',
'Castel Baronia',
'Castel Boglione',
'Castel Bolognese',
'Castel Campagnano',
'Castel Castagna',
'Castel Cellesi',
'Castel Colonna',
'Castel Condino',
'Castel De\' Ratti',
'Castel Del Monte Udinese',
'Castel Di Croce',
'Castel Di Lago',
'Castel Dobra',
'Castel Focognano',
'Castel Frentano',
'Castel Gabbiano',
'Castel Gandolfo',
'Castel Giorgio',
'Castel Goffredo',
'Castel Guelfo di Bologna',
'Castel Iablanizza',
'Castel Ivano',
'Castel Lambro',
'Castel Madama',
'Castel Maggiore',
'Castel Mella',
'Castel Morrone',
'Castel Ritaldi',
'Castel Rocchero',
'Castel Rozzone',
'Castel San Benedetto Reatino',
'Castel San Felice',
'Castel San Giorgio',
'Castel San Giovanni',
'Castel San Giovanni Di Spoleto',
'Castel San Lorenzo',
'Castel San Niccolò',
'Castel San Pietro Monferrato',
'Castel San Pietro Romano',
'Castel San Pietro Terme',
'Castel San Vincenzo',
'Castel Sant\'Angelo',
'Castel Sant\'Elia',
'Castel Viscardo',
'Castel Vittorio',
'Castel Volturno',
'Castel d\'Aiano',
'Castel d\'Ario',
'Castel d\'Azzano',
'Castel del Giudice',
'Castel del Monte',
'Castel del Piano',
'Castel del Rio',
'Castel di Casio',
'Castel di Ieri',
'Castel di Iudica',
'Castel di Lama',
'Castel di Lucio',
'Castel di Sangro',
'Castel di Sasso',
'Castel di Tora',
'Castelbaldo',
'Castelbelforte',
'Castelbellino',
'Castelbello',
'Castelbello-Ciardes',
'Castelbianco',
'Castelbottaccio',
'Castelbuono',
'Castelcivita',
'Castelcovati',
'Castelcucco',
'Casteldaccia',
'Casteldarne',
'Casteldelci',
'Casteldelfino',
'Casteldidone',
'Casteldimezzo',
'Castelfidardo',
'Castelfiorentino',
'Castelfondo',
'Castelforte',
'Castelfranci',
'Castelfranco D\'Oglio',
'Castelfranco Di Sopra',
'Castelfranco Emilia',
'Castelfranco Piandiscò',
'Castelfranco Veneto',
'Castelfranco di Sotto',
'Castelfranco in Miscano',
'Castelgerundo',
'Castelgomberto',
'Castelgrande',
'Castelguglielmo',
'Castelguidone',
'Castell\'Alfero',
'Castell\'Arquato',
'Castell\'Azzara',
'Castell\'Umberto',
'Castellabate',
'Castellafiume',
'Castellalto',
'Castellammare Adriatico',
'Castellammare del Golfo',
'Castellammare di Stabia',
'Castellamonte',
'Castellana Grotte',
'Castellana Sicula',
'Castellaneta',
'Castellania Coppi',
'Castellano',
'Castellanza',
'Castellar',
'Castellar Guidobono',
'Castellarano',
'Castellaro',
'Castellaro De\' Giorgi',
'Castellavazzo',
'Castellazzo Bormida',
'Castellazzo De\' Barzi',
'Castellazzo Novarese',
'Castellengo',
'Castelleone',
'Castelleone di Suasa',
'Castellero',
'Castelletto Cervo',
'Castelletto Mendosio',
'Castelletto Merli',
'Castelletto Molina',
'Castelletto Monferrato',
'Castelletto Monforte',
'Castelletto Po',
'Castelletto Stura',
'Castelletto Uzzone',
'Castelletto Villa',
'Castelletto d\'Erro',
'Castelletto d\'Orba',
'Castelletto di Branduzzo',
'Castelletto sopra Ticino',
'Castelli',
'Castelli Calepio',
'Castellina Marittima',
'Castellina in Chianti',
'Castellinaldo d\'Alba',
'Castellino Tanaro',
'Castellino del Biferno',
'Castelliri',
'Castello',
'Castello Cabiaglio',
'Castello Di Serravalle',
'Castello Sopra Lecco',
'Castello Tesino',
'Castello Valsolda',
'Castello d\'Agogna',
'Castello d\'Argile',
'Castello del Matese',
'Castello dell\'Acqua',
'Castello di Annone',
'Castello di Brianza',
'Castello di Cisterna',
'Castello di Godego',
'Castello-Molina di Fiemme',
'Castellone Al Volturno',
'Castellonorato',
'Castellucchio',
'Castelluccio',
'Castelluccio Inferiore',
'Castelluccio Superiore',
'Castelluccio Valmaggiore',
'Castelluccio dei Sauri',
'Castelmagno',
'Castelmarte',
'Castelmassa',
'Castelmauro',
'Castelmezzano',
'Castelmola',
'Castelnovate',
'Castelnovetto',
'Castelnovo Bariano',
'Castelnovo del Friuli',
'Castelnovo di Sotto',
'Castelnovo ne\' Monti',
'Castelnuovo',
'Castelnuovo Belbo',
'Castelnuovo Berardenga',
'Castelnuovo Bocca d\'Adda',
'Castelnuovo Bormida',
'Castelnuovo Bozzente',
'Castelnuovo Calcea',
'Castelnuovo Cilento',
'Castelnuovo Cremasco',
'Castelnuovo D\'Istria',
'Castelnuovo Del Vescovo',
'Castelnuovo Del Zappa',
'Castelnuovo Don Bosco',
'Castelnuovo Gherardi',
'Castelnuovo Magra',
'Castelnuovo Nigra',
'Castelnuovo Parano',
'Castelnuovo Rangone',
'Castelnuovo Scrivia',
'Castelnuovo del Garda',
'Castelnuovo della Daunia',
'Castelnuovo di Ceva',
'Castelnuovo di Conza',
'Castelnuovo di Farfa',
'Castelnuovo di Garfagnana',
'Castelnuovo di Porto',
'Castelnuovo di Val di Cecina',
'Castelpagano',
'Castelpetroso',
'Castelpietra',
'Castelpizzuto',
'Castelplanio',
'Castelponzone',
'Castelpoto',
'Castelraimondo',
'Castelrotto',
'Castelruggiero',
'Castelsantangelo sul Nera',
'Castelsaraceno',
'Castelsardo',
'Castelseprio',
'Castelsilano',
'Castelspina',
'Casteltermini',
'Castelveccana',
'Castelvecchio Calvisio',
'Castelvecchio Di Santa Maria Maggiore',
'Castelvecchio Subequo',
'Castelvecchio di Rocca Barbena',
'Castelvenere',
'Castelverde',
'Castelvero D\'Asti',
'Castelverrino',
'Castelvetere in Val Fortore',
'Castelvetere sul Calore',
'Castelvetrano',
'Castelvetro Piacentino',
'Castelvetro di Modena',
'Castelvisconti',
'Castenaso',
'Castenedolo',
'Castiadas',
'Castiglion Fibocchi',
'Castiglion Fiorentino',
'Castiglion Ubertini',
'Castiglione Chiavarese',
'Castiglione Cosentino',
'Castiglione D\'Asti',
'Castiglione D\'Intelvi',
'Castiglione D\'Ossola',
'Castiglione Falletto',
'Castiglione Messer Marino',
'Castiglione Messer Raimondo',
'Castiglione Olona',
'Castiglione Tinella',
'Castiglione Torinese',
'Castiglione a Casauria',
'Castiglione d\'Adda',
'Castiglione d\'Orcia',
'Castiglione dei Pepoli',
'Castiglione del Genovesi',
'Castiglione del Lago',
'Castiglione della Pescaia',
'Castiglione delle Stiviere',
'Castiglione di Garfagnana',
'Castiglione di Sicilia',
'Castiglione in Teverina',
'Castignano',
'Castilenti',
'Castino',
'Castione Andevenno',
'Castione Veronese',
'Castione della Presolana',
'Castions di Strada',
'Castiraga Da Reggio',
'Castiraga Vidardo',
'Casto',
'Castorano',
'Castrezzato',
'Castrezzone',
'Castri di Lecce',
'Castrignano de\' Greci',
'Castrignano del Capo',
'Castro',
'Castro dei Volsci',
'Castrocaro Terme e Terra del Sole',
'Castrocielo',
'Castrofilippo',
'Castrolibero',
'Castronno',
'Castronovo di Sicilia',
'Castronuovo di Sant\'Andrea',
'Castropignano',
'Castroreale',
'Castroregio',
'Castrovillari',
'Cataforio',
'Catania',
'Catanzaro',
'Catenanuova',
'Catignano',
'Catona',
'Cattolica',
'Cattolica Eraclea',
'Caulonia',
'Cauria',
'Cautano',
'Cava Manara',
'Cava Tigozzi',
'Cava de\' Tirreni',
'Cavacurta',
'Cavaglietto',
'Cavaglio San Donnino',
'Cavaglio d\'Agogna',
'Cavaglio-Spoccia',
'Cavaglià',
'Cavagnera',
'Cavagnolo',
'Cavaion Veronese',
'Cavaione',
'Cavalese',
'Cavallara',
'Cavallasca',
'Cavallerleone',
'Cavallermaggiore',
'Cavallino',
'Cavallino-Treporti',
'Cavallirio',
'Cavandone',
'Cavareno',
'Cavargna',
'Cavaria con Premezzo',
'Cavarzere',
'Cavaso del Tomba',
'Cavasso Nuovo',
'Cavatore',
'Cavazzo Carnico',
'Cave',
'Cave Auremiane',
'Cavedago',
'Cavedine',
'Cavenago d\'Adda',
'Cavenago di Brianza',
'Cavernago',
'Caversaccio',
'Cavezzo',
'Caviaga',
'Cavizzana',
'Cavona',
'Cavoretto',
'Cavour',
'Cavrasto',
'Cavriago',
'Cavriana',
'Cavriglia',
'Cazzago Brabbia',
'Cazzago San Martino',
'Cazzano Besana',
'Cazzano Sant\'Andrea',
'Cazzano di Tramigna',
'Ceccano',
'Cecima',
'Cecina',
'Ceconico',
'Cedegolo',
'Cedrasco',
'Cedrate',
'Cefalà Diana',
'Cefalù',
'Ceggia',
'Ceglie',
'Ceglie Del Campo',
'Ceglie Messapica',
'Celano',
'Celentino',
'Celenza Valfortore',
'Celenza sul Trigno',
'Celico',
'Celina',
'Cella Dati',
'Cella Di Bobbio',
'Cella Monte',
'Cellamare',
'Cellara',
'Cellarengo',
'Cellatica',
'Celle Enomondo',
'Celle Ligure',
'Celle di Bulgheria',
'Celle di Macra',
'Celle di San Vito',
'Celledizzo',
'Celleno',
'Cellere',
'Cellino Attanasio',
'Cellino San Marco',
'Cellio',
'Cellio con Breia',
'Cellole',
'Celpenchio',
'Cembra',
'Cembra Lisignago',
'Cenadi',
'Cenate',
'Cenate D\'Argon',
'Cenate Sopra',
'Cenate Sotto',
'Cencenighe Agordino',
'Cene',
'Ceneda',
'Ceneselli',
'Cenesi',
'Cengio',
'Cengles',
'Cenova',
'Centa San Nicolo\'',
'Centallo',
'Centemero',
'Cento',
'Centola',
'Centrache',
'Centrisola',
'Centro Valle Intelvi',
'Centuripe',
'Cepagatti',
'Cepino',
'Ceppaloni',
'Ceppeda',
'Ceppo Morelli',
'Ceprano',
'Cerami',
'Ceranesi',
'Cerano',
'Cerano d\'Intelvi',
'Ceranova',
'Ceraso',
'Cercemaggiore',
'Cercenasco',
'Cercepiccola',
'Cerchiara In Sabina',
'Cerchiara di Calabria',
'Cerchiate',
'Cerchio',
'Cercino',
'Cercivento',
'Cercola',
'Cerda',
'Cerea',
'Cereda',
'Ceregnano',
'Cerenzia',
'Ceres',
'Ceresara',
'Cereseto',
'Ceresole Alba',
'Ceresole Reale',
'Cerete',
'Ceretto Lomellina',
'Cergnago',
'Ceriale',
'Ceriana',
'Ceriano Laghetto',
'Cerignale',
'Cerignola',
'Cerisano',
'Cermenate',
'Cermes',
'Cermignano',
'Cernizza Goriziana',
'Cernobbio',
'Cernusco Lombardone',
'Cernusco Montevecchia',
'Cernusco sul Naviglio',
'Cerreto Castello',
'Cerreto Grue',
'Cerreto Guidi',
'Cerreto Laziale',
'Cerreto Sannita',
'Cerreto d\'Asti',
'Cerreto d\'Esi',
'Cerreto di Spoleto',
'Cerretto Langhe',
'Cerrina Monferrato',
'Cerrione',
'Cerro Lago Maggiore',
'Cerro Maggiore',
'Cerro Tanaro',
'Cerro Veronese',
'Cerro al Lambro',
'Cerro al Volturno',
'Cersosimo',
'Certaldo',
'Certosa di Pavia',
'Cerva',
'Cervara di Roma',
'Cervarese Santa Croce',
'Cervaro',
'Cervarolo',
'Cervasca',
'Cervatto',
'Cerveno',
'Cervere',
'Cervesina',
'Cerveteri',
'Cervia',
'Cervicati',
'Cervignano d\'Adda',
'Cervignano del Friuli',
'Cervinara',
'Cervino',
'Cervo',
'Cerzeto',
'Cesa',
'Cesana Brianza',
'Cesana Torinese',
'Cesano Boscone',
'Cesano Maderno',
'Cesara',
'Cesarò',
'Cesate',
'Cesclans',
'Ceselli',
'Cesello Brianza',
'Cesena',
'Cesenatico',
'Cesi',
'Cesinali',
'Cesio',
'Cesiomaggiore',
'Cesnola',
'Cessalto',
'Cessaniti',
'Cessapalombo',
'Cessole',
'Cetara',
'Ceto',
'Ceto-Cerveno',
'Cetona',
'Cetraro',
'Ceva',
'Ceves',
'Cevo',
'Challand-Saint-Anselme',
'Challand-Saint-Victor',
'Challant Saint Anselme Et Challant Saint Victor',
'Chambave',
'Chamois',
'Champdepraz',
'Champlas-Du-Col',
'Champorcher',
'Charvensod',
'Cherasco',
'Cheremule',
'Cherso',
'Chiabrano',
'Chiaiano Ed Uniti',
'Chialamberto',
'Chiampo',
'Chianche',
'Chianchetelle',
'Chianciano Terme',
'Chianni',
'Chianocco',
'Chiapovano',
'Chiaramonte Gulfi',
'Chiaramonti',
'Chiarano',
'Chiaravalle',
'Chiaravalle Centrale',
'Chiaravalle Milanese',
'Chiari',
'Chiaromonte',
'Chiauci',
'Chiavari',
'Chiavazza',
'Chiavenna',
'Chiaverano',
'Chienes',
'Chienis',
'Chieri',
'Chies d\'Alpago',
'Chiesa in Valmalenco',
'Chiesanuova',
'Chiesina Uzzanese',
'Chieti',
'Chieuti',
'Chieve',
'Chignolo Po',
'Chignolo Verbano',
'Chignolo d\'Isola',
'Chioggia',
'Chiomonte',
'Chions',
'Chiopris-Viscone',
'Chiosi D\'Adda Vigadore',
'Chiosi Di Porta Cremonese',
'Chiosi Di Porta D\'Adda',
'Chiosi Di Porta Regale',
'Chiosi Uniti Con Bottedo',
'Chirignago',
'Chitignano',
'Chiuduno',
'Chiuppano',
'Chiuro',
'Chiusa',
'Chiusa Sclafani',
'Chiusa di Pesio',
'Chiusa di San Michele',
'Chiusaforte',
'Chiusanico',
'Chiusano d\'Asti',
'Chiusano di San Domenico',
'Chiusavecchia',
'Chiusdino',
'Chiusi',
'Chiusi della Verna',
'Chivasso',
'Chizzola',
'Chiùso',
'Châtillon',
'Ciago',
'Ciampino',
'Cianciana',
'Ciardes',
'Cibiana di Cadore',
'Cicagna',
'Cicala',
'Cicciano',
'Cicerale',
'Ciciliano',
'Cicognolo',
'Ciconio',
'Cigliano',
'Cigliè',
'Cignano',
'Cignone',
'Cigognola',
'Cigole',
'Cilavegna',
'Ciliverghe',
'Cima',
'Cimadolmo',
'Cimamulera',
'Cimbergo',
'Cimbergo-Paspardo',
'Cimbro',
'Cimego',
'Ciminna',
'Ciminà',
'Cimitile',
'Cimolais',
'Cimone',
'Cinaglio',
'Cineto Romano',
'Cingia de\' Botti',
'Cingoli',
'Cinigiano',
'Cinisello',
'Cinisello Balsamo',
'Cinisi',
'Cino',
'Cinquefrondi',
'Cintano',
'Cinte Tesino',
'Cinto Caomaggiore',
'Cinto Euganeo',
'Cinzano',
'Ciorlano',
'Cipressa',
'Circello',
'Circhina',
'Cireggio',
'Cirigliano',
'Cirimido',
'Ciriè',
'Cirò',
'Cirò Marina',
'Cis',
'Cisano Bergamasco',
'Cisano sul Neva',
'Ciserano',
'Ciseriis',
'Cislago',
'Cisliano',
'Cismon Del Grappa',
'Cison di Valmarino',
'Cisore',
'Cissone',
'Cisterna d\'Asti',
'Cisterna di Latina',
'Cisternino',
'Citerna',
'Cittadella',
'Cittaducale',
'Cittanova',
'Cittanova D\'Istria',
'Cittareale',
'Cittiglio',
'Città Sant\'Angelo',
'Città della Pieve',
'Città di Castello',
'Civate',
'Civello',
'Civenna',
'Civezza',
'Civezzano',
'Civiasco',
'Cividale del Friuli',
'Cividate Camuno',
'Cividate Malegno',
'Cividate al Piano',
'Civiglio',
'Civita',
'Civita Castellana',
'Civita d\'Antino',
'Civitacampomarano',
'Civitaluparella',
'Civitanova Marche',
'Civitanova del Sannio',
'Civitaquana',
'Civitavecchia',
'Civitella',
'Civitella Alfedena',
'Civitella Casanova',
'Civitella Messer Raimondo',
'Civitella Paganico',
'Civitella Roveto',
'Civitella San Paolo',
'Civitella d\'Agliano',
'Civitella del Tronto',
'Civitella di Romagna',
'Civitella in Val di Chiana',
'Civo',
'Cizzago',
'Claino con Osteno',
'Clana',
'Claut',
'Clauzetto',
'Clavesana',
'Claviere',
'Cles',
'Cleto',
'Clivio',
'Cloz',
'Clusane Sul Lago',
'Clusio',
'Clusone',
'Coassolo Torinese',
'Coazzano',
'Coazze',
'Coazzolo',
'Cobbia',
'Coccaglio',
'Cocconato',
'Cocconito',
'Cocquio',
'Cocquio-Trevisago',
'Cocullo',
'Codevigo',
'Codevilla',
'Codigoro',
'Codogno',
'Codognè',
'Codroipo',
'Codrongianos',
'Coggiola',
'Cogliate',
'Cogne',
'Cognola',
'Cogoleto',
'Cogollo del Cengio',
'Cogolo',
'Cogorno',
'Coimo',
'Coiromonte',
'Col Di Rodi',
'Col San Giovanni',
'Colazza',
'Colbordolo',
'Colcavagno',
'Colceresa',
'Colciago',
'Coldrano',
'Colere',
'Colfelice',
'Colfosco',
'Coli',
'Colico',
'Collagna',
'Collalto Sabino',
'Collarmele',
'Collazzone',
'Colle Brianza',
'Colle In Casies',
'Colle San Magno',
'Colle Sannita',
'Colle Santa Lucia',
'Colle Umberto',
'Colle d\'Anchise',
'Colle di Tora',
'Colle di Val d\'Elsa',
'Collebaccaro',
'Collebeato',
'Collecchio',
'Collecorvino',
'Colledara',
'Colledimacine',
'Colledimezzo',
'Colleferro',
'Collegiove',
'Collegno',
'Collelongo',
'Collemancio',
'Collepardo',
'Collepasso',
'Collepietro',
'Colleretto Castelnuovo',
'Colleretto Giacosa',
'Collesalvetti',
'Collesano',
'Collescipoli',
'Collestatte',
'Colletorto',
'Collevecchio',
'Colli In Pusteria',
'Colli Verdi',
'Colli a Volturno',
'Colli al Metauro',
'Colli del Tronto',
'Colli sul Velino',
'Colliano',
'Collinas',
'Collio',
'Collobiano',
'Colloredo di Monte Albano',
'Colmurano',
'Colnago',
'Colobraro',
'Cologna',
'Cologna In Giudicarie',
'Cologna Veneta',
'Cologna-Gavazzo',
'Cologne',
'Cologno Monzese',
'Cologno al Serio',
'Colognola Del Piano',
'Colognola ai Colli',
'Colombaro',
'Colombarolo',
'Colonna',
'Colonnella',
'Colonno',
'Colorina',
'Colorno',
'Colosimi',
'Colsano',
'Colturano',
'Colverde',
'Colzano',
'Colzate',
'Comabbio',
'Comacchio',
'Comairano',
'Comano',
'Comano Terme',
'Comasine',
'Comazzo',
'Comeglians',
'Comelico Superiore',
'Comeno',
'Comerio',
'Comero',
'Comezzano',
'Comezzano-Cizzago',
'Comighello',
'Comignago',
'Comiso',
'Comitini',
'Comiziano',
'Commessaggio',
'Commezzadura',
'Comnago',
'Como',
'Compiano',
'Comun Nuovo',
'Comunanza',
'Cona',
'Conca Casale',
'Conca dei Marini',
'Conca della Campania',
'Concadirame',
'Concamarise',
'Concei',
'Concenedo',
'Concerviano',
'Concesa',
'Concesio',
'Conco',
'Concordia Sagittaria',
'Concordia sulla Secchia',
'Concorezzo',
'Condino',
'Condofuri',
'Condove',
'Condrò',
'Conegliano',
'Confienza',
'Configni',
'Conflenti',
'Conio',
'Coniolo',
'Conselice',
'Conselve',
'Consiglio Di Rumo',
'Consonno',
'Contarina',
'Contessa Entellina',
'Contigliano',
'Contra',
'Contrada',
'Controguerra',
'Controne',
'Contursi Terme',
'Contà',
'Conversano',
'Conza della Campania',
'Conzano',
'Copertino',
'Copiano',
'Copparo',
'Copreno',
'Corana',
'Corato',
'Corbara',
'Corbesate',
'Corbetta',
'Corbola',
'Corchiano',
'Corciago',
'Corciano',
'Cordenons',
'Cordignano',
'Cordovado',
'Coredo',
'Coreglia Antelminelli',
'Coreglia Ligure',
'Corenno Plinio',
'Coreno Ausonio',
'Corfinio',
'Corgeno',
'Corgnale',
'Cori',
'Coriano',
'Corigliano Calabro',
'Corigliano d\'Otranto',
'Corigliano-Rossano',
'Corinaldo',
'Corio',
'Corleone',
'Corleto Monforte',
'Corleto Perticara',
'Cormano',
'Cormons',
'Corna Imagna',
'Cornalba',
'Cornale',
'Cornale e Bastida',
'Cornaredo',
'Cornate d\'Adda',
'Cornedo Vicentino',
'Cornedo all\'Isarco',
'Cornegliano Laudense',
'Corneliano Bertario',
'Corneliano d\'Alba',
'Cornigliano Ligure',
'Corniglio',
'Corno Giovine',
'Corno di Rosazzo',
'Cornovecchio',
'Cornuda',
'Corona',
'Corpi Santi Di Cremona',
'Corpi Santi Di Milano',
'Corpi Santi Di Pavia',
'Correggio',
'Correzzana',
'Correzzo',
'Correzzola',
'Corrido',
'Corridonia',
'Corropoli',
'Corsano',
'Corsico',
'Corsione',
'Cortabbio',
'Cortaccia sulla strada del vino',
'Cortale',
'Cortandone',
'Cortanze',
'Cortazzone',
'Corte',
'Corte Brugnatella',
'Corte Franca',
'Corte Madama',
'Corte Palasio',
'Corte Sant\'Andrea',
'Corte de\' Cortesi con Cignone',
'Corte de\' Frati',
'Cortemaggiore',
'Cortemilia',
'Cortenedolo',
'Corteno Golgi',
'Cortenova',
'Cortenuova',
'Corteolona',
'Corteolona e Genzone',
'Corteranzo',
'Cortetano',
'Corti In Pusteria',
'Corticelle Pieve',
'Cortiglione',
'Cortile San Martino',
'Cortina d\'Ampezzo',
'Cortina sulla strada del vino',
'Cortino',
'Cortona',
'Corvara',
'Corvara In Passiria',
'Corvara in Badia',
'Corvino San Quirico',
'Corzano',
'Corzes',
'Cosbana Nel Collio',
'Coseano',
'Cosenza',
'Cosio Valtellino',
'Cosio d\'Arroscia',
'Cosoleto',
'Cossana',
'Cossano Belbo',
'Cossano Canavese',
'Cossato',
'Cosseria',
'Cossignano',
'Cossila',
'Cossirano',
'Cossogno',
'Cossoine',
'Cossombrato',
'Costa Al Lambro',
'Costa D\'Oneglia',
'Costa Masnaga',
'Costa Sant\'Abramo',
'Costa Serina',
'Costa Valle Imagna',
'Costa Vescovato',
'Costa Volpino',
'Costa de\' Nobili',
'Costa di Mezzate',
'Costa di Rovigo',
'Costabissara',
'Costacciaro',
'Costanzana',
'Costarainera',
'Costasavina',
'Costermano sul Garda',
'Costigliole Saluzzo',
'Costigliole d\'Asti',
'Cotignola',
'Cotronei',
'Cottanello',
'Courmayeur',
'Covelano',
'Covelo',
'Covo',
'Cozzo',
'Craco',
'Crana',
'Crana Gattugno',
'Crandola Valsassina',
'Cravagliana',
'Cravanzana',
'Craveggia',
'Cravegna',
'Creazzo',
'Crecchio',
'Creda',
'Credaro',
'Credera',
'Credera Rubbiano',
'Crema',
'Cremella',
'Cremenaga',
'Cremeno',
'Cremezzano',
'Cremia',
'Cremnago',
'Cremolino',
'Cremona',
'Cremosano',
'Crenna',
'Crenovizza',
'Crescentino',
'Crescenzago',
'Crespadoro',
'Crespano Del Grappa',
'Crespellano',
'Crespiatica',
'Crespina',
'Crespina Lorenzana',
'Crespino',
'Cressa',
'Cressogno',
'Creto',
'Crevacuore',
'Crevalcore',
'Crevenna',
'Crevola Sesia',
'Crevoladossola',
'Crispano',
'Crispiano',
'Crissolo',
'Croce',
'Croce Di Mosso',
'Crocefieschi',
'Crocetta',
'Crocetta del Montello',
'Crodo',
'Crodo Mòzzio',
'Crognaleto',
'Cropalati',
'Cropani',
'Crosa',
'Crosara',
'Crosia',
'Crosio della Valle',
'Crotone',
'Crotta d\'Adda',
'Crova',
'Croviana',
'Crucoli',
'Crugnola',
'Crusinallo',
'Cuasso al Monte',
'Cuccaro Monferrato',
'Cuccaro Vetere',
'Cucciago',
'Cuceglio',
'Cuggiono',
'Cugliate-Fabiasco',
'Cuglieri',
'Cugnoli',
'Cumiana',
'Cumignano sul Naviglio',
'Cunardo',
'Cuneo',
'Cunevo',
'Cunico',
'Cuorgnè',
'Cupello',
'Cupra Marittima',
'Cupramontana',
'Cuquello',
'Cura Carpignano',
'Curcuris',
'Curdomo',
'Cureggio',
'Curiglia con Monteviasco',
'Curinga',
'Curino',
'Curnasco',
'Curno',
'Curon',
'Curon Venosta',
'Cursi',
'Cursolo',
'Cursolo-Orasso',
'Curtarolo',
'Curtatone',
'Curti',
'Cusago',
'Cusano Milanino',
'Cusano Mutri',
'Cusino',
'Cusio',
'Custonaci',
'Cutigliano',
'Cutro',
'Cutrofiano',
'Cuveglio',
'Cuvio',
'Cuvirone',
'Cuzzago',
'Dagnente',
'Daiano',
'Dairago',
'Dalmine',
'Dambel',
'Danta di Cadore',
'Daone',
'Dardine',
'Dare\'',
'Darfo Boario Terme',
'Darzo',
'Dasindo',
'Dasio',
'Dasà',
'Davagna',
'Daverio',
'Davoli',
'Dazio',
'Decimomannu',
'Decimoputzu',
'Decollatura',
'Degagna',
'Deggiano',
'Dego',
'Deiva Marina',
'Delebio',
'Delia',
'Delianuova',
'Deliceto',
'Dello',
'Demonte',
'Denice',
'Denno',
'Dercolo',
'Dergano',
'Dermulo',
'Dernice',
'Derovere',
'Deruta',
'Dervio',
'Desana',
'Descla',
'Desenzano Al Serio',
'Desenzano del Garda',
'Desio',
'Desulo',
'Desértes',
'Dezzo Di Scalve',
'Diamante',
'Diano Arentino',
'Diano Borello',
'Diano Calderina',
'Diano Castello',
'Diano Marina',
'Diano San Pietro',
'Diano d\'Alba',
'Dicomano',
'Dignano',
'Dignano D\'Istria',
'Dimaro',
'Dimaro Folgarida',
'Dinami',
'Dipignano',
'Diso',
'Dissimo',
'Divaccia Grotte Del Timavo',
'Divignano',
'Dizzasco',
'Dobbiaco',
'Doberdò del Lago',
'Doccio',
'Dogliani',
'Dogliola',
'Dogna',
'Dol Grande',
'Dol-Ottelza',
'Dolceacqua',
'Dolcedo',
'Dolcè',
'Dole',
'Dolegna del Collio',
'Dolianova',
'Dolo',
'Dolzago',
'Domanico',
'Domaso',
'Domegge di Cadore',
'Domicella',
'Domodossola',
'Domus de Maria',
'Domusnovas',
'Domusnovas Canales',
'Don',
'Donada',
'Donato',
'Donelasco',
'Dongo',
'Donigala Fenughedu',
'Donigala Siurgus',
'Donnas',
'Donori',
'Dorgali',
'Dorio',
'Dormelletto',
'Dorno',
'Dorsino',
'Dorzano',
'Dosolo',
'Dossena',
'Dosso Baroardo',
'Dosso De\' Frati',
'Dosso del Liro',
'Doues',
'Dovadola',
'Dovera',
'Dozio',
'Dozza',
'Dragoni',
'Draguccio',
'Drano',
'Drapia',
'Drena',
'Drenchia',
'Dresano',
'Dresenza',
'Drezzo',
'Drizzona',
'Dro',
'Dronero',
'Druento',
'Druogno',
'Drusacco',
'Dualchi',
'Dubino',
'Due Carrare',
'Due Cossani',
'Duemiglia',
'Dueville',
'Dugenta',
'Dugnano',
'Duino',
'Duino Aurisina',
'Dumenza',
'Duno',
'Durazzano',
'Duronia',
'Dusino',
'Dusino San Michele',
'Duttogliano',
'Duvredo',
'Eboli',
'Edolo',
'Egna',
'Elena',
'Elice',
'Elini',
'Elle',
'Ellera',
'Ello',
'Elmas',
'Elsane',
'Elva',
'Emarèse',
'Empoli',
'Endenna',
'Endine',
'Endine Gaiano',
'Enego',
'Enemonzo',
'Enguiso',
'Enna',
'Entracque',
'Entratico',
'Envie',
'Eores',
'Episcopia',
'Eraclea',
'Erba',
'Erba Incino',
'Erbanno',
'Erbezzo',
'Erbusco',
'Erbè',
'Erchie',
'Ercolano',
'Erice',
'Erli',
'Erpelle-Cosina',
'Ersel In Monte',
'Erto e Casso',
'Erula',
'Erve',
'Esanatoglia',
'Escalaplano',
'Escolca',
'Escovedu',
'Esine',
'Esino Inferiore',
'Esino Lario',
'Esino Superiore',
'Esio',
'Esmate',
'Esperia',
'Esporlatu',
'Este',
'Esterzili',
'Etroubles',
'Eupilio',
'Exilles',
'Fabbrica Curone',
'Fabbrica Durini',
'Fabbriche Di Vallico',
'Fabbriche di Vergemoli',
'Fabbrico',
'Fabiasco',
'Fabriano',
'Fabrica di Roma',
'Fabrizia',
'Fabro',
'Faedis',
'Faedo',
'Faedo Valtellino',
'Faenza',
'Faeto',
'Faetto',
'Fagagna',
'Faggeto Lario',
'Faggiano',
'Fagnano Alto',
'Fagnano Castello',
'Fagnano Olona',
'Fagnano Sul Naviglio',
'Fai della Paganella',
'Faicchio',
'Falcade',
'Falciano del Massico',
'Falconara Albanese',
'Falconara Marittima',
'Falcone',
'Faleria',
'Falerna',
'Falerone',
'Falesina',
'Fallascoso',
'Fallo',
'Falmenta',
'Faloppio',
'Falvaterra',
'Falzes',
'Famie',
'Fanano',
'Fanna',
'Fano',
'Fano Adriano',
'Fara Filiorum Petri',
'Fara Gera d\'Adda',
'Fara Novarese',
'Fara Olivana con Sola',
'Fara San Martino',
'Fara Vicentino',
'Fara in Sabina',
'Fardella',
'Farfengo',
'Farigliano',
'Farinate',
'Farindola',
'Farini',
'Farnese',
'Farra D\'Alpago',
'Farra d\'Isonzo',
'Farra di Soligo',
'Fasana D\'Istria',
'Fasano',
'Fascia',
'Fauglia',
'Faule',
'Favale di Malvaro',
'Favara',
'Favaro Veneto',
'Faver',
'Faverzano',
'Favignana',
'Favogna Di Sotto',
'Favria',
'Favria-Oglianico',
'Favrio',
'Feisoglio',
'Feletto',
'Feletto Umberto',
'Felino',
'Felitto',
'Felizzano',
'Felonica',
'Feltre',
'Fenegrò',
'Fenestrelle',
'Fengo',
'Fenigli',
'Fenile',
'Fenils',
'Ferentillo',
'Ferentino',
'Ferla',
'Fermignano',
'Fermo',
'Ferno',
'Feroleto Antico',
'Feroleto della Chiesa',
'Ferrandina',
'Ferrara',
'Ferrara di Monte Baldo',
'Ferrazzano',
'Ferrera Erbognone',
'Ferrera di Varese',
'Ferrere',
'Ferriere',
'Ferruta',
'Ferruzzano',
'Fertilia',
'Fiamignano',
'Fiano',
'Fiano Romano',
'Fianona',
'Fiastra',
'Fiavè',
'Ficarazzi',
'Ficarolo',
'Ficarra',
'Ficulle',
'Fidenza',
'Fiera Di Primiero',
'Fierozzo',
'Fiesco',
'Fiesole',
'Fiesse',
'Fiesso Umbertiano',
'Fiesso d\'Artico',
'Figino Di Milano',
'Figino Serenza',
'Figline Valdarno',
'Figline Vegliaturo',
'Figline e Incisa Valdarno',
'Figu',
'Filacciano',
'Filadelfia',
'Filago',
'Filandari',
'Filattiera',
'Filettino',
'Filetto',
'Filiano',
'Filighera',
'Filignano',
'Filogaso',
'Filottrano',
'Finalborgo',
'Finale Emilia',
'Finale Ligure',
'Finale Marina',
'Finale Pia',
'Finero',
'Fino Mornasco',
'Fino del Monte',
'Fiorano Canavese',
'Fiorano Modenese',
'Fiorano al Serio',
'Fiordimonte',
'Fiorenzuola Di Focara',
'Fiorenzuola d\'Arda',
'Firenze',
'Firenzuola',
'Firmo',
'Fiscaglia',
'Fisciano',
'Fisto',
'Fiuggi',
'Fiumalbo',
'Fiumana',
'Fiumara',
'Fiume',
'Fiume Veneto',
'Fiumedinisi',
'Fiumefreddo Bruzio',
'Fiumefreddo di Sicilia',
'Fiumenero',
'Fiumicello',
'Fiumicello Urago',
'Fiumicello Villa Vicentina',
'Fiumicino',
'Fiuminata',
'Fivizzano',
'Fiè allo Sciliar',
'Flaibano',
'Flavon',
'Flecchia',
'Fleres',
'Flero',
'Floresta',
'Floridia',
'Florinas',
'Flumeri',
'Fluminimaggiore',
'Flussio',
'Fobello',
'Foce',
'Foggia',
'Foglianise',
'Fogliano Redipuglia',
'Foglizzo',
'Fogna',
'Foiana',
'Foiano della Chiana',
'Foiano di Val Fortore',
'Folgaria',
'Folignano',
'Foligno',
'Follina',
'Follo',
'Follonica',
'Folsogno',
'Folzano',
'Fomarco',
'Fombio',
'Fondachelli-Fantina',
'Fondi',
'Fondo',
'Fondra',
'Fonni',
'Fontainemore',
'Fontana Del Conte',
'Fontana Liri',
'Fontanafredda',
'Fontanarosa',
'Fontanelice',
'Fontanella',
'Fontanellato',
'Fontanelle',
'Fontaneto d\'Agogna',
'Fontanetto Po',
'Fontanigorda',
'Fontanile',
'Fontaniva',
'Fonte',
'Fonte Nuova',
'Fontecchio',
'Fontechiari',
'Fontegreca',
'Fonteno',
'Fontevivo',
'Fonzaso',
'Foppa',
'Foppolo',
'Forano',
'Force',
'Forcello',
'Forchia',
'Forcola',
'Fordongianus',
'Forenza',
'Foresto Di Susa',
'Foresto Sesia',
'Foresto Sparso',
'Forgaria nel Friuli',
'Forino',
'Forio',
'Forlimpopoli',
'Forlì',
'Forlì del Sannio',
'Formazza',
'Formello',
'Formia',
'Formicola',
'Formigara',
'Formigine',
'Formigliana',
'Formignana',
'Fornace',
'Fornelli',
'Fornero',
'Forni Avoltri',
'Forni Di Val D\'Astico',
'Forni di Sopra',
'Forni di Sotto',
'Forno',
'Forno Alpi Graie',
'Forno Canavese',
'Forno Di Zoldo',
'Fornovo San Giovanni',
'Fornovo di Taro',
'Forotondo',
'Forte dei Marmi',
'Fortezza',
'Fortunago',
'Forza d\'Agrò',
'Fosciandora',
'Fosdinovo',
'Fossa',
'Fossa Guazzona',
'Fossacesia',
'Fossalta di Piave',
'Fossalta di Portogruaro',
'Fossalto',
'Fossano',
'Fossarmato',
'Fossato Serralta',
'Fossato di Vico',
'Fosseno',
'Fossombrone',
'Fossò',
'Foza',
'Frabosa Soprana',
'Frabosa Sottana',
'Fracchia',
'Fraconalto',
'Fragagnano',
'Fragneto Monforte',
'Fragneto l\'Abate',
'Fraine',
'Framura',
'Francavilla Angitola',
'Francavilla Bisio',
'Francavilla Fontana',
'Francavilla Marittima',
'Francavilla al Mare',
'Francavilla d\'Ete',
'Francavilla di Sicilia',
'Francavilla in Sinni',
'Francica',
'Francofonte',
'Francolise',
'Frascaro',
'Frascarolo',
'Frascata',
'Frascati',
'Frascineto',
'Frassilongo',
'Frassinelle Polesine',
'Frassinello Monferrato',
'Frassinere',
'Frassineto Po',
'Frassinetto',
'Frassino',
'Frassinoro',
'Frasso Sabino',
'Frasso Telesino',
'Fratta Polesine',
'Fratta Todina',
'Frattamaggiore',
'Frattaminore',
'Fratte Rosa',
'Fraveggio',
'Frazzanò',
'Fregona',
'Frerola',
'Fresagrandinaria',
'Fresonara',
'Frigento',
'Frignano',
'Frinco',
'Frisa',
'Frisanco',
'Front',
'Frontignano',
'Frontino',
'Frontone',
'Frontone-Serra',
'Frosinone',
'Frosolone',
'Frossasco',
'Frugarolo',
'Fubine Monferrato',
'Fucecchio',
'Fuipiano Al Brembo',
'Fuipiano Valle Imagna',
'Fumane',
'Fumone',
'Fundres',
'Funes',
'Furato',
'Furci',
'Furci Siculo',
'Furnari',
'Furore',
'Furtei',
'Fuscaldo',
'Fusignano',
'Fusine',
'Fusine In Valromana',
'Futani',
'Fénis',
'Gabbioneta',
'Gabbioneta-Binanuova',
'Gabiano',
'Gabicce Mare',
'Gabria',
'Gabrovizza',
'Gaby',
'Gadesco',
'Gadesco-Pieve Delmona',
'Gadoni',
'Gaeta',
'Gaggi',
'Gaggiano',
'Gaggino',
'Gaggio Montano',
'Gaglianico',
'Gagliano Aterno',
'Gagliano Castelferrato',
'Gagliano del Capo',
'Gagliato',
'Gagliole',
'Gaiarine',
'Gaiba',
'Gaiola',
'Gaiola Moiola',
'Gaiole in Chianti',
'Gairo',
'Gais',
'Galati Mamertino',
'Galatina',
'Galatone',
'Galatro',
'Galbiate',
'Galdo',
'Galeata',
'Galgagnano',
'Gallarate',
'Gallese',
'Galliate',
'Galliate Lombardo',
'Galliavola',
'Gallicano',
'Gallicano nel Lazio',
'Gallicchio',
'Gallico',
'Galliera',
'Galliera Veneta',
'Gallina',
'Gallinaro',
'Gallio',
'Gallipoli',
'Gallo Matese',
'Gallodoro',
'Galluccio',
'Galluzzo',
'Galtellì',
'Galzignano Terme',
'Gamalero',
'Gambara',
'Gambarana',
'Gambarare',
'Gambasca',
'Gambassi Terme',
'Gambatesa',
'Gambellara',
'Gamberale',
'Gambettola',
'Gambina Barchetti',
'Gambolò',
'Gambugliano',
'Gandellino',
'Gandini',
'Gandino',
'Gandosso',
'Gangi',
'Garabiolo',
'Garaguso',
'Garbagna',
'Garbagna Novarese',
'Garbagnate Milanese',
'Garbagnate Monastero',
'Garbatola',
'Garda',
'Gardolo Al Piano',
'Gardone Riviera',
'Gardone Val Trompia',
'Garegnano',
'Garessio',
'Gargallo',
'Gargaro',
'Gargazzone',
'Gargnano',
'Garlasco',
'Garlate',
'Garlenda',
'Garniga Terme',
'Garofai',
'Garzeno',
'Garzigliana',
'Gasperina',
'Gassino Torinese',
'Gattatico',
'Gatteo',
'Gattera Maiocca',
'Gattico',
'Gattico-Veruno',
'Gattinara',
'Gauna',
'Gavardo',
'Gavazzana',
'Gavello',
'Gaverina Terme',
'Gavi',
'Gavignano',
'Gavirate',
'Gavoi',
'Gavorrano',
'Gazoldo degli Ippoliti',
'Gazzada',
'Gazzada Schianno',
'Gazzaniga',
'Gazzano',
'Gazzelli',
'Gazzo',
'Gazzo Veronese',
'Gazzola',
'Gazzuolo',
'Gela',
'Gemmano',
'Gemona del Friuli',
'Gemonio',
'Genazzano',
'Genga',
'Genivolta',
'Genola',
'Genoni',
'Genova',
'Genuri',
'Genzano di Lucania',
'Genzano di Roma',
'Genzone',
'Gera Lario',
'Gerace',
'Geraci Siculo',
'Gerano',
'Gerenzago',
'Gerenzano',
'Gergei',
'Germagnano',
'Germagno',
'Germanedo',
'Germasino',
'Germignaga',
'Gerocarne',
'Gerola Alta',
'Gerolanuova',
'Gerosa',
'Gerre de\' Caprioli',
'Gerrechiozzo',
'Gesico',
'Gessate',
'Gessopalena',
'Gesturi',
'Gesualdo',
'Ghedi',
'Ghemme',
'Ghevio',
'Ghiffa',
'Ghilarza',
'Ghisalba',
'Ghislarengo',
'Giacciano con Baruchella',
'Giaglione',
'Gianico',
'Giano Vetusto',
'Giano dell\'Umbria',
'Giardinello',
'Giardini-Naxos',
'Giarole',
'Giarratana',
'Giarre',
'Giave',
'Giaveno',
'Giavera del Montello',
'Giba',
'Gibellina',
'Gifflenga',
'Giffone',
'Giffoni Sei Casali',
'Giffoni Valle Piana',
'Gignese',
'Gignod',
'Gildone',
'Gimigliano',
'Gimino',
'Ginestra',
'Ginestra degli Schiavoni',
'Ginestreto',
'Ginosa',
'Gioi',
'Gioia Sannitica',
'Gioia Tauro',
'Gioia dei Marsi',
'Gioia del Colle',
'Gioiosa Ionica',
'Gioiosa Marea',
'Giove',
'Giovenzano',
'Giovinazzo',
'Giovo',
'Girasole',
'Girifalco',
'Gironico',
'Gissi',
'Giuggianello',
'Giugliano in Campania',
'Giuliana',
'Giuliano Teatino',
'Giuliano di Roma',
'Giulianova',
'Giuncugnano',
'Giungano',
'Giurdignano',
'Giussago',
'Giussano',
'Giustenice',
'Giustino',
'Giusvalla',
'Givoletto',
'Gizzeria',
'Glorenza',
'Godega di Sant\'Urbano',
'Godenzo',
'Godiasco Salice Terme',
'Godovici',
'Godrano',
'Goglione Sopra',
'Goglione Sotto',
'Goiaci',
'Goido',
'Goito',
'Golasecca',
'Golese',
'Golferenzo',
'Golfo Aranci',
'Gombito',
'Gonars',
'Goni',
'Gonnesa',
'Gonnoscodina',
'Gonnosfanadiga',
'Gonnosnò',
'Gonnostramatza',
'Gonzaga',
'Gordona',
'Gorga',
'Gorgo al Monticano',
'Gorgoglione',
'Gorgonzola',
'Goriano',
'Goriano Sicoli',
'Gorizia',
'Gorla Maggiore',
'Gorla Minore',
'Gorlago',
'Gorlaprecotto',
'Gorle',
'Gornate Olona',
'Gornate-Superiore',
'Gorno',
'Goro',
'Gorra',
'Gorreto',
'Gorrino',
'Gorzegno',
'Gorzone',
'Gosaldo',
'Gossolengo',
'Gottasecca',
'Gottolengo',
'Gottro',
'Govone',
'Gozza',
'Gozzano',
'Gracova Serravalle',
'Gradara',
'Gradella',
'Gradisca d\'Isonzo',
'Grado',
'Gradoli',
'Graffignana',
'Graffignano',
'Graglia',
'Graglia Piana',
'Gragnano',
'Gragnano Trebbiense',
'Grammichele',
'Grana',
'Granaglione',
'Granarolo dell\'Emilia',
'Grancino',
'Grancona',
'Grandate',
'Grandola',
'Grandola ed Uniti',
'Graniti',
'Granozzo con Monticello',
'Grantola',
'Grantorto',
'Granze',
'Grassano',
'Grassobbio',
'Gratteri',
'Grauno',
'Gravedona',
'Gravedona ed Uniti',
'Gravellona Lomellina',
'Gravellona Toce',
'Gravere',
'Gravina di Catania',
'Gravina in Puglia',
'Grazzanello',
'Grazzanise',
'Grazzano Badoglio',
'Greccio',
'Greci',
'Greco Milanese',
'Greggio',
'Gremiasco',
'Gressan',
'Gressoney',
'Gressoney-La-Trinité',
'Gressoney-Saint-Jean',
'Greve in Chianti',
'Grezzago',
'Grezzana',
'Griante',
'Gricignano di Aversa',
'Gries',
'Grignano',
'Grignano Di Polesine',
'Grignasco',
'Grigno',
'Grimacco',
'Grimaldi',
'Grimaldo',
'Grinzane Cavour',
'Grisignana',
'Grisignano di Zocco',
'Grisolia',
'Grizzana Morandi',
'Grognardo',
'Gromo',
'Grona',
'Grondona',
'Grone',
'Grontardo',
'Grontorto',
'Gropello Cairoli',
'Gropparello',
'Groppello D\'Adda',
'Groppo',
'Groscavallo',
'Grosio',
'Grosotto',
'Grosseto',
'Grosso',
'Grottaferrata',
'Grottaglie',
'Grottaminarda',
'Grottammare',
'Grottazzolina',
'Grotte',
'Grotte Santo Stefano',
'Grotte di Castro',
'Grotteria',
'Grottole',
'Grottolella',
'Gruaro',
'Grugliasco',
'Grumello Cremonese ed Uniti',
'Grumello De\' Zanchi',
'Grumello Del Piano',
'Grumello del Monte',
'Grumento Nova',
'Grumes',
'Grumo',
'Grumo Appula',
'Grumo Nevano',
'Grumolo delle Abbadesse',
'Guagnano',
'Gualdo',
'Gualdo Cattaneo',
'Gualdo Tadino',
'Gualdrasco',
'Gualtieri',
'Gualtieri Sicaminò',
'Guamaggiore',
'Guanzate',
'Guarcino',
'Guarda Veneta',
'Guardabosone',
'Guardamiglio',
'Guardavalle',
'Guardea',
'Guardia Lombardi',
'Guardia Perticara',
'Guardia Piemontese',
'Guardia Piemontese Terme',
'Guardia Sanframondi',
'Guardiagrele',
'Guardialfiera',
'Guardiaregia',
'Guardistallo',
'Guarene',
'Guasila',
'Guastalla',
'Guazzina',
'Guazzora',
'Gubbio',
'Gudo Visconti',
'Gudon',
'Guglionesi',
'Gugnano',
'Guidizzolo',
'Guidomandri',
'Guidonia Montecelio',
'Guiglia',
'Guilmi',
'Guinzano',
'Gurone',
'Gurro',
'Guspini',
'Gussago',
'Gussola',
'Hône',
'Iatrinoli',
'Iavrè',
'Idresca D\'Isonzo',
'Idria',
'Idria Di Sotto',
'Idro',
'Iglesias',
'Igliano',
'Ilbono',
'Illasi',
'Illorai',
'Imberido',
'Imbersago',
'Imer',
'Imola',
'Imperia',
'Impruneta',
'Inarzo',
'Incino',
'Incirano',
'Incisa In Val D\'Arno',
'Incisa Scapaccino',
'Incudine',
'Indovero',
'Induno Olona',
'Induno Ticino',
'Ingria',
'Intimiano',
'Intra',
'Intragna',
'Introbio',
'Introd',
'Introdacqua',
'Introzzo',
'Inverigo',
'Inverno e Monteleone',
'Inverso Pinasca',
'Inverso Porte',
'Inveruno',
'Invorio',
'Invorio Superiore',
'Inzago',
'Inzino',
'Ioannis',
'Ionadi',
'Ionia',
'Ipplis',
'Irgoli',
'Irgoli Di Galtelli',
'Irma',
'Irsina',
'Isasca',
'Isca sullo Ionio',
'Ischia',
'Ischia di Castro',
'Ischitella',
'Iseo',
'Isera',
'Isernia',
'Isili',
'Isnello',
'Isola Balba',
'Isola Comacina',
'Isola D\'Istria',
'Isola Di Fano',
'Isola Dovarese',
'Isola Pescaroli',
'Isola Rizza',
'Isola San Giulio',
'Isola Sant\'Antonio',
'Isola Vicentina',
'Isola d\'Asti',
'Isola del Cantone',
'Isola del Giglio',
'Isola del Gran Sasso d\'Italia',
'Isola del Liri',
'Isola del Piano',
'Isola della Scala',
'Isola delle Femmine',
'Isola di Capo Rizzuto',
'Isola di Fondra',
'Isolabella',
'Isolabona',
'Isole Tremiti',
'Isolella',
'Isolello',
'Isorella',
'Ispani',
'Ispica',
'Ispra',
'Issengo',
'Issiglio',
'Issime',
'Isso',
'Issogne',
'Istrana',
'Itala',
'Itri',
'Ittireddu',
'Ittiri',
'Ivano Fracena',
'Ivrea',
'Izano',
'Jacurso',
'Jelsi',
'Jenne',
'Jerago Con Besnate Ed Orago',
'Jerago con Orago',
'Jerzu',
'Jesi',
'Jesolo',
'Jolanda di Savoia',
'Joppolo',
'Joppolo Giancaxio',
'Jovençan',
'L\'Aquila',
'La Cassa',
'La Loggia',
'La Maddalena',
'La Magdeleine',
'La Morra',
'La Salle',
'La Spezia',
'La Thuile',
'La Valle',
'La Valle Agordina',
'La Valletta Brianza',
'Labico',
'Labro',
'Lacchiarella',
'Lacco Ameno',
'Lacedonia',
'Laces',
'Lacinigo',
'Laconi',
'Ladispoli',
'Laerru',
'Laganadi',
'Laghi',
'Laglesie San Leopoldo',
'Laglio',
'Lagnasco',
'Lago',
'Lagonegro',
'Lagosanto',
'Lagosta',
'Laguna Mustè',
'Lagundo',
'Laigueglia',
'Lainate',
'Laino',
'Laino Borgo',
'Laino Bruzio',
'Laino Castello',
'Laion',
'Laives',
'Lajatico',
'Lallio',
'Lama Mocogno',
'Lama dei Peligni',
'Lambrate',
'Lambrugo',
'Lamezia Terme',
'Lamon',
'Lampedusa e Linosa',
'Lamporecchio',
'Lamporo',
'Lana',
'Lanciano',
'Landiona',
'Landriano',
'Langhirano',
'Langosco',
'Lanischie',
'Lanusei',
'Lanuvio',
'Lanza',
'Lanzada',
'Lanzo D\'Intelvi',
'Lanzo Torinese',
'Laorca',
'Lapedona',
'Lapio',
'Lappago',
'Lappano',
'Larciano',
'Lardaro',
'Lardera',
'Lardirago',
'Lari',
'Lariano',
'Larido',
'Larino',
'Las Plassas',
'Lasa',
'Lascari',
'Lase',
'Lases',
'Lasino',
'Lasnigo',
'Lastebasse',
'Lastra a Signa',
'Latera',
'Laterina',
'Laterina Pergine Valdarno',
'Laterza',
'Latiano',
'Latina',
'Latisana',
'Latronico',
'Lattarico',
'Lauco',
'Laudes',
'Laurana',
'Laureana Cilento',
'Laureana di Borrello',
'Lauregno',
'Laurenzana',
'Lauria',
'Lauriano',
'Laurino',
'Laurito',
'Lauro',
'Lavagna',
'Lavagno',
'Lavarone',
'Lavello',
'Lavena Ponte Tresa',
'Laveno',
'Laveno-Mombello',
'Lavenone',
'Laviano',
'Lavina',
'Lavis',
'Lazfons',
'Lazise',
'Lazzate',
'Lecce',
'Lecce nei Marsi',
'Lecco',
'Ledine',
'Ledro',
'Leffe',
'Leggiuno',
'Legnago',
'Legnaia',
'Legnano',
'Legnaro',
'Legos',
'Lei',
'Leini',
'Leivi',
'Lemie',
'Lemna',
'Lendinara',
'Leni',
'Lenna',
'Lenno',
'Leno',
'Lenola',
'Lenta',
'Lentate sul Seveso',
'Lentella',
'Lentiai',
'Lentini',
'Lenzima',
'Lenzumo',
'Leonessa',
'Leonforte',
'Leporano',
'Lequile',
'Lequio Berria',
'Lequio Tanaro',
'Lercara Friddi',
'Lerici',
'Lerma',
'Lesa',
'Lesegno',
'Lesignano de\' Bagni',
'Lesina',
'Lesmo',
'Lessolo',
'Lessona',
'Lestizza',
'Letino',
'Letojanni',
'Lettere',
'Lettomanoppello',
'Lettopalena',
'Levanto',
'Levata',
'Levate',
'Leverano',
'Levice',
'Levico Terme',
'Levone',
'Levrange',
'Lezza',
'Lezzeno',
'Liberi',
'Librizzi',
'Libussina',
'Licata',
'Licciana Nardi',
'Licengo',
'Licenza',
'Licignano Di Napoli',
'Licodia Eubea',
'Liconasco',
'Licusati',
'Lierna',
'Lieto Colle',
'Lignana',
'Lignano Sabbiadoro',
'Ligonchio',
'Ligosullo',
'Lillianes',
'Limana',
'Limatola',
'Limbadi',
'Limbiate',
'Limena',
'Limido Comasco',
'Limina',
'Limone Piemonte',
'Limone sul Garda',
'Limonta',
'Limosano',
'Linarolo',
'Linate Al Lambro',
'Linguaglossa',
'Lingueglietta',
'Linzanico',
'Lioni',
'Lipari',
'Lipomo',
'Lirio',
'Lisanza',
'Liscate',
'Liscia',
'Lisciano Del Tronto',
'Lisciano Niccone',
'Lisignago',
'Lisio',
'Lissago',
'Lissone',
'Livemmo',
'Liveri',
'Livigno',
'Livinallongo del Col di Lana',
'Livo',
'Livorno',
'Livorno Ferraris',
'Livraga',
'Lizzana',
'Lizzanello',
'Lizzano',
'Lizzano in Belvedere',
'Lizzola',
'Loano',
'Loazzolo',
'Locadi',
'Locana',
'Locarno',
'Locate Bergamasco',
'Locate Varesino',
'Locate di Triulzi',
'Locatello',
'Locavizza Di Aidussina',
'Locavizza Di Canale',
'Locca',
'Loceri',
'Locorotondo',
'Locri',
'Loculi',
'Lodi',
'Lodi Vecchio',
'Lodine',
'Lodisio',
'Lodrino',
'Lodrone',
'Lodè',
'Lograto',
'Loiano',
'Loiri Porto San Paolo',
'Lomagna',
'Lomaniga',
'Lomaso',
'Lomazzo',
'Lombardore',
'Lombriasco',
'Lomello',
'Lon',
'Lona',
'Lona-Lases',
'Lonate Ceppino',
'Lonate Pozzolo',
'Lonato',
'Lonato del Garda',
'Londa',
'Longano',
'Longardore',
'Longare',
'Longarone',
'Longhena',
'Longi',
'Longiano',
'Longiarù',
'Longobardi',
'Longobucco',
'Longone Sabino',
'Longone al Segrino',
'Lonigo',
'Loranzè',
'Loreggia',
'Loreglia',
'Lorentino',
'Lorenzago di Cadore',
'Lorenzana',
'Loreo',
'Loreto',
'Loreto Aprutino',
'Loria',
'Loro Ciuffenna',
'Loro Piceno',
'Lorsica',
'Lose',
'Loseto',
'Losine',
'Lottulo',
'Lotzorai',
'Loveno Grumello',
'Loveno Sopra Menaggio',
'Lover',
'Lovere',
'Lovero',
'Lozio',
'Lozza',
'Lozzo',
'Lozzo Atestino',
'Lozzo di Cadore',
'Lozzolo',
'Lu',
'Lu e Cuccaro Monferrato',
'Lubriano',
'Lucca',
'Lucca Sicula',
'Lucera',
'Lucernate',
'Lucignano',
'Lucinasco',
'Lucinico',
'Lucino',
'Lucito',
'Luco dei Marsi',
'Lucoli',
'Ludriano',
'Lugagnano Val d\'Arda',
'Lugnacco',
'Lugnano in Teverina',
'Lugo',
'Lugo di Vicenza',
'Luico',
'Luignano',
'Luino',
'Luisago',
'Lula',
'Lumarzo',
'Lumezzane',
'Lumezzane Pieve',
'Lumezzane San Sebastiano',
'Lumezzane Sant\'Apollonio',
'Lunamatrona',
'Lunano',
'Lundo',
'Lungavilla',
'Lungro',
'Luni',
'Luogosano',
'Luogosanto',
'Lupara',
'Lurago Marinone',
'Lurago d\'Erba',
'Lurano',
'Luras',
'Lurate Caccivio',
'Lusciano',
'Lusciano E Ducenta',
'Luserna',
'Luserna San Giovanni',
'Lusernetta',
'Lusevera',
'Lusia',
'Lusiana',
'Lusiana Conco',
'Lusigliè',
'Luson',
'Lussingrande',
'Lussinpiccolo',
'Lustra',
'Lutago',
'Luvinate',
'Luzzana',
'Luzzano',
'Luzzara',
'Luzzi',
'Luzzogno',
'Lìmito',
'Maccagno',
'Maccagno Inferiore',
'Maccagno con Pino e Veddasca',
'Maccastorna',
'Macchia Valfortore',
'Macchia d\'Isernia',
'Macchiagodena',
'Maccio',
'Macello',
'Macerata',
'Macerata Campania',
'Macerata Feltria',
'Macherio',
'Maclodio',
'Macomer',
'Macra',
'Macugnaga',
'Maddaloni',
'Maderno',
'Madesimo',
'Madice',
'Madignano',
'Madone',
'Madonna del Sasso',
'Madrano',
'Madruzzo',
'Maenza',
'Mafalda',
'Magasa',
'Magenta',
'Maggiora',
'Maggiànico',
'Magherno',
'Magione',
'Magisano',
'Magliano Alfieri',
'Magliano Alpi',
'Magliano Romano',
'Magliano Sabina',
'Magliano Vetere',
'Magliano de\' Marsi',
'Magliano di Tenna',
'Magliano in Toscana',
'Maglie',
'Magliolo',
'Maglione',
'Magnacavallo',
'Magnago',
'Magnano',
'Magnano in Riviera',
'Magno Sopra Inzino',
'Magognino',
'Magomadas',
'Magras',
'Magreglio',
'Magrè',
'Magrè Vicentino',
'Magrè sulla strada del vino',
'Maia Alta',
'Maia Bassa',
'Maida',
'Maierato',
'Maierà',
'Maiolati Spontini',
'Maiolo',
'Maiori',
'Mairago',
'Mairano',
'Maissana',
'Majano',
'Mala',
'Malagnino',
'Malalbergo',
'Malamocco',
'Malborghetto',
'Malborghetto Valbruna',
'Malcesine',
'Malchina',
'Malegno',
'Maleo',
'Malesco',
'Maletto',
'Malfa',
'Malgesso',
'Malgolo',
'Malgrate',
'Malito',
'Mallare',
'Malles',
'Malles Venosta',
'Malnate',
'Malo',
'Malonno',
'Malosco',
'Malpotremo',
'Maltignano',
'Malvagna',
'Malvicino',
'Malvino',
'Malvito',
'Malé',
'Mammola',
'Mamoiada',
'Manciano',
'Mandanici',
'Mandas',
'Mandatoriccio',
'Mandela',
'Mandello Vitta',
'Mandello del Lario',
'Mandrino',
'Manduria',
'Manerba del Garda',
'Manerbio',
'Manfredonia',
'Mangialupo',
'Mango',
'Mangone',
'Maniace',
'Maniago',
'Maniglia',
'Manocalzati',
'Manoppello',
'Mansuè',
'Manta',
'Mantana',
'Mantello',
'Mantova',
'Manzano',
'Manziana',
'Mapello',
'Mappano',
'Mara',
'Maracalagonis',
'Maranello',
'Marano',
'Marano Equo',
'Marano Lagunare',
'Marano Marchesato',
'Marano Principato',
'Marano Ticino',
'Marano Vicentino',
'Marano di Napoli',
'Marano di Valpolicella',
'Marano sul Panaro',
'Maranola',
'Maranza',
'Maranzana',
'Marassi',
'Maratea',
'Marcallo',
'Marcallo con Casone',
'Marcaria',
'Marcedusa',
'Marcellina',
'Marcellinara',
'Marcellise',
'Marcetelli',
'Marcheno',
'Marchirolo',
'Marciana',
'Marciana Marina',
'Marcianise',
'Marciano della Chiana',
'Marcignago',
'Marco',
'Marcon',
'Marcorengo',
'Marebbe',
'Marene',
'Mareno di Piave',
'Marentino',
'Maresego',
'Mareta',
'Maretto',
'Margarita',
'Margherita di Savoia',
'Margno',
'Margone',
'Mariana Mantovana',
'Mariano Al Brembo',
'Mariano Comense',
'Mariano del Friuli',
'Marianopoli',
'Mariglianella',
'Marigliano',
'Marina di Gioiosa Ionica',
'Marineo',
'Marino',
'Marlengo',
'Marliana',
'Marmentino',
'Marmirolo',
'Marmora',
'Marmorito',
'Marnate',
'Maro Castello',
'Marone',
'Maropati',
'Marore',
'Marostica',
'Marradi',
'Marrubiu',
'Marsaglia',
'Marsala',
'Marsciano',
'Marsico Nuovo',
'Marsicovetere',
'Marta',
'Martano',
'Martellago',
'Martello',
'Martignacco',
'Martignana di Po',
'Martignano',
'Martina Franca',
'Martina Olba',
'Martinengo',
'Martiniana Po',
'Martinsicuro',
'Martirano',
'Martirano Lombardo',
'Martis',
'Martone',
'Marudo',
'Maruggio',
'Marzabotto',
'Marzalengo',
'Marzano',
'Marzano Appio',
'Marzano di Nola',
'Marzi',
'Marzio',
'Masainas',
'Masate',
'Mascali',
'Mascalucia',
'Maschito',
'Masciago Milanese',
'Masciago Primo',
'Maser',
'Masera',
'Maserada sul Piave',
'Maserà di Padova',
'Masi',
'Masi Di Vigo',
'Masi Torello',
'Masio',
'Maslianico',
'Masnago',
'Mason Vicentino',
'Masone',
'Massa',
'Massa Fermana',
'Massa Fiscaglia',
'Massa Lombarda',
'Massa Lubrense',
'Massa Marittima',
'Massa Martana',
'Massa Superiore',
'Massa d\'Albe',
'Massa di Somma',
'Massa e Cozzile',
'Massafra',
'Massalengo',
'Massama',
'Massanzago',
'Massari Melzi',
'Massarosa',
'Massazza',
'Masse Di Città',
'Masse Di San Martino',
'Masse Di Siena',
'Massello',
'Masserano',
'Massignano',
'Massimeno',
'Massimino',
'Massino Visconti',
'Massiola',
'Mastellina',
'Masullas',
'Matelica',
'Matera',
'Mathi',
'Matino',
'Matrice',
'Mattarello',
'Matteria',
'Mattie',
'Mattinata',
'Mattuglie',
'Mazara del Vallo',
'Mazia',
'Mazzano',
'Mazzano Romano',
'Mazzarino',
'Mazzarrone',
'Mazzarrà Sant\'Andrea',
'Mazzin',
'Mazzo Milanese',
'Mazzo di Valtellina',
'Mazzoleni E Falghera',
'Mazzunno',
'Mazzè',
'Meana Sardo',
'Meana di Susa',
'Meano',
'Mechel',
'Meda',
'Medana',
'Mede',
'Medea',
'Medesano',
'Medicina',
'Mediglia',
'Medolago',
'Medole',
'Medolla',
'Meduna di Livenza',
'Meduno',
'Megliadino San Fidenzio',
'Megliadino San Vitale',
'Meina',
'Mel',
'Melara',
'Melazzo',
'Meldola',
'Mele',
'Melegnanello',
'Melegnano',
'Melendugno',
'Meleti',
'Melezet',
'Melfi',
'Melicucco',
'Melicuccà',
'Melilli',
'Melissa',
'Melissano',
'Melito Irpino',
'Melito di Napoli',
'Melito di Porto Salvo',
'Melizzano',
'Melle',
'Mello',
'Melpignano',
'Meltina',
'Melzo',
'Menaggio',
'Menarola',
'Menas',
'Menconico',
'Mendatica',
'Mendatica Montegrosso',
'Mendicino',
'Menfi',
'Mentana',
'Mentoulles',
'Menzago',
'Meolo',
'Merana',
'Merano',
'Merate',
'Mercallo',
'Mercatello sul Metauro',
'Mercatino Conca',
'Mercato San Severino',
'Mercato Saraceno',
'Mercenasco',
'Mercogliano',
'Mercugnano',
'Mercurago',
'Mereto di Tomba',
'Mergo',
'Mergozzo',
'Merlara',
'Merlino',
'Merna',
'Merone',
'Merì',
'Mesagne',
'Mese',
'Mesenzana',
'Mesero',
'Mesola',
'Mesoraca',
'Messina',
'Mestre',
'Mestriago',
'Mestrino',
'Meta',
'Mettone',
'Meugliano',
'Mezzago',
'Mezzana',
'Mezzana Bigli',
'Mezzana Casati',
'Mezzana Mortigliengo',
'Mezzana Rabattone',
'Mezzana Superiore',
'Mezzane di Sotto',
'Mezzanego',
'Mezzani',
'Mezzanino',
'Mezzano',
'Mezzano Imer',
'Mezzano Parpanese',
'Mezzano Passone',
'Mezzano Siccomario',
'Mezzegra',
'Mezzenile',
'Mezzocorona',
'Mezzojuso',
'Mezzolago',
'Mezzoldo',
'Mezzolombardo',
'Mezzomerico',
'Miagliano',
'Miane',
'Miasino',
'Miazzina',
'Micigliano',
'Miggiano',
'Migiandone',
'Miglianico',
'Migliano',
'Migliarino',
'Migliaro',
'Miglierina',
'Miglionico',
'Mignanego',
'Mignano Monte Lungo',
'Mignette',
'Milano',
'Milazzo',
'Milena',
'Mileto',
'Milis',
'Militello Rosmarino',
'Militello in Val di Catania',
'Millan-Sarnes',
'Millaures',
'Millesimo',
'Milo',
'Milzanello',
'Milzano',
'Mineo',
'Minerbe',
'Minerbio',
'Minervino Murge',
'Minervino di Lecce',
'Minoprio',
'Minori',
'Minturno',
'Minucciano',
'Mioglia',
'Miola',
'Mione',
'Mira',
'Mirabella Eclano',
'Mirabella Imbaccari',
'Mirabello',
'Mirabello Ciria',
'Mirabello Comasco',
'Mirabello Ed Uniti Di Pavia',
'Mirabello Monferrato',
'Mirabello San Bernardino',
'Mirabello Sannitico',
'Miradolo Terme',
'Miranda',
'Mirandola',
'Mirano',
'Mirto',
'Misano Adriatico',
'Misano Olona',
'Misano di Gera d\'Adda',
'Misilmeri',
'Misinto',
'Missaglia',
'Missanello',
'Misterbianco',
'Mistretta',
'Mizzole',
'Moano',
'Moasca',
'Mocchie',
'Moconesi',
'Modena',
'Modica',
'Modigliana',
'Modolo',
'Modugno',
'Moena',
'Moerna',
'Moggio',
'Moggio Udinese',
'Moglia',
'Mogliano',
'Mogliano Veneto',
'Mogorella',
'Mogoro',
'Moiana',
'Moiano',
'Moimacco',
'Moio Alcantara',
'Moio de\' Calvi',
'Moio della Civitella',
'Moiola',
'Mola di Bari',
'Molare',
'Molassana',
'Molazzana',
'Molfetta',
'Molina',
'Molina Aterno',
'Molina Di Ledro',
'Molinara',
'Molinella',
'Molini Di Colognola',
'Molini Di Tures',
'Molini di Triora',
'Molino Alzano',
'Molino dei Torti',
'Molise',
'Moliterno',
'Mollaro',
'Mollia',
'Mollières',
'Molo Di Borbera',
'Molochio',
'Moltedo Superiore',
'Molteno',
'Moltrasio',
'Molvena',
'Molveno',
'Mombaldone',
'Mombarcaro',
'Mombaroccio',
'Mombaruzzo',
'Mombasiglio',
'Mombello Lago Maggiore',
'Mombello Monferrato',
'Mombello di Torino',
'Mombercelli',
'Momo',
'Mompantero',
'Mompeo',
'Momperone',
'Mompiano',
'Monacilioni',
'Monale',
'Monasterace',
'Monastero Bormida',
'Monastero di Lanzo',
'Monastero di Vasco',
'Monasterolo Casotto',
'Monasterolo Torinese',
'Monasterolo del Castello',
'Monasterolo di Savigliano',
'Monastier di Treviso',
'Monastir',
'Monate',
'Moncalieri',
'Moncalvo',
'Moncenisio',
'Moncestino',
'Monchiero',
'Monchio delle Corti',
'Monclassico',
'Moncrivello',
'Moncucco Di Monza',
'Moncucco Torinese',
'Moncucco Vecchio',
'Mondaino',
'Mondavio',
'Mondolfo',
'Mondonico',
'Mondonio',
'Mondovì',
'Mondragone',
'Mondrone',
'Moneglia',
'Monesiglio',
'Monfalcone',
'Monforte San Giorgio',
'Monforte d\'Alba',
'Monfumo',
'Mongardino',
'Monghezzo Di Fuori',
'Monghidoro',
'Mongiana',
'Mongiardino Ligure',
'Mongiardino Sillaro',
'Mongiuffi Melia',
'Mongrando',
'Mongrassano',
'Monguelfo-Tesido',
'Monguzzo',
'Moniga del Garda',
'Monleale',
'Monno',
'Monopoli',
'Monreale',
'Monrupino',
'Monsampietro Morico',
'Monsampolo del Tronto',
'Monsano',
'Monselice',
'Monserrato',
'Monsummano Terme',
'Montabone',
'Montacuto',
'Montadamo',
'Montafia',
'Montagano',
'Montagna',
'Montagna in Valtellina',
'Montagnana',
'Montagnareale',
'Montagne',
'Montaguto',
'Montaione',
'Montalbano Elicona',
'Montalbano Jonico',
'Montalcino',
'Montaldeo',
'Montaldo Bormida',
'Montaldo Roero',
'Montaldo Scarampi',
'Montaldo Torinese',
'Montaldo di Mondovì',
'Montale',
'Montalenghe',
'Montalero',
'Montalfoglio',
'Montallegro',
'Montalto Carpasio',
'Montalto Dora',
'Montalto Ligure',
'Montalto Pavese',
'Montalto Tarugo',
'Montalto Uffugo',
'Montalto delle Marche',
'Montalto di Castro',
'Montanara',
'Montanaro',
'Montanaso Lombardo',
'Montanate',
'Montanera',
'Montano Antilia',
'Montano Comasco',
'Montano Lucino',
'Montappone',
'Montaquila',
'Montasola',
'Montassilone',
'Montauro',
'Montazzoli',
'Monte Argentario',
'Monte Castello di Vibio',
'Monte Cavallo',
'Monte Cerignone',
'Monte Colombo',
'Monte Compatri',
'Monte Cremasco',
'Monte Di Capodistria',
'Monte Di Mezzodì',
'Monte Di Tramontana',
'Monte Giberto',
'Monte Grimano Terme',
'Monte Isola',
'Monte Marenzo',
'Monte Montanaro',
'Monte Olimpino',
'Monte Ossolano',
'Monte Porzio',
'Monte Porzio Catone',
'Monte Rinaldo',
'Monte Roberto',
'Monte Romano',
'Monte San Biagio',
'Monte San Candido',
'Monte San Giacomo',
'Monte San Giovanni Campano',
'Monte San Giovanni in Sabina',
'Monte San Giusto',
'Monte San Martino',
'Monte San Pietrangeli',
'Monte San Pietro',
'Monte San Savino',
'Monte San Vito',
'Monte Sant\'Angelo',
'Monte Santa Maria In Sabina',
'Monte Santa Maria Tiberina',
'Monte Urabice',
'Monte Urano',
'Monte Vidon Combatte',
'Monte Vidon Corrado',
'Monte di Malo',
'Monte di Procida',
'Montebello Jonico',
'Montebello Metaurense',
'Montebello Vicentino',
'Montebello della Battaglia',
'Montebello di Bertona',
'Montebello sul Sangro',
'Montebelluna',
'Montebolognola',
'Montebruno',
'Montebuglio',
'Montebuono',
'Montecalvo Del Castellano',
'Montecalvo Irpino',
'Montecalvo Versiggia',
'Montecalvo in Foglia',
'Montecalvoli',
'Montecarlo',
'Montecarotto',
'Montecassiano',
'Montecastello',
'Montecastrilli',
'Montecatini Di Val Di Nievole',
'Montecatini Val di Cecina',
'Montecatini-Terme',
'Montecchia di Crosara',
'Montecchio',
'Montecchio Emilia',
'Montecchio Maggiore',
'Montecchio Precalcino',
'Montecelio',
'Montechiaro',
'Montechiaro Denice',
'Montechiaro d\'Acqui',
'Montechiaro d\'Asti',
'Montechiarugolo',
'Monteciccardo',
'Montecilfone',
'Montecopiolo',
'Montecorice',
'Montecorvino Pugliano',
'Montecorvino Rovella',
'Montecosaro',
'Montecrestese',
'Montecreto',
'Montedinove',
'Montedoro',
'Montefabbri',
'Montefalcione',
'Montefalco',
'Montefalcone Appennino',
'Montefalcone di Val Fortore',
'Montefalcone nel Sannio',
'Montefano',
'Montefelcino',
'Monteferrante',
'Montefiascone',
'Montefino',
'Montefiore Conca',
'Montefiore dell\'Aso',
'Montefiorino',
'Monteflavio',
'Montefontana',
'Monteforte Cilento',
'Monteforte Irpino',
'Monteforte d\'Alpone',
'Montefortino',
'Montefranco',
'Montefredane',
'Montefusco',
'Montegabbione',
'Montegalda',
'Montegaldella',
'Montegallo',
'Montegioco',
'Montegiordano',
'Montegiorgio',
'Montegiove',
'Montegranaro',
'Montegrazie',
'Montegridolfo',
'Montegrino',
'Montegrino Valtravaglia',
'Montegrosso Pian Latte',
'Montegrosso d\'Asti',
'Montegrotto Terme',
'Monteguiduccio',
'Monteiasi',
'Montelabbate',
'Montelanico',
'Montelapiano',
'Monteleone Rocca Doria',
'Monteleone Sabino',
'Monteleone Sui Colli Pavesi',
'Monteleone d\'Orvieto',
'Monteleone di Fermo',
'Monteleone di Puglia',
'Monteleone di Spoleto',
'Montelepre',
'Montelibretti',
'Montella',
'Montello',
'Montelongo',
'Montelparo',
'Montelupo Albese',
'Montelupo Fiorentino',
'Montelupone',
'Montemaggiore',
'Montemaggiore Al Metauro',
'Montemaggiore Belsito',
'Montemagno',
'Montemale di Cuneo',
'Montemarano',
'Montemarciano',
'Montemarzino',
'Montemesola',
'Montemezzo',
'Montemignaio',
'Montemiletto',
'Montemilone',
'Montemitro',
'Montemonaco',
'Montemurlo',
'Montemurro',
'Montenars',
'Montenero D\'Idria',
'Montenero Sabino',
'Montenero Val Cocchiara',
'Montenero di Bisaccia',
'Montenerodomo',
'Monteodorisio',
'Montepaone',
'Monteparano',
'Monteponente',
'Monteprandone',
'Montepulciano',
'Monterado',
'Monterchi',
'Montereale',
'Montereale Valcellina',
'Monterenzio',
'Monteriggioni',
'Monteroduni',
'Monterolo',
'Monteroni d\'Arbia',
'Monteroni di Lecce',
'Monterosi',
'Monterosso Almo',
'Monterosso Calabro',
'Monterosso Grana',
'Monterosso al Mare',
'Monterotondo',
'Monterotondo Marittimo',
'Monterubbiaglio',
'Monterubbiano',
'Montes',
'Montesano Al Piano',
'Montesano Salentino',
'Montesano sulla Marcellana',
'Montesanto Vigi',
'Montesarchio',
'Montescaglioso',
'Montescano',
'Montescheno',
'Montescudaio',
'Montescudo',
'Montescudo-Monte Colombo',
'Montese',
'Montesecco Antico',
'Montesegale',
'Montesicuro',
'Montesilvano',
'Montesilvano Marina',
'Montesiro',
'Montespertoli',
'Montespino',
'Montestrutto',
'Monteu Roero',
'Monteu da Po',
'Montevaccino',
'Montevago',
'Montevarchi',
'Montevecchia',
'Montevecchio',
'Monteveglio',
'Monteverde',
'Monteverdi Marittimo',
'Monteviale',
'Monteviasco',
'Montezemolo',
'Monti',
'Montiano',
'Monticelli Brusati',
'Monticelli Pavese',
'Monticelli Ripa D\'Oglio',
'Monticelli d\'Ongina',
'Monticello Brianza',
'Monticello Conte Otto',
'Monticello d\'Alba',
'Montichiari',
'Monticiano',
'Montieri',
'Montiglio',
'Montiglio Monferrato',
'Montignoso',
'Montirone',
'Montjovet',
'Montodine',
'Montoggio',
'Montona',
'Montonate',
'Montone',
'Montopoli di Sabina',
'Montopoli in Val d\'Arno',
'Montorfano',
'Montorio Romano',
'Montorio Veronese',
'Montorio al Vomano',
'Montorio nei Frentani',
'Montoro',
'Montoro Inferiore',
'Montoro Superiore',
'Montorso',
'Montorso Vicentino',
'Montottone',
'Montresta',
'Montrigiasco',
'Montrone',
'Montà',
'Montù Beccaria',
'Montù Berchielli',
'Monvalle',
'Monza',
'Monzambano',
'Monzuno',
'Morano Calabro',
'Morano sul Po',
'Moransengo',
'Moraro',
'Morazzone',
'Morbegno',
'Morbello',
'Morca',
'Morciano di Leuca',
'Morciano di Romagna',
'Morcone',
'Mordano',
'Moregnano',
'Morengo',
'Mores',
'Moresco',
'Moretta',
'Morfasso',
'Morgano',
'Morgex',
'Morgongiori',
'Mori',
'Moriago della Battaglia',
'Moricone',
'Morigerati',
'Morimondo',
'Morino',
'Moriondo Torinese',
'Morlupo',
'Mormanno',
'Mornago',
'Mornese',
'Mornico Losana',
'Mornico al Serio',
'Morolo',
'Morondo',
'Morosolo',
'Morozzo',
'Morra De Sanctis',
'Morro Reatino',
'Morro d\'Alba',
'Morro d\'Oro',
'Morrone del Sannio',
'Morrovalle',
'Morsano al Tagliamento',
'Morsasco',
'Morsenchio',
'Mortano',
'Mortara',
'Mortaso',
'Mortegliano',
'Morter',
'Morterone',
'Mortizza',
'Moruzzo',
'Moscazzano',
'Moschiano',
'Moschienizze',
'Mosciano Sant\'Angelo',
'Moscufo',
'Moso in Passiria',
'Mossa',
'Mossano',
'Mosso',
'Mosso Santa Maria',
'Motta Baluffi',
'Motta Camastra',
'Motta Montecorvino',
'Motta San Damiano',
'Motta San Giovanni',
'Motta Sant\'Anastasia',
'Motta Santa Lucia',
'Motta Vigana',
'Motta Visconti',
'Motta d\'Affermo',
'Motta de\' Conti',
'Motta di Livenza',
'Mottafollone',
'Mottalciata',
'Motteggiana',
'Mottola',
'Mozzagrogna',
'Mozzanica',
'Mozzano',
'Mozzate',
'Mozzecane',
'Mozzio',
'Mozzo',
'Mu',
'Muccia',
'Muceno',
'Muggia',
'Muggiano',
'Muggiò',
'Mugnano del Cardinale',
'Mugnano di Napoli',
'Mulargia',
'Mulazzano',
'Mulazzo',
'Mules',
'Multedo',
'Mura',
'Murano',
'Muravera',
'Murazzano',
'Murello',
'Muriaglio',
'Murialdo',
'Murisengo',
'Murlo',
'Muro Leccese',
'Muro Lucano',
'Muros',
'Musadino',
'Muscoli Strassoldo',
'Muscoline',
'Musei',
'Musellaro',
'Musiano',
'Musignano',
'Musile di Piave',
'Musocco',
'Musso',
'Mussolente',
'Mussomeli',
'Muzzana del Turgnano',
'Muzzano',
'Nago-Torbole',
'Nalles',
'Nanno',
'Nanto',
'Napoli',
'Narbolia',
'Narcao',
'Nardodipace',
'Nardò',
'Narni',
'Naro',
'Narzole',
'Nasino',
'Naso',
'Naturno',
'Nava',
'Nave',
'Nave San Rocco',
'Navelli',
'Navono',
'Naz-Sciaves',
'Nazzano',
'Ne',
'Nebbiuno',
'Negarine',
'Negrar di Valpolicella',
'Neirone',
'Neive',
'Nembro',
'Nemi',
'Nemoli',
'Neoneli',
'Nepi',
'Neresine',
'Nereto',
'Nerola',
'Nervesa della Battaglia',
'Nervi',
'Nerviano',
'Nese',
'Nespolo',
'Nesso',
'Netro',
'Nettunia',
'Nettuno',
'Neviano',
'Neviano degli Arduini',
'Neviglie',
'Niardo',
'Nibbiano',
'Nibbiola',
'Nibionno',
'Nicastro',
'Nichelino',
'Nicolosi',
'Nicorvo',
'Nicosia',
'Nicotera',
'Niella Belbo',
'Niella Tanaro',
'Nigoline',
'Niguarda',
'Nimis',
'Niscemi',
'Nissoria',
'Nizza Monferrato',
'Nizza di Sicilia',
'Nizzolina',
'Noale',
'Noarna',
'Noasca',
'Nocara',
'Nocciano',
'Nocco',
'Nocera Inferiore',
'Nocera Superiore',
'Nocera Terinese',
'Nocera Umbra',
'Noceto',
'Noci',
'Nociglia',
'Noepoli',
'Nogara',
'Nogaredo',
'Nogarole Rocca',
'Nogarole Vicentino',
'Nogarè',
'Noicattaro',
'Nola',
'Nole',
'Noli',
'Nomaglio',
'Nomesino',
'Nomi',
'Nonantola',
'None',
'Nonio',
'Noragugume',
'Norbello',
'Norcia',
'Noriglio',
'Norma',
'Nosadello',
'Nosate',
'Nosedo',
'Notaresco',
'Noto',
'Nova Levante',
'Nova Milanese',
'Nova Ponente',
'Nova Siri',
'Novacella',
'Novafeltria',
'Novale',
'Novaledo',
'Novalesa',
'Novara',
'Novara di Sicilia',
'Novareglia',
'Novate Brianza',
'Novate Mezzola',
'Novate Milanese',
'Nove',
'Novedrate',
'Novegro',
'Novella',
'Novellara',
'Novello',
'Novello Monchiero',
'Noventa Padovana',
'Noventa Vicentina',
'Noventa di Piave',
'Novi Ligure',
'Novi Velia',
'Novi di Modena',
'Noviglio',
'Novillara',
'Novoli',
'Nozza',
'Nucetto',
'Nuchis',
'Nughedu San Nicolò',
'Nughedu Santa Vittoria',
'Nule',
'Nulvi',
'Numana',
'Nuoro',
'Nurachi',
'Nuragus',
'Nurallao',
'Nuraminis',
'Nuraxinieddu',
'Nureci',
'Nurri',
'Nus',
'Nusco',
'Nuvolento',
'Nuvolera',
'Nuxis',
'Occhieppo Inferiore',
'Occhieppo Superiore',
'Occhiobello',
'Occimiano',
'Ocre',
'Odalengo Grande',
'Odalengo Piccolo',
'Oderzo',
'Odolo',
'Ofena',
'Offagna',
'Offanengo',
'Offida',
'Offlaga',
'Oggebbio',
'Oggiona con Santo Stefano',
'Oggiono',
'Oglianico',
'Ogliastro Cilento',
'Olate',
'Olba',
'Olbia',
'Olcenengo',
'Olcio',
'Oldenico',
'Oleggio',
'Oleggio Castello',
'Olevano Romano',
'Olevano di Lomellina',
'Olevano sul Tusciano',
'Olgia',
'Olgiate Comasco',
'Olgiate Molgora',
'Olgiate Olona',
'Olginasio',
'Olginate',
'Oliena',
'Oliva Gessi',
'Olivadi',
'Olivastri',
'Oliveri',
'Oliveto Citra',
'Oliveto In Sabina',
'Oliveto Lario',
'Oliveto Lucano',
'Olivetta San Michele',
'Olivola',
'Ollastra',
'Ollolai',
'Ollomont',
'Olmedo',
'Olmeneta',
'Olmo Gentile',
'Olmo al Brembo',
'Oltre il Colle',
'Oltrepovo',
'Oltresarca',
'Oltresonzia',
'Oltressenda Alta',
'Oltressenda Bassa',
'Oltrona Al Lago',
'Oltrona di San Mamette',
'Olzai',
'Omate',
'Ombriano',
'Ome',
'Omegna',
'Omignano',
'Onano',
'Onanì',
'Oncino',
'Oneglia',
'Oneta',
'Onies',
'Onifai',
'Oniferi',
'Onno',
'Ono Degno',
'Ono San Pietro',
'Onore',
'Onzo',
'Opacchiasella',
'Opera',
'Opi',
'Oppeano',
'Oppido Lucano',
'Oppido Mamertina',
'Ora',
'Orani',
'Orasso',
'Oratino',
'Orbassano',
'Orbetello',
'Orciano Di Pesaro',
'Orciano Pisano',
'Orco',
'Orco Feglino',
'Ordona',
'Oreno',
'Orero',
'Orezzo',
'Orgiano',
'Orgosolo',
'Oria',
'Oriano',
'Oriano Di Brianza',
'Oriano Sopra Ticino',
'Oricola',
'Origgio',
'Origioso',
'Orino',
'Orino-Azzio',
'Orio Canavese',
'Orio Litta',
'Orio al Serio',
'Oriolo',
'Oriolo Romano',
'Oris',
'Oristano',
'Ormea',
'Ormelle',
'Ornago',
'Ornavasso',
'Ornica',
'Orosei',
'Orotelli',
'Orria',
'Orroli',
'Orsago',
'Orsara Bormida',
'Orsara di Puglia',
'Orsenigo',
'Orsera',
'Orsogna',
'Orsomarso',
'Orta Nova',
'Orta Novarese',
'Orta San Giulio',
'Orta di Atella',
'Ortacesus',
'Orte',
'Ortelle',
'Ortezzano',
'Orti',
'Ortignano Raggiolo',
'Ortisei',
'Ortisè',
'Ortona',
'Ortona dei Marsi',
'Ortovero',
'Ortucchio',
'Ortueri',
'Orune',
'Orvieto',
'Orvinio',
'Orzinuovi',
'Orzivecchi',
'Osasco',
'Osasio',
'Oschiri',
'Osidda',
'Osiglia',
'Osilo',
'Osimo',
'Osini',
'Osio Sopra',
'Osio Sotto',
'Osmate',
'Osnago',
'Osoli',
'Osoppo',
'Ospedaletti',
'Ospedaletto',
'Ospedaletto Euganeo',
'Ospedaletto Lodigiano',
'Ospedaletto d\'Alpinolo',
'Ospitale di Cadore',
'Ospitaletto',
'Ossago Lodigiano',
'Ossalengo',
'Ossana',
'Ossanesga',
'Ossecca Vittuglia',
'Ossegliano San Michele',
'Ossero',
'Ossi',
'Ossimo',
'Ossolaro',
'Ossona',
'Ossuccio',
'Ostana',
'Ostellato',
'Ostiano',
'Ostiglia',
'Ostra',
'Ostra Vetere',
'Ostuni',
'Otranto',
'Otricoli',
'Ottana',
'Ottati',
'Ottaviano',
'Ottiglio',
'Ottobiano',
'Ottone',
'Oulx',
'Ovada',
'Ovaro',
'Oviglio',
'Ovindoli',
'Ovodda',
'Oyace',
'Ozegna',
'Ozieri',
'Ozzano Monferrato',
'Ozzano dell\'Emilia',
'Ozzero',
'Pabillonis',
'Pace del Mela',
'Paceco',
'Pacentro',
'Pachino',
'Paciano',
'Padenghe sul Garda',
'Padergnone',
'Paderna',
'Padernello',
'Paderno Cremonese',
'Paderno Del Grappa',
'Paderno Dugnano',
'Paderno Franciacorta',
'Paderno Ponchielli',
'Paderno d\'Adda',
'Padova',
'Padria',
'Padru',
'Padula',
'Paduli',
'Paesana',
'Paese',
'Pagani',
'Paganica',
'Paganico Sabino',
'Pagazzano',
'Pagliara',
'Pagliare',
'Paglieres',
'Paglieta',
'Pagnacco',
'Pagnano Valsassina',
'Pagno',
'Pagnona',
'Pago Veiano',
'Pago del Vallo di Lauro',
'Paina',
'Pairana',
'Paisco',
'Paisco Loveno',
'Paitone',
'Paladina',
'Palagano',
'Palagianello',
'Palagiano',
'Palagonia',
'Palaia',
'Palanzano',
'Palanzo',
'Palata',
'Palau',
'Palazzago',
'Palazzo Adriano',
'Palazzo Canavese',
'Palazzo Pignano',
'Palazzo Piverone',
'Palazzo San Gervasio',
'Palazzolo Acreide',
'Palazzolo Milanese',
'Palazzolo Vercellese',
'Palazzolo dello Stella',
'Palazzolo sull\'Oglio',
'Palazzuolo sul Senio',
'Palena',
'Palermiti',
'Palermo',
'Palestrina',
'Palestro',
'Paliano',
'Palizzi',
'Pallagorio',
'Pallanza',
'Pallanzeno',
'Pallare',
'Palma Campania',
'Palma di Montechiaro',
'Palmanova',
'Palmariggi',
'Palmas Arborea',
'Palmi',
'Palmiano',
'Palmoli',
'Palo del Colle',
'Palombara Sabina',
'Palombaro',
'Palomonte',
'Palosco',
'Paludi',
'Paluzza',
'Palvareto',
'Palù',
'Palù del Fersina',
'Pamparato',
'Pancalieri',
'Pancarana',
'Panchià',
'Pandino',
'Panettieri',
'Panicale',
'Paniqua',
'Pannarano',
'Panni',
'Pannone',
'Pantasina',
'Pantelleria',
'Pantigliate',
'Paola',
'Paolisi',
'Papasidero',
'Papiago',
'Papigno',
'Papozze',
'Parabiago',
'Parabita',
'Paracorio',
'Paratico',
'Parcines',
'Pare\'',
'Parella',
'Parenti',
'Parenzo',
'Parete',
'Pareto',
'Parghelia',
'Parlasco',
'Parma',
'Parodi Ligure',
'Paroldo',
'Parolise',
'Parona',
'Parona Di Valpolicella',
'Parone',
'Parrano',
'Parravicino',
'Parre',
'Partanna',
'Partinico',
'Paruzzaro',
'Parzanica',
'Pascelupo',
'Pasian di Prato',
'Pasiano di Pordenone',
'Paspardo',
'Passarera',
'Passerano',
'Passerano Marmorito',
'Passignano sul Trasimeno',
'Passirana Milanese',
'Passirano',
'Pastena',
'Pastene',
'Pastorano',
'Pastrengo',
'Pasturago',
'Pasturana',
'Pasturo',
'Paterno',
'Paterno Calabro',
'Paterno D\'Ancona',
'Paternopoli',
'Paternò',
'Patone',
'Patrica',
'Patrignone',
'Pattada',
'Patti',
'Patù',
'Pau',
'Paularo',
'Pauli Arbarei',
'Paulilatino',
'Paullo',
'Paupisi',
'Pavarolo',
'Pavia',
'Pavia di Udine',
'Pavone Canavese',
'Pavone D\'Alessandria',
'Pavone del Mella',
'Pavullo nel Frignano',
'Pazzano',
'Peccioli',
'Pecco',
'Pecetto Torinese',
'Pecetto di Valenza',
'Pecorara',
'Pedace',
'Pedanea',
'Pedara',
'Pedaso',
'Pedavena',
'Pedavoli',
'Pedemonte',
'Pederobba',
'Pederzano',
'Pedesina',
'Pedivigliano',
'Pedrengo',
'Pedriano',
'Pegli',
'Peglio',
'Pegognaga',
'Peia',
'Peio',
'Pelago',
'Pella',
'Pellaro',
'Pellegrino Da Careggi',
'Pellegrino Parmense',
'Pellestrina',
'Pellezzano',
'Pellio Intelvi',
'Pellizzano',
'Pelugo',
'Penango',
'Penna San Giovanni',
'Penna Sant\'Andrea',
'Penna in Teverina',
'Pennabilli',
'Pennadomo',
'Pennapiedimonte',
'Penne',
'Pentone',
'Penzano',
'Pera',
'Perano',
'Perarolo di Cadore',
'Perca',
'Percile',
'Perdasdefogu',
'Perdaxius',
'Perdifumo',
'Perego',
'Pereto',
'Perfugas',
'Pergine Valdarno',
'Pergine Valsugana',
'Pergola',
'Perinaldo',
'Perito',
'Perledo',
'Perletto',
'Perlo',
'Perloz',
'Perno',
'Pernumia',
'Pero',
'Perosa Argentina',
'Perosa Canavese',
'Perrero',
'Perrillo',
'Persico',
'Persico Dosimo',
'Persone',
'Pertengo',
'Perteole',
'Perti',
'Pertica Alta',
'Pertica Bassa',
'Pertosa',
'Pertusio',
'Perugia',
'Pesaro',
'Pescaglia',
'Pescantina',
'Pescara',
'Pescarolo ed Uniti',
'Pescasseroli',
'Pescate',
'Pesche',
'Peschici',
'Peschiera Borromeo',
'Peschiera Maraglio',
'Peschiera del Garda',
'Pescia',
'Pescina',
'Pesco Sannita',
'Pescocostanzo',
'Pescolanciano',
'Pescopagano',
'Pescopennataro',
'Pescorocchiano',
'Pescosansonesco',
'Pescosolido',
'Pessano',
'Pessano con Bornago',
'Pessina Cremonese',
'Pessina Valsassina',
'Pessinetto',
'Petacciato',
'Petilia Policastro',
'Petina',
'Petralia Soprana',
'Petralia Sottana',
'Petrella Salto',
'Petrella Tifernina',
'Petriano',
'Petriolo',
'Petritoli',
'Petrizzi',
'Petronà',
'Petrosino',
'Petruro Irpino',
'Pettenasco',
'Pettinengo',
'Pettineo',
'Pettoranello del Molise',
'Pettorano sul Gizio',
'Pettorazza Grimani',
'Peveragno',
'Peveranza',
'Pezzana',
'Pezzaze',
'Pezzolo De\' Codazzi',
'Pezzolo Di Tavazzano',
'Pezzolo Valle Uzzone',
'Pezzoro',
'Piacenza',
'Piacenza d\'Adige',
'Piadena',
'Piadena Drizzona',
'Piagge',
'Piaggine',
'Piaggine Soprane',
'Pian Camuno',
'Pian Di Sco',
'Pian Gaiano',
'Piana Crixia',
'Piana degli Albanesi',
'Piana di Monte Verna',
'Pianavia',
'Piancastagnaio',
'Pianceri',
'Piancogno',
'Piandimeleto',
'Piane Crati',
'Piane Sesia',
'Pianella',
'Pianello Val Tidone',
'Pianello del Lario',
'Pianengo',
'Pianezza',
'Pianezze',
'Pianfei',
'Piani',
'Pianico',
'Pianiga',
'Piano',
'Piano Porlezza',
'Piano di Sorrento',
'Pianopoli',
'Pianoro',
'Piansano',
'Piantedo',
'Pianura',
'Piario',
'Piasco',
'Piateda',
'Piatto',
'Piavon',
'Piazza Armerina',
'Piazza Brembana',
'Piazza Santo Stefano',
'Piazza al Serchio',
'Piazzatorre',
'Piazzo',
'Piazzo Alto',
'Piazzo Basso',
'Piazzola sul Brenta',
'Piazzolo',
'Picciano',
'Picerno',
'Picinisco',
'Pico',
'Piea',
'Piedicavallo',
'Piediluco',
'Piedimonte Del Calvario',
'Piedimonte Etneo',
'Piedimonte Matese',
'Piedimonte San Germano',
'Piedimulera',
'Piedipaterno Sul Nera',
'Piegaro',
'Piemonte D\'Istria',
'Pienza',
'Pieranica',
'Pietra Gavina',
'Pietra Ligure',
'Pietra Marazzi',
'Pietra de\' Giorgi',
'Pietrabbondante',
'Pietrabruna',
'Pietracamela',
'Pietracatella',
'Pietracupa',
'Pietradefusi',
'Pietraferrazzana',
'Pietrafitta',
'Pietragalla',
'Pietralunga',
'Pietramelara',
'Pietramontecorvino',
'Pietranico',
'Pietrapaola',
'Pietrapertosa',
'Pietraperzia',
'Pietraporzio',
'Pietraroja',
'Pietrarubbia',
'Pietrasanta',
'Pietrastornina',
'Pietravairano',
'Pietrelcina',
'Pieve Albignola',
'Pieve D\'Alpago',
'Pieve Delmona',
'Pieve Di Bono',
'Pieve Di Coriano',
'Pieve Di Ledro',
'Pieve Emanuele',
'Pieve Fissiraga',
'Pieve Fosciana',
'Pieve Ligure',
'Pieve Porto Morone',
'Pieve San Giacomo',
'Pieve San Maurizio',
'Pieve Santo Stefano',
'Pieve Terzagni',
'Pieve Tesino',
'Pieve Torina',
'Pieve Vergonte',
'Pieve a Nievole',
'Pieve d\'Olmi',
'Pieve del Cairo',
'Pieve del Grappa',
'Pieve di Bono-Prezzo',
'Pieve di Cadore',
'Pieve di Cento',
'Pieve di Soligo',
'Pieve di Teco',
'Pievebovigliana',
'Pievepelago',
'Piglio',
'Pigna',
'Pignataro Interamna',
'Pignataro Maggiore',
'Pignola',
'Pignone',
'Pigra',
'Pila',
'Pilcante',
'Pilzone',
'Pimentel',
'Pimonte',
'Pinarolo Po',
'Pinasca',
'Pincara',
'Pinerolo',
'Pineto',
'Pinguente',
'Pino Sulla Sponda Del Lago Maggiore',
'Pino Torinese',
'Pino d\'Asti',
'Pinzano',
'Pinzano al Tagliamento',
'Pinzolo',
'Piobbico',
'Piobesi Torinese',
'Piobesi d\'Alba',
'Piode',
'Pioltello',
'Piombino',
'Piombino Dese',
'Pione',
'Pioraco',
'Piossasco',
'Piove di Sacco',
'Piovene Rocchette',
'Piovera',
'Piovà Massaia',
'Piozzano',
'Piozzo',
'Piraino',
'Pirano',
'Pirri',
'Pisa',
'Pisano',
'Piscina',
'Piscinas',
'Piscinola',
'Pisciotta',
'Piscopio',
'Pisino',
'Pisogne',
'Pisoniano',
'Pissarello',
'Pisticci',
'Pistoia',
'Pistolesa',
'Piteglio',
'Pitigliano',
'Piubega',
'Piuro',
'Piverone',
'Pizzale',
'Pizzighettone',
'Pizzo',
'Pizzocorno',
'Pizzoferrato',
'Pizzolano',
'Pizzoli',
'Pizzone',
'Pizzoni',
'Placanica',
'Planina',
'Planol',
'Plata',
'Plataci',
'Platania',
'Platì',
'Plaus',
'Plesio',
'Plezzo',
'Pliscovizza Della Madonna',
'Ploaghe',
'Plodio',
'Pocapaglia',
'Pocenia',
'Pocrai Del Piro',
'Podargoni',
'Podenzana',
'Podenzano',
'Podraga',
'Pofi',
'Poggi',
'Poggiardo',
'Poggibonsi',
'Poggio Berni',
'Poggio Bustone',
'Poggio Catino',
'Poggio Fidoni',
'Poggio Imperiale',
'Poggio Mirteto',
'Poggio Moiano',
'Poggio Nativo',
'Poggio Picenze',
'Poggio Renatico',
'Poggio Rusco',
'Poggio San Lorenzo',
'Poggio San Marcello',
'Poggio San Vicino',
'Poggio Sannita',
'Poggio Torriana',
'Poggio a Caiano',
'Poggiodomo',
'Poggiofiorito',
'Poggiomarino',
'Poggioreale',
'Poggiorsini',
'Poggiridenti',
'Pogliano Milanese',
'Pognana Lario',
'Pognano',
'Pogno',
'Poirino',
'Pojana Maggiore',
'Pola',
'Polaveno',
'Polcenigo',
'Polengo',
'Polesella',
'Polesine Parmense',
'Polesine Zibello',
'Poli',
'Polia',
'Policoro',
'Polignano a Mare',
'Polinago',
'Polino',
'Polistena',
'Polizzi Generosa',
'Polla',
'Pollein',
'Pollena Trocchia',
'Pollenza',
'Pollica',
'Pollina',
'Pollone',
'Pollutri',
'Polonghera',
'Polpenazze del Garda',
'Polverara',
'Polverigi',
'Pomarance',
'Pomaretto',
'Pomarico',
'Pomaro Monferrato',
'Pomarolo',
'Pombia',
'Pometo',
'Pomezia',
'Pomigliano d\'Arco',
'Pompei',
'Pompeiana',
'Pompiano',
'Pomponesco',
'Pompu',
'Poncarale',
'Poncarale Flero',
'Ponderano',
'Ponna',
'Ponsacco',
'Ponso',
'Pont-Canavese',
'Pont-Saint-Martin',
'Pontagna',
'Pontassieve',
'Pontboset',
'Ponte',
'Ponte Buggianese',
'Ponte Gardena',
'Ponte Lambro',
'Ponte Nizza',
'Ponte Nossa',
'Ponte San Nicolò',
'Ponte San Pietro',
'Ponte dell\'Olio',
'Ponte di Legno',
'Ponte di Piave',
'Ponte in Valtellina',
'Ponte nelle Alpi',
'Pontebba',
'Pontebba Nova',
'Pontecagnano Faiano',
'Pontecarate',
'Pontecchio Polesine',
'Pontechianale',
'Pontecorvo',
'Pontecurone',
'Pontedassio',
'Pontedera',
'Pontedècimo',
'Pontelandolfo',
'Pontelatone',
'Pontelongo',
'Pontenure',
'Ponteranica',
'Pontesesto',
'Pontestura',
'Pontevico',
'Pontey',
'Ponti',
'Ponti sul Mincio',
'Ponticelli',
'Pontida',
'Pontinia',
'Pontinvrea',
'Pontirolo Capredoni',
'Pontirolo Nuovo',
'Pontoglio',
'Pontremoli',
'Ponza',
'Ponzano Monferrato',
'Ponzano Romano',
'Ponzano Veneto',
'Ponzano di Fermo',
'Ponzate',
'Ponzone',
'Popoli',
'Poppi',
'Por',
'Porano',
'Porcari',
'Porchia',
'Porchiano Del Monte',
'Porchiano Dell\'Ascensione',
'Porcia',
'Pordenone',
'Porlezza',
'Pornassio',
'Porpetto',
'Porretta Terme',
'Porta Al Borgo',
'Porta Carratica',
'Porta Lucchese',
'Porta Ombriano',
'Porta San Marco',
'Portacomaro',
'Portalbera',
'Portaria',
'Porte',
'Porte di Rendena',
'Portese',
'Portici',
'Portico di Caserta',
'Portico e San Benedetto',
'Portigliola',
'Portio',
'Porto Azzurro',
'Porto Ceresio',
'Porto Cesareo',
'Porto Civitanova',
'Porto D\'Adda',
'Porto Empedocle',
'Porto Mantovano',
'Porto Recanati',
'Porto San Giorgio',
'Porto Sant\'Elpidio',
'Porto Tolle',
'Porto Torres',
'Porto Valtravaglia',
'Porto Viro',
'Portobuffolè',
'Portocannone',
'Portoferraio',
'Portofino',
'Portogruaro',
'Portole',
'Portomaggiore',
'Portopalo di Capo Passero',
'Portoscuso',
'Portovenere',
'Portula',
'Porzano',
'Posada',
'Poscante',
'Posina',
'Positano',
'Possagno',
'Posta',
'Posta Fibreno',
'Postal',
'Postalesio',
'Posticciola',
'Postiglione',
'Postua',
'Postumia Grotte',
'Potenza',
'Potenza Picena',
'Pove del Grappa',
'Povegliano',
'Povegliano Veronese',
'Poverio',
'Poviglio',
'Povo',
'Povoletto',
'Pozza Di Fassa',
'Pozzaglia Sabina',
'Pozzaglio',
'Pozzaglio ed Uniti',
'Pozzallo',
'Pozzilli',
'Pozzo Alto',
'Pozzo Baronzio',
'Pozzo d\'Adda',
'Pozzol Groppo',
'Pozzolengo',
'Pozzoleone',
'Pozzolo Formigaro',
'Pozzomaggiore',
'Pozzonovo',
'Pozzuoli',
'Pozzuolo Martesana',
'Pozzuolo del Friuli',
'Pra',
'Pradalunga',
'Pradamano',
'Pradleves',
'Prado',
'Pragelato',
'Praia a Mare',
'Praiano',
'Pralboino',
'Prali',
'Pralormo',
'Pralungo',
'Pramaggiore',
'Pramollo',
'Prandaglio',
'Pranzo',
'Prarolo',
'Prarostino',
'Prasco',
'Prascorsano',
'Praso',
'Prata Camportaccio',
'Prata Sannita',
'Prata d\'Ansidonia',
'Prata di Pordenone',
'Prata di Principato Ultra',
'Pratella',
'Prati',
'Pratiglione',
'Prato',
'Prato Alla Drava',
'Prato Carnico',
'Prato Muzio',
'Prato Sesia',
'Prato allo Stelvio',
'Pratola Peligna',
'Pratola Serra',
'Pratovecchio',
'Pratovecchio Stia',
'Pravisdomini',
'Pray',
'Prazzo',
'Precenicco',
'Preci',
'Precotto',
'Predaia',
'Predappio',
'Predazzo',
'Predoi',
'Predore',
'Predosa',
'Preganziol',
'Pregasina',
'Preghena',
'Preglia',
'Pregnana Milanese',
'Prelà',
'Premana',
'Premariacco',
'Premeno',
'Premenugo',
'Premezzo',
'Premia',
'Premilcuore',
'Premione',
'Premolo',
'Premosello-Chiovenda',
'Preone',
'Preore',
'Prepotto',
'Prequartera',
'Preseglie',
'Presegno',
'Presenzano',
'Presezzo',
'Presicce',
'Presicce-Acquarica',
'Pressana',
'Presson',
'Prestine',
'Prestinone',
'Pretoro',
'Preturo',
'Prevacina',
'Prevalle',
'Prezza',
'Prezzo',
'Priacco',
'Priero',
'Priero Montezemolo',
'Prignano Cilento',
'Prignano sulla Secchia',
'Primaluna',
'Primano',
'Primeglio Schierano',
'Primiero',
'Primiero San Martino di Castrozza',
'Priocca',
'Priola',
'Priolo Gargallo',
'Priverno',
'Prizzi',
'Priò',
'Proceno',
'Procida',
'Propata',
'Proserpio',
'Prospiano',
'Prossedi',
'Provaglio Sopra',
'Provaglio Sotto',
'Provaglio Val Sabbia',
'Provaglio d\'Iseo',
'Proves',
'Provezze',
'Provvidenti',
'Prun',
'Prunetto',
'Prè',
'Pré-Saint-Didier',
'Puegnago del Garda',
'Puglianello',
'Pula',
'Pulfero',
'Pulsano',
'Pumenengo',
'Puos D\'Alpago',
'Puria',
'Pusiano',
'Putifigari',
'Putignano',
'Pòia',
'Quadrelle',
'Quadri',
'Quaglietta',
'Quagliuzzo',
'Qualiano',
'Quaranti',
'Quarazze',
'Quaregna',
'Quaregna Cerreto',
'Quargnento',
'Quarna',
'Quarna Sopra',
'Quarna Sotto',
'Quarona',
'Quarrata',
'Quart',
'Quarti',
'Quartiano',
'Quarto',
'Quarto Astese',
'Quarto Cagnino',
'Quarto Dei Mille',
'Quarto d\'Altino',
'Quartu Sant\'Elena',
'Quartucciu',
'Quassolo',
'Quattordio',
'Quattro Castella',
'Quero',
'Quero Vas',
'Quetta',
'Quiliano',
'Quincinetto',
'Quindici',
'Quingentole',
'Quintano',
'Quinto Al Mare',
'Quinto Di Valpantena',
'Quinto Romano',
'Quinto Vercellese',
'Quinto Vicentino',
'Quinto di Treviso',
'Quintodecimo',
'Quintosole',
'Quinzanello',
'Quinzano San Pietro',
'Quinzano Veronese',
'Quinzano d\'Oglio',
'Quistello',
'Quistro',
'Quittengo',
'Rabbi',
'Racale',
'Racalmuto',
'Raccolana',
'Racconigi',
'Raccuja',
'Racines',
'Radda in Chianti',
'Raddusa',
'Radicena',
'Radicofani',
'Radicondoli',
'Raffa',
'Raffadali',
'Ragalna',
'Raggiolo',
'Ragogna',
'Ragoli',
'Ragusa',
'Ragusa Ibla',
'Raiano',
'Ramacca',
'Ramiseto',
'Ramo Di Palo',
'Ramponio',
'Ramponio Verna',
'Rancio Di Lecco',
'Rancio Valcuvia',
'Ranco',
'Randazzo',
'Rango',
'Ranica',
'Ranzanico',
'Ranzi Pietra',
'Ranziano',
'Ranzo',
'Rapagnano',
'Rapallo',
'Rapino',
'Rapolano Terme',
'Rapolla',
'Rapone',
'Rassa',
'Rasun Di Sopra',
'Rasun Di Sotto',
'Rasun Valdaora',
'Rasun-Anterselva',
'Rasura',
'Ratecevo In Monte',
'Ravanusa',
'Ravarino',
'Ravascletto',
'Ravellino',
'Ravello',
'Ravenna',
'Raveo',
'Ravina',
'Raviscanina',
'Re',
'Rea',
'Realmonte',
'Reana del Rojale',
'Reano',
'Rebbio',
'Rebeccu',
'Recale',
'Recanati',
'Recco',
'Recetto',
'Recoaro Terme',
'Recorfano',
'Redavalle',
'Redona',
'Redondesco',
'Refrancore',
'Refrontolo',
'Regalbuto',
'Reggello',
'Reggio di Calabria',
'Reggio nell\'Emilia',
'Reggiolo',
'Regina Fittarezza',
'Reino',
'Reitano',
'Remanzacco',
'Remedello',
'Remedello Sopra',
'Remedello Sotto',
'Renate',
'Rende',
'Renon',
'Resana',
'Rescalda',
'Rescaldina',
'Resia',
'Resiutta',
'Resuttano',
'Retorbido',
'Revello',
'Revere',
'Reviano Folas',
'Revigliasco Torinese',
'Revigliasco d\'Asti',
'Revine',
'Revine Lago',
'Revo\'',
'Rezzago',
'Rezzato',
'Rezzo',
'Rezzoaglio',
'Rezzonico',
'Rho',
'Rhêmes',
'Rhêmes-Notre-Dame',
'Rhêmes-Saint-Georges',
'Riace',
'Rialto',
'Riano',
'Riardo',
'Ribera',
'Ribordone',
'Ricadi',
'Ricaldone',
'Riccia',
'Riccione',
'Riccò del Golfo di Spezia',
'Ricengo',
'Ricigliano',
'Riclaretto',
'Ridanna',
'Riese Pio X',
'Riesi',
'Rieti',
'Rifembergo',
'Rifiano',
'Rifreddo',
'Rignano Flaminio',
'Rignano Garganico',
'Rignano sull\'Arno',
'Rigolato',
'Rigosa',
'Rima San Giuseppe',
'Rimasco',
'Rimella',
'Rimini',
'Rina',
'Rinco',
'Rio',
'Rio Marina',
'Rio Nell\'Elba',
'Rio Saliceto',
'Rio di Pusteria',
'Riofreddo',
'Riola Sardo',
'Riolo Terme',
'Riolunato',
'Riomaggiore',
'Riomolino',
'Rionero Sannitico',
'Rionero in Vulture',
'Riozzo',
'Ripa Teatina',
'Ripaberarda',
'Ripabottoni',
'Ripacandida',
'Ripalimosani',
'Ripalta Arpina',
'Ripalta Cremasca',
'Ripalta Guerina',
'Ripalta Nuova',
'Ripalta Vecchia',
'Riparbella',
'Ripatransone',
'Ripe',
'Ripe San Ginesio',
'Ripi',
'Riposto',
'Riscone',
'Rittana',
'Riva Di Tures',
'Riva Ligure',
'Riva Santo Stefano',
'Riva Valdobbia',
'Riva del Garda',
'Riva del Po',
'Riva di Solto',
'Riva presso Chieri',
'Rivalba',
'Rivalta Bormida',
'Rivalta di Torino',
'Rivamonte Agordino',
'Rivanazzano Terme',
'Rivara',
'Rivarolo Canavese',
'Rivarolo Ligure',
'Rivarolo Mantovano',
'Rivarolo del Re ed Uniti',
'Rivarone',
'Rivarossa',
'Rive',
'Rive d\'Arcano',
'Rivello',
'Rivera',
'Rivergaro',
'Riviera D\'Adda',
'Rivignano',
'Rivignano Teor',
'Rivisondoli',
'Rivodutri',
'Rivoli',
'Rivoli Veronese',
'Rivolta d\'Adda',
'Rivoltella',
'Rivolto',
'Rizziconi',
'Ro',
'Roana',
'Roaschia',
'Roascio',
'Roasio',
'Roatto',
'Robassomero',
'Robbiano',
'Robbiate',
'Robbio',
'Robecchetto',
'Robecchetto con Induno',
'Robecco Lodigiano',
'Robecco Pavese',
'Robecco d\'Oglio',
'Robecco sul Naviglio',
'Robella',
'Robilante',
'Roburent',
'Rocca Canavese',
'Rocca Canterano',
'Rocca Cigliè',
'Rocca Del Colle',
'Rocca Grimalda',
'Rocca Imperiale',
'Rocca Massima',
'Rocca Pia',
'Rocca Pietore',
'Rocca Pietra',
'Rocca Priora',
'Rocca San Casciano',
'Rocca San Felice',
'Rocca San Giovanni',
'Rocca Santa Maria',
'Rocca Santo Stefano',
'Rocca Sinibalda',
'Rocca Susella',
'Rocca d\'Arazzo',
'Rocca d\'Arce',
'Rocca d\'Evandro',
'Rocca de\' Baldi',
'Rocca de\' Giorgi',
'Rocca di Botte',
'Rocca di Cambio',
'Rocca di Cave',
'Rocca di Mezzo',
'Rocca di Neto',
'Rocca di Papa',
'Roccabascerana',
'Roccabernarda',
'Roccabianca',
'Roccabruna',
'Roccacaramanico',
'Roccacasale',
'Roccadaspide',
'Roccafiorita',
'Roccafluvione',
'Roccaforte Ligure',
'Roccaforte Mondovì',
'Roccaforte del Greco',
'Roccaforzata',
'Roccafranca',
'Roccagiovine',
'Roccagloriosa',
'Roccagorga',
'Roccaguglielma',
'Roccalbegna',
'Roccalumera',
'Roccalvecce',
'Roccamandolfi',
'Roccamena',
'Roccamonfina',
'Roccamontepiano',
'Roccamorice',
'Roccanova',
'Roccantica',
'Roccapalumba',
'Roccapiatta',
'Roccapiemonte',
'Roccarainola',
'Roccaraso',
'Roccareonile',
'Roccaromana',
'Roccascalegna',
'Roccasecca',
'Roccasecca dei Volsci',
'Roccasicura',
'Roccasparvera',
'Roccaspinalveti',
'Roccastrada',
'Roccavaldina',
'Roccaverano',
'Roccavignale',
'Roccavione',
'Roccavivara',
'Roccella Ionica',
'Roccella Valdemone',
'Rocchetta Belbo',
'Rocchetta Cairo',
'Rocchetta Cengio',
'Rocchetta Ligure',
'Rocchetta Nervina',
'Rocchetta Palafea',
'Rocchetta Sant\'Antonio',
'Rocchetta Tanaro',
'Rocchetta a Volturno',
'Rocchetta di Vara',
'Rocchetta e Croce',
'Rocchette',
'Rochemolles',
'Rodano',
'Rodda',
'Roddi',
'Roddino',
'Rodello',
'Rodengo',
'Rodengo Saiano',
'Rodero',
'Rodi Garganico',
'Rodigo',
'Roditti',
'Rodoretto',
'Rodì Milici',
'Rofrano',
'Rogeno',
'Roggiano Gravina',
'Roggiano Valtravaglia',
'Roghudi',
'Rogliano',
'Rognano',
'Rogno',
'Rogolo',
'Roiate',
'Roio Piano',
'Roio del Sangro',
'Roisan',
'Roletto',
'Rollières',
'Rolo',
'Roma',
'Romagnano',
'Romagnano Sesia',
'Romagnano al Monte',
'Romagnese',
'Romallo',
'Romana',
'Romanengo',
'Romano Canavese',
'Romano d\'Ezzelino',
'Romano di Lombardia',
'Romans',
'Romans d\'Isonzo',
'Romanò Brianza',
'Romarzolo',
'Rombiolo',
'Romeno',
'Romentino',
'Rometta',
'Romprezzagno',
'Ronago',
'Ronca De\' Golferami',
'Roncade',
'Roncadelle',
'Roncadello D\'Adda',
'Roncaro',
'Roncegno Terme',
'Roncello',
'Ronchetto',
'Ronchi Di Ala',
'Ronchi Valsugana',
'Ronchi dei Legionari',
'Ronchis',
'Ronciglione',
'Ronco Biellese',
'Ronco Briantino',
'Ronco Canavese',
'Ronco Scrivia',
'Ronco all\'Adige',
'Roncobello',
'Roncoferraro',
'Roncofreddo',
'Roncogno',
'Roncola',
'Roncone',
'Roncà',
'Rondanina',
'Rondissone',
'Rongio',
'Ronsecco',
'Ronzina',
'Ronzo',
'Ronzo-Chienis',
'Ronzone',
'Roppolo',
'Rorà',
'Rosalì',
'Rosarno',
'Rosasco',
'Rosate',
'Rosazza',
'Rosciano',
'Rosciate',
'Roscigno',
'Rose',
'Rosello',
'Roserio',
'Roseto Capo Spulico',
'Roseto Valfortore',
'Roseto degli Abruzzi',
'Rosignano Marittimo',
'Rosignano Monferrato',
'Rosingo',
'Rosolina',
'Rosolini',
'Rosora',
'Rosora Mergo',
'Rossa',
'Rossana',
'Rossano',
'Rossano Veneto',
'Rossiglione',
'Rossino',
'Rosta',
'Rosà',
'Rota Dentro',
'Rota Fuori',
'Rota Greca',
'Rota d\'Imagna',
'Rotella',
'Rotello',
'Rotonda',
'Rotondella',
'Rotondi',
'Rottofreno',
'Rotzo',
'Roure',
'Rovagnasco',
'Rovagnate',
'Rovasenda',
'Rovate',
'Rovato',
'Rovegno',
'Rovegro',
'Rovellasca',
'Rovello Porro',
'Rovenna',
'Rover Carbonare',
'Roverbella',
'Roverchiara',
'Roveredo di Guà',
'Roveredo in Piano',
'Rovereto',
'Roversano',
'Roverè Veronese',
'Roverè della Luna',
'Rovescala',
'Rovetta',
'Rovetta Con Fino',
'Rovezzano',
'Roviano',
'Rovigno D\'Istria',
'Rovigo',
'Rovito',
'Rovolon',
'Rozzano',
'Rozzo',
'Roè Volciano',
'Rubano',
'Rubbiano',
'Rubiana',
'Rubiera',
'Ruda',
'Rudiano',
'Rueglio',
'Ruffano',
'Ruffia',
'Ruffrè-Mendola',
'Rufina',
'Ruginello',
'Ruinas',
'Ruino',
'Rumianca',
'Rumo',
'Runo',
'Ruoti',
'Russi',
'Rutigliano',
'Rutino',
'Ruviano',
'Ruvo del Monte',
'Ruvo di Puglia',
'Sabaudia',
'Sabbia',
'Sabbio Bergamasco',
'Sabbio Chiese',
'Sabbioncello',
'Sabbioneta',
'Sable Grande',
'Sacco',
'Saccolongo',
'Sacconago',
'Sacile',
'Sacrofano',
'Sadali',
'Saga',
'Sagama',
'Sagliano Di Crenna',
'Sagliano Micca',
'Sagoria San Martino',
'Sagrado',
'Sagron Mis',
'Saguedo',
'Saiano',
'Saint-Christophe',
'Saint-Denis',
'Saint-Marcel',
'Saint-Nicolas',
'Saint-Oyen',
'Saint-Pierre',
'Saint-Rhémy-en-Bosses',
'Saint-Vincent',
'Sala Al Barro',
'Sala Baganza',
'Sala Biellese',
'Sala Bolognese',
'Sala Comacina',
'Sala Consilina',
'Sala Monferrato',
'Salabue',
'Salandra',
'Salaparuta',
'Salara',
'Salasco',
'Salassa',
'Salbertrand',
'Salcano',
'Salcedo',
'Salcito',
'Sale',
'Sale Castelnuovo',
'Sale Marasino',
'Sale San Giovanni',
'Sale delle Langhe',
'Salecanischio',
'Salecchio',
'Salemi',
'Salento',
'Salerano Canavese',
'Salerano sul Lambro',
'Salerno',
'Saletto',
'Salgareda',
'Sali Vercellese',
'Salice Calabro',
'Salice Salentino',
'Saliceto',
'Salina',
'Salisano',
'Salizzole',
'Salle',
'Salmour',
'Salona D\'Isonzo',
'Salorno',
'Salsomaggiore Terme',
'Saltara',
'Salto',
'Saltrio',
'Saludecio',
'Saluggia',
'Salussola',
'Saluzzo',
'Salvaterra',
'Salve',
'Salvirola',
'Salvitelle',
'Salza Irpina',
'Salza di Pinerolo',
'Salzano',
'Salò',
'Samarate',
'Samaria',
'Samassi',
'Samatzai',
'Sambasso',
'Sambatello',
'Sambiase',
'Sambuca Pistoiese',
'Sambuca di Sicilia',
'Sambuci',
'Sambuco',
'Sambuco-Pietraporzio',
'Sambughetto',
'Sammichele di Bari',
'Samo',
'Samo Di Calabria',
'Samoclevo',
'Samolaco',
'Samone',
'Sampeyre',
'Samugheo',
'San Barbato',
'San Bartolomeo',
'San Bartolomeo Al Bosco',
'San Bartolomeo Val Cavargna',
'San Bartolomeo Valmara',
'San Bartolomeo al Mare',
'San Bartolomeo in Galdo',
'San Basile',
'San Basilio',
'San Bassano',
'San Bellino',
'San Benedetto Belbo',
'San Benedetto Po',
'San Benedetto Ullano',
'San Benedetto Val di Sambro',
'San Benedetto dei Marsi',
'San Benedetto del Tronto',
'San Benedetto in Perillis',
'San Benigno Canavese',
'San Bernardino',
'San Bernardino Verbano',
'San Biagio Platani',
'San Biagio Saracinisco',
'San Biagio della Cima',
'San Biagio di Callalta',
'San Biase',
'San Bonifacio',
'San Buono',
'San Calogero',
'San Candido',
'San Canzian d\'Isonzo',
'San Carlo Canavese',
'San Carlo D\'Ossola',
'San Casciano dei Bagni',
'San Casciano in Val di Pesa',
'San Cassiano',
'San Cataldo',
'San Cesareo',
'San Cesario di Lecce',
'San Cesario sul Panaro',
'San Chirico Nuovo',
'San Chirico Raparo',
'San Cipirello',
'San Cipriano Picentino',
'San Cipriano Po',
'San Cipriano d\'Aversa',
'San Clemente',
'San Colombano Belmonte',
'San Colombano Certenoli',
'San Colombano al Lambro',
'San Cono',
'San Cosmo Albanese',
'San Costantino Albanese',
'San Costantino Calabro',
'San Costanzo',
'San Cristoforo',
'San Damiano Di Monza',
'San Damiano Macra',
'San Damiano al Colle',
'San Damiano d\'Asti',
'San Daniele Del Carso',
'San Daniele Po',
'San Daniele del Friuli',
'San Demetrio Corone',
'San Demetrio ne\' Vestini',
'San Didero',
'San Donaci',
'San Donato D\'Enza',
'San Donato Milanese',
'San Donato Val di Comino',
'San Donato di Lecce',
'San Donato di Ninea',
'San Donà di Piave',
'San Dorligo della Valle',
'San Fedele Intelvi',
'San Fele',
'San Felice',
'San Felice Circeo',
'San Felice Di Scovolo',
'San Felice a Cancello',
'San Felice del Benaco',
'San Felice del Molise',
'San Felice sul Panaro',
'San Ferdinando',
'San Ferdinando di Puglia',
'San Fermo della Battaglia',
'San Fili',
'San Filippo del Mela',
'San Fior',
'San Fiorano',
'San Floriano del Collio',
'San Floro',
'San Francesco D\'Albaro',
'San Francesco al Campo',
'San Fratello',
'San Fruttuoso',
'San Gallo',
'San Gavino Monreale',
'San Gemini',
'San Genesio Atesino',
'San Genesio ed Uniti',
'San Gennaro Vesuviano',
'San Germano Chisone',
'San Germano Dei Berici',
'San Germano Vercellese',
'San Gervasio Bresciano',
'San Gervasio D\'Adda',
'San Giacomo',
'San Giacomo Filippo',
'San Giacomo In Colle',
'San Giacomo Vercellese',
'San Giacomo degli Schiavoni',
'San Giacomo delle Segnate',
'San Gillio',
'San Gillio Torinese',
'San Gimignano',
'San Ginesio',
'San Giorgio',
'San Giorgio Albanese',
'San Giorgio Bigarello',
'San Giorgio Canavese',
'San Giorgio Di Lago',
'San Giorgio Di Pesaro',
'San Giorgio Ionico',
'San Giorgio La Molara',
'San Giorgio Lucano',
'San Giorgio Monferrato',
'San Giorgio Morgeto',
'San Giorgio Piacentino',
'San Giorgio Scarampi',
'San Giorgio a Cremano',
'San Giorgio a Liri',
'San Giorgio del Sannio',
'San Giorgio della Richinvelda',
'San Giorgio delle Pertiche',
'San Giorgio di Lomellina',
'San Giorgio di Nogaro',
'San Giorgio di Piano',
'San Giorgio in Bosco',
'San Giorgio su Legnano',
'San Giorio di Susa',
'San Giovanni',
'San Giovanni A Teduccio',
'San Giovanni Alla Castagna',
'San Giovanni Battista',
'San Giovanni Bianco',
'San Giovanni D\'Asso',
'San Giovanni Di Galermo',
'San Giovanni Gemini',
'San Giovanni Ilarione',
'San Giovanni Incarico',
'San Giovanni Lipioni',
'San Giovanni Lupatoto',
'San Giovanni Pellice',
'San Giovanni Reatino',
'San Giovanni Rotondo',
'San Giovanni Suergiu',
'San Giovanni Teatino',
'San Giovanni Valdarno',
'San Giovanni a Piro',
'San Giovanni al Natisone',
'San Giovanni del Dosso',
'San Giovanni di Fassa',
'San Giovanni di Gerace',
'San Giovanni in Croce',
'San Giovanni in Fiore',
'San Giovanni in Galdo',
'San Giovanni in Marignano',
'San Giovanni in Persiceto',
'San Giovanni la Punta',
'San Giuliano Milanese',
'San Giuliano Terme',
'San Giuliano del Sannio',
'San Giuliano di Puglia',
'San Giuseppe Di Casto',
'San Giuseppe Jato',
'San Giuseppe Vesuviano',
'San Giustino',
'San Giusto Canavese',
'San Godenzo',
'San Gregorio Magno',
'San Gregorio Matese',
'San Gregorio d\'Ippona',
'San Gregorio da Sassola',
'San Gregorio di Catania',
'San Gregorio nelle Alpi',
'San Lazzaro Alberoni',
'San Lazzaro Parmense',
'San Lazzaro Reale',
'San Lazzaro di Savena',
'San Leo',
'San Leonardo',
'San Leonardo in Passiria',
'San Leucio',
'San Leucio del Sannio',
'San Lorenzello',
'San Lorenzo',
'San Lorenzo Aroldo',
'San Lorenzo Bellizzi',
'San Lorenzo De\' Picenardi',
'San Lorenzo Dorsino',
'San Lorenzo Guazzone',
'San Lorenzo In Banale',
'San Lorenzo Isontino',
'San Lorenzo Maggiore',
'San Lorenzo Mondinari',
'San Lorenzo Nuovo',
'San Lorenzo al Mare',
'San Lorenzo del Vallo',
'San Lorenzo di Sebato',
'San Lorenzo in Campo',
'San Luca',
'San Lucido',
'San Lugano',
'San Lupo',
'San Mango Piemonte',
'San Mango d\'Aquino',
'San Mango sul Calore',
'San Marcellino',
'San Marcello',
'San Marcello Pistoiese',
'San Marcello Piteglio',
'San Marco A Monti',
'San Marco Argentano',
'San Marco Evangelista',
'San Marco d\'Alunzio',
'San Marco dei Cavoti',
'San Marco in Lamis',
'San Marco la Catola',
'San Martino Al Cimino',
'San Martino Al Monte',
'San Martino Alfieri',
'San Martino Buon Albergo',
'San Martino Canavese',
'San Martino D\'Albaro',
'San Martino De\' Calvi',
'San Martino Di Perrero',
'San Martino In Beliseto',
'San Martino In Casies',
'San Martino In Valle',
'San Martino Quisca',
'San Martino Sannita',
'San Martino Siccomario',
'San Martino Sinzano',
'San Martino Valle Caudina',
'San Martino al Tagliamento',
'San Martino d\'Agri',
'San Martino dall\'Argine',
'San Martino del Lago',
'San Martino di Finita',
'San Martino di Lupari',
'San Martino di Venezze',
'San Martino in Badia',
'San Martino in Passiria',
'San Martino in Pensilis',
'San Martino in Rio',
'San Martino in Strada',
'San Martino sulla Marrucina',
'San Marzano Moasca',
'San Marzano Oliveto',
'San Marzano di San Giuseppe',
'San Marzano sul Sarno',
'San Marzanotto',
'San Massimo',
'San Massimo All\'Adige',
'San Maurizio Canavese',
'San Maurizio d\'Opaglio',
'San Mauro Castelverde',
'San Mauro Cilento',
'San Mauro Forte',
'San Mauro Marchesato',
'San Mauro Pascoli',
'San Mauro Torinese',
'San Mauro di Saline',
'San Mauro la Bruca',
'San Michele Cremasco',
'San Michele D\'Asti',
'San Michele Delle Badesse',
'San Michele Di Postumia',
'San Michele Extra',
'San Michele In Teverina',
'San Michele Mondovì',
'San Michele Prazzo',
'San Michele Salentino',
'San Michele al Tagliamento',
'San Michele all\'Adige',
'San Michele di Ganzaria',
'San Michele di Serino',
'San Miniato',
'San Nazario',
'San Nazzaro',
'San Nazzaro Mella',
'San Nazzaro Sesia',
'San Nazzaro Val Cavargna',
'San Nicandro D\'Aquila',
'San Nicandro Garganico',
'San Nicola Arcella',
'San Nicola Baronia',
'San Nicola Manfredi',
'San Nicola da Crissa',
'San Nicola dell\'Alto',
'San Nicola la Strada',
'San Nicolò Gerrei',
'San Nicolò d\'Arcidano',
'San Nicolò di Comelico',
'San Novo',
'San Pancrazio',
'San Pancrazio Al Colle',
'San Pancrazio Parmense',
'San Pancrazio Salentino',
'San Pantaleo',
'San Paolo',
'San Paolo Albanese',
'San Paolo Bel Sito',
'San Paolo Cervo',
'San Paolo Della Valle',
'San Paolo Ripa D\'Oglio',
'San Paolo Solbrito',
'San Paolo d\'Argon',
'San Paolo di Civitate',
'San Paolo di Jesi',
'San Pedrino',
'San Pelagio',
'San Pellegrino Terme',
'San Perone',
'San Pier D\'Arena',
'San Pier Fedele',
'San Pier Niceto',
'San Pier d\'Isonzo',
'San Piero A Sieve',
'San Piero Patti',
'San Pietro',
'San Pietro A Patierno',
'San Pietro Apostolo',
'San Pietro Avellana',
'San Pietro Bestazzo',
'San Pietro Clarenza',
'San Pietro Cusico',
'San Pietro D\'Orzio',
'San Pietro Del Carso',
'San Pietro Di Barbozza',
'San Pietro Di Gorizia',
'San Pietro Di Monterosso',
'San Pietro In Curolis',
'San Pietro Infine',
'San Pietro Irpino',
'San Pietro Mosezzo',
'San Pietro Mussolino',
'San Pietro Val Lemina',
'San Pietro Vernotico',
'San Pietro Viminario',
'San Pietro a Maida',
'San Pietro al Natisone',
'San Pietro al Tanagro',
'San Pietro di Cadore',
'San Pietro di Caridà',
'San Pietro di Feletto',
'San Pietro di Morubio',
'San Pietro in Amantea',
'San Pietro in Cariano',
'San Pietro in Casale',
'San Pietro in Cerro',
'San Pietro in Gu',
'San Pietro in Guarano',
'San Pietro in Lama',
'San Pio delle Camere',
'San Polo Matese',
'San Polo d\'Enza',
'San Polo dei Cavalieri',
'San Polo di Piave',
'San Ponso',
'San Ponzo Semola',
'San Possidonio',
'San Potito Sannitico',
'San Potito Ultra',
'San Prisco',
'San Procopio',
'San Prospero',
'San Quirico In Val Polcevera',
'San Quirico d\'Orcia',
'San Quirino',
'San Raffaele Cimena',
'San Roberto',
'San Rocco al Porto',
'San Romano in Garfagnana',
'San Rufo',
'San Salvatore',
'San Salvatore Monferrato',
'San Salvatore Telesino',
'San Salvatore di Fitalia',
'San Salvo',
'San Sebastiano Curone',
'San Sebastiano al Vesuvio',
'San Sebastiano da Po',
'San Secondo Parmense',
'San Secondo di Pinerolo',
'San Severino Lucano',
'San Severino Marche',
'San Severo',
'San Sigismondo',
'San Sillo',
'San Silvestro',
'San Siro',
'San Sossio Baronia',
'San Sostene',
'San Sosti',
'San Sperate',
'San Stino di Livenza',
'San Tammaro',
'San Teodoro',
'San Tomaso Agordino',
'San Valentino Alla Mutta',
'San Valentino Torio',
'San Valentino in Abruzzo Citeriore',
'San Varese',
'San Venanzo',
'San Vendemiano',
'San Vero Congius',
'San Vero Milis',
'San Vigilio',
'San Vincenzo',
'San Vincenzo La Costa',
'San Vincenzo Valle Roveto',
'San Vitaliano',
'San Vito',
'San Vito Chietino',
'San Vito Di Vipacco',
'San Vito E Marta',
'San Vito E Modesto',
'San Vito In Monte',
'San Vito Lo Capo',
'San Vito Romano',
'San Vito Sul Cesano',
'San Vito al Tagliamento',
'San Vito al Torre',
'San Vito dei Normanni',
'San Vito di Cadore',
'San Vito di Fagagna',
'San Vito di Leguzzano',
'San Vito sullo Ionio',
'San Vittore Olona',
'San Vittore del Lazio',
'San Zeno',
'San Zeno Naviglio',
'San Zeno di Montagna',
'San Zenone al Lambro',
'San Zenone al Po',
'San Zenone degli Ezzelini',
'Sanarica',
'Sandigliano',
'Sandrigo',
'Sanfront',
'Sanfrè',
'Sangano',
'Sangiano',
'Sangineto',
'Sanguinetto',
'Sanluri',
'Sannazzaro de\' Burgondi',
'Sannicandro di Bari',
'Sannicola',
'Sanremo',
'Sansepolcro',
'Sant\'Abbondio',
'Sant\'Agapito',
'Sant\'Agata Bolognese',
'Sant\'Agata Feltria',
'Sant\'Agata Fossili',
'Sant\'Agata Irpina',
'Sant\'Agata Martesana',
'Sant\'Agata Sopra Cannobio',
'Sant\'Agata de\' Goti',
'Sant\'Agata del Bianco',
'Sant\'Agata di Esaro',
'Sant\'Agata di Militello',
'Sant\'Agata di Puglia',
'Sant\'Agata li Battiati',
'Sant\'Agata sul Santerno',
'Sant\'Agnello',
'Sant\'Agostino',
'Sant\'Albano Di Bobbio',
'Sant\'Albano Stura',
'Sant\'Alessandro',
'Sant\'Alessio Siculo',
'Sant\'Alessio con Vialone',
'Sant\'Alessio in Aspromonte',
'Sant\'Alfio',
'Sant\'Ambrogio Olona',
'Sant\'Ambrogio di Torino',
'Sant\'Ambrogio di Valpolicella',
'Sant\'Ambrogio sul Garigliano',
'Sant\'Anastasia',
'Sant\'Anatolia di Narco',
'Sant\'Andrea Apostolo dello Ionio',
'Sant\'Andrea Di Cavasagra',
'Sant\'Andrea Di Gorizia',
'Sant\'Andrea Di Suasa',
'Sant\'Andrea Di Vallefredda',
'Sant\'Andrea Frius',
'Sant\'Andrea In Monte',
'Sant\'Andrea del Garigliano',
'Sant\'Andrea di Conza',
'Sant\'Angelo In Lizzola',
'Sant\'Angelo Le Fratte',
'Sant\'Angelo Limosano',
'Sant\'Angelo Lodigiano',
'Sant\'Angelo Lomellina',
'Sant\'Angelo Muxaro',
'Sant\'Angelo Romano',
'Sant\'Angelo a Cupolo',
'Sant\'Angelo a Fasanella',
'Sant\'Angelo a Scala',
'Sant\'Angelo all\'Esca',
'Sant\'Angelo d\'Alife',
'Sant\'Angelo dei Lombardi',
'Sant\'Angelo del Pesco',
'Sant\'Angelo di Brolo',
'Sant\'Angelo di Piove di Sacco',
'Sant\'Angelo in Pontano',
'Sant\'Angelo in Vado',
'Sant\'Anna Arresi',
'Sant\'Anna d\'Alfaedo',
'Sant\'Antimo',
'Sant\'Antioco',
'Sant\'Antonino Ticino',
'Sant\'Antonino di Susa',
'Sant\'Antonio A Trebbia',
'Sant\'Antonio Abate',
'Sant\'Antonio D\'Adda',
'Sant\'Antonio di Gallura',
'Sant\'Apollinare',
'Sant\'Apollinare Con Selva',
'Sant\'Arcangelo',
'Sant\'Arcangelo Trimonte',
'Sant\'Arpino',
'Sant\'Arsenio',
'Sant\'Egidio alla Vibrata',
'Sant\'Egidio del Monte Albino',
'Sant\'Elena',
'Sant\'Elena Sannita',
'Sant\'Elia Fiumerapido',
'Sant\'Elia Reatino',
'Sant\'Elia a Pianisi',
'Sant\'Elpidio Morico',
'Sant\'Elpidio a Mare',
'Sant\'Erasmo',
'Sant\'Eufemia Della Fonte',
'Sant\'Eufemia Lamezia',
'Sant\'Eufemia a Maiella',
'Sant\'Eufemia d\'Aspromonte',
'Sant\'Eusanio Forconese',
'Sant\'Eusanio del Sangro',
'Sant\'Ilario Ligure',
'Sant\'Ilario d\'Enza',
'Sant\'Ilario dello Ionio',
'Sant\'Ippolito',
'Sant\'Olcese',
'Sant\'Omero',
'Sant\'Omobono Terme',
'Sant\'Onofrio',
'Sant\'Oreste',
'Sant\'Orsola Terme',
'Sant\'Urbano',
'Santa Brigida',
'Santa Caterina Albanese',
'Santa Caterina Villarmosa',
'Santa Caterina dello Ionio',
'Santa Cesarea Terme',
'Santa Cristina Gela',
'Santa Cristina Valgardena',
'Santa Cristina d\'Aspromonte',
'Santa Cristina e Bissone',
'Santa Croce Camerina',
'Santa Croce Di Aidussina',
'Santa Croce del Sannio',
'Santa Croce di Magliano',
'Santa Croce sull\'Arno',
'Santa Domenica Talao',
'Santa Domenica Vittoria',
'Santa Elisabetta',
'Santa Fiora',
'Santa Flavia',
'Santa Giuletta',
'Santa Giulia',
'Santa Giusta',
'Santa Giustina',
'Santa Giustina in Colle',
'Santa Luce',
'Santa Luce Orciano',
'Santa Lucia D\'Isonzo',
'Santa Lucia del Mela',
'Santa Lucia di Piave',
'Santa Lucia di Serino',
'Santa Maddalena In Casies',
'Santa Margherita',
'Santa Margherita D\'Adige',
'Santa Margherita Di Bobbio',
'Santa Margherita Ligure',
'Santa Margherita Po',
'Santa Margherita di Belice',
'Santa Margherita di Staffora',
'Santa Maria Capua Vetere',
'Santa Maria Coghinas',
'Santa Maria Del Monte',
'Santa Maria Del Tronto',
'Santa Maria Della Croce',
'Santa Maria Di Rovagnate',
'Santa Maria Hoè',
'Santa Maria Imbaro',
'Santa Maria In Prato',
'Santa Maria In Stelle',
'Santa Maria Maggiore',
'Santa Maria Nuova',
'Santa Maria Rezzonico',
'Santa Maria a Monte',
'Santa Maria a Vico',
'Santa Maria del Cedro',
'Santa Maria del Molise',
'Santa Maria della Versa',
'Santa Maria di Licodia',
'Santa Maria di Sala',
'Santa Maria la Carità',
'Santa Maria la Fossa',
'Santa Maria la Longa',
'Santa Marina',
'Santa Marina Salina',
'Santa Marinella',
'Santa Marìa Maggiore E Crana',
'Santa Ninfa',
'Santa Paolina',
'Santa Severina',
'Santa Sofia',
'Santa Sofia d\'Epiro',
'Santa Teresa Gallura',
'Santa Teresa di Riva',
'Santa Valeria',
'Santa Venerina',
'Santa Vittoria d\'Alba',
'Santa Vittoria in Matenano',
'Santadi',
'Santarcangelo di Romagna',
'Sante Marie',
'Santena',
'Santeramo in Colle',
'Santhià',
'Santi Cosma e Damiano',
'Santicolo',
'Santino',
'Santo Spirito Di Bainsizza',
'Santo Stefano Belbo',
'Santo Stefano Del Mare Con Castellaro',
'Santo Stefano Del Monte Degli Angeli',
'Santo Stefano Di Briga',
'Santo Stefano Lodigiano',
'Santo Stefano Quisquina',
'Santo Stefano Roero',
'Santo Stefano Ticino',
'Santo Stefano al Mare',
'Santo Stefano d\'Aveto',
'Santo Stefano del Sole',
'Santo Stefano di Cadore',
'Santo Stefano di Camastra',
'Santo Stefano di Magra',
'Santo Stefano di Rogliano',
'Santo Stefano di Sessanio',
'Santo Stefano in Aspromonte',
'Santomenna',
'Santopadre',
'Santorso',
'Santu Lussurgiu',
'Sanvincenti',
'Sanza',
'Sanzeno',
'Saonara',
'Saone',
'Saponara',
'Sappada',
'Sapri',
'Saracena',
'Saracinesco',
'Sarcedo',
'Sarconi',
'Sardagna',
'Sardara',
'Sardigliano',
'Sarego',
'Sarentino',
'Sarezzano',
'Sarezzo',
'Sarmato',
'Sarmede',
'Sarnano',
'Sarnico',
'Sarno',
'Sarnonico',
'Sarola',
'Saronno',
'Sarre',
'Sarroch',
'Sarsina',
'Sarteano',
'Sartirana Briantea',
'Sartirana Lomellina',
'Sarule',
'Sarzana',
'Sassa',
'Sassano',
'Sassari',
'Sassello',
'Sassetta',
'Sassinoro',
'Sasso',
'Sasso Di Bordighera',
'Sasso Marconi',
'Sasso di Castalda',
'Sassocorvaro',
'Sassocorvaro Auditore',
'Sassofeltrio',
'Sassoferrato',
'Sassuolo',
'Satriano',
'Satriano di Lucania',
'Sauris',
'Sauze d\'Oulx',
'Sauze di Cesana',
'Sava',
'Savelli',
'Saviano',
'Savigliano',
'Savignano',
'Savignano Irpino',
'Savignano sul Panaro',
'Savignano sul Rubicone',
'Savigno',
'Savignone',
'Saviore dell\'Adamello',
'Savoca',
'Savogna',
'Savogna d\'Isonzo',
'Savoia di Lucania',
'Savona',
'Savoulx',
'Scafa',
'Scafati',
'Scagnello',
'Scala',
'Scala Coeli',
'Scaldasole',
'Scalea',
'Scalenghe',
'Scaleres',
'Scaletta Uzzone',
'Scaletta Zanclea',
'Scampitella',
'Scandale',
'Scandeluzza',
'Scandiano',
'Scandicci',
'Scandolara Ravara',
'Scandolara Ripa d\'Oglio',
'Scandriglia',
'Scannabue',
'Scanno',
'Scano Al Brembo',
'Scano di Montiferro',
'Scansano',
'Scanzano Jonico',
'Scanzo',
'Scanzorosciate',
'Scapoli',
'Scarenna',
'Scaria',
'Scarlino',
'Scarmagno',
'Scarnafigi',
'Scarnafigi-Ruffia',
'Scarperia',
'Scarperia e San Piero',
'Scarpizzolo',
'Scavolino',
'Scena',
'Scerni',
'Scheggia e Pascelupo',
'Scheggino',
'Scherbina',
'Schianno',
'Schiavi di Abruzzo',
'Schiavon',
'Schieranco',
'Schignano',
'Schilpario',
'Schio',
'Schivenoglia',
'Schèggia',
'Sciacca',
'Sciara',
'Sciaves',
'Scicli',
'Scido',
'Scigliano',
'Scilla',
'Scillato',
'Sciolze',
'Scisciano',
'Sclafani Bagni',
'Sclemo',
'Scodovacca',
'Scontrone',
'Scopa',
'Scopello',
'Scoppito',
'Scoppo',
'Scordia',
'Scorrano',
'Scorzè',
'Scrilla',
'Scurcola Marsicana',
'Scurelle',
'Scurzolengo',
'Seborga',
'Sebreglie',
'Secinaro',
'Seclì',
'Secondigliano',
'Secugnago',
'Sedegliano',
'Sedico',
'Sedilo',
'Sedini',
'Sedriano',
'Sedrina',
'Sedula',
'Sefro',
'Segariu',
'Seggiano',
'Seghebbia',
'Segnacco',
'Segni',
'Segno',
'Segonzano',
'Segrate',
'Segusino',
'Seio',
'Selargius',
'Selci',
'Selegas',
'Selino',
'Sella Giudicarie',
'Sellano',
'Sellanuova',
'Sellere',
'Sellero',
'Sellia',
'Sellia Marina',
'Sello',
'Selva dei Molini',
'Selva di Cadore',
'Selva di Progno',
'Selva di Val Gardena',
'Selvazzano Dentro',
'Selve Marcone',
'Selvino',
'Semestene',
'Semiana',
'Seminara',
'Semproniano',
'Senadole',
'Senago',
'Senale-San Felice',
'Senales',
'Seneghe',
'Senerchia',
'Seniga',
'Senigallia',
'Senis',
'Senise',
'Senna Comasco',
'Senna Lodigiana',
'Sennariolo',
'Sennori',
'Senorbì',
'Senosecchia',
'Seo',
'Sepino',
'Seppiana',
'Seprio',
'Sequals',
'Seravezza',
'Serbariu',
'Serdiana',
'Seregno',
'Seren del Grappa',
'Sergnano',
'Seriate',
'Serina',
'Serino',
'Serle',
'Sermide e Felonica',
'Sermoneta',
'Sernaglia della Battaglia',
'Sernio',
'Serole',
'Serpenizza',
'Serra Pedace',
'Serra Riccò',
'Serra San Bruno',
'Serra San Quirico',
'Serra Sant\'Abbondio',
'Serra d\'Aiello',
'Serra de\' Conti',
'Serracapriola',
'Serradifalco',
'Serralunga d\'Alba',
'Serralunga di Crea',
'Serramanna',
'Serramazzoni',
'Serramezzana',
'Serramonacesca',
'Serrapetrona',
'Serrara Fontana',
'Serrastretta',
'Serrata',
'Serravalle',
'Serravalle All\'Adige',
'Serravalle D\'Asti',
'Serravalle Langhe',
'Serravalle Pistoiese',
'Serravalle Scrivia',
'Serravalle Sesia',
'Serravalle a Po',
'Serravalle di Chienti',
'Serre',
'Serrenti',
'Serri',
'Serrone',
'Serrungarina',
'Sersale',
'Serso',
'Servigliano',
'Sesana',
'Sesona',
'Sessa Aurunca',
'Sessa Cilento',
'Sessame',
'Sessano del Molise',
'Sessant',
'Sesta Godano',
'Sestino',
'Sesto',
'Sesto Calende',
'Sesto Campano',
'Sesto Fiorentino',
'Sesto Pergola',
'Sesto San Giovanni',
'Sesto Ulteriano',
'Sesto al Reghena',
'Sesto ed Uniti',
'Sestola',
'Sestri Levante',
'Sestri Ponente',
'Sestriere',
'Sestu',
'Settala',
'Settefrati',
'Settime',
'Settimo Milanese',
'Settimo Rottaro',
'Settimo San Pietro',
'Settimo Torinese',
'Settimo Vittone',
'Settingiano',
'Setzu',
'Seui',
'Seulo',
'Seuni',
'Seveso',
'Sevignano',
'Sezzadio',
'Sezze',
'Sforzatica',
'Sfruz',
'Sgonico',
'Sgurgola',
'Siamaggiore',
'Siamanna',
'Siano',
'Siapiccia',
'Sicci San Biagio',
'Sicignano degli Alburni',
'Siculiana',
'Siddi',
'Siderno',
'Siena',
'Sigillo',
'Signa',
'Silandro',
'Silanus',
'Silea',
'Siligo',
'Siliqua',
'Silius',
'Sillano',
'Sillano Giuncugnano',
'Sillavengo',
'Silvano Pietra',
'Silvano d\'Orba',
'Silvella',
'Silvi',
'Silì',
'Simala',
'Simaxis',
'Simbario',
'Simeri Crichi',
'Sinagra',
'Sinalunga',
'Sindia',
'Sini',
'Sinio',
'Siniscola',
'Sinnai',
'Sinopoli',
'Siracusa',
'Sirico',
'Sirignano',
'Siris',
'Sirmione',
'Sirolo',
'Sirone',
'Siror',
'Sirtori',
'Sisini',
'Sissa',
'Sissa Trecasali',
'Siurgus',
'Siurgus Donigala',
'Siviano',
'Siziano',
'Sizzano',
'Slappe Zorzi',
'Slingia',
'Slivia',
'Sluderno',
'Smarano',
'Smeria',
'Smerillo',
'Soave',
'Soccavo',
'Socchieve',
'Soddì',
'Sogliano Cavour',
'Sogliano al Rubicone',
'Soglio',
'Soiano del Lago',
'Solagna',
'Solanas',
'Solarino',
'Solaro',
'Solarolo',
'Solarolo Del Persico',
'Solarolo Monasterolo',
'Solarolo Rainerio',
'Solarussa',
'Solbiate',
'Solbiate Arno',
'Solbiate Olona',
'Solbiate con Cagno',
'Solbrito',
'Soldano',
'Soleminis',
'Solero',
'Solesino',
'Soleto',
'Solferino',
'Soliera',
'Solignano',
'Solofra',
'Sologno',
'Solomiac',
'Solonghello',
'Solopaca',
'Soltarico',
'Solto',
'Solto Collina',
'Solza',
'Solzago',
'Somaglia',
'Somana',
'Somano',
'Sombreno',
'Somendenna',
'Somma Lombardo',
'Somma Vesuviana',
'Sommacampagna',
'Sommariva Perno',
'Sommariva del Bosco',
'Sommatino',
'Sommo',
'Sona',
'Soncino',
'Sondalo',
'Sondrio',
'Songavazzo',
'Sonico',
'Sonnino',
'Sonzia',
'Sopramonte',
'Soprana',
'Sopraponte',
'Soprazocco',
'Sora',
'Soraga di Fassa',
'Soragna',
'Sorano',
'Sorbano',
'Sorbo San Basile',
'Sorbo Serpico',
'Sorbolo',
'Sorbolo Mezzani',
'Sorbolongo',
'Sordevolo',
'Sordio',
'Soresina',
'Sorgono',
'Sorgà',
'Sori',
'Sorianello',
'Soriano Calabro',
'Soriano nel Cimino',
'Sorico',
'Soriso',
'Sorisole',
'Sorli',
'Sormano',
'Sorradile',
'Sorrento',
'Sorso',
'Sortino',
'Sospiro',
'Sospirolo',
'Sossano',
'Sostegno',
'Sotto il Monte Giovanni XXIII',
'Sovazza',
'Sover',
'Soverato',
'Sovere',
'Soveria Mannelli',
'Soveria Simeri',
'Soverzene',
'Sovicille',
'Sovico',
'Sovizzo',
'Sovramonte',
'Sozzago',
'Spadafora',
'Spadafora San Martino',
'Spadafora San Pietro',
'Spadola',
'Sparanise',
'Sparone',
'Specchia',
'Spello',
'Spera',
'Spercenigo',
'Sperlinga',
'Sperlonga',
'Sperone',
'Spessa',
'Spezzano Albanese',
'Spezzano Grande',
'Spezzano Piccolo',
'Spezzano della Sila',
'Spiazzo',
'Spigno Monferrato',
'Spigno Saturnia',
'Spilamberto',
'Spilimbergo',
'Spilinga',
'Spinadesco',
'Spinazzola',
'Spinea',
'Spineda',
'Spinete',
'Spineto Scrivia',
'Spinetoli',
'Spinga',
'Spino Al Brembo',
'Spino d\'Adda',
'Spinone',
'Spinone al Lago',
'Spinoso',
'Spirago',
'Spirano',
'Spoccia',
'Spoleto',
'Spoltore',
'Spongano',
'Spormaggiore',
'Sporminore',
'Spotorno',
'Spresiano',
'Spriana',
'Squillace',
'Squinzano',
'Stabello',
'Staffolo',
'Staghiglione',
'Staglieno',
'Stagno Lombardo',
'Staiti',
'Stalettì',
'Stanghella',
'Staranzano',
'Statte',
'Stava',
'Stazzano',
'Stazzema',
'Stazzona',
'Stazzona Germasino',
'Stefanaconi',
'Stella',
'Stella Cilento',
'Stellanello',
'Stelvio',
'Stenico',
'Sternatia',
'Stezzano',
'Stia',
'Stienta',
'Stigliano',
'Stignano',
'Stilo',
'Stilo De\' Mariani',
'Stilves',
'Stimigliano',
'Stintino',
'Stio',
'Storie',
'Stornara',
'Stornarella',
'Storo',
'Stra',
'Stracondolo',
'Strada',
'Stradella',
'Strambinello',
'Strambino',
'Strambino Romano',
'Stramentizzo',
'Strangolagalli',
'Stravino',
'Stregna',
'Strembo',
'Stresa',
'Strevi',
'Striano',
'Strigno',
'Strona',
'Stroncone',
'Strongoli',
'Stropino',
'Stroppiana',
'Stroppo',
'Strozza',
'Struppa',
'Stumiaga',
'Sturia Delle Fusine',
'Sturno',
'Suardi',
'Subbiano',
'Subiaco',
'Succivo',
'Sueglio',
'Suelli',
'Suello',
'Suisio',
'Sulbiate',
'Sulbiate Inferiore',
'Sulbiate Superiore',
'Sulmona',
'Sulzano',
'Sumirago',
'Summonte',
'Suna',
'Suni',
'Suno',
'Supersano',
'Supino',
'Surano',
'Surbo',
'Susa',
'Susegana',
'Sustinente',
'Susà',
'Sutera',
'Sutri',
'Sutrio',
'Suvereto',
'Suzzara',
'Tablà',
'Taceno',
'Tadasuni',
'Taggia',
'Tagliacozzo',
'Taglio di Po',
'Tagliolo Belforte',
'Tagliolo Monferrato',
'Tagliuno',
'Taibon Agordino',
'Tainate',
'Taino',
'Taio',
'Taipana',
'Talamello',
'Talamona',
'Talana',
'Taleggio',
'Talla',
'Talmassons',
'Tambre',
'Tanas',
'Taormina',
'Tapigliano',
'Tapogliano',
'Tappia',
'Tarano',
'Taranta Peligna',
'Tarantasca',
'Taranto',
'Tarcento',
'Tarces',
'Tarcetta',
'Tarnova Della Selva',
'Tarquinia',
'Tarres',
'Tarsia',
'Tartano',
'Tarvisio',
'Tarzo',
'Tassarolo',
'Tassullo',
'Taurano',
'Taurasi',
'Taurianova',
'Taurisano',
'Tavagnacco',
'Tavagnasco',
'Tavarnelle Val Di Pesa',
'Tavazzano',
'Tavazzano con Villavesco',
'Tavenna',
'Taverna',
'Tavernerio',
'Tavernette',
'Tavernola Bergamasca',
'Tavernola San Felice',
'Tavernole sul Mella',
'Taviano',
'Tavigliano',
'Tavodo',
'Tavole',
'Tavoleto',
'Tavon',
'Tavordo',
'Tavullia',
'Teana',
'Teano',
'Teggiano',
'Teglio',
'Teglio Veneto',
'Telese Terme',
'Telgate',
'Telti',
'Telve',
'Telve di Sopra',
'Telves',
'Temenizza',
'Tempio Pausania',
'Temù',
'Tenda',
'Tenna',
'Tenno',
'Teodone',
'Teodorano',
'Teolo',
'Teor',
'Teora',
'Teramo',
'Terdobbiate',
'Terelle',
'Terento',
'Terenzo',
'Tergu',
'Terlago',
'Terlano',
'Terlizzi',
'Terme Vigliatore',
'Termenago',
'Termeno sulla strada del vino',
'Termini Imerese',
'Termoli',
'Termon',
'Ternate',
'Ternengo',
'Terni',
'Terno d\'Isola',
'Ternova D\'Isonzo',
'Terracina',
'Terragnolo',
'Terralba',
'Terranova Sappo Minulio',
'Terranova da Sibari',
'Terranova dei Passerini',
'Terranova di Pollino',
'Terranuova Bracciolini',
'Terrarossa',
'Terrasa',
'Terrasini',
'Terrassa Padovana',
'Terrati',
'Terravecchia',
'Terrazzano',
'Terrazzo',
'Terre Roveresche',
'Terre d\'Adige',
'Terre del Reno',
'Terres',
'Terricciola',
'Terruggia',
'Tertenia',
'Terzano',
'Terzigno',
'Terzo',
'Terzo d\'Aquileia',
'Terzolas',
'Terzorio',
'Tesero',
'Tesido',
'Tesimo',
'Tessennano',
'Testaccio D\'Ischia',
'Testico',
'Teti',
'Teulada',
'Teverola',
'Tezze sul Brenta',
'Thiene',
'Thiesi',
'Thures',
'Tiana',
'Tiarno',
'Tiarno Di Sopra',
'Tiarno Di Sotto',
'Ticengo',
'Ticineto',
'Tiggiano',
'Tiglieto',
'Tigliole',
'Tignale',
'Tignerone',
'Timoline',
'Tina',
'Tinnura',
'Tione degli Abruzzi',
'Tione di Trento',
'Tirano',
'Tires',
'Tiriolo',
'Tirolo',
'Tiso',
'Tissi',
'Tito',
'Tivoli',
'Tizzano Val Parma',
'Toano',
'Tocco Caudio',
'Tocco da Casauria',
'Toceno',
'Todi',
'Toffia',
'Toirano',
'Tolentino',
'Tolfa',
'Tollegno',
'Tollo',
'Tolmezzo',
'Tolmino',
'Tolve',
'Tomadio',
'Tombolo',
'Ton',
'Tonadico',
'Tonara',
'Tonco',
'Tonengo',
'Tonezza del Cimone',
'Tora e Piccilli',
'Torano Castello',
'Torano Nuovo',
'Torba',
'Torbiato',
'Torbole Casaglia',
'Torcegno',
'Torchiara',
'Torchiarolo',
'Torella dei Lombardi',
'Torella del Sannio',
'Torgiano',
'Torgnon',
'Torino',
'Torino di Sangro',
'Toritto',
'Torlino Vimercati',
'Tormo',
'Tornaco',
'Tornareccio',
'Tornata',
'Tornavento',
'Tornimparte',
'Torno',
'Tornolo',
'Toro',
'Torpè',
'Torra',
'Torraca',
'Torradello',
'Torralba',
'Torrazza',
'Torrazza Coste',
'Torrazza Piemonte',
'Torrazzo',
'Torre Annunziata',
'Torre Beretti',
'Torre Beretti e Castellaro',
'Torre Boldone',
'Torre Bormida',
'Torre Cajetani',
'Torre Canavese',
'Torre D\'Angiolini',
'Torre De\' Ratti',
'Torre De\' Torti',
'Torre Del Mangano',
'Torre Del Monte',
'Torre Di Palme',
'Torre Le Nocelle',
'Torre Mondovì',
'Torre Orsaia',
'Torre Orsina',
'Torre Pallavicina',
'Torre Pellice',
'Torre San Giorgio',
'Torre San Marco',
'Torre San Patrizio',
'Torre Santa Susanna',
'Torre Uzzone',
'Torre d\'Arese',
'Torre d\'Isola',
'Torre de\' Busi',
'Torre de\' Negri',
'Torre de\' Passeri',
'Torre de\' Picenardi',
'Torre de\' Roveri',
'Torre del Greco',
'Torre di Mosto',
'Torre di Ruggiero',
'Torre di Santa Maria',
'Torreano',
'Torrebelvicino',
'Torrebruna',
'Torrecuso',
'Torreglia',
'Torregrotta',
'Torremaggiore',
'Torrenova',
'Torrenova Di Bisterza',
'Torresina',
'Torretta',
'Torrevecchia Pia',
'Torrevecchia Teatina',
'Torri del Benaco',
'Torri di Quartesolo',
'Torri in Sabina',
'Torria',
'Torriana',
'Torriano',
'Torrice',
'Torricella',
'Torricella Peligna',
'Torricella Sicura',
'Torricella Verzate',
'Torricella del Pizzo',
'Torricella in Sabina',
'Torriglia',
'Torrile',
'Torrino',
'Torrioni',
'Torrita Tiberina',
'Torrita di Siena',
'Tortolì',
'Tortona',
'Tortora',
'Tortorella',
'Tortoreto',
'Tortorici',
'Torviscosa',
'Toscolano',
'Toscolano-Maderno',
'Toss',
'Tossicia',
'Tovo San Giacomo',
'Tovo di Sant\'Agata',
'Trabia',
'Trabuchello',
'Tradate',
'Traffiume',
'Tramatza',
'Trambileno',
'Tramonti',
'Tramonti di Sopra',
'Tramonti di Sotto',
'Tramutola',
'Trana',
'Trani',
'Transacqua',
'Traona',
'Trapani',
'Trappeto',
'Trarego Viggiona',
'Trasacco',
'Trasaghis',
'Trasquera',
'Tratalias',
'Trausella',
'Travacò Siccomario',
'Travagliato',
'Travedona',
'Travedona-Monate',
'Traversa',
'Traverse',
'Traversella',
'Traversetolo',
'Traves',
'Travesio',
'Travo',
'Tre Ville',
'Trebaseleghe',
'Trebbiano Nizza',
'Trebecco',
'Trebiano Magra',
'Trebisacce',
'Trecasali',
'Trecase',
'Trecastagni',
'Trecastelli',
'Trecate',
'Trecchina',
'Trecella',
'Trecenta',
'Tredossi',
'Tredozio',
'Tregasio',
'Treglio',
'Tregnago',
'Tregolo',
'Treia',
'Treiso',
'Tremenico',
'Tremestieri Etneo',
'Tremezzina',
'Tremezzo',
'Tremosine sul Garda',
'Trenno',
'Trens',
'Trenta',
'Trenta D\'Isonzo',
'Trentinara',
'Trento',
'Trentola Ducenta',
'Trenzano',
'Treppo Carnico',
'Treppo Grande',
'Treppo Ligosullo',
'Trepuzzi',
'Trequanda',
'Tres',
'Tresana',
'Treschè Conca',
'Trescore Balneario',
'Trescore Cremasco',
'Tresigallo',
'Tresignana',
'Tresilico',
'Tresivio',
'Tresnuraghes',
'Tretto',
'Trevano',
'Trevenzuolo',
'Trevi',
'Trevi nel Lazio',
'Trevico',
'Treviglio',
'Trevignano',
'Trevignano Romano',
'Treville',
'Treviolo',
'Trevisago',
'Treviso',
'Treviso Bresciano',
'Trezzano Rosa',
'Trezzano sul Naviglio',
'Trezzo Tinella',
'Trezzo sull\'Adda',
'Trezzolasco',
'Trezzone',
'Tribano',
'Tribiano',
'Tribogna',
'Triburgo',
'Tribussa',
'Tricarico',
'Tricase',
'Tricerro',
'Tricesimo',
'Trichiana',
'Triei',
'Trieste',
'Triggiano',
'Trigolo',
'Trinitapoli',
'Trinità',
'Trinità d\'Agultu e Vignola',
'Trino',
'Triora',
'Tripi',
'Trisobbio',
'Trissino',
'Triuggio',
'Trivento',
'Trivero',
'Trivigliano',
'Trivignano Udinese',
'Trivigno',
'Trivolzio',
'Trivulza',
'Trivulzina',
'Trobaso',
'Trodena nel parco naturale',
'Trofarello',
'Trognano',
'Troia',
'Troina',
'Tromello',
'Trontano',
'Tronzano Lago Maggiore',
'Tronzano Vercellese',
'Tropea',
'Trovo',
'Truccazzano',
'Tubre',
'Tuenetto',
'Tuenno',
'Tufara',
'Tufillo',
'Tufino',
'Tufo',
'Tuglie',
'Tuili',
'Tula',
'Tunes',
'Tuoro sul Trasimeno',
'Turago Bordone',
'Turania',
'Turano Lodigiano',
'Turate',
'Turbigo',
'Turi',
'Turri',
'Turriaco',
'Turrivalignani',
'Turro Milanese',
'Tursi',
'Tusa',
'Tuscania',
'Ubaga',
'Ubiale Clanezzo',
'Uboldo',
'Ucria',
'Udine',
'Ugento',
'Uggiano la Chiesa',
'Uggiate',
'Uggiate-Trevano',
'Ugovizza Valbruna',
'Ulassai',
'Ultimo',
'Ulà Tirso',
'Umago',
'Umbertide',
'Umbriatico',
'Unchio',
'Urago Mella',
'Urago d\'Oglio',
'Uras',
'Urbana',
'Urbania',
'Urbe',
'Urbino',
'Urbisaglia',
'Urgnano',
'Uri',
'Urio',
'Ururi',
'Urzulei',
'Uscio',
'Usellus',
'Usini',
'Usmate',
'Usmate Velate',
'Ussana',
'Ussaramanna',
'Ussassai',
'Usseaux',
'Usseglio',
'Ussita',
'Ussolo',
'Ustica',
'Ustie',
'Uta',
'Uzzano',
'Vaccarizza',
'Vaccarizzo Albanese',
'Vacone',
'Vacri',
'Vadena',
'Vado Ligure',
'Vagli Sotto',
'Vaglia',
'Vaglierano',
'Vaglio Basilicata',
'Vaglio Serra',
'Vagna',
'Vaiano',
'Vaiano Cremasco',
'Vaiano Valle',
'Vaie',
'Vailate',
'Vairano Cremasco',
'Vairano Patenora',
'Vairano Pavese',
'Vajont',
'Val Brembilla',
'Val Liona',
'Val Masino',
'Val Rezzo',
'Val della Torre',
'Val di Chy',
'Val di Nizza',
'Val di Vizze',
'Val di Zoldo',
'Valas',
'Valbondione',
'Valbrembo',
'Valbrenta',
'Valbrevenna',
'Valbrona',
'Valchiusa',
'Valchiusella',
'Valda',
'Valdagno',
'Valdaone',
'Valdaora',
'Valdarsa',
'Valdastico',
'Valdengo',
'Valderice',
'Valdidentro',
'Valdieri',
'Valdigna D\'Aosta',
'Valdilana',
'Valdina',
'Valdisotto',
'Valdobbiadene',
'Valduggia',
'Valeggio',
'Valeggio sul Mincio',
'Valentano',
'Valenza',
'Valenzano',
'Valera Fratta',
'Valfabbrica',
'Valfenera',
'Valfloriana',
'Valfornace',
'Valfurva',
'Valganna',
'Valgioie',
'Valgiovo',
'Valgoglio',
'Valgrana',
'Valgreghentino',
'Valgrisenche',
'Valguarnera Caropepe',
'Vall\'Alta',
'Vallada Agordina',
'Vallanzengo',
'Vallarga',
'Vallarsa',
'Vallata',
'Valle Agricola',
'Valle Aurina',
'Valle Cannobina',
'Valle Castellana',
'Valle D\'Istria',
'Valle Guidino',
'Valle Lomellina',
'Valle Mosso',
'Valle Salimbene',
'Valle San Felice',
'Valle San Giovanni',
'Valle San Nicolao',
'Valle San Silvestro',
'Valle Superiore Mosso',
'Valle dell\'Angelo',
'Valle di Cadore',
'Valle di Casies',
'Valle di Maddaloni',
'Vallebona',
'Vallecorsa',
'Vallecrosia',
'Vallecrosia Con San Biagio Della Cima',
'Valledolmo',
'Valledoria',
'Vallefiorita',
'Vallefoglia',
'Vallelaghi',
'Vallelonga',
'Vallelunga',
'Vallelunga Pratameno',
'Vallemaio',
'Vallenoncello',
'Vallepietra',
'Vallerano',
'Vallermosa',
'Vallerotonda',
'Valles',
'Vallesaccarda',
'Valleve',
'Valli del Pasubio',
'Vallinfreda',
'Vallio Terme',
'Vallo Torinese',
'Vallo della Lucania',
'Vallo di Nera',
'Vallonara',
'Valloria Marittima',
'Valloriate',
'Valmacca',
'Valmadrera',
'Valmaggia',
'Valmala',
'Valmontone',
'Valmorea',
'Valmozzola',
'Valnegra',
'Valpelline',
'Valperga',
'Valprato',
'Valprato Soana',
'Valrovina',
'Valsamoggia',
'Valsantamarina',
'Valsavarenche',
'Valsaviore',
'Valsecca',
'Valsinni',
'Valsolda',
'Valstagna',
'Valstrona',
'Valtesse',
'Valtopina',
'Valtorta',
'Valtournenche',
'Valva',
'Valvarrone',
'Valvasone',
'Valvasone Arzene',
'Valverde',
'Valvestino',
'Vandoies',
'Vandoies Di Sopra',
'Vandoies Di Sotto',
'Vanga',
'Vanzaghello',
'Vanzago',
'Vanzone con San Carlo',
'Vaprio d\'Adda',
'Vaprio d\'Agogna',
'Varallo',
'Varallo Pombia',
'Varano',
'Varano Borghi',
'Varano de\' Melegari',
'Varapodio',
'Vararo',
'Varazze',
'Varco Sabino',
'Varedo',
'Varena',
'Varengo',
'Varenna',
'Varese',
'Varese Ligure',
'Vargo',
'Varigotti',
'Varisella',
'Varmo',
'Varna',
'Varoni',
'Varsi',
'Varzi',
'Varzo',
'Vas',
'Vasanello',
'Vasia',
'Vasio',
'Vassena',
'Vasto',
'Vastogirardi',
'Vattaro',
'Vauda Canavese',
'Vazia',
'Vazzano',
'Vazzola',
'Veccana',
'Vecchiano',
'Vedano Olona',
'Vedano al Lambro',
'Veddasca',
'Vedelago',
'Vedeseta',
'Veduggio',
'Veduggio con Colzano',
'Veggiano',
'Veglie',
'Veglio',
'Vejano',
'Velate',
'Veleso',
'Velezzo Lomellina',
'Vellano',
'Vellego',
'Velletri',
'Vellezzo Bellini',
'Vello',
'Velo Veronese',
'Velo d\'Astico',
'Velturno',
'Venafro',
'Venaria Reale',
'Venarotta',
'Venasca',
'Venaus',
'Vendone',
'Vendrogno',
'Venegono',
'Venegono Inferiore',
'Venegono Superiore',
'Venetico',
'Venezia',
'Veniano',
'Venosa',
'Ventasso',
'Venticano',
'Ventimiglia',
'Ventimiglia di Sicilia',
'Ventotene',
'Venzone',
'Verano',
'Verano Brianza',
'Verbania',
'Verbicaro',
'Vercana',
'Verceia',
'Vercelli',
'Vercurago',
'Verdellino',
'Verdello',
'Verderio',
'Verderio Inferiore',
'Verderio Superiore',
'Verdesina',
'Verduno',
'Verezzi',
'Vergano Novarese',
'Vergato',
'Vergemoli',
'Verghereto',
'Vergiate',
'Vergo',
'Vergobbio',
'Vermezzo',
'Vermezzo con Zelo',
'Vermiglio',
'Verna',
'Vernante',
'Vernasca',
'Vernate',
'Vernazza',
'Vernio',
'Vernole',
'Verolanuova',
'Verolavecchia',
'Verolengo',
'Veroli',
'Verona',
'Veronella',
'Verpogliano',
'Verrayes',
'Verretto',
'Verrone',
'Verrua Po',
'Verrua Savoia',
'Verrua Siccomario',
'Verrès',
'Versa',
'Versciaco',
'Vertemate',
'Vertemate con Minoprio',
'Verteneglio',
'Vertoiba In Campi Santi',
'Vertova',
'Vertovino',
'Verucchio',
'Veruno',
'Vervio',
'Vervo\'',
'Verzegnis',
'Verzi Pietra',
'Verzino',
'Verzuolo',
'Vescovana',
'Vescovato',
'Vesime',
'Vespolate',
'Vessalico',
'Vestenanova',
'Vestignè',
'Vestone',
'Vestreno',
'Vetralla',
'Vetto',
'Vezza d\'Alba',
'Vezza d\'Oglio',
'Vezzano',
'Vezzano Ligure',
'Vezzano sul Crostolo',
'Vezzi',
'Vezzi Portio',
'Vezzo',
'Vho',
'Viadana',
'Viadanica',
'Viagrande',
'Viale',
'Vialfrè',
'Viano',
'Viarago',
'Viareggio',
'Viarigi',
'Vibo Valentia',
'Vibonati',
'Vicalvi',
'Vicari',
'Vicchio',
'Viceno',
'Vicenza',
'Vico Canavese',
'Vico Equense',
'Vico del Gargano',
'Vico nel Lazio',
'Vicoforte',
'Vicoli',
'Vicolungo',
'Viconago',
'Vicopisano',
'Vicovaro',
'Vidardo',
'Viddalba',
'Vidiceto',
'Vidigulfo',
'Vidolasco',
'Vidor',
'Vidracco',
'Vieste',
'Vietri di Potenza',
'Vietri sul Mare',
'Vigadore',
'Vigalfo',
'Vigalzano',
'Viganella',
'Vigano San Martino',
'Viganò',
'Viganò Certosino',
'Vigarano Mainarda',
'Vigasio',
'Vigatto',
'Vigentino',
'Vigevano',
'Viggianello',
'Viggiano',
'Viggiona',
'Viggiù',
'Vighizzolo',
'Vighizzolo d\'Este',
'Vigliano Biellese',
'Vigliano d\'Asti',
'Vignale Monferrato',
'Vignanello',
'Vignate',
'Vignola',
'Vignola-Falesina',
'Vignole Borbera',
'Vignolo',
'Vignone',
'Vigo Cavèdine',
'Vigo D\'Anaunia',
'Vigo Di Fassa',
'Vigo Lomaso',
'Vigo Rendena',
'Vigo di Cadore',
'Vigodarzere',
'Vigolo',
'Vigolo Baselga',
'Vigolo Vattaro',
'Vigolzone',
'Vigone',
'Vigonovo',
'Vigonza',
'Vigonzino',
'Vigonzone',
'Viguzzolo',
'Vill\'Albese',
'Villa Agnedo',
'Villa Banale',
'Villa Bartolomea',
'Villa Basilica',
'Villa Biscossi',
'Villa Carcina',
'Villa Castelli',
'Villa Castelnuovo',
'Villa Celiera',
'Villa Cogozzo',
'Villa Collemandina',
'Villa Corsione',
'Villa Cortese',
'Villa D\'Adige',
'Villa D\'Allegno',
'Villa Decani',
'Villa Del Nevoso',
'Villa Dosia',
'Villa Estense',
'Villa Faraldi',
'Villa Guardia',
'Villa Lagarina',
'Villa Latina',
'Villa Literno',
'Villa Minozzo',
'Villa Ottone',
'Villa Pizzone',
'Villa Poma',
'Villa Pompeiana',
'Villa Raverio',
'Villa Rendena',
'Villa Rocca',
'Villa Romanò',
'Villa San Giovanni',
'Villa San Giovanni in Tuscia',
'Villa San Giuseppe',
'Villa San Pietro',
'Villa San Secondo',
'Villa Sant\'Angelo',
'Villa Sant\'Antonio',
'Villa Santa Caterina',
'Villa Santa Lucia',
'Villa Santa Lucia degli Abruzzi',
'Villa Santa Maria',
'Villa Santina',
'Villa Santo Stefano',
'Villa Slavina',
'Villa Verde',
'Villa Vergano',
'Villa Vicentina',
'Villa Volturno',
'Villa d\'Adda',
'Villa d\'Almè',
'Villa d\'Ogna',
'Villa del Bosco',
'Villa del Conte',
'Villa di Briano',
'Villa di Chiavenna',
'Villa di Serio',
'Villa di Tirano',
'Villabassa',
'Villabassa Di Senosecchia',
'Villabate',
'Villabella',
'Villachiara',
'Villacidro',
'Villadeati',
'Villadose',
'Villadossola',
'Villafalletto',
'Villafranca Padovana',
'Villafranca Piemonte',
'Villafranca Sicula',
'Villafranca Tirrena',
'Villafranca d\'Asti',
'Villafranca di Verona',
'Villafranca in Lunigiana',
'Villafrati',
'Villaga',
'Villagrande Strisaili',
'Villagreca',
'Villaguardia',
'Villalago',
'Villalba',
'Villalfonsina',
'Villalunga',
'Villalvernia',
'Villamagna',
'Villamaina',
'Villamar',
'Villamarzana',
'Villamassargia',
'Villamiroglio',
'Villamontagna',
'Villandro',
'Villanova Biellese',
'Villanova Canavese',
'Villanova Marchesana',
'Villanova Mondovì',
'Villanova Monferrato',
'Villanova Monteleone',
'Villanova Solaro',
'Villanova Truschedu',
'Villanova Tulo',
'Villanova Vimercate',
'Villanova d\'Albenga',
'Villanova d\'Ardenghi',
'Villanova d\'Asti',
'Villanova del Battista',
'Villanova del Ghebbo',
'Villanova del Sillaro',
'Villanova di Camposampiero',
'Villanova sull\'Arda',
'Villanovaforru',
'Villanovafranca',
'Villanovetta',
'Villanterio',
'Villanuova sul Clisi',
'Villaperuccio',
'Villapiana',
'Villaputzu',
'Villar Dora',
'Villar Focchiardo',
'Villar Pellice',
'Villar Perosa',
'Villar San Costanzo',
'Villarbasse',
'Villarboit',
'Villareggia',
'Villareggio',
'Villaricca',
'Villaromagnano',
'Villarosa',
'Villarossa',
'Villasalto',
'Villasanta',
'Villasimius',
'Villasor',
'Villaspeciosa',
'Villastellone',
'Villata',
'Villatalla',
'Villaurbana',
'Villavallelonga',
'Villaverla',
'Villaviani',
'Villazzano',
'Ville Del Monte',
'Ville Montevecchio',
'Ville San Pietro',
'Ville San Sebastiano',
'Ville d\'Anaunia',
'Ville di Fiemme',
'Villeneuve',
'Villesse',
'Villetta Barrea',
'Villette',
'Villimpenta',
'Villongo',
'Villongo San Filastro',
'Villongo Sant\'Alessandro',
'Villorba',
'Vilminore',
'Vilminore di Scalve',
'Vimanone',
'Vimercate',
'Vimodrone',
'Vimogno',
'Vinadio',
'Vinago',
'Vinchiaturo',
'Vinchio',
'Vinci',
'Vinovo',
'Vintebbio',
'Vinzaglio',
'Viola',
'Vion',
'Vione',
'Vipacco',
'Vipiteno',
'Virgilio',
'Virle Piemonte',
'Virle Treponti',
'Visano',
'Vische',
'Visciano',
'Visco',
'Visignano D\'Istria',
'Visinada',
'Visone',
'Visso',
'Vistarino',
'Vistrorio',
'Vita',
'Viterbo',
'Viticuso',
'Viticuso Ed Acquafondata',
'Vito d\'Asio',
'Vitorchiano',
'Vittadone',
'Vittoria',
'Vittorio Veneto',
'Vittorito',
'Vittuone',
'Vitulano',
'Vitulazio',
'Vivaro',
'Vivaro Romano',
'Vivente',
'Viverone',
'Vizze',
'Vizzini',
'Vizzola Ticino',
'Vizzolo Predabissi',
'Viù',
'Vo\'',
'Vobarno',
'Vobbia',
'Vocca',
'Vocogno E Prestinone',
'Vodo Cadore',
'Voghera',
'Voghiera',
'Vogogna',
'Voissizza Di Comeno',
'Volano',
'Voldomino',
'Volla',
'Volongo',
'Volosca-Abbazia',
'Volpago del Montello',
'Volpara',
'Volpedo',
'Volpeglino',
'Volpiano',
'Volta Mantovana',
'Voltaggio',
'Voltago Agordino',
'Volterra',
'Voltido',
'Voltore',
'Voltri',
'Volturara Appula',
'Volturara Irpina',
'Volturino',
'Volvera',
'Volzana',
'Voschia',
'Vottignasco',
'Xitta',
'Zaccanopoli',
'Zafferana Etnea',
'Zagarise',
'Zagarolo',
'Zambana',
'Zambrone',
'Zandobbio',
'Zanengo',
'Zanica',
'Zanè',
'Zappello',
'Zapponeta',
'Zara',
'Zavattarello',
'Zavattarello Valverde',
'Zeccone',
'Zeddiani',
'Zelarino',
'Zelata',
'Zelbio',
'Zelo Buon Persico',
'Zelo Foramagno',
'Zelo Surrigone',
'Zeme',
'Zenevredo',
'Zenson di Piave',
'Zeppara',
'Zerba',
'Zerbo',
'Zerbolò',
'Zerfaliu',
'Zeri',
'Zermeghedo',
'Zero Branco',
'Zevio',
'Ziano Piacentino',
'Ziano di Fiemme',
'Zibello',
'Zibido Al Lambro',
'Zibido San Giacomo',
'Zignago',
'Zimella',
'Zimone',
'Zinasco',
'Zivido',
'Zoagli',
'Zocca',
'Zogno',
'Zola Predosa',
'Zoldo Alto',
'Zolla',
'Zollino',
'Zone',
'Zoppola',
'Zoppè di Cadore',
'Zorlesco',
'Zornasco',
'Zorzino',
'Zovencedo',
'Zoverallo',
'Zubiena',
'Zuccarello',
'Zuclo',
'Zugliano',
'Zuglio',
'Zumaglia',
'Zumpano',
'Zungoli',
'Zungri',
'Zuri'
];
