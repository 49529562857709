import { Component, OnInit } from '@angular/core';
import { FreeTrialService } from '../services/free-trial.service';
import { AutoLogoutService } from '../../../services/autologout.service';

@Component({
    selector: 'cp-free-trial-recap',
    template: `
        <cp-waiting *ngIf="loading"></cp-waiting>
        <cp-purchase-recap
            *ngIf="!loading"
            [isError]="isError"
            [isFreeTrial]="true"
            [showHomeLink]="true"
        ></cp-purchase-recap>
    `,
    styles: [``],
})
export class FreeTrialRecapComponent implements OnInit {
    isError: boolean = false;
    loading: boolean = true;

    constructor(
        public freeTrialService: FreeTrialService,
        private autoLogoutService: AutoLogoutService
    ) {}

    ngOnInit(): void {
        this.autoLogoutService.logoutLater();

        this.freeTrialService.getUserTrial().subscribe(
            res => {
                this.loading = false;
            },
            err => {
                console.error(err);
                this.loading = false;
                this.isError = true;
            }
        );
    }
}
