import { Component } from '@angular/core';
import { NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserModel} from '../../../../shared/models/user.model';
import {AccessService} from '../../services/access.service';
import {environment} from '../../../../../environments/environment';
import {CorporateStorageService} from '../../../../core/api/services/corporate-storage.service';

@Component({
  selector: 'cp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public errors = false;
  public loading = false;

  constructor(private accessService: AccessService, private activatedRoute: ActivatedRoute, private router: Router,  private corporateStorageService: CorporateStorageService) {

  }

  performLogin(form: NgForm) {
    if (form.valid) {
      this.loading = true;

      this.accessService.login(form.value)
        .subscribe(
          user => this.manageSuccess(user),
          error => {
            form.controls.password.reset();
            this.errors = true;
            form.form.markAsPristine()
            form.form.markAsUntouched()
          }
        ).add(() => {
          this.loading = false;
        });
    }
  }

  private manageSuccess(user: UserModel) {
    const params = this.activatedRoute.snapshot.paramMap;

    if (params.has('returnUrl')) {
      this.router.navigateByUrl(params.get('returnUrl'));
    } else if (user.subscription || user.last_subscription) {
      this.router.navigate(['/home']);
    } else if (this.corporateStorageService.getRegistrationType()) {
      this.router.navigate([this.corporateStorageService.getRegistrationType()]);
    } else {
      this.router.navigate([environment.default_roots.logged]);
    }
  }
}
