import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {UserModel} from '../../../shared/models/user.model';
import {Observable} from 'rxjs';
import {UserService} from '../../../shared/services/user.service';
import {AuthorizationService} from '../../../core/api/services/authorization.service';
import {CorporateStorageService} from '../../../core/api/services/corporate-storage.service';

export interface LoginInterface {
  email: string;
  password: string;
}

export interface RegisterInterface {
  email: string;
  name: string;
  surname: string;
  password: string;
  password_confirmation: string;
  privacy_consent: boolean;
  marketing_opt: boolean;
  profiling_opt: boolean;
  query?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  canRegister = false;
  isMailFree = true;

  constructor(private authorizationService: AuthorizationService, private http: HttpClient, private userService: UserService,
              private corporateStorageService: CorporateStorageService) { }

  login(data: LoginInterface): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.apiUrl}/auth/login`, data , {observe: 'response'})
      .pipe(map(res => {
          this.authorizationService.setToken(res.headers);
          const user = res.body as UserModel;
          this.userService.setUser(user);
          return user;
        }
      ));
  }

  register(register: RegisterInterface): Observable<any> {
    register.query = this.corporateStorageService.toQueryString();
    return this.http.post<UserModel>(`${environment.apiUrl}/auth/register`, register, {observe: 'response'});
  }

  verify(id: string, token: string, type: string): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.apiUrl}/auth/verify`, {id, token}, {observe: 'response'})
      .pipe(map(res => {
        if (type === 'registration') {
          this.authorizationService.setToken(res.headers);
          const user = res.body as UserModel;
          this.userService.setUser(user);
          return user;
        } else {
          const user = res.body as UserModel;
          this.userService.setUser(user);
          return user;
        }
      })
    );
  }

  checkEmail(email: string) {
    return this.http
      .post(`${environment.apiUrl}/auth/email`, { email })
      .subscribe(
        (response: any) => {
          this.isMailFree = !response.email_exist;
          this.canRegister = this.isMailFree;
          return response;
        },
        error => {
          this.canRegister = false;
          return error;
        }
      );
  }

  recoverPassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/password/request`, {
      email,
      url: environment.reset_password
    });
  }

  checkEmailAndToken(email: string, token: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/password/verification`, {
      email,
      token
    });
  }

  resetPassword(email: string, token: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/password/reset`, {
      email,
      token,
      password
    });
  }

  resetRegistration() {
    this.canRegister = false;
    this.isMailFree = true;
  }
}
