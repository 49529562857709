import { Injectable } from '@angular/core';
import { InvoiceModel } from '../models/invoices.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class PdfService {
    constructor(private readonly http: HttpClient) {}

    GenerateInvoicePdf(id: string, invoice: InvoiceModel = null): Observable<HttpResponse<Blob>> {
        let req = id ? { id } : { invoice };
        return this.http.post(`${environment.apiUrl}/pdf/generate-invoice`, req, {
            headers: new HttpHeaders().set('Accept', 'application/pdf'),
            observe: 'response',
            responseType: 'blob',
        });
    }

    DownloadInvoicePdf(id: string, invoice: InvoiceModel = null) {
        return this.GenerateInvoicePdf(id, invoice).pipe(
            map(
                (res: HttpResponse<Blob>) => {
                    console.log(res);
                    saveAs(res.body, invoice.internal_number + '.pdf');
                    return;
                },
                //NOTE: Allo stato attuale di angular anche gli errori di richieste come questa sono dei blob.
                //      In questo particolare caso non c'è bisogno di una risoluzione d'errore più specifica, 
                //      quindi non mi pongo il problema di estrapolare il json della risposta dal blob.
                err => err
            )
        );
    }
}
