<section class="mt-3 mt-lg-10 mb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1 col-md-10 form--color-textborder form--secondary line-form offset-md-1">
        <h1 class="text-secondary text-center">PRENOTA LA TUA GIORNATA DI PROVA</h1>
        <form
          #f="ngForm"
          (ngSubmit)="!submitted ? (f.valid && !dateErrors ? createOrUpdateTrial(f) : f.form.markAllAsTouched()) : null"
          class="mt-5"
        >
          <div class="container">
            <div class="row">
              <!-- Gym -->
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="GymInput">
                    Palestra*
                    <span
                      class="spinner-grow spinner-text ml-1"
                      role="status"
                      aria-hidden="true"
                      *ngIf="!gymService.gyms"
                    ></span>
                  </label>
                  <select
                    id="GymInput"
                    [ngModel]="gymService.gyms? freeTrial? gymService.getSelectableGym(freeTrial?.gym?.id) : gymService.defaultGym : null"
                    [required]="true"
                    #gym="ngModel"
                    name="gym"
                    [disabled]="!gymService.gyms"
                    (ngModelChange)="onGymChanged($event)"
                    class="custom-select"
                    [ngClass]="{ 'is-invalid': gym.invalid && gym.touched }"
                  >
                    <option hidden [value]="null" disabled selected>Preferenza Palestra*</option>
                    <option *ngFor="let gym of gymService.gyms" [ngValue]="gym">{{ gym.name }}</option>
                  </select>
                  <small class="invalid-tooltip" *ngIf="gym.invalid && gym.touched">
                    È necessario selezionare una palestra
                  </small>
                </div>
              </div>

              <!-- Type -->
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="FitnessTypeInput">Tipologia di<br />allenamento*</label>
                  <select
                    id="FitnessTypeInput"
                    [ngModel]="freeTrial?.fitness_type"
                    [required]="true"
                    #fitness_type="ngModel"
                    name="fitness_type"
                    class="custom-select"
                    [ngClass]="{ 'is-invalid': fitness_type.invalid && fitness_type.touched }"
                  >
                    <option hidden [value]="null" disabled selected>-seleziona-</option>
                    <option value="gym">Palestra</option>
                    <option value="digital_training">Digital Training</option>
                  </select>
                  <small class="invalid-tooltip" *ngIf="fitness_type.invalid && fitness_type.touched">
                    È necessario selezionare una tipologia
                  </small>
                </div>
              </div>

              <!-- Date -->
              <div class="col-md-6">
                <div class="form-group">
                  <cp-date-picker
                    [id]="'DateInput'"
                    [label]="'Data di<br>prova*'"
                    (errors)="dateErrors = $event"
                    name="date"
                    #date="ngModel"
                    [ngModel]="defaultDate"
                    [required]="true"
                    [minDate]="purchaseTrialService.getTrialMinDate()"
                    [maxDate]="purchaseTrialService.getTrialMaxDate()"
                    [markDisabled]="checkDisabledDate.bind(this)"
                    [disabled]="submitted"
                    (ngModelChange)="onDateChange($event)"
                    [isInvalid]="dateErrors && date.touched"
                  ></cp-date-picker>
                  <small class="invalid-tooltip" *ngIf="dateErrors?.required && date.touched">
                    È necessario selezionare una data
                  </small>
                  <small class="invalid-tooltip" *ngIf="dateErrors && !dateErrors?.required && date.touched">
                    Data non valida
                  </small>
                </div>
              </div>

              <!-- Time -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="TimeInput">
                    Orario*
                    <span
                      class="spinner-grow spinner-text ml-1"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loadingTimes"
                    ></span>
                  </label>
                  <select
                    [(ngModel)]="freeTrialTime"
                    [required]="true"
                    #time="ngModel"
                    name="time"
                    [disabled]="purchaseTrialService.times.length === 0"
                    class="custom-select"
                    [ngClass]="{ 'is-invalid': time.invalid && time.touched }"
                  >
                    <option hidden [value]="null" disabled selected>-seleziona-</option>
                    <option *ngFor="let time of purchaseTrialService.times" [value]="time">{{ time }}</option>
                  </select>
                  <small class="invalid-tooltip" *ngIf="time.touched && purchaseTrialService.times.length == 0">
                    È necessario selezionare una palestra ed una data
                  </small>
                  <small
                    class="invalid-tooltip"
                    *ngIf="time.invalid && purchaseTrialService.times.length > 0 && time.touched"
                  >
                    È necessario selezionare un orario
                  </small>
                </div>
              </div>

              <!-- Cel -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="CelInput">Cellulare</label>

                  <input
                    id="CelInput"
                    #cel="ngModel"
                    type="text"
                    name="cel"
                    [ngModel]="freeTrial?.user?.cel"
                    class="form-control"
                    pattern="^((\+|(00))[0-9]{1,3})?\s*[0-9]{2,4}\s*[0-9]{2,3}\s*[0-9]{2,3}\s*[0-9]{2,3}$"
                    (ngModelChange)="onCelChange($event)"
                    [ngClass]="{ 'is-invalid': cel.invalid && cel.touched }"
                  />
                  <small class="invalid-tooltip" *ngIf="cel.errors?.pattern && cel.touched">
                    Il formato non è riconosciuto
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mt-3 mx-auto d-flex">
                <button type="button" class="btn btn-outline-primary btn-block mr-2" (click)="done.emit('restart')" *ngIf="freeTrial">
                  Annulla
                </button>
                <button type="submit" class="btn btn-outline-secondary-text-primary btn-block mt-0">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submitted"
                  ></span>
                  Continua
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
