import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../../../../../shared/services/user.service';
import * as moment from 'moment';
import { UnsolvedObject, InvoiceModel } from '../../../../../../shared/models/invoices.model';
import { UserModel } from '../../../../../../shared/models/user.model';
import {environment} from '../../../../../../../environments/environment';
import { PaymentCard } from '../../../../../../shared/models/payments.model';
import { PaymentDialog } from '../../../../../../shared/features/payment-dialog/payment-dialog.component';
import { UnexpectedErrorDialogComponent } from '../../../../../../shared/features/unexpected-error-dialog/unexpected-error-dialog.component';

@Component({
  selector: 'cp-client-unsolveds-view',
  templateUrl: './unsolveds-view.component.html',
  styleUrls: [],
})
export class UnsolvedsViewComponent implements OnInit {

  public expanded: boolean = false;
  public intentClientSecret: string = null;

  // Control of actions
  public modalShown = false;

  @Input()
  public user: UserModel;
  public unsolveds: UnsolvedObject[];
  public openInvoices: InvoiceModel[];
  private paymentCards: PaymentCard[];
  @Output() reload: EventEmitter<void> = new EventEmitter()

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.userService.subscribeUnsolveds()
      .subscribe(unsolveds => {
        this.unsolveds = unsolveds;
      });
    this.userService.subscribeOpenInvoices()
      .subscribe(invoices => {
        this.openInvoices = invoices;
      });
    this.userService.subscribePaymentCards()
      .subscribe(cards => {
        this.paymentCards = cards;
      });
  }

  toggleExpandIfNotSelected() {
    const selection = window.getSelection();
    if ((selection.type !== undefined && selection.type != 'Range') || (selection.type === undefined && !selection.toString())) {
      this.toggleExpand();
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  payOutstandingBalance() {
    if (this.modalShown) return;
    this.modalShown = true;

    this._prepareOutstanding().subscribe(
      res => {
        if (this.user.outstanding_balance !== res.amount) {
          this._showError('Si è verificato un errore.<br />Ricarica la pagina e riprova.');
          return;
        }
        this.intentClientSecret = res.client_secret;
        this.modalShown = true;
        let ref = this.modalService.open(PaymentDialog, { centered: true });
        let refInnerComponent = ref.componentInstance as PaymentDialog;
        refInnerComponent.onSubmit.subscribe(() => {
          this._registerPaymentIntentConfirmation(
            res.included_unsolveds, res.included_open_invoices
          ).subscribe(_ => {
            ref.close();
          }, _ => {
            const errorMessage = `La richiesta di pagamento è stata presa in carico, tuttavia potrebbe essere necessario qualche altro minuto.<br />
              Nel frattempo, per via di un imprevisto riscontrato durante il tuo processo di pagamento, <b><u>ti preghiamo di non ritentare.</u></b><br/>
              Se il tuo abbonamento non dovesse riattivarsi nel giro di un giorno, ti invitiamo a contattare il servizio clienti.<br/>
              Ci scusiamo per il disagio.`;
            ref.dismiss();
            this._showError(errorMessage);
          });
        },
          (ex: string) => {
            ref.dismiss();
            this._showError(ex);
        });

        ref.result.then(() => {
          setTimeout(() => {
            this.reload.emit();
          }, 1500);
        }).catch(err => {
          this.reload.emit();
          console.log('Rejected!');
        }).finally(() => {
          this.modalShown = false;
        });
        refInnerComponent.user = this.user;
        refInnerComponent.paymentCards = this.paymentCards;
        refInnerComponent.title = "PAGA SALDO IN SOSPESO";
        refInnerComponent.label = "Saldo in sospeso:";
        refInnerComponent.total = this.user.outstanding_balance;
        refInnerComponent.intentClientSecret = this.intentClientSecret;

      },
      _ => {
        this._showError('Non è stato possibile procedere con il pagamento, riprova più tardi.');
      });
  }

  _prepareOutstanding() {
    return this.http.post<any>(`${environment.apiUrl}/unsolveds/prepare-payment`, {})
  }

  _registerPaymentIntentConfirmation(unsolveds: string[], invoices: string[]) {
    return this.http.post<any>(`${environment.apiUrl}/unsolveds/register-intent-confirmation`, {
      unsolveds, invoices
    })
  }

  _formatDateString(value: string|Date, fmt: string = 'DD/MM/YYYY', utc = false) {
    const date = utc ? moment.utc(value) : moment(value);
    return utc ? date.local().format(fmt) : date.format(fmt);
  }

  _showError(errorMessage: string) {
    this.modalShown = true;
    let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, { centered: true });
    ueRef.result.finally(() => {
      this.modalShown = false;
    });
    (ueRef.componentInstance as UnexpectedErrorDialogComponent).error = errorMessage;
  }
}
