import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ConfigurationModel} from '../models/configuration.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class ConfigurationService {

  private dataSubjectConfiguration: BehaviorSubject<ConfigurationModel>;

  constructor(private http: HttpClient) { }

  private setConfiguration(configuration: ConfigurationModel): void {
    if (!this.dataSubjectConfiguration) {
      this.dataSubjectConfiguration = new BehaviorSubject<ConfigurationModel>(configuration);
    } else {
      this.dataSubjectConfiguration.next(configuration);
    }
  }

  subscribeConfiguration(): Observable<ConfigurationModel> {
    if (!this.dataSubjectConfiguration) {
      return this.http.post<ConfigurationModel>(`${environment.apiUrl}/configuration`, { })
        .pipe(tap(res => {
            this.setConfiguration(res);
            return res;
          },
          err => err
        ));
    } else {
      return this.dataSubjectConfiguration.asObservable();
    }
  }
}
