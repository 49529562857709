import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccessService } from "../../services/access.service";
import { CorporateStorageService } from "../../../../core/api/services/corporate-storage.service";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'cp-verify-action',
  templateUrl: './verify-action.component.html',
  styleUrls: [],
})
export class VerifyActionComponent implements OnInit {
  public verifying = true;
  public invalid = false;
  public invalidEmailChange = false;
  public error = false;

  constructor(
    private accessService: AccessService,
    private corporateStorageService: CorporateStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const params = this.route.snapshot.paramMap;
    const id = params.get("id");
    const token = params.get("token");
    const type = params.get("type");
    this.accessService.verify(id, token, type).subscribe(
      () => {
        const target = type === 'registration'
          ? (this.corporateStorageService.getRegistrationType() ||
            environment.default_roots.logged)
          : (type === 'email-change' ? 'email-changed' : 'email-verified');
        this.router.navigate([target]);
      },
      error => {
        this.verifying = false;
        this.invalid = error.status === 404;
        this.invalidEmailChange = error.status === 410 || error.status === 400;
        this.error = !this.invalid && !this.invalidEmailChange;
      }
    );
  }
}
