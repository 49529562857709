<div class="mt-2">
  <div class="row" *ngIf="isCardPayment() && useSavedCard">
    <div class="col-12 mb-1" *ngIf="showFirstLabel">Carta selezionata:</div>
    <div class="col-12">
      <cp-payment-card-component
        [card]="selectedCard"
        [isSelected]="true"
        class="flex-fill"
      ></cp-payment-card-component>
    </div>
  </div>

  <div class="row" *ngIf="isSepaPayment() || !useSavedCard">
    <div class="col-12 mb-1" *ngIf="showFirstLabel">Inserisci {{isCardPayment()? 'una carta' : 'un iban'}}:</div>
    <div class="col-12">
      <cp-stripe-elements
        #stripeComponent
        [intentClientSecret]="intentClientSecret"
        [paymentIntentClientSecretType]="intentClientSecretType"
        [type]="paymentMethod"
        [style]="light ? stripeComponent.lightStyle : darkCardElementStyle"
        [user]="user"
        (onValidityChange)="canSubmit.emit($event)"
      >
        <ngb-alert *ngIf="isSepaPayment()" class="text-justify" [type]="'primary'" [dismissible]="false" style="font-size: 0.8rem">
          Con la sottoscrizione del presente mandato, il debitore autorizza FITNESSGEST S.r.l. e Stripe ad inviare alla propria
          banca le istruzioni per addebitare il proprio conto e la banca ad eseguire l’addebito conformemente alle
          disposizioni impartite da Stripe. Il sottoscritto debitore ha diritto di ottenere il rimborso dalla propria banca
          secondo quanto previsto nel contratto con la propria banca. Eventuali richieste di rimborso devono essere
          presentate entro e non oltre 8 settimane a decorrere dalla data di addebito in conto. I diritti del debitore sono
          indicati nella documentazione ottenibile dalla propria banca.
        </ngb-alert>
      </cp-stripe-elements>
    </div>
  </div>

</div>

<ng-container *ngIf="isCardPayment()">
  <div *ngIf="hasOtherCards && useSavedCard">
    <div
      *ngIf="showOtherCards"
      class="mt-1 p-1 rounded"
      [ngClass]="light ? 'bg-primary text-white' : 'bg-secondary text-primary'"
    >
      <div class="mb-1">Tocca una carta per selezionarla</div>
      <div class="list-group">
        <ng-container *ngFor="let card of paymentCards; let isFirst = first; let index = index">
          <ng-container *ngIf="card.id !== selectedCard.id">
            <div
              class="list-group-item list-group-item-action pointer border-left-0 border-right-0"
              [ngClass]="{'border-top-0' : isFirst || (index == 1 && paymentCards[0].id == selectedCard.id)}"
              (click)="selectCard(card)"
            >
              <cp-payment-card-component class="mb-3" [card]="card"></cp-payment-card-component>
            </div>
          </ng-container>
        </ng-container>
        <div
          class="list-group-item list-group-item-action pointer line-height-1 d-flex align-items-center small border-0"
          (click)="setUseSavedCard(false)"
        >
          <i class="ci ci-plus-primary ci-small mr-1"></i> Aggiungi una nuova
        </div>
      </div>
    </div>
    <div class="mt-1">
      <a
        class="pointer btn badge"
        [ngClass]="light ? 'badge-primary text-white' : 'badge-secondary text-primary'"
        (click)="showOtherCards = !showOtherCards"
      >
        <span *ngIf="!showOtherCards"> Scegli un'altra </span>
        <span *ngIf="showOtherCards"> Nascondi le altre carte </span>
      </a>
    </div>
  </div>

  <div class="mt-1" *ngIf="!useSavedCard && hasCards">
    <a
      class="pointer btn badge"
      [ngClass]="light ? 'badge-primary text-white' : 'badge-secondary text-primary'"
      (click)="setUseSavedCard(true)"
    >
      <span> Usane una salvata </span>
    </a>
  </div>

  <div class="mt-1" *ngIf="useSavedCard && !hasOtherCards">
    <a
      class="pointer btn badge"
      [ngClass]="light ? 'badge-primary text-white' : 'badge-secondary text-primary'"
      (click)="setUseSavedCard(false)"
    >
      <span> Usane un'altra </span>
    </a>
  </div>

</ng-container>
