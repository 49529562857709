import { Injectable } from '@angular/core';
import { SubscriptionModel } from '../models/subscription.model';
import { PromotionObject } from '../models/user-subscription.model';
import * as moment from 'moment/moment';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionPriceService {
    constructor() {}

    private computeDiscount(price: number, promotion: PromotionObject) {
        if (promotion && promotion.amount) {
            return promotion.amount;
        }
        if (promotion && promotion.sale) {
            return (price * promotion.sale) / 100;
        }
        return 0;
    }

    private applyDiscount(price: number, discount: number) {
        const discountedPrice = Math.max(price - discount, 0);
        const remainingDiscount = Math.max(discount - price, 0);
        return [discountedPrice, remainingDiscount];
    }

    private flexPartialPaymentInfo(price: number) {
        const today = moment();
        const monthDurationDays = today.daysInMonth();
        const numDaysToPay = monthDurationDays - today.date() + 1;
        price = Math.round(price * 100);
        const priceMinusCost = price - 100;
        const pricePerDay = priceMinusCost / monthDurationDays;
        return [(Math.round(pricePerDay * numDaysToPay) + 100) / 100, numDaysToPay];
    }

    getPriceComponents(subscription: SubscriptionModel, promotion: PromotionObject) {
        const { price, associative_price, tax_inclusive, interval, subscription_category } = subscription;
        const isMonthly = interval === 'month';

        if (!tax_inclusive){ // This should never happen anymore.
            return {
                isMonthly,
                price: 999,
                associativePrice: 999,
                commission: 999,
                flexFirstPaymentPrice: null as number,
                numDaysToPay: null as number,
            };
        }

        const totalPrice = price + associative_price;
        const totalDiscountAmount = this.computeDiscount(totalPrice, promotion);
        const commission = promotion || !isMonthly ? 0 : 1;
        const priceNoCommission = price - commission;
        const [discountedPrice, remainingDiscount] = this.applyDiscount(priceNoCommission, totalDiscountAmount);
        const [discountedAssociativePrice] = this.applyDiscount(associative_price, remainingDiscount);

        const components = {
            isMonthly,
            price: discountedPrice,
            associativePrice: discountedAssociativePrice,
            commission: commission,
            flexFirstPaymentPrice: null as number,
            numDaysToPay: null as number,
        };
        if (subscription_category === 'flex') {
            const [adjustedPrice, numDaysToPay] = this.flexPartialPaymentInfo(price);
            const discount = this.computeDiscount(adjustedPrice + associative_price, promotion);
            const adjustedPriceNoCommission = adjustedPrice - commission;
            const [discountedPrice] = this.applyDiscount(adjustedPriceNoCommission, discount);
            components.flexFirstPaymentPrice = discountedPrice;
            components.numDaysToPay = numDaysToPay;
        }
        return components;
    }
}
