import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IntentResponse, StripeService } from "../services/stripe.service";

@Component({
    selector: 'cp-stripe-elements',
    template: `
        <div>
            <div class="position-relative">
                <div
                    id="payment-element-{{hash}}"
                    class="col-12 border-bottom border-secondary"
                    [ngClass]="{ 'is-invalid border-danger': errors }"
                >
                    <!-- a Stripe Element will be inserted here. -->
                </div>

                <!-- Used to display form errors -->
                <div class="invalid-tooltip" role="alert" *ngIf="errors">
                    {{ errors }}
                </div>
            </div>

            <div class="mt-2 text-justify" *ngIf="type === 'sepa'">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [``],
})
export class StripeElementsComponent implements AfterViewInit, OnChanges {
    @Input() type: 'sepa' | 'card' = 'card';
    public lightStyle = {
        base: {
            backgroundColor: 'transparent',
            color: '#495057',
            fontFamily: "'Roboto',  sans-serif",
            fontSize: '17px',
            fontWeight: 'normal',
            iconColor: '#1d356a',
            lineHeight: 1.5,
            letterSpacing: 'normal',

            '::placeholder': {
                color: '#6c757d',
            },
        },
        invalid: {
            iconColor: '#ff2828',
            color: '#ff2828',
        },
    };
    @Input() style: any = this.lightStyle;
    @Input() params: any = {};
    @Input() user: any = {};
    @Input() intentClientSecret: string;
    @Input() paymentIntentClientSecretType: string;

    // tslint:disable:no-output-on-prefix
    @Output() onValidityChange = new EventEmitter<boolean>();

    public hash = Math.random().toString(36).substring(2, 5);
    public errors: string;
    public isValid = false;
    public isLoading = false;

    private paymentElement: any;
    private elements: any;

    constructor(private readonly stripeService: StripeService) {
    }

    ngAfterViewInit() {
        this.initializeStripe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.type){
            this.initializeStripe();
        }
    }

    private setValid(value: boolean) {
        if (this.isValid === value) return;
        this.isValid = value;
        this.onValidityChange.emit(this.isValid);
    }

    private initializeStripe() {
        this.elements = this.stripeService.getElements();

        let payment;

        if (this.type === 'card') {
            payment = this.elements.create('card', {
                style: this.style,
                hidePostalCode: true,
            });
        } else if (this.type === 'sepa') {
            payment = this.elements.create('iban', {
                style: this.style,
                supportedCountries: ['SEPA'],
                placeholderCountry: 'IT',
            });
        } else {
            console.error('Missing payment type!');
        }

        if (payment) {
            payment.mount('#payment-element-' + this.hash);
            payment.addEventListener('ready', () => {
                payment.focus();
            });
            payment.addEventListener('change', (event) => {
                this.errors = '';

                if (event.complete) {
                    this.setValid(true);
                } else {
                    this.setValid(false);
                }

                if (event.error) {
                    this.errors = event.error.message;
                    this.setValid(false);
                }
            });

            this.paymentElement = payment;
        }
    }

    public async confirmIntent(): Promise<IntentResponse>{
        if (!this.isValid || this.isLoading) { return; }
        this.isLoading = true;
        let result: IntentResponse;
        try {
            if (this.type === 'card' && this.paymentIntentClientSecretType == 'paymentIntent') {
                result = await this.stripeService.confirmCardPaymentIntentFromElements(this.paymentElement, this.intentClientSecret)
            } else if (this.type === 'card' && this.paymentIntentClientSecretType == 'setupIntent') {
                result = await this.stripeService.confirmCardSetupIntentFromElements(this.paymentElement, this.intentClientSecret)
            } else if (this.type === 'sepa') {
                result = await this.stripeService.confirmSepaDebitSetupIntentFromElements(this.paymentElement, this.intentClientSecret, this.user)
            }
        } catch (ex: any) {
            console.debug("Obtained error in stripe-elements.component, rethrowing");
            this.isLoading = false;
            throw ex;
        }
        this.isLoading = false;
        return result;
    }
}
