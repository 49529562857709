<label [innerHTML]="label" *ngIf="!!label" for="{{ id }}"></label>
<input
    id="{{ id || 'DatePicker' }}"
    type="text"
    class="form-control"
    [placeholder]="placeholder"
    [ngClass]="{ 'is-invalid': isInvalid }"

    ngbDatepicker
    #date="ngbDatepicker"

    [ngModel]="dateModel"
    #model="ngModel"

    [disabled]="disabled"
    [required]="required"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [startDate]="startDate"
    [markDisabled]="markDisabled"
    
    (click)="date.open()"
    (closed)="onTouch()"
    (dateSelect)="onChange($event)"
    (ngModelChange)="onChange($event)"
    maxlength="10"
/>
