import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthorizationService} from '../services/authorization.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthorizationService,
  ) {
  }

  inflightAuthRequest = null;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isWhitelistedDomain(req) && !this.authService.isBlacklistedRoute(req)) {

      if (!this.inflightAuthRequest) {
        this.inflightAuthRequest = this.authService.getToken();
      }

      return this.inflightAuthRequest.pipe(
        switchMap((newToken: string) => {
          // unset request inflight
          this.inflightAuthRequest = null;

          // use the newly returned token
          const authReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + (newToken ? newToken : ''))
          });
          return next.handle(authReq);
        }),
        catchError((error: HttpErrorResponse) => {
          // checks if a url is to an admin api or not
          if (error.status === 401) {
            // check if the response is from the token refresh end point
            const isFromRefreshTokenEndpoint = error.url === AuthorizationService.getRefreshTokenUrl();

            if (isFromRefreshTokenEndpoint) {
              this.authService.logout();
              return throwError(error);
            }

            if (!this.inflightAuthRequest) {
              this.inflightAuthRequest = this.authService.refreshToken();

              if (!this.inflightAuthRequest) {
                this.authService.logout();
                return throwError(error);
              }
            }

            return this.inflightAuthRequest.pipe(
              switchMap((newToken: string) => {
                // unset inflight request
                this.inflightAuthRequest = null;

                // clone the original request
                const authReqRepeat = req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${newToken}`)
                });

                // resend the request
                return next.handle(authReqRepeat);
              })
            );
          } else {
            return throwError(error);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
