<div class="d-flex flex-column flex-fill">
    <cp-waiting *ngIf="preconditionsOk === null"></cp-waiting>

    <cp-unexpected-error *ngIf="preconditionsOk === false" redirectTo="{{ getRedirectUrl() }}"></cp-unexpected-error>

    <section class="flex-fill d-flex flex-column container-fluid pr-lg-0" *ngIf="preconditionsOk === true">
        <div class="row mx-lg-0 flex-fill">

            <div class="py-3 pt-lg-10 col-12 col-md-10 col-lg-6 col-xl-6 mx-auto px-lg-3">
                <div class="text-center text-lg-left">
                    <h1 class="h1 text-secondary">
                        <span *ngIf="!isFreeTrial()"> ABBONATI A WEBFIT </span>
                        <span *ngIf="isFreeTrial()">
                            REGISTRATI PER PRENOTARE <br />
                            LA TUA PROVA GRATUITA
                        </span>
                    </h1>
                    <div class="h4 text-primary" autofocus>Compila i campi per continuare</div>
                </div>

                <div class="text line-form form--secondary form--color-textborder">
                    <div class="gform_wrapper">
                        <form #f="ngForm" (ngSubmit)="performRegistration(f)">
                            <ul class="gform_fields">
                                <!-- name -->
                                <li>
                                    <label for="NameInput">Nome*</label>

                                    <input
                                        id="NameInput"
                                        #name="ngModel"
                                        type="text"
                                        name="name"
                                        [ngModel]
                                        required
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (name.invalid && name.touched) || (error.name && name.pristine)
                                        }"
                                    />
                                    <small class="invalid-tooltip" *ngIf="name.invalid && name.touched">
                                        Il nome non è valido
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="error.name && name.pristine">
                                        {{ error.name }}
                                    </small>
                                </li>

                                <!-- surname -->
                                <li>
                                    <label for="SurnameInput">Cognome*</label>

                                    <input
                                        id="SurnameInput"
                                        #surname="ngModel"
                                        type="text"
                                        name="surname"
                                        [ngModel]
                                        required
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (surname.invalid && surname.touched) ||
                                                (error.surname && surname.pristine)
                                        }"
                                    />
                                    <small class="invalid-tooltip" *ngIf="surname.invalid && surname.touched">
                                        Il cognome non è valido
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="error.surname && surname.pristine">
                                        {{ error.surname }}
                                    </small>
                                </li>

                                <!-- taxcode -->
                                <li>
                                    <label for="TaxCodeInput">
                                        Codice <br />
                                        Fiscale*
                                    </label>

                                    <input
                                        id="TaxCodeInput"
                                        #tax_code="ngModel"
                                        type="text"
                                        name="tax_code"
                                        [ngModel]
                                        oninput="this.value = this.value.toUpperCase()"
                                        required
                                        class="form-control"
                                        pattern="^[a-zA-Z]{6}[a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$"
                                        [ngClass]="{
                                            'is-invalid':
                                                (tax_code.invalid && tax_code.touched) ||
                                                (error.tax_code && tax_code.pristine)
                                        }"
                                    />
                                    <small class="invalid-tooltip" *ngIf="tax_code.errors?.required">
                                        Il codice fiscale è richiesto
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="tax_code.errors?.pattern">
                                        Il formato non è riconosciuto
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="isTaxcodeExisting && tax_code.pristine">
                                        Sembra che tu sia già registrato. Prova ad
                                        <a
                                            class="text-reset"
                                            style="text-decoration: underline"
                                            routerLink="/login"
                                        >
                                            accedere
                                        </a>
                                        o a
                                        <a
                                            class="text-reset"
                                            style="text-decoration: underline"
                                            routerLink="/password-recovery"
                                        >
                                            resettare la password </a
                                        >.
                                    </small>
                                    <small
                                        class="invalid-tooltip"
                                        *ngIf="error.tax_code && !isTaxcodeExisting && tax_code.pristine"
                                    >
                                        {{ error.tax_code }}
                                    </small>
                                </li>

                                <!-- email -->
                                <li>
                                    <label for="EmailInput">Email*</label>
                                    <input
                                        id="EmailInput"
                                        #email="ngModel"
                                        type="email"
                                        name="email"
                                        [ngModel]
                                        required
                                        email
                                        (ngModelChange)="onMailChange(email.value, email.valid)"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (email.invalid && email.touched) ||
                                                !accessService.isMailFree ||
                                                (error.email && email.pristine)
                                        }"
                                    />
                                    <small
                                        class="invalid-tooltip"
                                        [hidden]="email.valid || (!email.touched && !f.submitted)"
                                    >
                                        La mail non è valida
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="!accessService.isMailFree">
                                        La mail è già registrata. Prova ad
                                        <a
                                            class="text-reset"
                                            style="text-decoration: underline"
                                            routerLink="/login"
                                        >
                                            accedere
                                        </a>
                                        o a
                                        <a
                                            class="text-reset"
                                            style="text-decoration: underline"
                                            routerLink="/password-recovery"
                                        >
                                            resettare la password </a
                                        >.
                                    </small>
                                    <small
                                        class="invalid-tooltip"
                                        *ngIf="error.email && !accessService.isMailFree && email.pristine"
                                    >
                                        {{ error.email }}
                                    </small>
                                </li>

                                <!-- password -->
                                <li>
                                    <label for="PasswordInput">Password*</label>
                                    <input
                                        id="PasswordInput"
                                        #password="ngModel"
                                        type="password"
                                        name="password"
                                        [ngModel]
                                        required
                                        minlength="6"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (password.invalid && password.touched) ||
                                                (error.password && password.pristine)
                                        }"
                                    />
                                    <small
                                        class="invalid-tooltip"
                                        *ngIf="password.invalid && (password.touched || f.submitted)"
                                    >
                                        La password è richiesta (minimo 6 caratteri)
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="error.password && password.pristine">
                                        {{ error.password }}
                                    </small>
                                </li>

                                <!-- password 2 -->
                                <li>
                                    <label for="ConfirmPasswordInput">Conferma<br />Password*</label>
                                    <input
                                        id="ConfirmPasswordInput"
                                        #password_confirmation="ngModel"
                                        type="password"
                                        name="password_confirmation"
                                        [ngModel]
                                        required
                                        minlength="6"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (password_confirmation.invalid ||
                                                    password.value !== password_confirmation.value) &&
                                                password_confirmation.touched
                                        }"
                                    />

                                    <small
                                        class="invalid-tooltip"
                                        *ngIf="
                                            !password_confirmation.errors?.required &&
                                            password.value !== password_confirmation.value &&
                                            password_confirmation.touched
                                        "
                                    >
                                        Le password inserite sono differenti
                                    </small>
                                    <small
                                        class="invalid-tooltip"
                                        *ngIf="
                                            password_confirmation.errors?.required && password_confirmation.touched
                                        "
                                    >
                                        Campo obbligatorio
                                    </small>
                                </li>

                                <!-- privacy -->
                                <li class="form-check custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        #privacy_consent="ngModel"
                                        name="privacy_consent"
                                        [ngModel]
                                        required
                                        id="PrivacyInput"
                                        [ngClass]="{
                                            'is-invalid':
                                                (privacy_consent.invalid && privacy_consent.touched) ||
                                                (error.privacy_consent && privacy_consent.pristine)
                                        }"
                                    />
                                    <label class="custom-control-label text-reset" for="PrivacyInput">
                                        ACCETTO
                                        <a href="http://www.webfit.it/blog/privacy-policy/" target="_blank"
                                            >L’INFORMATIVA SULLA PRIVACY</a
                                        >
                                    </label>
                                    <small class="invalid-tooltip" *ngIf="privacy_consent.invalid && privacy_consent.touched">
                                        È necessario approvare questo campo
                                    </small>
                                    <small class="invalid-tooltip" *ngIf="error.privacy_consent && privacy_consent.pristine">
                                        {{ error.privacy_consent }}
                                    </small>
                                </li>

                                <!-- commercial comunications -->
                                <li class="form-check custom-control custom-checkbox mt-n2">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        #marketing_opt="ngModel"
                                        name="marketing_opt"
                                        [ngModel]
                                        id="CommercialCommunicationsInput"
                                        [ngClass]="{
                                            'is-invalid':
                                                (marketing_opt.invalid &&
                                                    marketing_opt.touched) ||
                                                (error.marketing_opt &&
                                                    marketing_opt.pristine)
                                        }"
                                    />
                                    <label class="custom-control-label" for="CommercialCommunicationsInput">
                                        Acconsento alla ricezione di offerte commerciali e promozionali
                                    </label>
                                    <small
                                        class="invalid-tooltip"
                                        *ngIf="error.marketing_opt && marketing_opt.pristine"
                                    >
                                        {{ error.marketing_opt }}
                                    </small>
                                </li>

                                <!-- profilation -->
                                <li class="form-check custom-control custom-checkbox mt-n2">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        #profiling_opt="ngModel"
                                        name="profiling_opt"
                                        [ngModel]
                                        id="ProfilingInput"
                                        [ngClass]="{
                                            'is-invalid':
                                                (profiling_opt.invalid && profiling_opt.touched) ||
                                                (error.profiling_opt && profiling_opt.pristine)
                                        }"
                                    />
                                    <label class="custom-control-label" for="ProfilingInput">
                                        Acconsento alla profilazione commerciale su sito e App mobile
                                    </label>
                                    <small class="invalid-tooltip" *ngIf="error.profiling_opt && profiling_opt.pristine">
                                        {{ error.profiling_opt }}
                                    </small>
                                </li>

                                <!-- sub buttons -->
                                <li class="mt-3">
                                    <button type="submit" class="btn btn-outline-secondary-text-primary btn-block">
                                        <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                            *ngIf="loading"
                                        ></span>
                                        Registrati
                                    </button>
                                </li>
                                <li class="mt-2">
                                    <ngb-toast
                                        *ngIf="errorMessage != null"
                                        [delay]="5000"
                                        [autohide]="autohideErrorMessage"
                                        (mouseenter)="autohideErrorMessage = false"
                                        (mouseleave)="autohideErrorMessage = true"
                                        (hide)="errorMessage = null; autohideErrorMessage = true"
                                        class="
                                            mb-0
                                            bg-danger
                                            text-light
                                            w-100
                                            full-width-toast
                                            text-center text-lg-left
                                        "
                                    >
                                        {{ errorMessage }}
                                    </ngb-toast>
                                </li>

                                <li class="text-center mt-2 text-lg-left">
                                    <a class="text-reset" routerLink="/login">Sei già iscritto? Accedi ora!</a>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-6 pr-0 d-lg-flex d-none">
                <ngb-carousel [pauseOnHover]="true" class="d-flex flex-fill align-self-stretch" *ngIf="imageUrls.length > 0">
                    <ng-template ngbSlide *ngFor="let url of imageUrls">
                        <div
                            class="picsum-img-wrapper bg-gray-500"
                            [ngStyle]="{'background-image': 'url(' + url + ')'}"
                        ></div>
                        <div class="carousel-caption"></div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </section>
</div>

<ng-template #mobileCarouselContainer let-modal>
    <button type="button" id="close-button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
    <ngb-carousel [pauseOnHover]="true" class="d-flex flex-fill rounded overflow-hidden">
        <ng-template ngbSlide *ngFor="let url of imageUrls">
            <div
                class="picsum-img-wrapper bg-gray-500"
                [ngStyle]="{'background-image': 'url(' + url + ')'}"
            ></div>
            <div class="carousel-caption"></div>
        </ng-template>
    </ngb-carousel>

    <div id="subscribe-button-wrapper">
        <button class="btn btn-outline-secondary-text-white" (click)="modal.close()" autofocus>ISCRIVITI ORA</button>
    </div>
</ng-template>
