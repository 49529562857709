<section class="card bg-primary border-0 rounded text-white position-relative mx-n2">
  <div class="card-body p-md-3 p-lg-4">
    <div class="mb-2">
      <h4 class="text-center text-secondary">DOCCE</h4>
    </div>

    <p *ngIf="showers.user_needs_badge" class="text-center">
      Non puoi acquistare docce se non hai un badge!
      <br />
      Recati in palestra e richiedine uno.
    </p>
    <ng-container *ngIf="!showers.user_needs_badge">
      <p *ngIf="showers.quantity" class="text-center">
        Hai a disposizione ancora <b class="text-secondary">{{ showers.quantity }}</b>
        {{ showers.quantity == 1 ? " doccia" : " docce" }}
      </p>
      <p *ngIf="!showers.quantity" class="text-center">
        Hai <b class="text-secondary">terminato</b> le docce a tua disposizione.
      </p>
      <p class="text-center">
        <small> Tocca qui sotto per acquistarne altre! </small>
      </p>
    </ng-container>

    <div [ngbCollapse]="!expanded" id="CardsCollapse">
      <div *ngIf="!areShowerPacksLoaded" class="text-center">
        <cp-spinner spinnerClass="text-white"></cp-spinner>
      </div>

      <div class="container" *ngIf="areShowerPacksLoaded">
        <div class="row justify-content-center">
          <div class="col-12 col-xxxl-6 mb-2" *ngFor="let sPack of showerPacks">
            <button class="btn btn-outline-secondary-text-white btn-block" (click)="Buy(sPack)">
              <div>
                {{ sPack.name }}
                <br />
                {{ sPack.caption }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-lg-3 mt-2" *ngIf="!showers.user_needs_badge">
      <button
        type="button"
        (click)="toggleExpand()"
        class="btn btn-icon-outline-secondary"
        [attr.aria-expanded]="expanded"
        aria-controls="CardsCollapse"
      >
        <i class="ci" [ngClass]="expanded ? 'ci-minus-white' : 'ci-plus-white'"></i>
      </button>
    </div>
  </div>
  <cp-spinner spinnerClass="text-white" backdropClass="rounded" [hasBackDrop]="true" *ngIf="isBooking"></cp-spinner>
</section>
