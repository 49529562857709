import { HomeComponent } from './features/home/home.component';
import { EnvHomeGuard } from './guards/env-home.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NeedLoginGuard} from '../../../../core/api/guards/need-login.guard';
import {SettingsComponent} from './features/settings/settings.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NeedLoginGuard, EnvHomeGuard],
  },
  {
    path: 'home/:id',
    component: HomeComponent,
    canActivate: [NeedLoginGuard, EnvHomeGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [NeedLoginGuard],
  },
  { path: '',
    pathMatch: 'full',
    redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [NeedLoginGuard]
})
export class HomeRoutingModule { }
