<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">AGGIORNA LA CARTA</h4>
  </div>
  <p class="mt-2">Aggiorna la data di scadenza della carta:</p>
  <cp-payment-card-component [card]="card" [allowActions]="false" [disableTooltip]="true"></cp-payment-card-component>

  <form
    #f="ngForm"
    (ngSubmit)="!loading ? (f.valid && !isDatePassed ? sub(f) : f.form.markAllAsTouched()) : null"
    class="line-form form--light form--color-textborder"
  >
    <div class="form-group mt-1">
      <label for="monthInput">Scadenza*<br />MM/YY</label>
      <input
        id="monthInput"
        type="text"
        name="expiration"
        ngModel
        pattern="((0[1-9])|(1[0-2]))\/\d\d"
        #expiration="ngModel"
        (ngModelChange)="onDateChange(expiration)"
        [ngClass]="{
          'is-invalid': (expiration.invalid || isDatePassed) && expiration.touched
        }"
        required
        onDrop="return false"
        onPaste="return false"
        autocomplete=off
      />
      <small class="invalid-tooltip" *ngIf="expiration?.errors?.required && expiration?.touched">
        Campo obbligatorio
      </small>
      <small class="invalid-tooltip" *ngIf="expiration?.errors?.pattern && expiration?.touched"> Data non valida </small>
      <small class="invalid-tooltip" *ngIf="isDatePassed && expiration?.touched && expiration?.valid"> La data dev'essere futura </small>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-6">
          <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
        </div>
        <div class="col-6">
          <button class="btn btn-block btn-outline-secondary-text-white" type="submit" [ngClass]="{ active: loading }">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            Prosegui
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
