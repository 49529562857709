import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild,
    HostBinding,
} from "@angular/core";
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NgModel,
} from "@angular/forms";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from "../../services/helper.service";
import * as moment from "moment";

/**
 * NgModel does not work that well with this component
 * Ignore NgModel.valid and NgModel.invalid metadata
 * Ignore NgModel.errors propriety
 * Use instead error event emitter. If no errors are found, consider input valid.
 */
@Component({
    selector: "cp-date-picker",
    templateUrl: "./date-picker.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
    ],
})
export class DatePickerComponent implements ControlValueAccessor {
    @Input() id: string;
    @Input() label: string;
    @Input() minDate: NgbDate;
    @Input() startDate = { year: 1980, month: 1, day: 1 };
    @Input() maxDate: NgbDate;
    @Input() placeholder: string = "";
    @Input() disabled = false;
    @Input() required = false;
    @Input() markDisabled = (date: NgbDate) => false;

    @ViewChild("model") model: NgModel;
    @Output() errors: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding("class.is-invalid") @Input() isInvalid: boolean;

    public dateModel: NgbDate;

    private onTouchFn;

    private onChangeFn;

    constructor(private helperService: HelperService) {}

    // has access to a function that informs the outside world about changes
    registerOnChange(fn) {
        this.onChangeFn = fn;
    }

    // registers a callback that is executed whenever a form control is “touched”
    registerOnTouched(fn) {
        this.onTouchFn = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    // called when the form is initialized, with the form model’s initial value
    writeValue(value: any) {
        if (value && moment(value).isValid()) {
            this.dateModel = this.helperService.getDateForPicker(moment(value));
        }
        this.emitErrors();
    }
    
    onChange(date: NgbDate) {
        
        let parsedDate;
        if (date && date.year && date.month && date.day) {
            parsedDate = this.helperService.convertDateFromPicker(date);
        }
        
        if (this.onChangeFn) {
            this.onChangeFn(parsedDate);
        }
        this.emitErrors();
    }

    onTouch() {
        if (this.onTouchFn) {
            this.onTouchFn();
        }
    }
    
    async emitErrors() {
        setTimeout(() => { 
            this.errors.emit(this.model.errors);
         }, 1);
        
    }
}
