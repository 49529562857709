import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[autofocus]',
})
export class AutofocusDirective {
    constructor(private host: ElementRef) {}

    ngAfterViewInit() {
        console.log('autofocus');
        this.host.nativeElement.focus();
    }
}
