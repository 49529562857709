<section class="card bg-primary border-0 rounded text-white mx-n2">
  <div class="card-body p-md-3 p-lg-4">
    <div>
      <div class="mb-2">
        <div ngbDropdown class="float-right" [placement]="['bottom-right', 'top-right']" *ngIf="canSuspend || canRenew || canCheckout">
          <button
            ngbDropdownToggle
            class="btn btn-link btn-sm line-height-1 mr-n2 mt-n2 py-1 px-0 text-body no-after btn-icon btn-icon-white-shadowed"
            id="subscritionDropDown"
          >
            <i class="ci ci-ellipsis-vertical-white"></i>
          </button>

          <div ngbDropdownMenu aria-labelledby="subscritionDropDown">
            <button ngbDropdownItem *ngIf="canSuspend" [disabled]="modalShown" (click)="openCreateSuspensionDialog()">
              Sospendi
            </button>
            <button ngbDropdownItem *ngIf="canRenew" [disabled]="modalShown" (click)="goToRenewPage()">Rinnova</button>
            <button ngbDropdownItem *ngIf="canCheckout" [disabled]="modalShown" (click)="openCheckoutDialog()">Disdetta</button>
          </div>
        </div>

        <h4 class="text-secondary text-uppercase mr-2">{{ userSubscription.sub_info.name }}</h4>
      </div>

      <dl class="row mb-0">
        <dt class="col-6">Stato</dt>
        <dd class="col-6 col-md-4 col-sm-5 ml-auto">
          <span class="badge badge-pill" [ngClass]="subscriptionStatusCssClass">
            {{ subscriptionStatus }}
          </span>
        </dd>
        <dt class="col-6">Valido dal</dt>
        <dd class="col-6 col-md-4 col-sm-5 ml-auto">{{ subStartDate }}</dd>
        <dt class="col-6" *ngIf="subCategory == 'standard' || (!userSubscription.active && !userSubscription.is_suspended)">Valido fino al</dt>
        <dt class="col-6" *ngIf="subCategory == 'flex' && (userSubscription.active || userSubscription.is_suspended)">Termine ciclo corrente</dt>
        <dd class="col-6 col-md-4 col-sm-5 ml-auto">{{ subEndDate }}</dd>
        <ng-container *ngIf="userSubscription.sub_checkout_date">
          <dt class="col-6">Disdetta</dt>
          <dd class="col-6 col-md-4 col-sm-5 ml-auto">{{ formatDateString(userSubscription.sub_checkout_date) }}</dd>
          <ng-container *ngIf="subCategory == 'flex' && !userSubscription.active && !userSubscription.is_suspended && subEndDate != guessedEndDateForCheckout">
            <dt class="col-6">Terminerà il</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">{{ guessedEndDateForCheckout }}</dd>
          </ng-container>
        </ng-container>
      </dl>

      <div [id]="'SubscriptionCollapse' + userSubscription.id" [ngbCollapse]="!expanded">
        <dl class="row">
          <dt class="col-6">Sede</dt>
          <dd class="col-6 col-md-4 col-sm-5 ml-auto">
            {{ userSubscription?.gym?.name }}
          </dd>
        </dl>

        <div>
          <h6 class="text-secondary">PAGAMENTI</h6>
          <dl class="row mb-0" *ngIf="isMonthlySubscription">
            <dt class="col-6">Prossima fattura</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ nextInvoiceAt }}
            </dd>
            <dt class="col-6">Ultima fattura</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ lastInvoiceAt }}
            </dd>
            <dt class="col-6">Rate pagate</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ userSubscription.monthly_payments_completed }}
            </dd>
            <dt class="col-6" *ngIf="subCategory == 'standard'">Rate rimanenti</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto" *ngIf="subCategory == 'standard'">
              {{ userSubscription.monthly_payments_remaining }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-6">{{ paymentMethodLabel }}</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ paymentMethod }}
            </dd>
            <dt class="col-6">Acquistato il</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ subCreatedAt }}
            </dd>
          </dl>
        </div>

        <div class="pb-2">
          <h6 class="text-secondary">SOSPENSIONI</h6>

          <dl class="row">
            <dt class="col-6">Utilizzate:</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ suspensionInfo.usedText }}
            </dd>
            <dt class="col-6">Fruibili:</dt>
            <dd class="col-6 col-md-4 col-sm-5 ml-auto">
              {{ suspensionInfo.remainingText }}
            </dd>
          </dl>

          <ng-container *ngIf="hasSuspension">
            <div *ngFor="let susp of suspensions" class="mb-1">
              <cp-client-suspension
                [suspension]="susp"
                [deleteSuspension]="openDeleteSuspensionDialog.bind(this)"
                [subscriptionCategory]="subCategory"
              ></cp-client-suspension>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center pt-md-2 pt-lg-3">
      <button
        type="button"
        (click)="toggleExpand()"
        class="btn btn-icon-outline-secondary"
        [attr.aria-expanded]="expanded"
        [attr.aria-controls]="'SubscriptionCollapse' + userSubscription.id"
      >
        <i class="ci" [ngClass]="expanded ? 'ci-minus-white' : 'ci-plus-white'"></i>
      </button>
    </div>
  </div>
</section>
