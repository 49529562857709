import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NeedLoginGuard} from '../../core/api/guards/need-login.guard';
import {KioskModeGuard} from '../../core/api/guards/kioskmode.guard';
import {LogoutComponent} from '../../core/api/features/logout.component';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [NeedLoginGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [NeedLoginGuard, KioskModeGuard]
})
export class LoggedInRoutingModule { }
