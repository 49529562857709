import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CorporateStorageService } from 'src/app/core/api/services/corporate-storage.service';
import { ListInterface } from 'src/app/shared/models/type.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  clear() {
    throw new Error('Method not implemented.');
  }
  constructor(
    private readonly httpClient: HttpClient,
    private readonly corporateStorageService: CorporateStorageService
  ) {}

  public getRegisterImageUrls(): Observable<string[]> {
    const gym_id = this.corporateStorageService.getGymId();
    const params =  gym_id? {gym_id} : {}
    return this.httpClient
      .post<ListInterface<string>>(`${environment.apiUrl}/customer-portal-images/list-urls-by-gym`, params)
      .pipe(map(response => response.data));
  }
}
