<div class="d-flex flex-row flex-wrap align-items-center justify-content-around p-0 mx-0">
  <dl class="mb-0 d-flex col-12 col-lg-5 col-md">
    <dt class="pr-2 col-6 col-md-4 col-lg-3 col-xl-2">Descr.</dt>
    <dd class="mb-0">{{ formattedInvoice.description }}</dd>
  </dl>
  <dl class="mb-0 d-flex col-12 col-lg-5 col-md">
    <dt class="pr-2 col-6 col-md-7 col-lg-4">N. fattura</dt>
    <dd class="mb-0">{{ formattedInvoice.invoiceNumber }}</dd>
  </dl>
  <dl class="mb-0 d-flex col-12 col-md-3 col-lg-2">
    <dt class="pr-2 col-6 col-md-5">Tot.</dt>
    <dd class="mb-0">{{ formattedInvoice.total }}</dd>
  </dl>
</div>
