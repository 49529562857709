<div class="text-center">
  <h1 class="text-secondary">RISULTANO DEI PAGAMENTI DA SALDARE</h1>
  <h4 class="text-primary d-flex justify-content-center align-items-center flex-wrap">
    Totale del saldo:
    <span>
      <s class="h5 mb-0 badge badge-gray-500 text-white ml-2" *ngIf="amnestyToForgive">{{
        user.outstanding_balance.toFixed(2) + " €"
      }}</s>
      <span class="line-height-1 ml-2" *ngIf="amnestyToForgive">
        <i class="ci ci-arrow-right-primary"></i>
      </span>
      <span class="h4 mb-0 badge badge-secondary ml-2">{{ formattedOpenPaymentsDueNow }}</span>
    </span>
  </h4>
</div>

<div *ngFor="let unsolved of formattedUnsolveds" class="bg-primary rounded p-1 mt-4">
  <p class="text-white line-height-1 mb-1">Insoluto di {{ unsolved.total }} del {{ unsolved.date }}:</p>
  <div class="list-group">
    <div class="list-group-item border-top-0 border-left-0 border-right-0 p-1">
      <cp-invoice-list-item-recap [invoice]="unsolved.invoice"></cp-invoice-list-item-recap>
    </div>
    <div class="list-group-item border-bottom-0 border-left-0 border-right-0 p-1">
      <cp-invoice-list-item-recap [invoice]="unsolved.fees"></cp-invoice-list-item-recap>
    </div>
  </div>
</div>

<div *ngIf="openInvoices.length" class="bg-primary rounded p-1 mt-1">
  <p class="text-white line-height-1 mb-1">Altre fatture pendenti:</p>
  <div class="list-group">
    <div
      *ngFor="let invoice of openInvoices; let isFirst = first; let isLast = last"
      class="list-group-item border-left-0 border-right-0 p-1"
      [ngClass]="{ 'border-top-0': isFirst, 'border-bottom-0': isLast }"
    >
      <cp-invoice-list-item-recap [invoice]="invoice"></cp-invoice-list-item-recap>
    </div>
  </div>
</div>

<div class="bg-gray-500 rounded p-1 mt-1" *ngIf="amnestyToForgive">
  <p class="text-white line-height-1 mb-1">
    Questa sanatoria verrà condonata se paghi gli altri arretrati e se completi l'acquisto dell'abbonamento:
  </p>
  <div class="list-group">
    <div class="list-group-item border-bottom-0 border-top-0 border-left-0 border-right-0 p-1">
      <cp-invoice-list-item-recap [invoice]="amnestyToForgive"></cp-invoice-list-item-recap>
    </div>
  </div>
</div>

<div class="row mb-5 mt-3">
  <div class="col-12 col-md-7 col-lg-6 col-xl-4 mx-auto">
    <button class="btn btn-outline-secondary-text-primary btn-block" (click)="payOutstandingBalance()">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
      SALDA ARRETRATI
    </button>
  </div>
  <div class="col-12">
    <ngb-alert *ngIf="gotPaymentError" class="text-center mt-3" [dismissible]="false" [type]="'danger'">
      Si è verificato un problema durante il pagamento{{
        paymentError ? ': ' + paymentError : ", riprova più tardi o con un altro metodo di pagamento"
      }}
    </ngb-alert>
  </div>
</div>
