import {Injectable} from '@angular/core';
import {AbstractAuthorizationService} from './abstract-authorization.service';
import {environment} from '../../../../environments/environment';

@Injectable()
export class CorporateStorageService implements AbstractAuthorizationService {
  constructor(
  ) { }

  setGymId(gymId: string = '') {
    if (!gymId) {
      localStorage.removeItem('gym_id');
    } else {
      this.setHasQueryParams(true);
      localStorage.setItem('gym_id', gymId);
    }
  }

  getGymId(): string {
    return localStorage.getItem('gym_id');
  }

  getRegistrationType(): string {
    return localStorage.getItem('reg_type');
  }

  setRegistrationType(regType: string = '') {
    if (!regType || ['pass_ospiti', 'abbonamento'].indexOf(regType) === -1) {
      localStorage.removeItem('reg_type');
    } else if (regType === 'abbonamento' || (regType === 'pass_ospiti' && !environment.free_trial_visible) ) {
      this.setHasQueryParams(true);
      localStorage.setItem('reg_type', '/subscription');
    } else {
      this.setHasQueryParams(true);
      localStorage.setItem('reg_type', '/free-trial');
    }
  }

  setSubscriptionIds(subIds: Array<string>) {
    if (subIds.length > 0) {
      this.setHasQueryParams(true);
      localStorage.setItem('subscription_ids', JSON.stringify(subIds));
    } else {
      localStorage.removeItem('subscription_ids');
    }
  }

  getSubscriptionIds(): string[] {
    const ids = localStorage.getItem('subscription_ids');
    if (ids) {
      try {
        return JSON.parse(ids);
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  setPayloadParams(payload: string = '') {
    if (!payload) {
      localStorage.removeItem('payload');
    } else {
      this.setHasQueryParams(true);
      localStorage.setItem('payload', payload);
    }
  }

  getPayloadParams(): string {
    return localStorage.getItem('payload');
  }

  setRedirectTo(redirectTo: string = '') {
    if (!redirectTo) {
      localStorage.removeItem('redirect_to');
    } else {
      this.setHasQueryParams(true);
      localStorage.setItem('redirect_to', redirectTo);
    }
  }

  getRedirectTo(): string {
    return localStorage.getItem('redirect_to');
  }

  setHasQueryParams(value: boolean = null) {
    if (value === null || value === undefined) {
      localStorage.setItem('has_query_params', value ? "true" : "false");
    } else {
      localStorage.removeItem('has_query_params');
    }
  }

  hasQueryParams() {
    return localStorage.getItem('has_query_params') === 'true';
  }

  getEncodedPayload(): any {
    return JSON.stringify({
      payload: this.getPayloadParams(),
      redirect_to: this.getRedirectTo()
    });
  }

  setEncodedPayload(payload: string): any {
    try {
      const parsed = JSON.parse(payload);
      this.setPayloadParams(parsed.payload);
      this.setRedirectTo(parsed.redirect_to);
    } catch (e) {
      console.error('Not parsable');
    }
    return JSON.stringify({
      payload: this.getPayloadParams(),
      redirect_to: this.getRedirectTo()
    });
  }

  setAutoLogoutEnabled(enabled: boolean) {
    localStorage.setItem('auto_logout_enabled', '' + enabled);
  }

  getAutoLogoutEnabled() {
    return localStorage.getItem('auto_logout_enabled') === 'true';
  }

  getUrl(url: string): string {
    const payload = this.getPayloadParams();

    if (payload) {
      return `${url}${decodeURIComponent(payload)}`;
    }
    return  url;
  }

  /**
   * Returns a query readable by CorporateGuard.
   */
  toQueryString(): string {
    const subscriptionTypes = {
      '/subscription': 'abbonamento',
      '/free-trial': 'pass_ospiti',
    }
    const params = [
      ['gym_id', this.getGymId()],
      ['subscription_ids', this.getSubscriptionIds()],
      ['registration_type', subscriptionTypes[this.getRegistrationType()]],
      ['payload', this.getPayloadParams()],
      ['redirect_to', this.getRedirectTo()],
    ];
    return params
      .filter(([_, value]) => value)
      .map(([name, value]) => {
        const text = Array.isArray(value) ? value.join(',') : String(value);
        return `${name}=${encodeURIComponent(text)}`;
      })
      .join('&');
  }

  clear() {
    this.setGymId();
    this.setSubscriptionIds([]);
    this.setRegistrationType();
    this.setPayloadParams();
    this.setHasQueryParams();
    this.setRedirectTo();
  }
}
