<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">DISDETTA</h4>
  </div>
  <div ngbAutofocus>
      Se attivi la disdetta ora,
      <ng-container [ngSwitch]="situation">
          <ng-container *ngSwitchCase="'flex_suspended_planned'">
              le tue sospensioni pianificate saranno eliminate e la data di disdetta effettiva coinciderà con la fine della sospensione attuale ( {{ sub_sus_end_date }} ),
          </ng-container>
          <ng-container *ngSwitchCase="'flex_suspended'">
              la data di disdetta effettiva coinciderà con la fine della sospensione attuale ( {{ sub_sus_end_date }} ),
          </ng-container>
          <ng-container *ngSwitchCase="'flex_planned'">
              le tue sospensioni pianificate saranno eliminate e
          </ng-container>
          <ng-container *ngSwitchDefault>
          </ng-container>
      </ng-container>
      l'abbonamento terminerà il {{sub_end_date}} e non verrà {{subCategory == "flex" ? "esteso ulteriormente" : "rinnovato automaticamente"}}
      <br/>
      fino ad allora continuerai regolarmente a ricevere gli addebiti sul metodo di pagamento selezionato in fase di acquisto.
  </div>
    <div class="mt-3">
      <div class="row">
        <div class="col-6">
          <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
        </div>
        <div class="col-6">
          <button class="btn btn-block btn-outline-secondary-text-white" type="button" (click)="sub()" [ngClass]="{ active: loading }">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            Prosegui
          </button>
        </div>
      </div>
    </div>
</div>
