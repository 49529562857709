import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../../shared/services/user.service';
import { AutoLogoutService } from '../../../services/autologout.service';

@Component({
    selector: 'cp-subscription-recap',
    template: `
        <cp-waiting *ngIf="!isError && !isReady"></cp-waiting>
        <cp-purchase-recap
            *ngIf="isReady || isError"
            [isError]="isError"
            [hasAmnestyToForgive]="hasAmnestyToForgive"
            [isIntentConfirmationRegistrationFailed]="isIntentConfirmationRegistrationFailed"
        ></cp-purchase-recap>
    `,
    styles: [`

    `]
})
export class SubscriptionRecapComponent implements OnInit, OnDestroy {

    public isReady: boolean = false
    public isError: boolean = false;
    public hasAmnestyToForgive: boolean;
    public isIntentConfirmationRegistrationFailed: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private autoLogoutService: AutoLogoutService
    ) {
    }

    async ngOnInit() {
        this.autoLogoutService.logoutLater();

        this.activatedRoute.queryParams.subscribe(params => {
            this.hasAmnestyToForgive = params['hasAmnestyToForgive'] ?? false;
            this.isIntentConfirmationRegistrationFailed = params['isIntentConfirmationRegistrationFailed'] ?? false;
        });

        this.activatedRoute.paramMap.subscribe(_ => {
            this.userService.clear()
            this.userService.subscribe().subscribe(
                _ => {
                    this.isReady = true
                },
                _ => this.isError = true
            );
        });
    }

    ngOnDestroy(): void {
        this.userService.clear();
        this.autoLogoutService.clearTimers();
    }
}
