import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentCard } from 'src/app/shared/models/payments.model';
import { InvoiceModel, UnsolvedObject } from 'src/app/shared/models/invoices.model';
import { UserModel } from 'src/app/shared/models/user.model';
import * as moment from 'moment';
import { PaymentDialog } from 'src/app/shared/features/payment-dialog/payment-dialog.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UnexpectedErrorDialogComponent } from 'src/app/shared/features/unexpected-error-dialog/unexpected-error-dialog.component';

@Component({
    selector: 'cp-unsolveds-pay-at-purchase',
    templateUrl: './unsolveds-pay-at-purchase.component.html',
    styleUrls: [],
})
export class UnsolvedsPayAtPurchaseComponent implements OnInit {
    @Input() user: UserModel;
    @Input() public paymentCards: PaymentCard[];
    @Input() private unsolveds: UnsolvedObject[];
    @Input() public openInvoices: InvoiceModel[];
    @Input() public amnestyToForgive: InvoiceModel;
    @Output() public done: EventEmitter<void> = new EventEmitter();

    public totalOpenPaymentsBalance: string;
    public openPaymentsDueNow: number;
    public formattedOpenPaymentsDueNow: string;
    public formattedUnsolveds: UnsolvedShow[];
    public paymentError: string = null;
    public gotPaymentError: boolean = false;
    public isLoading: boolean = false;

    constructor(
        private readonly modalService: NgbModal,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.totalOpenPaymentsBalance = this.user.outstanding_balance.toFixed(2) + ' €';

        let outstandingBalance = this.user.outstanding_balance;
        if (this.amnestyToForgive != null) {
            outstandingBalance -= this.amnestyToForgive.total / 100;
        }
        this.openPaymentsDueNow = outstandingBalance
        this.formattedOpenPaymentsDueNow = outstandingBalance.toFixed(2) + ' €';

        this.formattedUnsolveds = this.unsolveds.map(unsolved => {
            return {
                date: moment(unsolved.created_at).format('DD/MM/YYYY'),
                total:
                    ((unsolved.invoice.total + unsolved.unsolved_invoice.total) / 100).toFixed(2) +
                    ' €',
                fees: unsolved.unsolved_invoice,
                invoice: unsolved.invoice,
            };
        });
    }

    payOutstandingBalance() {
        if (this.isLoading) return;
        this.isLoading = true;
        this._prepareOutstanding().subscribe(res => {
            if (this.openPaymentsDueNow !== res.amount) {
                this._showError('Si è verificato un errore.<br />Riprova.');
                return;
            }
            let ref = this.modalService.open(PaymentDialog, { centered: true });
            let refInnerComponent = ref.componentInstance as PaymentDialog;
            refInnerComponent.onSubmit.subscribe(
                _ => {
                    this._registerPaymentIntentConfirmation(
                        res.included_unsolveds, res.included_open_invoices
                      ).subscribe(_ => {
                        this.done.emit();
                        ref.close();
                      }, _ => {
                        const errorMessage = `La richiesta di pagamento è stata presa in carico, tuttavia potrebbe essere necessario qualche altro minuto.<br />
                          Nel frattempo, per via di un imprevisto riscontrato durante il tuo processo di pagamento, <b><u>ti preghiamo di non ritentare.</u></b><br/>
                          Se risultano ancora pagamenti da saldare, ti invitiamo a contattare il servizio clienti.<br/>
                          Ci scusiamo per il disagio.`;
                        ref.dismiss();
                        this._showError(errorMessage);
                      });
                },
                (ex: Error) => {
                    ref.dismiss();
                    this.paymentError = ex.message;
                    this.gotPaymentError = true;
                }
            );
            ref.result
                .catch(err => {
                    console.log('Rejected!');
                })
                .finally(() => {
                    this.isLoading = false;
                });
            refInnerComponent.user = this.user;
            refInnerComponent.paymentCards = this.paymentCards;
            refInnerComponent.title = 'PAGA SALDO IN SOSPESO';
            refInnerComponent.label = 'Saldo in sospeso:';
            refInnerComponent.total = this.openPaymentsDueNow;
            refInnerComponent.intentClientSecret = res.client_secret;
        }, err => {
            this._showError(err.error.message);
        });
    }

    _prepareOutstanding() {
        return this.http.post<any>(`${environment.apiUrl}/unsolveds/prepare-payment`, {
            ignore_amnesty: !!this.amnestyToForgive
        })
    }

    _registerPaymentIntentConfirmation(unsolveds: string[], invoices: string[]) {
        return this.http.post<any>(`${environment.apiUrl}/unsolveds/register-intent-confirmation`, {
            unsolveds, invoices
        })
    }

    _showError(errorMessage: string) {
        this.isLoading = true;
        let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, { centered: true });
        ueRef.result.finally(() => {
          this.isLoading = false;
          this.done.emit();
        });
        (ueRef.componentInstance as UnexpectedErrorDialogComponent).error = errorMessage;
      }
}

class UnsolvedShow {
    date: string;
    total: string;
    fees: InvoiceModel;
    invoice: InvoiceModel;
}
