<section class="pt-4 mb-5 container">
  <div class="container mt-1">

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <h1 class="text-secondary text-center">IMPOSTAZIONI ACCOUNT</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 offset-md-1 mt-2">
        <div class="form-check custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="cgu"
            name="cgu"
            [ngModel]="cgu"
            disabled="true"
          />
          <label class="custom-control-label text-reset" for="cgu">
            Condizioni generali di utilizzo
          </label>
        </div>
        <small class="font-weight-light font-italic text-muted">
          Accetto i <a [href]="getPreContractualDocsUrl()" target="_blank">TERMINI DELL'INFORMATIVA PRECONTRATTUALE.</a>
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 offset-md-1 mt-2">
        <div class="form-check custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="privacy_consent"
            name="privacy_consent"
            [ngModel]="privacy_consent"
            disabled="true"
          />
          <label class="custom-control-label text-reset" for="privacy_consent">
            Informativa sulla privacy
          </label>
        </div>
        <small class="font-weight-light font-italic text-muted">
          Accetto <a [href]="getPrivacyUrl()">l'INFORMATIVA SULLA PRIVACY</a> acconsentendo al trattamento dei miei dati
          personali ai fini dell'erogazione del servizio.
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 offset-md-1 mt-2">
        <div class="form-check custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="marketing_opt"
            name="marketing_opt"
            [(ngModel)]="marketing_opt"
          />
          <label class="custom-control-label text-reset" for="marketing_opt">
            Invio di offerte commerciali
          </label>
        </div>
        <small class="font-weight-light font-italic text-muted">
          Acconsento all'utilizzo dei miei dati personali per ricevere comunicazioni commerciali
          da parte di FITNESSGEST o altri soggetti terzi associati ad essa associati.
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 offset-md-1 mt-2">
        <div class="form-check custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="profiling_opt"
            name="profiling_opt"
            [(ngModel)]="profiling_opt"
          />
          <label class="custom-control-label text-reset" for="profiling_opt">
            Profilazione a fini commerciali
          </label>
        </div>
        <small class="font-weight-light font-italic text-muted">
          Acconsento la profilazione per motivi di marketing e commerciale della mia
          attività sul sito e sulla App mobile Webfit.
        </small>
      </div>
    </div>

    <div class="row mt-3 mb-5">
      <div class="col-12 col-md-7 col-lg-6 col-xl-4 mx-auto">
        <button type="submit" class="btn btn-outline-secondary-text-primary btn-block" (click)="updateSettings()">
          <span *ngIf="working" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Salva
        </button>
      </div>
    </div>

  </div>
</section>
