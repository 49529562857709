import _ from 'lodash';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {CorporateStorageService} from '../services/corporate-storage.service';

@Injectable()
export class CorporateGuard implements CanActivate {

  constructor(private corporateStorageService: CorporateStorageService) { }

  // @ts-ignore
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParamMap.has('gym_id')) {
      this.corporateStorageService.setGymId(route.queryParamMap.get('gym_id'));
    }

    const subscriptionIds = [];
    if (route.queryParamMap.has('subscription_id')) {
      subscriptionIds.push(route.queryParamMap.get('subscription_id'));
    }
    if (route.queryParamMap.has('subscription_ids')) {
      route.queryParamMap.get('subscription_ids')
        .split(/\s*,\s*/)
        .forEach(it => subscriptionIds.push(it));
    }
    if (subscriptionIds.length > 0) {
      this.corporateStorageService.setSubscriptionIds(_.uniq(subscriptionIds));
    }

    if (route.queryParamMap.has('registration_type')) {
      this.corporateStorageService.setRegistrationType(route.queryParamMap.get('registration_type'));
    }

    if (route.queryParamMap.has('payload')) {
      this.corporateStorageService.setPayloadParams(route.queryParamMap.get('payload'));
    }

    if (route.queryParamMap.has('redirect_to')) {
      this.corporateStorageService.setRedirectTo(route.queryParamMap.get('redirect_to'));
    }

    return true;
  }
}
