<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">FILTRA PER ABBONAMENTO</h4>
  </div>
  <div class="line-form form--light form--color-textborder mt-2">
    <label for="SubscriptionFilterInput">Scegli l'abbonamento:</label>
    <select id="SubscriptionFilterInput" class="form-control" [(ngModel)]="selectedUserService">
      <option hidden [ngValue]="undefined" disabled>-seleziona-</option>
      <option *ngFor="let us of userServices" [ngValue]="us" class="dropdown-item">
        {{ _formatLabel(us) }}
      </option>
    </select>
  </div>

  <div class="mt-3">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-outline-secondary-text-white" (click)="close()">Filtra</button>
      </div>
    </div>
  </div>
</div>
