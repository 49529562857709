import { Component, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentCard } from '../../../../../../../shared/models/payments.model';

@Component({
    selector: 'cp-set-default-payment-card-dialog',
    templateUrl: './set-default-dialog.component.html',
    styleUrls: [],
})
export class SetDefaultDialog {
    public loading: boolean = false;

    @Input() public card: PaymentCard;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

    constructor(public modal: NgbActiveModal) {}

    sub() {
        if (this.loading) return;
        this.loading = true;
        this.onSubmit.emit();
    }
}
