import { Component, Input, OnInit } from '@angular/core';
import {SubscriptionModel} from "../../models/subscription.model";
import {SubscriptionPriceService} from "../../services/subscription-price.service";

@Component({
  selector: 'cp-subscription-price',
  templateUrl: './subscription-price.component.html',
  styleUrls: ['./subscription-price.component.scss']
})
export class SubscriptionPriceComponent implements OnInit {
    @Input() subscription: SubscriptionModel

    isMonthly: boolean
    price: number
    associativePrice: number
    commission: number
    flexFirstPaymentPrice: number
    numDaysToPay: number

    constructor(
        public subscriptionPriceService: SubscriptionPriceService,) {
    }

  ngOnInit(): void {
      const components = this.subscriptionPriceService.getPriceComponents(this.subscription, this.subscription.promotion);
      this.isMonthly = components.isMonthly;
      this.price = components.price;
      this.associativePrice = components.associativePrice;
      this.commission = components.commission;
      this.flexFirstPaymentPrice = components.flexFirstPaymentPrice;
      this.numDaysToPay = components.numDaysToPay;
  }

}
