import { StyleTestComponent } from './core/components/style-test/style-test.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // {
  //   path: "test",
  //   pathMatch: "full",
  //   component: StyleTestComponent
  // },
  {   
    path: '**',
    redirectTo: 'login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
  routes,
    {
    enableTracing: false,
    anchorScrolling: "enabled",
    relativeLinkResolution: 'legacy'
}
  )],
  exports: [RouterModule],
  providers: [
  ]
})
export class AppRoutingModule { }
