import {Component, OnInit} from '@angular/core';
import { AccessService } from "../../../services/access.service";
import { NgForm } from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import {ConfigurationService} from '../../../../../shared/services/configuration.service';

@Component({
    selector: "cp-password-recovery-start",
    templateUrl: "./password-recovery-start.component.html",
    styleUrls: ["./password-recovery-start.scss"],
})
export class PasswordRecoveryStartComponent implements OnInit {
    public loading = false;
    public response: { success: boolean } = null;
    public customerServiceEmail: string = environment.customer_service.email;
    public customerServicePhone: string = environment.customer_service.phone;
    public customerServicePhoneForLink: string = environment.customer_service.phone_for_link;
    public customerServiceActiveTimeDescription = '';

    constructor(
      private accessService: AccessService,
      public configurationService: ConfigurationService
    ) { }

    ngOnInit(): void {
        this.configurationService.subscribeConfiguration().subscribe(info => {
            this.customerServiceActiveTimeDescription = info.customer_service_active_time;
        });
    }

    checkEmail(form: NgForm) {
        this.loading = true;
        this.accessService
            .recoverPassword(form.value.email)
            .subscribe((response) => {
                this.response = response;
                this.loading = false;
            });
    }
}
