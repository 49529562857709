<div class="container-fluid d-flex flex-column flex-fill">
  <div class="flex-column flex-fill flex-lg-row row">
    <div class="col-lg-4 p-0">
      <div class="mt-lg-10 my-3 ph-text">
        <div class="px-lg-3 px-xl-5 text-center text-lg-left">
          <h1 class="text-secondary">ACCEDI</h1>
          <div class="h4 text-primary">Compila i campi per continuare</div>
        </div>
        <div class="text line-form form--secondary form--color-textborder">
          <div class="gform_wrapper">
            <form #f="ngForm" (ngSubmit)="!loading ? (f.valid ? performLogin(f) : f.form.markAllAsTouched()) : null">
              <ul class="gform_fields px-lg-3 px-xl-5">
                <li>
                  <label for="EmailInput">Email</label>
                  <input
                    #email="ngModel"
                    type="email"
                    name="email"
                    id="EmailInput"
                    [ngModel]
                    email
                    [required]="true"
                    placeholder=""
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': (email.invalid && email.touched) || (errors && f.pristine)
                    }"
                    autofocus
                  />
                  <small class="invalid-tooltip" *ngIf="email.invalid && email.touched"> L'email non è valida </small>
                  <small class="invalid-tooltip" *ngIf="errors && f.pristine">
                    Le credenziali inserite non sono valide
                  </small>
                </li>
                <li>
                  <label for="PwdInput">Password</label>
                  <input
                    #password="ngModel"
                    type="password"
                    name="password"
                    id="PwdInput"
                    [ngModel]
                    [required]="true"
                    minlength="6"
                    placeholder=""
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': (password.invalid && password.touched) || (errors && f.pristine)
                    }"
                  />
                  <small
                    class="invalid-tooltip"
                    *ngIf="password.invalid && password.touched && !(errors && f.pristine)"
                  >
                    La password è richiesta (minimo 6 caratteri)
                  </small>
                  <small class="invalid-tooltip" *ngIf="errors && f.pristine">
                    Le credenziali inserite non sono valide
                  </small>
                </li>
                <li class="mt-5">
                  <button type="submit" class="btn btn-outline-secondary-text-primary btn-block">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading"
                    ></span>
                    Accedi
                  </button>
                </li>

                <li class="mt-2 text-center text-lg-left">
                  <a class="text-reset" routerLink="/password-recovery">Password sconosciuta o dimenticata?</a>
                </li>
                <li class="mt-2 text-center text-lg-left">
                  <a class="text-reset font-weight-bold" routerLink="/register">Non sei iscritto? Abbonati a Webfit!</a>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 flex-fill p-0">
      <div class="ph-image-mondo background"></div>
    </div>
  </div>
</div>
