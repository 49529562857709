<footer id="footer">
  <div class="footer-section footer-bottom">
    <div class="background has-color">
      <div class="background__color bg-primary"></div>
    </div>
    <div class="section-inner">
      <div class="container">
        <div class="row text-center text-sm-left footer-grid">
            <div class="col-lg-3 text-white">
                <style>#footer #menu-footer-menu>li {display:block;text-transform:uppercase}</style>
                <section class="widget nav_menu-2 widget_nav_menu">
                    <div class="menu-footer-menu-container">
                        <ul id="menu-footer-menu" class="menu">
                            <li id="menu-item-4799" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4799 nav-item">
                                <a [href]="corporateStorageService.getUrl('https://www.webfit.it/dati-societari/')">Dati Societari</a>
                            </li>
                            <li id="menu-item-4770" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-4770 nav-item">
                                <a rel="privacy-policy" [href]="corporateStorageService.getUrl('https://www.webfit.it/privacy-policy/')">Privacy Policy</a>
                            </li>
                            <li id="menu-item-4769" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4769 nav-item">
                                <a [href]="corporateStorageService.getUrl('https://www.webfit.it/cookie-policy/')">Cookie Policy</a>
                            </li>
                            <li id="menu-item-4771" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4771 nav-item">
                                <a [href]="corporateStorageService.getUrl('https://www.webfit.it/termini-e-condizioni/')">Termini e condizioni</a>
                            </li>
                        </ul>
                    </div>
                </section>
                <p class="copyright text-white mb-0">© 2024 - Tutti i diritti riservati</p>
                <div class="social-wrapper social-wrapper--links ">
                    <ul aria-label="Lista social network" class="social-list">
                        <li class="social-list__item social-list__item--facebook">
                            <a rel="noopener noreferrer" target="_blank" title="Visita il nostro profilo facebook"
                               [href]="corporateStorageService.getUrl('https://www.facebook.com/webfitofficial')">
                                <span class="icon icon-facebook">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                </span>
                            </a>
                        </li>
                        <li class="social-list__item social-list__item--instagram">
                            <a rel="noopener noreferrer" target="_blank" title="Visita il nostro profilo instagram"
                               [href]="corporateStorageService.getUrl('https://www.instagram.com/webfit_official/')">
                                <span class="icon icon-instagram">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <section class="widget text-3 widget_text">
                    <h3>SERVIZIO CLIENTI</h3>
                    <div class="textwidget">
                        <p>
                            {{ customerServiceActiveTimeDescription}}<br />
                            Tel: {{ customerServicePhone }} | {{ customerServiceEmail }}
                        </p>
                        <p>P. IVA 01268260310</p>
                    </div>
                </section>
            </div>
            <div class="col-md-6 col-lg-4 text-md-right">
                <section class="widget">
                </section>
            </div>
        </div>
      </div>
    </div>
  </div>
</footer>
