<section class="mt-3 mt-lg-10 flex-fill">
    <cp-waiting *ngIf="isPreloading"></cp-waiting>

    <div class="container" *ngIf="isReset && !isPreloading">
        <div class="row">
            <div class="col-12">
                <div *ngIf="isReset?.success">
                    <h1 class="text-secondary text-center">
                        RECUPERO PASSWORD
                    </h1>
                    <h4 class="text-primary text-center">
                        La password è stata reimpostata correttamente.
                    </h4>
                </div>
                <div *ngIf="!isReset?.success">
                    <h1 class="text-secondary text-center">
                        SI È VERIFICATO UN ERRORE!
                    </h1>
                    <h4 class="text-primary text-center">Riprova più tardi.</h4>
                </div>
            </div>
            <div class="mx-auto col-12 col-md-8 col-lg-6 col-xl-5 mt-3 mt-lg-5">
                <a
                    class="btn btn-outline-secondary-text-primary btn-block"
                    routerLink="/login"
                    autofocus
                >
                    Vai al login
                </a>
            </div>
        </div>
    </div>

    <div
        class="container"
        *ngIf="isVerified && !isVerified?.success && !isPreloading"
    >
        <div class="row">
            <div class="col-12">
                <h1
                    class="text-secondary text-center"
                    *ngIf="isVerified && !isVerified?.success"
                >
                    IL LINK INSERITO NON È PIÙ VALIDO
                </h1>
                <h4 class="text-primary text-center">Richiedine un'altro</h4>
            </div>
            <div class="mx-auto col-12 col-md-8 col-lg-6 col-xl-5 mt-3 mt-lg-5">
                <a
                    class="btn btn-outline-secondary-text-primary btn-block"
                    routerLink="/login"
                    autofocus
                >
                    Vai al login
                </a>
            </div>
        </div>
    </div>

    <div
        class="container"
        *ngIf="isVerified?.success && !isReset?.success && !isPreloading"
    >
        <div class="row">
            <div class="col-12 col-lg-8 col-md-10 col-xl-6 mx-auto">
                <h1 class="text-secondary text-center">RECUPERO PASSWORD</h1>
                <h4 class="text-primary text-center">
                    Non preoccuparti, succede ai migliori.<br />Inserisci la tua
                    nuova password.
                </h4>

                <div
                    class="text line-form form--secondary form--color-textborder"
                >
                    <div class="gform_wrapper">
                        <form
                            #f="ngForm"
                            (ngSubmit)="
                            !this.loading
                                ? f.valid && password.value === password_confirmation.value
                                    ? recoverPassword(f)
                                    : f.form.markAllAsTouched()
                                : null
                            "
                        >
                            <ul class="gform_fields">
                                <li>
                                    <label for="PasswordInput">Password</label>
                                    <input
                                        Id="PasswordInput"
                                        #password="ngModel"
                                        type="password"
                                        name="password"
                                        [ngModel]
                                        required
                                        minlength="6"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                password.invalid &&
                                                password.touched
                                        }"
                                        autofocus
                                    />
                                    <small
                                        class="invalid-tooltip"
                                        [hidden]="
                                            password.valid ||
                                            (password.untouched && !f.submitted)
                                        "
                                    >
                                        La password è richiesta (minimo 6
                                        caratteri).
                                    </small>
                                </li>
                                <li>
                                    <label for="PasswordConfirmationInput">
                                        Confirm<br />Password
                                    </label>
                                    <input
                                        id="PasswordConfirmationInput"
                                        #password_confirmation="ngModel"
                                        type="password"
                                        name="password_confirmation"
                                        [ngModel]
                                        required
                                        minlength="6"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (password_confirmation.invalid ||
                                                    password.value !==
                                                        password_confirmation.value) &&
                                                password_confirmation.touched
                                        }"
                                    />
                                    <small
                                        class="invalid-tooltip"
                                        [hidden]="
                                            password.value ===
                                                password_confirmation.value ||
                                            (password_confirmation.pristine &&
                                                !f.submitted)
                                        "
                                    >
                                        Le password inserite sono differenti
                                    </small>
                                </li>
                                <li class="row mt-5">
                                    <div class="col-md-6">
                                        <button
                                            type="submit"
                                            class="btn btn-outline-secondary-text-primary btn-block"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                                *ngIf="loading"
                                            ></span>
                                            Conferma
                                        </button>
                                    </div>
                                    <div class="col-md-6 mt-md-0 mt-1">
                                        <a
                                            class="btn btn-outline-primary btn-block"
                                            routerLink="/login"
                                        >
                                            Annulla
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
