import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { SuspensionModel } from '../../../../../../../shared/models/user.model';
import * as moment from 'moment';

@Component({
    selector: 'cp-client-suspension',
    templateUrl: './suspension.component.html',
    styleUrls: [],
})
export class SuspensionComponent implements OnInit, OnChanges {


    @Input() public deleteSuspension: (suspension: SuspensionModel) => void;
    @Input() public suspension: SuspensionModel;
    @Input() public subscriptionCategory: "flex"|"standard";

    public description: string;
    public startDate: string;
    public endDate: string;
    public status: string;
    private isFuture: boolean;
    public showDeleteButton: boolean;
    public isCurrent: boolean;

    constructor() {
    }

    ngOnInit() {
        this.recomputeValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.suspension) {
            this.recomputeValues();
        }
    }

    recomputeValues() {
        this.description = this.suspension.description;
        const start = moment(this.suspension.sub_sus_start_date);
        const end = moment(this.suspension.sub_sus_end_date);
        this.startDate = start.format('DD/MM/YYYY');
        this.endDate = end.format('DD/MM/YYYY');
        this.status = this.suspension.processed || 'create';
        const now = moment();
        this.isFuture = start.startOf('day').isAfter(now);
        this.isCurrent = start.startOf('day').isBefore(now)
            && end.startOf('day').isAfter(now);
        this.showDeleteButton = this.status === 'create' && this.isFuture && !!this.deleteSuspension;
    }

    _formatDateString(value: string | Date, fmt: string = 'DD/MM/YYYY', utc = false) {
        const date = utc ? moment.utc(value) : moment(value);
        return utc ? date.local().format(fmt) : date.format(fmt);
    }
}
