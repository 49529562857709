import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentDialog } from '../../../../../../shared/features/payment-dialog/payment-dialog.component';
import { UnexpectedErrorDialogComponent } from '../../../../../../shared/features/unexpected-error-dialog/unexpected-error-dialog.component';
import { PaymentCard } from '../../../../../../shared/models/payments.model';
import { ShowerProduct } from '../../../../../../shared/models/shower-product.model';
import { ShowersModel } from '../../../../../../shared/models/showers.model';
import { ProductService } from '../../../../../../shared/services/product.service';
import { UserService } from '../../../../../../shared/services/user.service';

@Component({
    selector: 'cp-showers-view',
    templateUrl: './showers-view.component.html',
    styleUrls: [],
})
export class ShowersViewComponent implements OnInit {
    @Input() showers: ShowersModel;
    @Output() reload: EventEmitter<void> = new EventEmitter();

    public expanded: boolean = false;
    public modalShown: boolean = false;
    public isBooking: boolean = false;

    public showerPacks: ShowerProduct[];
    public areShowerPacksLoaded: boolean = false;

    private paymentCards: PaymentCard[];

    constructor(
        private readonly productService: ProductService,
        private readonly userService: UserService,
        private readonly modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.userService.subscribePaymentCards().subscribe(cards => {
            this.paymentCards = cards;
        });
    }

    toggleExpand() {
        this.expanded = !this.expanded;
        if (this.expanded) this.fetchShowerPacks();
    }

    fetchShowerPacks() {
        if (this.areShowerPacksLoaded) return;
        this.productService.getAvailableShowerProducts().subscribe(
            res => {
                this.showerPacks = res.sort((a, b) => {
                    return a.total < b.total ? -1 : a.total == b.total ? 0 : 1;
                });
                this.areShowerPacksLoaded = true;
            },
            ex => {
                console.log(ex)
            }
        );
    }

    Buy(pack: ShowerProduct) {
        if (this.modalShown || this.isBooking) return;
        this.isBooking = true;
        this.productService.bookShowerProduct(pack).subscribe(
            virtual_prod => {
                this.isBooking = false;
                this.modalShown = true;
                let modalRef = this.modalService.open(PaymentDialog, { centered: true });
                let dialogRef = modalRef.componentInstance as PaymentDialog;
                dialogRef.onSubmit.subscribe(
                    () => {
                        //TODO Nice: Segna il virtual product come confermato
                        modalRef.close();
                    },
                    (ex) => {
                        modalRef.dismiss();
                        this.modalShown = true;
                        let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, {
                            centered: true,
                        });
                        ueRef.result.finally(() => {
                            this.modalShown = false;
                        });
                        (ueRef.componentInstance as UnexpectedErrorDialogComponent).error =
                            (ex.error ? ex.error?.message + '<br/>' : '')
                            + 'Controlla il tuo mezzo di pagamento e riprova più tardi.';
                    }
                );

                modalRef.result
                    .then(() => {
                        setTimeout(() => {
                            this.reload.emit();
                        }, 1500)
                    })
                    .catch(_ => {
                        console.log('Rejected!');
                        this.reload.emit();
                    })
                    .finally(() => {
                        this.modalShown = false;
                    });

                dialogRef.paymentCards = this.paymentCards;
                dialogRef.title = 'ACQUISTO DOCCE';
                dialogRef.label = pack.caption + ':';
                dialogRef.total = pack.price;
                dialogRef.intentClientSecret = virtual_prod.strp_payment_intent_client_secret;
            },
            ex => {
                this.isBooking = false;
                this.modalShown = true;
                let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, {
                    centered: true,
                });
                ueRef.result.finally(() => {
                    this.modalShown = false;
                });

                (ueRef.componentInstance as UnexpectedErrorDialogComponent).error =
                    ex.error.message +
                    '<br/>Se il problema persiste, rivolgiti al servizio clienti.';
            }
        );
    }
}
