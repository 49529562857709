import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentCard, PaymentCardEnriched } from '../../models/payments.model';

@Component({
    selector: 'cp-payment-card-component',
    templateUrl: './payment-card.component.html',
    styleUrls: [],
})
export class PaymentCardComponent implements OnInit, OnChanges {
    @Input() public card: PaymentCard;
    @Input() public actions: {
        key: string;
        value: {
            action: (card: PaymentCard) => void;
            disabled: () => boolean;
            ngIf: () => boolean;
        };
    }[] = [];
    @Input() public isSelected: boolean;
    @Input() public allowActions: boolean = false;
    @Input() public disableTooltip: boolean = false;

    public enrichedCard: PaymentCardEnriched;

    constructor(public modalService: NgbModal) {}

    ngOnInit() {
        this.enrichedCard = this._enrichCard(this.card);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.card) {
            this.enrichedCard = this._enrichCard(this.card);
        }
    }

    _enrichCard(card: PaymentCard): PaymentCardEnriched {
        const fundingFormatted = PaymentCardComponent._fmtFundingType(card.funding);
        let enriched: PaymentCardEnriched = {
            card: card,
            fundingFormatted: fundingFormatted,
        };
        const logoName = PaymentCardComponent._brandToLogoImg(card.brand);
        if (logoName !== undefined) {
            enriched.logoUrl = '/assets/images/payment-brands/' + logoName;
        }
        return enriched;
    }

    static _brandToLogoImg(brand) {
        switch (brand) {
            case 'American Express':
                return 'amex.svg';
            case 'Diners Clubs':
                return 'diners.svg';
            case 'Discover':
                return 'discover.svg';
            case 'JCB':
                return 'jcb.svg';
            case 'MasterCard':
                return 'mastercard.svg';
            case 'UnionPay':
                return 'unionpay.svg';
            case 'Visa':
                return 'visa.svg';
            default:
                return undefined;


        }
    }

    static _fmtFundingType(funding) {
        switch (funding) {
            case 'prepaid':
                return 'Prepagata';
            case 'credit':
                return 'Carta di Credito';
            case 'debit':
                return 'Carta di Debito';
            case 'unknown':
                return 'Sconosciuto';
        }
    }

    hasVisibleActions() {
        return this.actions?.some(a => a.value.ngIf())
    }
}
