import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {UserService} from '../../../../shared/services/user.service';
import {SubscriptionsService} from 'src/app/shared/services/subscriptions.service';
import {SubscriptionComponent} from './features/subscription/subscription.component';
import {ApiModule} from '../../../../core/api/api.module';
import {FormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SubscriptionRecapComponent} from './features/subscription-recap.component';
import {SharedModule} from '../../../../shared/shared.module';
import {MomentDateFormatter} from '../../../../core/formatters/moment-date.formatter';
import {SubscriptionPayComponent} from './features/subscription-pay/subscription-pay.component';
import {SubscriptionRoutingModule} from './subscription-routing.module';
import {UnsolvedsPayAtPurchaseComponent} from "./features/subscription-pay/unsolveds-pay-at-purchase/unsolveds-pay-at-purchase.component";
import {NgSelectModule} from '@ng-select/ng-select';
import { InvoiceListItemRecapComponent } from './features/subscription-pay/invoice-list-item-recap/invoice-list-item-recap.component';

@NgModule({
  declarations: [
    SubscriptionComponent,
    SubscriptionRecapComponent,
    SubscriptionPayComponent,
    UnsolvedsPayAtPurchaseComponent,
    InvoiceListItemRecapComponent,
  ],
  imports: [
    SubscriptionRoutingModule,
    CommonModule,
    HttpClientModule,
    ApiModule,
    FormsModule,
    NgbModule,
    RouterModule,
    SharedModule,
    NgSelectModule,
  ],
  providers: [
    SubscriptionsService,
    UserService,
    {
      provide: NgbDateParserFormatter,
      useClass: MomentDateFormatter
    },
    CurrencyPipe
  ]
})
export class SubscriptionModule {
}
