import { Injectable } from '@angular/core';
import {AbstractAuthorizationService} from './abstract-authorization.service';

@Injectable()
export class TokenStorageService implements AbstractAuthorizationService {
  constructor(
  ) { }

  setAccessToken(accessToken: string = ''): string {
    // console.log(`Saving token: ${accessToken}`);
    if (!accessToken) {
      localStorage.removeItem('access_token');
    } else {
      accessToken = accessToken.replace('Bearer', '').trim();
      localStorage.setItem('access_token', accessToken);
    }
    return accessToken;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    // console.log(`Getting token : ${accessToken}`);
    return accessToken;
  }

  clear() {
    this.setAccessToken();
  }
}
