import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiModule} from '../../core/api/api.module';
import {HttpClientModule} from '@angular/common/http';
import {AutoLogoutService} from './services/autologout.service';
import {LoggedInRoutingModule} from './logged-in-routing.module';
import {LogoutComponent} from '../../core/api/features/logout.component';
import {SubscriptionModule} from './features/subscription/subscription.module';
import {FreeTrialModule} from './features/free-trial/free-trial.module';
import {HomeModule} from './features/home/home.module';

@NgModule({
  declarations: [
    LogoutComponent,
  ],
  imports: [
    LoggedInRoutingModule,
    CommonModule,
    HttpClientModule,
    ApiModule,
    FreeTrialModule,
    SubscriptionModule,
    HomeModule,
  ],
  providers: [
    AutoLogoutService,
  ]
})
export class LoggedInModule {
}
