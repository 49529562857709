import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceObject } from '../../../../../../../shared/models/user.model';
import * as moment from 'moment';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
    selector: 'cp-checkout-dialog',
    templateUrl: './checkout-dialog.component.html',
    styleUrls: ['./checkout-dialog.component.scss'],
})
export class CheckoutDialog implements OnInit {
    @Input() userSubscription: UserServiceObject;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    public loading: boolean = false;
    public subCategory : "standard" | "flex";
    public sub_end_date: string;
    public sub_sus_end_date: string;
    public situation: string;

    constructor(public readonly modal: NgbActiveModal, private readonly helper: HelperService) {}

    ngOnInit(): void {
        this.subCategory = this.userSubscription.sub_info.subscription_category;
        this.situation = this.subCategory;
        if (this.subCategory === 'flex') {
            if (this.userSubscription.is_suspended) this.situation = this.situation + '_suspended';
            if (this.userSubscription.has_planned_suspensions) this.situation = this.situation + '_planned';
        }
        const today = moment().format('YYYY-MM-DD');
        let sub_sus_end_date;
        if (this.userSubscription.is_suspended) {
            sub_sus_end_date = (this.userSubscription.suspensions || [])
                .filter(susp =>
                    !susp.deleted_at
                    && susp.sub_sus_start_date <= today
                    && susp.sub_sus_end_date >= today
                )[0].sub_sus_end_date;
            this.sub_sus_end_date = moment(sub_sus_end_date).format('DD/MM/YYYY');
        }
        const expectedCheckoutDate = this.subCategory !== 'flex' || !this.userSubscription.is_suspended
            ? moment()
            : moment(sub_sus_end_date);
        this.sub_end_date = this.helper.guessUserServiceEndDateForCheckout(this.userSubscription, expectedCheckoutDate);
    }

    sub() {
        this.loading = true;
        this.onSubmit.emit();
    }
}
