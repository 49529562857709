<section class="border border-secondary rounded p-1 p-sm-2">
  <div ngbDropdown class="float-right" [placement]="['bottom-right', 'top-right']" *ngIf="showDeleteButton">
    <button
      ngbDropdownToggle
      class="btn btn-link btn-sm line-height-1 mr-n1 mt-n1 py-1 px-0 text-body no-after btn-icon btn-icon-white-shadowed"
      id="subscritionDropDown"
    >
      <i class="ci ci-ellipsis-vertical-white"></i>
    </button>

    <div ngbDropdownMenu aria-labelledby="subscritionDropDown">
      <button ngbDropdownItem (click)="deleteSuspension(suspension)">Elimina</button>
    </div>
  </div>

  <dl class="row mb-0">
    <dt class="col-12 col-sm-4 col-md-4">Descrizione</dt>
    <dd class="col-12 col-sm-8 col-md-8">
      <div [ngClass]="{ 'pr-sm-2': showDeleteButton }">
        {{ description || "-" }}
      </div>
    </dd>
    <dt class="col-12 col-sm-4 col-md-4">
      <ng-container *ngIf="status === 'create'">Pianificata</ng-container>
      <ng-container *ngIf="status !== 'create'">Durata</ng-container>
    </dt>
    <dd class="col-12 col-sm-8 col-md-8">
      {{ startDate }}
      <ng-container *ngIf="status !== 'create' || subscriptionCategory == 'flex'">
        -
        <span [ngClass]="{ 'text-warning': isCurrent }" *ngIf="isCurrent">In corso</span>
        <span *ngIf="!isCurrent">{{ endDate }}</span>
      </ng-container>
    </dd>
  </dl>
</section>
