<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">ELIMINA SOSPENSIONE</h4>
  </div>
  <p ngbAutofocus class="mt-2">Vuoi davvero eliminare questa sospensione?</p>

  <div class="mt-3">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-danger" (click)="sub()">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
          Prosegui
        </button>
      </div>
    </div>
  </div>
</div>
