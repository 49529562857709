import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AccessService } from "../../../services/access.service";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: "cp-password-recover",
    templateUrl: "./password-recover.component.html",
    styleUrls: ["./password-recover.component.scss"],
})
export class PasswordRecoverComponent implements OnInit {
    public isVerified;
    public isReset;
    private email: string;
    private token: string;
    public isPreloading: boolean = true;
    public loading: boolean = false;

    constructor(
        private accessService: AccessService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            this.email = paramMap.get("email");
            this.token = paramMap.get("token");

            if (!this.email || !this.token) {
                this.isVerified = { success: false };
            } else {
                this.accessService
                    .checkEmailAndToken(this.email, this.token)
                    .subscribe((response) => {
                        this.isVerified = response;
                        this.isPreloading = false;
                    });
            }
        });
    }

    recoverPassword(form: NgForm) {
        this.loading = true;
        this.accessService
            .resetPassword(this.email, this.token, form.value.password)
            .subscribe((response) => {
                this.isReset = response;
                this.loading = false;
            });
    }
}
