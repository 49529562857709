import {Injectable} from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../../../environments/environment';

@Injectable()
export class EnvFreeTrialGuard implements CanActivate {

  constructor(private router: Router) { }

  // @ts-ignore
  canActivate(state: RouterStateSnapshot): Observable<boolean> {

    if (environment.free_trial_visible) {
      return of(true);
    } else {
      this.router.navigate(['/subscription']);
      return of(false);
    }
  }
}
