<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">IMPOSTA CARTA PREDEFINITA</h4>
  </div>

  <p ngbAutoFocus class="mt2">Vuoi impostare la seguente carta come carta predefinita?</p>
  <p>
    <cp-payment-card-component [card]="card"></cp-payment-card-component>
  </p>
  <p>Eventuali abbonamenti rateali esistenti verranno addebitati su di essa.</p>

  <div class="mt-3">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-outline-secondary-text-white" [ngClass]="{'active': loading}" (click)="sub()">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
          Prosegui
        </button>
      </div>
    </div>
  </div>
</div>
