import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {SubscriptionsService} from 'src/app/shared/services/subscriptions.service';
import {ApiModule} from '../../../../core/api/api.module';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FreeTrialService} from './services/free-trial.service';
import {GymService} from '../../../../shared/services/gym.service';
import { FreeTrialEditorComponent } from './features/free-trial-editor/free-trial-editor.component';
import {FreeTrialRecapComponent} from './features/free-trial-recap.component';
import {FreeTrialRoutingModule} from './free-trial-routing.module';
import {SharedModule} from '../../../../shared/shared.module';
import { FreeTrialMainComponent } from './features/free-trial-main/free-trial-main.component';

@NgModule({
  declarations: [
    FreeTrialEditorComponent,
    FreeTrialRecapComponent,
    FreeTrialMainComponent,
  ],
  imports: [
    FreeTrialRoutingModule,
    CommonModule,
    HttpClientModule,
    ApiModule,
    FormsModule,
    NgbModule,
    SharedModule,
  ],
  providers: [
    SubscriptionsService,
    FreeTrialService,
    GymService,
  ]
})
export class FreeTrialModule {
}
