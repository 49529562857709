import {Component} from '@angular/core';

@Component({
  selector: 'cp-waiting',
  template: `
    <div class="waiting d-flex align-items-center justify-content-center">
      <cp-spinner></cp-spinner>
    </div>
  `,
  styles: [`
      .waiting {
          height: 650px;
      }
  `]
})
export class WaitingComponent {
};
