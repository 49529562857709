import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cp-unexpected-error-dialog',
    templateUrl: './unexpected-error-dialog.component.html',
    styleUrls: [],
})
export class UnexpectedErrorDialogComponent implements OnInit {
    @Input() error: string = null;

    constructor(public modal: NgbActiveModal) { }

    ngOnInit(): void {}
}
