<h3 class="mb-0 mt-1">
    {{ price.toFixed(2) }} €
    <small *ngIf="commission" ngbPopover="Spese di incasso" triggers="click" placement="bottom" class="pointer">
        + {{ commission.toFixed(2) }} €
    </small>
    <small *ngIf="isMonthly"> / mese </small>
    <ng-container *ngIf="associativePrice">
        <br />
        <span
            [ngbPopover]="'Quota associativa una tantum' + isMonthly ? ' addebitata alla prima rata' : ''"
            triggers="click"
            placement="bottom"
            class="pointer"
        >
            {{ associativePrice.toFixed(2) }} €
            <small>
                Quota Associativa
            </small>
        </span>
    </ng-container>
</h3>
