<section class="container mt-3 mt-lg-10">
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <h1 class="text-secondary text-center">CONTROLLA LA TUA EMAIL</h1>
            <h4 class="text-primary text-center">
                Segui le istruzioni che riceverai via email per completare la
                registrazione.
            </h4>
        </div>
    </div>
</section>
