
    <header id="header">
      <div class="header-wrap" id="header-wrap">
        <div class="navbar-brand">
          <a class="" href="https://www.webfit.it/" rel="home" itemprop="url">
            <img src="assets/images/logo.svg"
                 alt="The logo" itemprop="logo" class="img-fluid">
            <h1 class="sitename sr-only">Webfit</h1>
          </a>
        </div>
        <div class="navbar-wrap d-none d-lg-block">
          <nav class="navbar navbar-expand-md navbar-light">
            <ul class="navbar-nav">
              <!-- <li class="nav-item menu-item">
                <a class="nav-link"></a>
              </li> -->
            </ul>
          </nav>
        </div>
        <div class="navbar-buttons">
          <ul class="navbar-nav menu-buttons">
            <!-- <li class="nav-item menu-item">
              <a class="nav-link"></a>
            </li> -->
            <li class="nav-item menu-item menu-prova-gratuita">
              <div ngbDropdown *ngIf="authorizationService.isAuthenticated()">
                <button class="btn h5 line-height-1 mb-0 p-0 text-white" id="user_menu" ngbDropdownToggle>
                  <i class="ci ci-user-white"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="user_menu" class="dropdown-menu-right text-center">
                  <span class="dropdown-header">Loggato come <br/>{{user?.email}}</span>
                  <div class="dropdown-divider"></div>
                  <a ngbDropdownItem title="Impostazioni" [routerLink]="'/settings'">Impostazioni</a>
                  <div class="dropdown-divider"></div>
                  <button ngbDropdownItem title="Logout" (click)="authorizationService.logout()">Logout</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
