import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/layout/header/header.component';
import { FooterComponent } from './core/components/layout/footer/footer.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {XWebfitInterceptor} from './core/interceptors/x-webfit.interceptor';
import {ApiModule} from './core/api/api.module';
import {GuestModule} from './features/guest/guest.module';
import {LoggedInModule} from './features/logged-in/logged-in.module';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import {UserService} from './shared/services/user.service';
import {SharedModule} from './shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {CookieService} from 'ngx-cookie-service';
import {CookieAlertComponent} from "./core/components/layout/cookie-alert.component";
import {NgIdleModule} from '@ng-idle/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StyleTestComponent } from './core/components/style-test/style-test.component';


registerLocaleData(localeIt, 'it-IT', localeItExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CookieAlertComponent,
    StyleTestComponent,
  ],
  imports: [
  BrowserModule,
    ApiModule,
    SharedModule,
    LoggedInModule,
    GuestModule,
    AppRoutingModule,
    NgSelectModule,
    NgIdleModule.forRoot(),
    NgbModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XWebfitInterceptor,
      multi: true
    },
    UserService,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
