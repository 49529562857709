import { HelperService } from './../../../../../../../shared/services/helper.service';
import { InvoiceModel } from './../../../../../../../shared/models/invoices.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cp-invoice-list-item-recap',
    templateUrl: './invoice-list-item-recap.component.html',
    styleUrls: [],
})
export class InvoiceListItemRecapComponent implements OnInit {
    @Input() invoice: InvoiceModel;
    public formattedInvoice: InvoiceShow;

    constructor(private readonly helper: HelperService) {}

    ngOnInit(): void {
        this.formattedInvoice = {
            invoiceNumber: this.invoice.internal_number,
            total: (this.invoice.total / 100).toFixed(2) + ' €',
            description: this.helper.guessInvoiceDescription(this.invoice),
        };
    }
}

export class InvoiceShow {
    invoiceNumber: number;
    total: string;
    description: string;
}
