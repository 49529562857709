import {Component, Input, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';

import {UserService} from '../../../../../../../shared/services/user.service';
import {HelperService} from '../../../../../../../shared/services/helper.service';
import {GymService} from '../../../../../../../shared/services/gym.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import { UnsolvedObject } from '../../../../../../../shared/models/invoices.model';

@Component({
  selector: 'cp-client-unsolved',
  templateUrl: './unsolved.component.html',
  styleUrls: [],
})
export class UnsolvedComponent implements OnInit, OnChanges {

  private expanded: boolean = false;

  @Input()
  public unsolved: UnsolvedObject;
  @Input()
  public download: (unsolved: UnsolvedObject) => void;

  public totalUnsolvedValue: string;
  public unsolvedDate: string;

  constructor(
    private userService: UserService,
    private gymService: GymService,
    private helper: HelperService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.recomputeValues();
  }

  toggleExpandIfNotSelected() {
    const selection = window.getSelection();
    if ((selection.type !== undefined && selection.type != 'Range') || (selection.type === undefined && !selection.toString())) {
      this.toggleExpand();
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unsolved) {
      this.recomputeValues();
    }
  }

  recomputeValues() {
    this.totalUnsolvedValue = ((this.unsolved.invoice.total + this.unsolved.unsolved_invoice.total) / 100).toFixed(2) + ' €';
    this.unsolvedDate = this.getCreatedAtDate();
  }

  getCreatedAtDate() {
    return moment(this.unsolved.created_at).format('DD/MM/YYYY');
  }
}
