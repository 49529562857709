import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserService} from '../../../../../shared/services/user.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class CanFreeTrialGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userService.subscribe()
      .pipe(map(
        user => {
          if (user.trial_status.can_book_trial || user.trial_status.has_pending_trial)
            return true;
          this.canNotActivate();
        },
        error => this.canNotActivate()
      ));
  }

  private canNotActivate() {
    this.router.navigate(['/subscription']);
    return false;
  }
}
