import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AbstractAuthorizationService} from '../../../../../core/api/services/abstract-authorization.service';
import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {GymModel} from '../../../../../shared/models/gym.model';
import {environment} from '../../../../../../environments/environment';
import {Observable, of} from 'rxjs';
import {FreeTrialModel} from '../../../models/free-trial.model';
import {UserService} from '../../../../../shared/services/user.service';
import {HelperService} from '../../../../../shared/services/helper.service';
import {first, mergeMap, map, tap} from 'rxjs/operators';
import {Moment} from 'moment';
import { ListInterface } from '../../../../../shared/models/type.model';

@Injectable()
export class FreeTrialService extends AbstractAuthorizationService {

  selectedDate: Moment;
  selectedGym: GymModel;
  trials: FreeTrialModel[];
  times: string[] = [];
  cel: string;

  private availableDays: any;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private helper: HelperService,
    router: Router
  ) {
    super(router);
  }

  clear() {
    this.selectedGym = undefined;
    this.selectedDate = undefined;
    this.trials = undefined;
    this.cel = undefined;
  }

  canActivateTrial(): Observable<boolean> {
    return this.retrieveTrials().pipe(
      map(trials => !trials || trials.length === 0)
    );
  }

  retrieveTrials(): Observable<FreeTrialModel[]> {
    return this.http.post<ListInterface<FreeTrialModel>>(`${environment.apiUrl}/guest-pass/my`, {})
      .pipe(
        map(list => list.data),
        tap(trials => {
            this.trials = trials;
            return trials;
          }
        ));
  }

  private getDateForPicker(value = 0, unit: string = 'day'): NgbDate {
    // @ts-ignore
    return this.helper.getDateForPicker(moment().add(value, unit));
  }

  getTrialMinDate(): NgbDate {
    return this.getDateForPicker(0);
  }

  getTrialMaxDate(): NgbDate {
    return this.getDateForPicker(6, 'month');
  }

  setDate(date: Moment) {
    this.selectedDate = date;
    this.calculateOpenTimes();
  }

  setGym(gym: GymModel) {
    this.selectedGym = gym;
    this.calculateOpenTimes();
  }

  isDayAvailable(date: Moment) {
    return !this.availableDays || this.availableDays[date.format('YYYY-MM-DD')] === true;
  }

  retrieveOpenTimes(): Observable<string[]> {
    if (!this.selectedGym || !this.selectedDate) {
      return of([]);
    }
    return this.http.post<ListInterface<string>>(`${environment.apiUrl}/guest-pass/free-slots`, {gym_id: this.selectedGym.id, date: this.selectedDate.format('YYYY-MM-DD')})
      .pipe(map(list => list.data));
  }

  retrieveValidityCache() {
    if (!this.selectedGym) {
      return of([]);
    }
    const start = moment().format('YYYY-MM-DD');
    const end = moment().add(6, 'month').format('YYYY-MM-DD');
    return this.http.post<ListInterface<string>>(`${environment.apiUrl}/guest-pass/available-days`, {gym_id: this.selectedGym.id, start_date: start, end_date: end})
      .pipe(
        map(days => days.data),
        map(days => {
          const availableDays = {};
          for (const day of days) {
            availableDays[day] = true;
          }
          return availableDays;
        }));
  }

  setCel(cel: string) {
    this.cel = cel;
  }

  calculateOpenTimes() {
    this.times = [];
    this.retrieveOpenTimes().subscribe(times => {
      this.times = times;
    });
    this.retrieveValidityCache().subscribe(cache => {
      this.availableDays = cache;
    });
  }

  proceedWithTrial(values: any, id: string = null): Observable<FreeTrialModel> {
    const date = moment(values.date)
      .hour(values.time.split(':')[0])
      .minute(values.time.split(':')[1])
      .format('YYYY-MM-DD HH:mm:00');

    const params: any = {
      cel: this.cel,
      id
    };

    if (id) {
      params.object = {};
      params.delete = {};
      params.object.gym_id = this.selectedGym.id;
      params.object.date = date;
      params.object.fitness_type = values.fitness_type;
    } else {
      params.gym_id = this.selectedGym.id;
      params.date = date;
      params.notes = '';
      params.fitness_type = values.fitness_type;
    }

    return this.http.post<FreeTrialModel>(
      `${environment.apiUrl}/guest-pass/${id ? 'update' : 'create'}`, params).pipe(tap(
      response => {
        this.userService.retrieveUser();
        return response;
      },
      error => error
    ));
  }

  public getUserTrialById(id: string) {
    return this.http.post<ListInterface<FreeTrialModel>>(`${environment.apiUrl}/guest-pass/my`, {id})
      .pipe(
        mergeMap(list => {
          return list.data;
        }),
        first(trial => {
          return trial.id === id;
        })
      );
  }

  public getUserTrial() {
    return this.http.post<FreeTrialModel>(`${environment.apiUrl}/guest-pass/my-one`, {});
  }
}
