import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token-interceptor';
import {AuthorizationService} from './services/authorization.service';
import {TokenStorageService} from './services/token-storage.service';
import {CorporateStorageService} from './services/corporate-storage.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AuthorizationService,
    TokenStorageService,
    CorporateStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  declarations: []
})
export class ApiModule {
}
