import {Component, OnInit, OnDestroy} from '@angular/core';
import {SubscriptionsService} from 'src/app/shared/services/subscriptions.service';
import {UserService} from '../../../../../../shared/services/user.service';
import {UserModel} from '../../../../../../shared/models/user.model';
import {NgForm} from '@angular/forms';
import {SubscriptionModel} from '../../../../../../shared/models/subscription.model';
import * as moment from 'moment';
import {HelperService} from '../../../../../../shared/services/helper.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GymService} from '../../../../../../shared/services/gym.service';
import {forkJoin, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import { Cities } from '../cities';
import { PaymentCard } from '../../../../../../shared/models/payments.model';
import { CorporateStorageService } from '../../../../../../core/api/services/corporate-storage.service';
import { AutoLogoutService} from '../../../../services/autologout.service';

@Component({
  selector: 'cp-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: []
})
export class SubscriptionComponent implements OnInit, OnDestroy {

  public submitted = false;
  public user: UserModel;
  public userBirthday: NgbDateStruct;
  public userHasFutureSubscription;
  public firstSubscriptionPurchaseByUser;
  public shouldSellARenew;
  public selectedCard: PaymentCard;
  public allCards: PaymentCard[];
  public hasOtherCards: boolean;
  public showOtherCards = false;
  public useSavedCard = true;

  private userSub: Subscription;
  public cities = Cities;
  public error: any = {};
  public birthdayErrors: any = undefined;
  public preconditionsOk: boolean = null;
  public isUserFormReady = false;
  public isSubmitting = false;
  public minBirthday = this.helper.getDateForPicker(moment('1900-1-1'));
  public showDetailsText = false;

  public get loading(): boolean {
    return !this.isUserFormReady || this.isSubmitting || this.preconditionsOk === null;
  }

  constructor(
    public subscriptionsService: SubscriptionsService,
    public userService: UserService,
    public gymService: GymService,
    private helper: HelperService,
    private router: Router,
    private corporateStorageService: CorporateStorageService,
    private autoLogoutService: AutoLogoutService,
  ) {
  }

  ngOnInit() {
    this.userSub = this.userService.subscribe()
      .subscribe(user => {
        this.user = user;
        this.userBirthday = user.birthday ? this.helper.getDateForPicker(moment(user.birthday)) : undefined;
        this.userHasFutureSubscription = user.future_subscription;
        this.firstSubscriptionPurchaseByUser = !user.last_subscription;
        this.shouldSellARenew = user.last_subscription && moment(user.last_subscription.sub_end_date).endOf('day').isAfter(moment());
        if (user.active_card) {
          this.selectedCard = user.active_card;
        }
      });

    this.userService.retrieveUserForm().add(() => {
      this.isUserFormReady = true;
    });

    forkJoin([
      this.gymService.retrieveGyms(),
      this.subscriptionsService.retrieveSubscriptions(),
    ]).subscribe(() => {
      const gymIdsWithASub = this.subscriptionsService.subscriptions.map(s => s.gyms_id).reduce( (a, b) => a.concat(b), [] );
      this.gymService.refreshPreferredGym(g => gymIdsWithASub.includes(g.id));
      this.subscriptionsService.refreshSelectableSubscriptions(this.gymService.defaultGym);
      this.checkPreconditions();
    });
    this.userService.subscribePaymentCards()
    .pipe(map(cards => cards))
    .subscribe(cards => {
      this.allCards = cards;
      this.hasOtherCards = cards.some(card => !card.default);
    });
    this.autoLogoutService.resetIdle();
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  private checkPreconditions(): void {
    const subs = this.subscriptionsService.selectableSubscriptions;
    const counts = {
      sepa_standard_month: 0,
      sepa_standard_year: 0,
      card_standard_month: 0,
      card_standard_year: 0,
      sepa_flex: 0,
      card_flex: 0,
      other: 0,
    };
    subs.forEach(sub => {
      switch (sub.subscription_category) {
        case 'standard':
          if (sub.payment_method === 'card' && sub.interval === 'month') counts.card_standard_month++;
           else if (sub.payment_method === 'card' && sub.interval === 'year') counts.card_standard_year++;
           else if (sub.payment_method === 'sepa' && sub.interval === 'month') counts.sepa_standard_month++;
           else if (sub.payment_method === 'sepa' && sub.interval === 'year') counts.sepa_standard_year++;
           else counts.other++;
          break;
        case 'flex':
          if (sub.payment_method === 'card' && sub.interval === 'month') counts.card_flex++;
          else if (sub.payment_method === 'sepa' && sub.interval === 'month') counts.sepa_flex++;
          else counts.other++;
          break;
        default:
          counts.other++;
      }
    });
    this.preconditionsOk = counts.other === 0 && Object.values(counts).some(c => c > 0);
  }

  public getRedirectUrl(): string {
    const regType = this.corporateStorageService.getRegistrationType();
    if (regType === '/free-trial') {
      return 'http://www.webfit.it/prova-gratuita';
    }
    return 'http://www.webfit.it/abbonamento';
  }

  selectCard(card: PaymentCard) {
    this.selectedCard = card;
    this.showOtherCards = false;
  }

  changeSubscription(subscription: SubscriptionModel) {
    this.showDetailsText = false;
    this.subscriptionsService.selected = subscription;
    this.gymService.refreshPreferredGym();
  }

  changeGym(gymId: string) {
    this.subscriptionsService.refreshSelectableSubscriptions(this.gymService.getSelectableGym(gymId));
    this.checkPreconditions();
  }

  getMaxBirthDate() {
    return UserService.getMaxBirthDate();
  }

  updateAndCreateVirtualSubscription(form: NgForm) {
    this.isSubmitting = true;
    this.error = {};
    if (form.valid && !this.birthdayErrors && !this.submitted) {
      this.submitted = true;
      if (this.userService.hasToUpdateProfile()) {
        const userObj = JSON.parse(JSON.stringify(form.value));
        userObj.cgu = true;
        this.userService.update(userObj)
          .pipe(
            catchError(e => {
              Object.keys(e.error).forEach(k => {
                this.error[k] = e.error[k];
                form.controls[k].markAsPristine();
              });
              this.submitted = false;
              this.isSubmitting = false;
              return '';
            })
          )
          .subscribe(response => {
            this.createVirtualSubscription(form).add(() => {
              this.isSubmitting = false;
            });
          });
      } else {
        this.createVirtualSubscription(form);
      }
    }
  }

  private createVirtualSubscription(form: NgForm) {
    const queryParams: {card_id?: string} = {};
    const payment_needs_a_card = this.subscriptionsService.selected.subscription_category === 'flex' || this.subscriptionsService.selected.payment_method === 'card';
    if (payment_needs_a_card && this.useSavedCard && this.selectedCard) {
      queryParams.card_id = this.selectedCard.id;
    }

    return this.subscriptionsService.createVirtualSubscription(form.value)
      .subscribe(res => this.router.navigate([`/subscription/pay/${res.id}`], { queryParams }),
        x => this.error = true);
  }
}
