import { Injectable } from "@angular/core";
import { AbstractAuthorizationService } from '../../../core/api/services/abstract-authorization.service';
import { Router } from '@angular/router';
import { Idle, DocumentInterruptSource } from '@ng-idle/core';
import { CorporateStorageService } from '../../../core/api/services/corporate-storage.service';
import { AuthorizationService } from '../../../core/api/services/authorization.service';
import { environment } from '../../../../environments/environment';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class AutoLogoutService extends AbstractAuthorizationService {

    public timeouts : Subscription[] = []

    constructor(router: Router, private idle: Idle, private corporateStorageService: CorporateStorageService, private authorizationService: AuthorizationService) {
        super(router);

        idle.setIdle(environment.kiosk.idle_timeout_seconds);
        idle.setTimeout(environment.kiosk.logout_timeout_seconds);
        idle.setInterrupts([new DocumentInterruptSource('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll click')])
        idle.onTimeout.subscribe(() => { this.conditionalLogout(); });
        idle.onIdleStart.subscribe(() => {
            console.log('User is idle.');
        });
    }

    conditionalLogout() {
        if (this.shouldLogout()) {
            this.authorizationService.logout();
        }
    }

    logoutLater(timeout=environment.kiosk.recap_page_timeout_seconds) {
        if (this.shouldLogout()) {
            this.timeouts.push(
                new Observable<void>(subscriber => {
                    setTimeout(() => {
                        subscriber.next();
                        subscriber.complete();
                    }, timeout * 1000);
                }).subscribe(() => {
                    this.conditionalLogout();
                })
            );
        }
    }

    resetIdle() {
        if (this.shouldLogout()) {
            console.log('Restart watching user activity for idleness...');
            this.idle.watch();
        }
    }

    private shouldLogout() {
        return this.corporateStorageService.getAutoLogoutEnabled();
    }

    clear() {
        this.idle.stop();
        this.clearTimers();
        console.log('clear')
    }

    clearTimers() {
        this.timeouts.forEach(sub => sub.unsubscribe());
        this.timeouts = [];
    }
}