import { Component, ViewChild, EventEmitter, Output, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../../../../../../../shared/models/user.model';
import { UserService } from '../../../../../../../shared/services/user.service';
import { PaymentElementComponent } from '../../../../../../../shared/features/payment-element/payment-element.component';
import { SubscriptionsService } from '../../../../../../../shared/services/subscriptions.service';

@Component({
    selector: 'cp-add-card-modal',
    templateUrl: './add-card-dialog.component.html',
    styleUrls: [],
})
export class AddCardDialog implements OnInit {

    @Input()
    public user: UserModel;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

    public loading: boolean = false;
    public canPay: boolean = false;
    public setupIntentClientSecret: string = null;

    @ViewChild('paymentCardSelector', { static: true }) paymentCardSelector: PaymentElementComponent;

    constructor(public modal: NgbActiveModal, private userService: UserService, private readonly subscriptionsService: SubscriptionsService) { }

    ngOnInit(): void {
        this.loading = true;
        this.subscriptionsService.createSetupIntent().toPromise()
            .then(response => {
                this.setupIntentClientSecret = response.client_secret;
            }).catch(_ => {
                this.onSubmit.error('Si è verificato un errore.<br />Riprova più tardi.');
            }).finally(() => {
                this.loading = false;
            });
    }

    sub() {
        if (this.loading || !this.canPay) return;
        this.loading = true;
        this.paymentCardSelector.confirmIntent()
            .then(async _ => {
                this.loading = false;
                this.onSubmit.emit(/* card as PaymentCard */);
            })
            .catch(ex => {
                this.loading = false;
                this.onSubmit.error(
                    (ex.error?  ex.error?.message + '<br/>' : '') + 'Controlla il tuo mezzo di pagamento e riprova più tardi.'
                );
            });
    }

    onCanSubmitChange(ready) {
        this.canPay = ready;
    }
}
