import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {GymService} from '../../../../../../shared/services/gym.service';
import {FreeTrialService} from '../../services/free-trial.service';
import {GymModel} from '../../../../../../shared/models/gym.model';
import {NgForm} from '@angular/forms';
import {Moment} from 'moment';
import {CorporateStorageService} from '../../../../../../core/api/services/corporate-storage.service';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from '../../../../../../shared/services/helper.service';
import { AutoLogoutService } from '../../../../services/autologout.service';
import { FreeTrialModel } from '../../../../models/free-trial.model';
import * as moment from 'moment';

@Component({
  selector: 'cp-free-trial-editor',
  templateUrl: './free-trial-editor.component.html',
  styleUrls: [],
})
export class FreeTrialEditorComponent implements OnInit {

  public submitted = false;

  public freeTrialTime: string = null;
  public defaultDate: string = null;
  public loadingTimes: boolean = false;

  @Input() public freeTrial: FreeTrialModel;

  @Output() done = new EventEmitter<string|any>();


  constructor(
    public purchaseTrialService: FreeTrialService,
    public gymService: GymService,
    private corporateStorageService: CorporateStorageService,
    public helperService: HelperService,
    private autoLogoutService: AutoLogoutService,
  ) { }

  public dateErrors: any = null;

  ngOnInit() {
    if (!this.gymService.gyms || !this.gymService.gyms.length) {
      this.gymService.retrieveGyms().subscribe(res => {
        if (res.length == 0)
          this.done.emit("no gyms available")
        if (this.freeTrial) { 
          this.onGymChanged(this.gymService.getSelectableGym(this.freeTrial.gym.id));
        }
      });
    }
    if (this.freeTrial) { 
      let datetime = moment(this.freeTrial.date); 
      this.defaultDate = datetime.format('YYYY-MM-DD');
      this.onDateChange(datetime);
      this.freeTrialTime = datetime.format('hh:mm');
    }
    this.autoLogoutService.resetIdle();
  }

  onDateChange(date: Moment) {
    this.loadingTimes = true;
    this.purchaseTrialService.setDate(date);
    if (this.purchaseTrialService.times.indexOf(this.freeTrialTime) === -1) {
      this.freeTrialTime = null;
    }
    this.loadingTimes = false;
  }

  onGymChanged(gym: GymModel) {
    this.purchaseTrialService.setGym(gym);
  }

  onCelChange(cel: string) {
    this.purchaseTrialService.setCel(cel);
  }

  checkDisabledDate(date: NgbDate) {
    const momentDate = this.helperService.convertDateFromPicker(date);
    return !this.purchaseTrialService.isDayAvailable(momentDate);
  }

  createOrUpdateTrial(form: NgForm) {
    if (form.valid && !this.submitted) {
      this.submitted = true;

      this.purchaseTrialService.proceedWithTrial(form.value, this.freeTrial?.id ?? null)
        .subscribe(
          response => {
            if (this.corporateStorageService.getRedirectTo()) {
              window.location.href = this.corporateStorageService.getRedirectTo();
            } else {
              this.done.emit('next');
            }
          },
          err => {
            this.done.emit(err);
          }
        );
    }
  }
}
