import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NoLoginGuard} from '../../core/api/guards/no-login.guard';
import {PasswordRecoverComponent} from './features/password-recovery/password-recovery/password-recover.component';
import {PasswordRecoveryStartComponent} from './features/password-recovery/password-recovery-start/password-recovery-start.component';
import {RegisterComponent} from './features/registration/register.component';
import {LoginComponent} from './features/login/login.component';
import {VerifyNoticeComponent} from './features/verification/verify-notice.component';
import {VerifyActionComponent} from './features/verification/verify-action.component';
import {CorporateGuard} from '../../core/api/guards/corporate.guard';
import {EmailChangedNoticeComponent} from './features/email-changed-notice/email-changed-notice.component';
import {EmailVerifiedNoticeComponent} from './features/email-verified-notice/email-verified-notice.component';
import {KioskModeGuard} from '../../core/api/guards/kioskmode.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [CorporateGuard, KioskModeGuard, NoLoginGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [CorporateGuard, KioskModeGuard, NoLoginGuard]
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryStartComponent,
    canActivate: [NoLoginGuard]
  },
  {
    path: 'reset-password/:email/:token',
    component: PasswordRecoverComponent,
    canActivate: [NoLoginGuard]
  },
  {
    path: 'verify',
    component: VerifyNoticeComponent,
    canActivate: []
  },
  {
    path: 'verify/:id/:token/:type',
    component: VerifyActionComponent,
    canActivate: [CorporateGuard, KioskModeGuard]
  },
  {
    path: 'email-changed',
    component: EmailChangedNoticeComponent,
    canActivate: []
  },
  {
    path: 'email-verified',
    component: EmailVerifiedNoticeComponent,
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CorporateGuard, KioskModeGuard, NoLoginGuard]
})
export class GuestRoutingModule { }
