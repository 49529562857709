import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionsService } from 'src/app/shared/services/subscriptions.service';
import { environment } from '../../../../../../../environments/environment';
import { UserService } from '../../../../../../shared/services/user.service';
import { UserModel } from '../../../../../../shared/models/user.model';
import { CorporateStorageService } from '../../../../../../core/api/services/corporate-storage.service';
import { StripeElementsComponent } from '../../../../../../shared/features/stripe-elements.component';
import { PaymentCard } from 'src/app/shared/models/payments.model';
import { PaymentElementComponent } from 'src/app/shared/features/payment-element/payment-element.component';
import { InvoiceModel, UnsolvedObject } from 'src/app/shared/models/invoices.model';
import * as moment from 'moment';
import { UnexpectedErrorDialogComponent } from 'src/app/shared/features/unexpected-error-dialog/unexpected-error-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { SubscriptionPriceService } from 'src/app/shared/services/subscription-price.service';

@Component({
    selector: 'cp-subscription-pay',
    templateUrl: './subscription-pay.component.html',
    styleUrls: [],
})
export class SubscriptionPayComponent implements OnInit {
    isError = false;
    user: UserModel;
    priceComponents = null;
    paymentErrorMessage = '';

    subPaymentMethod: 'sepa' | 'card';
    flexStep: 'monthly' | 'first';
    subType: 'flex' | 'standard';
    @ViewChild('stripeComponent') stripeComponent: StripeElementsComponent;
    @ViewChild('paymentCardSelector') paymentElement: PaymentElementComponent;
    currentPaymentMethod: 'sepa' | 'card';

    private virtualSubscriptionId: string;

    public isPaying = false;

    public paymentCards: PaymentCard[];
    public isPaymentElementValid: boolean = false;

    public unsolveds: UnsolvedObject[];
    public openInvoices: InvoiceModel[];
    public amnestyToForgive: InvoiceModel;
    public openPaymentsReady: boolean | string = false;
    public intentClientSecret: string;
    public intentClientSecretType: 'paymentIntent' | 'setupIntent';

    public ready(): boolean {
        return (
            this.user &&
            this.openPaymentsReady === true &&
            this.openInvoices &&
            this.unsolveds &&
            this.paymentCards &&
            (!!this.subscriptionService.virtualSubscription || this.hasOpenPayments())
        );
    }

    public hasOpenPayments(): boolean {
        return this.openInvoices.length > 0 || this.unsolveds.length > 0;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public subscriptionService: SubscriptionsService,
        private subscriptionPriceService: SubscriptionPriceService,
        private corporateStorageService: CorporateStorageService,
        private userService: UserService,
        private readonly modalService: NgbModal,
        private readonly stripeService: StripeService
    ) {}

    ngOnInit(): void {
        this.userService.subscribe().subscribe(user => {
            this.user = user;
        });

        this.userService.subscribePaymentCards().subscribe(
            cards => {
                this.paymentCards = cards;
            },
            _ => {
                this.isError = true;
            }
        );

        this.route.paramMap.subscribe(async paramMap => {
            this.virtualSubscriptionId = paramMap.get('id');

            if (!this.virtualSubscriptionId) {
                this.router.navigate([environment.default_roots.logged]);
                return;
            }

            let virtualSub = this.subscriptionService.virtualSubscription;

            if (!virtualSub || virtualSub.id !== this.virtualSubscriptionId) {
                try {
                    virtualSub = await this.subscriptionService
                        .retrieveVirtualSubscription(this.virtualSubscriptionId)
                        .toPromise();
                } catch (ex) {
                    this.isError = true;
                    return;
                }
            }

            if (this.isVirtualSubscriptionExpired() || this.isVirtualSubsriptionConfirmed()) {
                this.router.navigate([environment.default_roots.logged]);
            }

            this.intentClientSecret = virtualSub.strp_payment_intent_client_secret;
            this.intentClientSecretType =
                virtualSub.user_service_object.sub_info.interval === 'year' ? 'paymentIntent' : 'setupIntent';

            let sub = virtualSub.user_service_object.sub_info;
            this.subType = sub.subscription_category;
            this.subPaymentMethod = sub.payment_method as any;
            if (this.subType == 'flex') {
                this.flexStep = 'monthly';
            }
            this.currentPaymentMethod = this.subscriptionService.virtualSubscription.payment_method;

            this.checkOpenPayments();

            this.updatePriceComponents();
        });
    }

    private updatePriceComponents() {
        const {
          isMonthly, price, associativePrice, commission, flexFirstPaymentPrice, numDaysToPay
        } = this.subscriptionPriceService.getPriceComponents(
          this.subscriptionService.virtualSubscription.user_service_object.sub_info,
          this.subscriptionService.virtualSubscription.promotion
        );

        if (this.subType === 'standard') {
            this.priceComponents = {
                text: isMonthly ? 'Primo addebito:' : 'Importo:',
                prices: [
                    {
                        value: price.toFixed(2),
                    },
                ],
            };
            if (associativePrice > 0) {
                this.priceComponents.prices.push({
                    value: associativePrice.toFixed(2),
                    text: '(quota associativa)',
                });
            }
            if (commission > 0) {
                this.priceComponents.prices.push({
                    value: commission.toFixed(2),
                    text: "(spese d'incasso)",
                });
            }
        } else if (this.flexStep === 'first' || this.subPaymentMethod === 'card') {
            this.priceComponents = {
                text: 'Primo addebito:',
                endingText: this.subPaymentMethod === 'card' ? 'Addebiti successivi: ' : '',
                basePrice: (price + commission).toFixed(2),
                prices: [
                    {
                        value: flexFirstPaymentPrice.toFixed(2),
                        text: `(${numDaysToPay} giorni flex)`,
                    },
                ],
            };
            if (associativePrice > 0) {
                this.priceComponents.prices.push({
                    value: associativePrice.toFixed(2),
                    text: '(quota associativa)',
                });
            }
        } else if (this.flexStep === 'monthly' && this.subPaymentMethod === 'sepa') {
            this.priceComponents = {
                text: 'Addebito mensile (al rinnovo):',
                prices: [
                    {
                        value: price.toFixed(2),
                    },
                ],
            };

            if (commission > 0) {
                this.priceComponents.prices.push({
                    value: commission.toFixed(2),
                    text: "(spese d'incasso)",
                });
            }
        }
    }

    private isVirtualSubscriptionExpired() {
        let virtualSub = this.subscriptionService.virtualSubscription;
        return moment(virtualSub.created_at).add(1, 'hour').isAfter(moment());
    }

    private isVirtualSubsriptionConfirmed() {
        let virtualSub = this.subscriptionService.virtualSubscription;
        return !!virtualSub.flex_monthly_intent_confirmation_at || !!virtualSub.intent_confirmation_at;
    }

    resolveInMs(milliseconds: number) {
        return new Promise((res, _) => {
            setTimeout(res, milliseconds);
        });
    }

    public async waitForFirstInvoiceGeneration() {
        let flexFirstPaymentReady = false;
        const timeout = moment().add(30, 's');
        do {
            try {
                await this.subscriptionService.retrieveVirtualSubscription(this.virtualSubscriptionId).toPromise();
            } catch (ex) {
                //NB: Ignoring errors. When timeout will be reached, an error will be shown.
                //I do not expect to fall there unless some critical bug is live
            }
            flexFirstPaymentReady = !!this.subscriptionService.virtualSubscription.strp_flex_first_month_payment_intent;
            if (!flexFirstPaymentReady && moment().isAfter(timeout)) {
                throw new FatalError('Timeout nella preparazione del pagamento immediato.');
            }
            if (!flexFirstPaymentReady) await this.resolveInMs(500);
        } while (!flexFirstPaymentReady);
    }

    public async manageMonthlyFlexStepEnd(monthlyPaymentMethod: string) {
        this.flexStep = 'first';
        this.intentClientSecret = this.subscriptionService.virtualSubscription.strp_payment_intent_client_secret;
        this.intentClientSecretType = 'paymentIntent';
        switch (this.subPaymentMethod) {
            case 'sepa':
                this.currentPaymentMethod = 'card';
                this.updatePriceComponents();
                this.isPaying = false;
                break;
            case 'card':
                try {
                    await this.stripeService.confirmCardPaymentIntentFromId(
                        monthlyPaymentMethod,
                        this.intentClientSecret
                    );
                } catch (e) {
                    throw new FatalError(
                        'Si è verificato un problema durante il pagamento:' +
                            e.message +
                            " ricominciare l'operazione da capo."
                    );
                }
                this.registerIntentConfirmationAndRedirect();
                break;
        }
    }

    displayFatalError(error: string) {
        let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, {
            centered: true,
        });
        ueRef.result.finally(() => {
            this.router.navigate(['subscription']);
        });
        (ueRef.componentInstance as UnexpectedErrorDialogComponent).error = error;
    }

    public async performPayment() {
        if (!this.isPaying && this.isPaymentElementValid) {
            if (this.isVirtualSubscriptionExpired()) {
                this.displayFatalError('La procedura di acquisto ha impiegato troppo tempo, ricomincia da capo.');
                return;
            }

            try {
                this.isPaying = true;
                this.paymentErrorMessage = '';
                const intent = await this.paymentElement.confirmIntent();
                switch (this.subType) {
                    case 'flex':
                        switch (this.flexStep) {
                            case 'monthly':
                                void this.tryRegisterIntentConfirmation();
                                await this.waitForFirstInvoiceGeneration();
                                await this.manageMonthlyFlexStepEnd(intent.setupIntent.payment_method);
                                break;
                            case 'first':
                                await this.registerIntentConfirmationAndRedirect();
                                break;
                        }
                        break;
                    case 'standard':
                        await this.registerIntentConfirmationAndRedirect();
                        break;
                }
            } catch (ex) {
                if (ex instanceof FatalError) {
                    this.displayFatalError(ex.message);
                } else if (ex instanceof Error) {
                    this.paymentErrorMessage = ex.message;
                } else {
                    console.error('Unexpected error paying with stripe', ex);
                    this.paymentErrorMessage = 'Errore inatteso durante la gestione del pagamento';
                }
                this.isPaying = false;
            }
        }
    }

    async tryRegisterIntentConfirmation(): Promise<boolean> {
        try {
            //NB: This is circumstantial instead of parametrized in order to simplify logics where this is called.
            //    This works because standard subscriptions may have only a setup intent or a payment intent.
            //    Flex subscriptions instead have a setup intent, and after confirming that, a new first flex payment
            //    intent is generated, so when we have it, then we surely have just confirmed
            await this.subscriptionService
                .registerIntentConfirmation(
                    this.virtualSubscriptionId,
                    this.subscriptionService.virtualSubscription.strp_flex_first_month_payment_intent ??
                        this.subscriptionService.virtualSubscription.strp_pending_setup_intent_id ??
                        this.subscriptionService.virtualSubscription.strp_payment_intent_id
                )
                .toPromise();
            return true;
        } catch (ex) {
            console.log(ex)

            if (ex.status == 410) {
                throw new FatalError(
                    ex.error.message ?? 'La procedura di acquisto ha impiegato troppo tempo, nessun addebito è stato effettuato, ricomincia da capo.'
                );
            }
            return false;
        }
    }

    async registerIntentConfirmationAndRedirect() {
        const isIntentConfirmationRegistrationSucceeded = await this.tryRegisterIntentConfirmation();
        const redirectToSite = this.corporateStorageService.getRedirectTo();
        if (redirectToSite) {
            this.userService.clearPayload().finally(() => {
                window.location.href = redirectToSite;
            });
        } else {
            const destination = `/subscription/recap`;
            const queryParams = {};
            if (this.amnestyToForgive) {
                queryParams['hasAmnestyToForgive'] = true;
            }
            if (!isIntentConfirmationRegistrationSucceeded) {
                queryParams['isIntentConfirmationRegistrationFailed'] = true;
            }
            this.router.navigate([destination], { queryParams });
        }
    }

    onPaymentElementValidityChange(isValid) {
        this.paymentErrorMessage = '';
        this.isPaymentElementValid = isValid;
    }

    checkOpenPayments() {
        this.openPaymentsReady = false;
        this.userService.clearOpenInvoices();
        this.userService.clearUnsolveds();
        this.userService.subscribeOpenInvoices().subscribe(
            invoices => {
                let hasToForgiveAnAmnesty =
                    this.subscriptionService.virtualSubscription.user_service_object.sub_info.associative_price > 0;

                this.openInvoices = invoices.filter(x => x.billing !== 'amnesty' || !hasToForgiveAnAmnesty);

                if (hasToForgiveAnAmnesty) {
                    let amnesties = invoices.filter(x => x.billing === 'amnesty');
                    if (amnesties.length > 1) {
                        this.isError = true;
                        return;
                    }
                    this.amnestyToForgive = amnesties[0] ?? null;
                }
                this.openPaymentsReady = this.openPaymentsReady === false ? 'openInvoices' : true;
            },
            _ => {
                this.isError = true;
            }
        );

        this.userService.subscribeUnsolveds().subscribe(
            unsolveds => {
                this.unsolveds = unsolveds;
                this.openPaymentsReady = this.openPaymentsReady === false ? 'unsolveds' : true;
            },
            _ => {
                this.isError = true;
            }
        );
    }
}

class FatalError extends Error {}
