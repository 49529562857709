import _ from 'lodash';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {CorporateStorageService} from '../services/corporate-storage.service';

@Injectable()
export class KioskModeGuard implements CanActivate {

  constructor(private corporateStorageService: CorporateStorageService) { }

  // @ts-ignore
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParamMap.has('kiosk')) {
      this.corporateStorageService.setAutoLogoutEnabled(route.queryParamMap.get('kiosk') !== 'off');
    }
    return true;
  }
}
