import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    private readonly stripe: any;

    constructor() {
        if (!environment.stripe || !environment.stripe.publishable_key) {
            console.error('Missing Stripe publishable key');
        } else {
            // @ts-ignore
            this.stripe = Stripe(environment.stripe.publishable_key);
        }
    }

    public getElements() {
        return this.stripe.elements();
    }

    public confirmCardPaymentIntentFromElements(
        paymentElement: any,
        clientSecret: string
    ): Promise<PaymentIntentResponse> {
        return this.stripe
            .confirmCardPayment(clientSecret, {
                payment_method: {
                    card: paymentElement,
                },
            })
            .then(StripeService.throwIfError);
    }

    public confirmCardSetupIntentFromElements(paymentElement: any, clientSecret: string): Promise<SetupIntentResponse> {
        return this.stripe
            .confirmCardSetup(clientSecret, {
                payment_method: {
                    card: paymentElement,
                },
            })
            .then(StripeService.throwIfError);
    }

    public confirmSepaDebitSetupIntentFromElements(
        paymentElement: any,
        intentClientSecret: string,
        user: any
    ): Promise<SetupIntentResponse> {
        return this.stripe
            .confirmSepaDebitSetup(intentClientSecret, {
                payment_method: {
                    sepa_debit: paymentElement,
                    billing_details: {
                        name: `${user.name} ${user.surname}`,
                        email: `${user.email}`,
                    },
                },
            })
            .then(StripeService.throwIfError);
    }

    public confirmCardSetupIntentFromId(
        paymentMethodId: string,
        intentClientSecret: string
    ): Promise<SetupIntentResponse> {
        return this.stripe
            .confirmCardSetup(intentClientSecret, {
                payment_method: paymentMethodId,
            })
            .then(StripeService.throwIfError);
    }

    public confirmCardPaymentIntentFromId(
        paymentMethodId: string,
        intentClientSecret: string
    ): Promise<PaymentIntentResponse> {
        return this.stripe
            .confirmCardPayment(intentClientSecret, {
                payment_method: paymentMethodId,
            })
            .then(StripeService.throwIfError);
    }

    private static throwIfError(stripeResult: any) {
        if (stripeResult.error) {
            throw new StripeError(StripeService.getError(stripeResult));
        }
        return stripeResult;
    }

    public static getError(response: any): string {
        if (response.error) {
            console.error(response.error);
            return 'Il metodo di pagamento inserito non è valido';
        }

        if (!response.stripe) {
            return '';
        }

        const stripe_params = response.stripe.stripe_params;
        const stripe_code = response.stripe.stripe_code;
        const stripe_declined = response.stripe.stripe_declined;

        if (stripe_params && !stripe_code && !stripe_declined) {
            return 'Errore nella comunicazione. Riprova più tardi';
        }

        switch (stripe_code) {
            case 'account_number_invalid':
                return 'Il codice della banca fornito non è valido';

            case 'amount_too_large':
                return 'La cifra da pagare è troppo alta';

            case 'amount_too_small':
                return 'La cifra da pagare è troppo bassa';

            case 'balance_insufficient':
                return 'Fondi insufficienti per completare la transazione';

            case 'bank_account_unusable':
                return 'La banca fornita non è utilizzabile per il pagamento';

            case 'bank_account_unverified':
                return 'La banca non è stata verificata';

            case 'card_declined':
                return 'La carta è stata rifiutata';

            case 'expired_card':
                return 'La carta risulta scaduta';

            case 'incorrect_address':
                // tslint:disable:quotemark
                return "L'indirizzo fornito non è corretto";

            case 'incorrect_cvc':
                return 'Il codice CVC inserito non è corretto';

            case 'incorrect_number':
                return 'Il numero di carta non è corretto';

            case 'incorrect_zip':
                return 'Il CAP fornito non è corretto';

            case 'invalid_card_type':
                return 'Il tipo di carta fornito non è utilizzabile';

            case 'invalid_cvc':
                return 'Il codice CVC inserito non è valido';

            case 'invalid_expiry_month':
                return 'Il mese di scadenza inserito non è corretto';

            case 'invalid_expiry_year':
                // tslint:disable:quotemark
                return "L'anno di scadenza inserito non è corretto";

            case 'invalid_number':
                return 'Il numero di carta inserito non è valido';
        }

        return '';
    }
}

export class StripeError extends Error {
    constructor(message: string) {
        super(message);
    }
}

type StripeResult = {
    error?: any;
    stripe?: any;
};

type PaymentIntentResponse = {
    paymentIntent?: {
        payment_method: string
    }
} & StripeResult

type SetupIntentResponse = {
    setupIntent?: {
        payment_method: string
    }
} & StripeResult


export type IntentResponse = PaymentIntentResponse & SetupIntentResponse;
