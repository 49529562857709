import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentCard } from '../../../../../../../shared/models/payments.model';

@Component({
    selector: 'cp-update-card-dialog',
    templateUrl: './update-card-dialog.component.html',
    styleUrls: [],
})
export class UpdateCardDialogComponent {
    private previousExpirationValue = '';
    public loading: boolean = false;
    public isDatePassed: boolean = false;
    @Input() public card: PaymentCard;
    @Output() onSubmit = new EventEmitter<{
        exp_month: number;
        exp_year: number;
    }>();

    constructor(public modal: NgbActiveModal) {}

    sub(f: NgForm) {
        if (this.loading) return;
        this.loading = true;
        this.onSubmit.emit({
            exp_month: parseInt((f.value.expiration as string).substr(0, 2)),
            exp_year: 2000 + parseInt((f.value.expiration as string).substr(3, 2)),
        });
    }

    onDateChange(expiration: NgModel) {
        let value = expiration.value as string;
        if (expiration.value != this.previousExpirationValue || !expiration.value) {
            if (this.previousExpirationValue.length < value.length) {
                switch (value.length) {
                    case 6:
                        expiration.control.setValue(this.previousExpirationValue);
                        break;
                    case 5:
                        const year_s2 = parseInt(value.substr(4, 1));
                        if (isNaN(year_s2))
                            expiration.control.setValue(this.previousExpirationValue);
                        else {
                            const year_2 = parseInt(value.substr(3, 2));
                            if (isNaN(year_2))
                                expiration.control.setValue(this.previousExpirationValue);
                        }
                        break;
                    case 4:
                        const year_1 = parseInt(value.substr(3, 1));
                        if (isNaN(year_1))
                            expiration.control.setValue(this.previousExpirationValue);
                        break;
                    case 3:
                        const divider = value.substr(2, 1);
                        if (divider != '/') {
                            if (['\\', '-'].indexOf(divider) != -1)
                                expiration.control.setValue(parseInt(value.substr(0, 2)) + '/');
                            else if (parseInt(divider))
                                expiration.control.setValue(value.substr(0, 2) + '/' + divider);
                        }
                        break;
                    case 2:
                        const month_s2 = parseInt(value.substr(1, 1));
                        if (isNaN(month_s2) || value == '00')
                            expiration.control.setValue(this.previousExpirationValue);
                        else {
                            const month_2 = parseInt(value.substr(0, 2));
                            if (month_2 <= 12) expiration.control.setValue(value + '/');
                            else
                                expiration.control.setValue(
                                    '0' + value.substr(0, 1) + '/' + value.substr(1, 1)
                                );
                        }
                        break;
                    case 1:
                        const month_1 = parseInt(value);
                        if (isNaN(month_1))
                            expiration.control.setValue(this.previousExpirationValue);
                        else if (month_1 >= 2) expiration.control.setValue('0' + value + '/');
                }
            } else {
                switch (value.length) {
                    case 3:
                        expiration.control.setValue(value.substr(0, 2));
                        break;
                }
            }

            if (expiration.valid) {
                const month = parseInt(value.substr(0, 2));
                const year = 2000 + parseInt(value.substr(3, 2));
                const exp = new Date(year, month - 1);
                const today = new Date();
                this.isDatePassed = today >= exp;
            } else {
                this.isDatePassed = false;
            }

            expiration.control.markAsTouched;
            expiration.control.markAsDirty;

            this.previousExpirationValue = expiration.value;
            expiration.control.updateValueAndValidity();
        }
    }
}
