export const environment = {
  production: false,
  domain: 'collaudo-customerportal-back.webfit.it',
  apiUrl: 'https://collaudo-customerportal-back.webfit.it/api',
  reset_password: 'https://collaudo-customerportal-back.webfit.it/reset-password/',
  xwebfit: 'N0hDdFY1WWRIVnFwNXA0dkZvVzVxNW1hNEg4aGRsQmtOd0w3TTVRQXdzT1dBWVlIb3FOdVRMY1c3T0p2ZkRjMg==',
  maps_api_key: 'AIzaSyBDaYXiJgFjekPES_nkZhy1z8eYhNipH5o',
  free_trial_visible: true,
  home_visible: true,
  default_roots: {
    not_logged: 'login',
    logged: 'home'
  },
  stripe: {
    publishable_key: 'pk_test_b2OUIwo0tPnInA2JqPsrQSBs002mjT2481',
  },
  kiosk: {
    idle_timeout_seconds: 90,
    logout_timeout_seconds: 30,
    recap_page_timeout_seconds: 10,
  },
  legal: {
    contractUrl: '/assets/legal/2024_02_contratto_FITNESSGEST.pdf',
    preContractualAgreementUrl: '/assets/legal/2023_12_informativa_precontrattuale_FITNESSGEST.pdf',
  },
  customer_service: {
    email: 'servizioclienti@webfit.it',
    phone: '0431.96276',
    phone_for_link: '+39043196276',
    active_time_description: 'Dal lunedì al venerdì 10-12',
  }
};
