import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/shared/services/user.service';
import {catchError} from 'rxjs/operators';
import {UnexpectedErrorDialogComponent} from 'src/app/shared/features/unexpected-error-dialog/unexpected-error-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'src/environments/environment';
import {SubscriptionModel} from 'src/app/shared/models/subscription.model';
import {CorporateStorageService} from '../../../../../../core/api/services/corporate-storage.service';
import {Location} from '@angular/common';

@Component({
  selector: 'cp-client-settings',
  templateUrl: './settings.component.html',
  styleUrls: [],
})
export class SettingsComponent implements OnInit, OnDestroy {

  private userSub: Subscription;
  public cgu: boolean;
  public privacy_consent: boolean;
  public marketing_opt: boolean;
  public profiling_opt: boolean;
  public working: boolean;
  public subscription: SubscriptionModel;
  public defaultPrecontractualAgreementUrl: string = environment.legal.preContractualAgreementUrl;

  constructor(
    public userService: UserService,
    private corporateStorageService: CorporateStorageService,
    private modalService: NgbModal,
    private location: Location
  ) {
    this.working = false;
  }

  ngOnInit() {
    this.userSub = this.userService.subscribe()
      .subscribe(user => {
        this.cgu = !!user.cgu;
        this.privacy_consent = !!user.privacy_consent;
        this.marketing_opt = !!user.marketing_opt;
        this.profiling_opt = !!user.profiling_opt;
        this.subscription = user.subscription || user.last_subscription;

        return user;
      });
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  getPreContractualDocsUrl(): string {
    return this.subscription?.active_promo_campaign?.precontractual_agreement_url ||
      this.subscription?.precontractual_agreement_url ||
      this.defaultPrecontractualAgreementUrl;
  }

  getPrivacyUrl(): string {
    return this.corporateStorageService.getUrl('https://www.webfit.it/privacy-policy/');
  }

  updateSettings() {
    this.working = true;
    this.userService.updatePrivacy(this.marketing_opt, this.profiling_opt)
      .subscribe(response => {
        this.working = false;
        this.location.back();
      }, err => {
        console.error(err);
        this.working = false;
        this.modalService.open(UnexpectedErrorDialogComponent, { centered: true });
      });
  }
}
