import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {TokenStorageService} from './token-storage.service';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';

const refreshTokenUrl = `${environment.apiUrl}/auth/token/refresh`;
const helper = new JwtHelperService();

const whitelistedDomains = [
  environment.domain,
  `${environment.domain}/api/auth/logout`,
];

const blacklistedRoutes = [
  refreshTokenUrl,
  `${environment.apiUrl}/auth/login`,
  `${environment.apiUrl}/auth/register`,
  `${environment.apiUrl}/auth/email`,
  `${environment.apiUrl}/auth/password/request`,
  `${environment.apiUrl}/auth/password/verification`,
  `${environment.apiUrl}/auth/password/reset`,
];

@Injectable()
export class AuthorizationService {

  constructor(
    private httpClient: HttpClient,
    private tokenStorageService: TokenStorageService,
    private router: Router,
  ) { }

  static getRefreshTokenUrl(): string {
    return refreshTokenUrl;
  }

  isWhitelistedDomain(req: HttpRequest<any>): boolean {
    return whitelistedDomains.reduce((acc, v) => acc || req.url.indexOf(v) !== -1, false);
  }

  isBlacklistedRoute(req: HttpRequest<any>): boolean {
    return blacklistedRoutes.reduce((acc, v) => acc || req.url.indexOf(v) !== -1, false);
  }

  isAuthenticated(): boolean {
    const token = this.tokenStorageService.getAccessToken();
    return !!token;
  }

  getToken(): Observable<string> {
    const token = this.tokenStorageService.getAccessToken();
    const isTokenExpired = helper.isTokenExpired(token);
    // console.log('token',   helper.getTokenExpirationDate(token), token);

    if (!isTokenExpired) {
      return of(token);
    }

    return this.refreshToken();
  }

  refreshToken(): Observable<string> {
    const expiredToken = this.tokenStorageService.getAccessToken();

    return this.httpClient
      .post(refreshTokenUrl, {}, {
        headers: new HttpHeaders()
          .set('Authorization', `Bearer ${expiredToken}`),
        responseType: 'text',
        observe: 'response'
      })
      .pipe(
        share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
        map(res => {
          return this.setToken(res.headers);
        })
      );
  }

  setToken(headers: HttpHeaders): string {
    const bearer = headers.get('Authorization');
    const newToken = this.tokenStorageService.setAccessToken(bearer);
    return newToken;
  }

  logout() {
    console.log('logout');
    this.router.navigate(['/logout']);
  }
}
