import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceObject } from '../../../../../../../shared/models/user.model';
import * as moment from 'moment';

@Component({
    selector: 'cp-subscription-selection',
    templateUrl: './subscription-selection-dialog.component.html',
    styleUrls: [],
})
export class SubscriptionSelectionDialog implements OnInit {
    @Input()
    public userServices: UserServiceObject[];
    @Input()
    public preselectedUserService: UserServiceObject;

    public selectedUserService: UserServiceObject;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() { }

    _formatLabel(us) {
        return (
            moment(us.sub_start_date).format('DD/MM/YYYY') +
            ' - ' +
            us.plan.name
        );
    }

    close() {
        this.modal.close(this.selectedUserService);
    }
}
