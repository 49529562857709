<cp-waiting *ngIf="!isError && !ready()"></cp-waiting>
<cp-unexpected-error *ngIf="isError"></cp-unexpected-error>

<section class="container mt-3 mt-lg-10" *ngIf="!isError && ready() && hasOpenPayments()">
  <cp-unsolveds-pay-at-purchase
    [user]="user"
    [paymentCards]="paymentCards"
    [openInvoices]="openInvoices"
    [unsolveds]="unsolveds"
    [amnestyToForgive]="amnestyToForgive"
    (done)="checkOpenPayments()"
  ></cp-unsolveds-pay-at-purchase>
</section>

<section class="container mt-3 mt-lg-10 mb-5" *ngIf="!isError && ready() && !hasOpenPayments()">
  <div class="text-center" *ngIf="currentPaymentMethod == 'sepa'">
    <h1 class="text-secondary">INSERISCI IL TUO IBAN</h1>
    <h4 class="text-primary">Inserisci il tuo IBAN al posto dell'esempio</h4>
  </div>
  <div class="text-center" *ngIf="currentPaymentMethod == 'card'">
    <h1 class="text-secondary" *ngIf="user.active_card != undefined">SELEZIONA IL METODO DI PAGAMENTO</h1>
    <h1 class="text-secondary" *ngIf="user.active_card == undefined">INSERISCI I DATI DELLA TUA CARTA</h1>
  </div>
  <h4 class="text-primary text-center" *ngIf="priceComponents">
    {{ priceComponents.text }}
    <span *ngFor="let price of priceComponents.prices; last as last">
      {{ price.value | currency: "€" }}
      <small *ngIf="price.text" [ngClass]="{'mr-2': !last}">{{ price.text }}</small>
      <span *ngIf="!last">+</span>
    </span>
  </h4>
    <h6 class="text-primary text-center" *ngIf="priceComponents.endingText">
        {{ priceComponents.endingText }}
        {{ priceComponents.basePrice | currency: "€" }}
    </h6>
  <h4 class="text-primary text-center" *ngIf="subscriptionService.virtualSubscription.user_service_object.sub_info.payment_custom_title">
    {{ subscriptionService.virtualSubscription.user_service_object.sub_info.payment_custom_title }}
  </h4>
  <div class="mt-3">
    <cp-payment-element
      #paymentCardSelector
      [intentClientSecret]="intentClientSecret"
      [intentClientSecretType]="intentClientSecretType"
      [paymentMethod]="currentPaymentMethod"
      [user]="user"
      [showFirstLabel]="false"
      [light]="true"
      [paymentCards]="paymentCards"
      (canSubmit)="onPaymentElementValidityChange($event)"
    ></cp-payment-element>

  </div>
  <div class="row mt-3">
    <div class="col-12 col-md-7 col-lg-6 col-xl-4 mx-auto">
      <button
        (click)="performPayment()"
        class="btn btn-outline-secondary-text-primary btn-block"
      >
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isPaying"></span>
        Effettua pagamento
      </button>
    </div>
    <div class="col-12">
      <ngb-alert *ngIf="paymentErrorMessage" class="text-center mt-3" [dismissible]="false" [type]="'danger'">
        Si è verificato un problema durante il pagamento: {{ paymentErrorMessage}}, riprova più tardi o con un altro metodo di pagamento
      </ngb-alert>
    </div>
  </div>
  <div class="bg-gray-500 rounded p-1 mt-6 col-12 col-xl-11 col-xxl-10 mx-auto" *ngIf="amnestyToForgive">
    <p class="text-white line-height-1 mb-1">
      Questa sanatoria verrà condonata se completi l'acquisto:
    </p>
    <div class="list-group">
      <div class="list-group-item border-bottom-0 border-top-0 border-left-0 border-right-0 p-1">
        <cp-invoice-list-item-recap [invoice]="amnestyToForgive"></cp-invoice-list-item-recap>
      </div>
    </div>
  </div>
</section>
